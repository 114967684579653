import { gettextLazy as _ } from "../../lib/translations.js";

export default {
  COLUMNS: [
    _("Indicator recalculated"),
    _("Justifications"),
    _("Explanatory information"),
    _("Quantitative impact of the recalculations on baseline"),
    _("Impact of the recalculations on national targets"),
  ],

  justifications: {
    methodology: _("Changes in methodology"),
    new_data: _("New and improved data"),
    corrections: _("Correction of errors in a previous version of the data"),
    other: _("Other adjustment"),
  },
};

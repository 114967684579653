var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'P4-comment-reply': _vm.index !== 0 },attrs:{"id":_vm.comment.id}},[_c('div',{class:{ 'P4-comment ml-6': _vm.index !== 0, 'P4-comment': _vm.index === 0 }},[_c('div',{directives:[{name:"translate",rawName:"v-translate",value:({
        html_start_small_author: `<small class='P4-comment-author'>`,
        html_start_small_date: `<small class='P4-comment-date'>`,
        html_start_small: `<small>`,
        html_end_small: `</small>`,
        email: _vm.comment.author.email,
        nameTag: _vm.nameTag,
        created: _vm.comment.created,
      }),expression:"{\n        html_start_small_author: `<small class='P4-comment-author'>`,\n        html_start_small_date: `<small class='P4-comment-date'>`,\n        html_start_small: `<small>`,\n        html_end_small: `</small>`,\n        email: comment.author.email,\n        nameTag: nameTag,\n        created: comment.created,\n      }"}],staticClass:"mx-8",attrs:{"render-html":"true"}},[_vm._v(" %{ html_start_small_author } %{ email } %{ html_end_small } %{ html_start_small } %{ nameTag } %{ html_end_small } commented on %{ html_start_small_date } %{ created } %{ html_end_small } ")]),_c('div',{staticClass:"P4-comment-content"},[(_vm.edit && !_vm.isReadOnly)?_c('div',[_c('FormulateForm',{staticClass:"bg-white text-black px-4 py-3",on:{"submit":_vm.submitEditComment}},[_c('FormulateInput',{attrs:{"type":"textarea","name":"message","value":_vm.comment.message,"validation":"required"}}),_c('FormulateInput',{attrs:{"type":"hidden","name":"id","value":_vm.comment.id}}),_c('FormulateInput',{attrs:{"type":"submit","label":_vm.$gettext('Save')}})],1)],1):_c('span',[_vm._v(_vm._s(_vm.comment.message))])]),(_vm.loggedUser === _vm.comment.author.email)?_c('button',{staticClass:"font-semibold hover:bg-blue-400",on:{"click":function($event){$event.preventDefault();_vm.edit = !_vm.edit}}},[(_vm.edit)?_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Cancel ")]):_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Edit ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <FormulateInput
    type="multitable"
    name="t2"
    :column-count="6"
    :columns="[
      $gettext('Change in the indicator'),
      $gettext('Drivers: Direct (Choose one or more items)'),
      $gettext('Drivers: Indirect (Choose one or more items)'),
      $gettext(
        'Which levers are being used to reverse negative trends and enable transformative change?'
      ),
      $gettext('Responses that led to positive RLI trends'),
      $gettext('Comments'),
    ]"
    :label-for-new="$gettext('+ Add Record')"
    :row-factory="rowFactory"
  >
    <template #cells="{ rowId, value }">
      <td class="P4-cell">
        <P4Input
          :id="rowId + '-change'"
          v-model="value.change"
          :options="OPTIONS.T2.change"
        />
      </td>
      <td class="P4-cell">
        <template v-if="value.change == 'negative'">
          <RankingSelect
            v-model="value['drivers_direct']"
            :select-key="rowId + '-drivers_direct-'"
            :select-options="OPTIONS.T2.drivers_direct"
          />
        </template>
      </td>
      <td class="P4-cell">
        <template v-if="value.change == 'negative'">
          <RankingSelect
            v-model="value['drivers_indirect']"
            :select-key="rowId + '-drivers_indirect-'"
            :select-options="OPTIONS.T2.drivers_indirect"
          />
        </template>
      </td>
      <td class="P4-cell">
        <template v-if="value.change == 'negative'">
          <RankingSelect
            v-model="value['change_levers']"
            :select-key="rowId + '-change_levers-'"
            :select-options="OPTIONS.T2.change_levers"
          />
        </template>
      </td>
      <td class="P4-cell">
        <template v-if="value.change == 'positive'">
          <RankingSelect
            v-model="value['change_responses']"
            :select-key="rowId + '-change_responses-'"
            :select-options="OPTIONS.T2.change_responses"
          />
        </template>
      </td>
      <td class="P4-cell">
        <P4Input :id="rowId + '-comments'" v-model="value.comments" textarea />
      </td>
    </template>
  </FormulateInput>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import RankingSelect from "@/lib/forms/RankingSelect";

function rowFactory() {
  return {
    drivers_direct: {},
    drivers_indirect: {},
    change_levers: {},
    change_responses: {},
  };
}

export default {
  components: {
    RankingSelect,
  },
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS() {
      return OPTIONS;
    },
    rowFactory,
  },
};
</script>

import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  BASELINE: {
    land_area_with_degraded_land_productivity: {
      name: _("Land area with degraded land productivity"),
      tooltip: _(
        "Area degraded based on land productivity dynamics in the baseline period"
      ),
    },
    land_area_with_land_productivity_not_degraded: {
      name: _("Land area with non-degraded land productivity"),
      tooltip: _(
        "Area not degraded based on land productivity dynamics in the baseline period"
      ),
    },
    land_area_with_no_data_for_land_productivity: {
      name: _("Land area with no land productivity data"),
      tooltip: _(
        "Area not reported due to a lack of valid land productivity dynamics data"
      ),
    },
  },
  REPORTING: {
    land_area_with_improved_land_productivity: {
      name: _("Land area with improved land productivity"),
      tooltip: _(
        "Area improved with respect to land productivity in the reporting period"
      ),
    },
    land_area_with_stable_land_productivity: {
      name: _("Land area with stable land productivity"),
      tooltip: _(
        "Area stable with respect to land productivity in the reporting period"
      ),
    },
    land_area_with_degraded_land_productivity: {
      name: _("Land area with degraded land productivity"),
      tooltip: _(
        "Area degraded with respect to land productivity in the reporting period"
      ),
    },
    land_area_with_no_data_for_land_productivity: {
      name: _("Land area with no land productivity data"),
      tooltip: _(
        "Area not reported due to a lack of valid land productivity data"
      ),
    },
  },
};

<template>
  <section>
    <FormulateInput
      type="multitable"
      :name="name"
      :columns="[''].concat(headingNamesKm).concat($gettext('No data (km²)'))"
    >
      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          {{ value.year }}
        </th>
        <td
          v-for="(cls, index) in headingKeys"
          :key="`${cls}-${index}`"
          class="P4-cell-right"
        >
          <P4Input
            :id="rowId + '-' + cls"
            v-model="value[cls]"
            :default-value-has-changed="defaultValueHasChanged(
              ['t5', rowIndex, cls],
              value[cls]
            )"
            numeric
          />
        </td >
        <td class="P4-cell-right">
          <P4Input :id="rowId + '-nodata'" v-model="value.nodata" numeric />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import T3HeadingsMixIn from "@/forms/so1/1/customT3HeadingsMixIn";
import defaultValuesChange from '../../../lib/mixins/defaultValuesChange';

export default {
  mixins: [defaultValuesChange, T3HeadingsMixIn],
};
</script>

<template>
  <td>
    <div v-if="value.polygon" :id="'polygon-' + value.polygon">
      <a
        v-if="value.polytype === 'Point'"
        v-translate
        :href="polygonUrl"
        class="rounded-empty-btn-sm print-hide whitespace-nowrap"
      >
        Point
      </a>
      <a
        v-else
        v-translate
        :href="polygonUrl"
        class="rounded-empty-btn-sm print-hide whitespace-nowrap"
      >
        Polygon
      </a>
      <span
        v-if="value.polytype === 'Point'"
        v-translate
        class="print-show whitespace-nowrap"
      >
        Point
      </span>
      <span v-else v-translate class="print-show whitespace-nowrap">
        Polygon
      </span>
    </div>
  </td>
</template>

<script>
export default {
  name: "PolygonCell",
  props: {
    value: {
      type: Object,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
  },
  computed: {
    layerType() {
      return window.hydrate.spatial_tables[this.tableId];
    },
    polygonUrl() {
      const url = new URL(window.hydrate.urls.country_maps, window.location);
      url.searchParams.set("selectedLayer", this.layerType);
      url.searchParams.set("featureId", this.value.polygon);
      return url.toString();
    },
  },
};
</script>

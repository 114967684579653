<template>
  <section>
    <FormulateInput type="multitable" name="t1" :column-count="5">
      <template #table_header>
        <tr>
          <th class="P4-cell-border" colspan="2"></th>
          <th v-translate class="P4-cell-border" colspan="2">
            Total Amount USD
          </th>
        </tr>
        <tr>
          <th
            v-for="(column, index) in [
              $gettext('Provided / Received'),
              $gettext('Year'),
              $gettext('Committed'),
              $gettext('Disbursed / Received'),
            ]"
            :key="index"
            class="P4-cell-border"
          >
            {{ column }}
          </th>
        </tr>
      </template>

      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          {{ value.provided_received | capitalize }}
        </td>

        <td class="P4-cell text-right">
          {{ value.year }}
        </td>

        <td class="P4-cell">
          <div :class="{ hidden: !isProvided(value) }">
            <span v-translate>Committed</span>
            <P4Input
              :id="rowId + '-total_amount_usd_provided_commited'"
              v-model="value.total_amount_usd_provided_commited"
              numeric
            />
          </div>
          <div :class="{ hidden: !isReceived(value) }">
            <span v-translate>Committed</span>
            <P4Input
              :id="rowId + '-total_amount_usd_received_commited'"
              v-model="value.total_amount_usd_received_commited"
              numeric
            />
          </div>
        </td>
        <td class="P4-cell">
          <div :class="{ hidden: !isProvided(value) }">
            <span v-translate>Disbursed</span>
            <P4Input
              :id="rowId + '-total_amount_usd_disbursed'"
              v-model="value.total_amount_usd_disbursed"
              numeric
            />
          </div>
          <div :class="{ hidden: !isReceived(value) }">
            <span v-translate>Received</span>
            <P4Input
              :id="rowId + '-total_amount_usd_received'"
              v-model="value.total_amount_usd_received"
              numeric
            />
          </div>
        </td>
      </template>

      <template #table_footer="{ values }">
        <tr>
          <th v-translate class="P4-cell" colspan="2">
            Total resources provided:
          </th>
          <td colspan="1" class="P4-cell text-right">
            <P4DisplayValue
              :value="calculateTotal(values, 'total_amount_usd_provided_commited')"
            />
          </td>
          <td colspan="1" class="P4-cell text-right">
            <P4DisplayValue
              :value="calculateTotal(values, 'total_amount_usd_disbursed')"
            />
          </td>
        </tr>

        <tr>
          <th v-translate class="P4-cell" colspan="2">
            Total resources received:
          </th>
          <td colspan="1" class="P4-cell text-right">
            <P4DisplayValue
              :value="calculateTotal(values, 'total_amount_usd_received_commited')"
            />
          </td>
          <td colspan="1" class="P4-cell text-right">
            <P4DisplayValue
              :value="calculateTotal(values, 'total_amount_usd_received')"
            />
          </td>
        </tr>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import SO5_OPTIONS from "../SO5_OPTIONS";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import { calculateTotal } from "../../../lib/calculateTotal.js";

export default {
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    SO5_OPTIONS: () => SO5_OPTIONS,
    calculateTotal: () => calculateTotal,
    isProvided() {
      return function (value) {
        return value["provided_received"] === "provided";
      };
    },
    isReceived() {
      return function (value) {
        return value["provided_received"] === "received";
      };
    },
  },
};
</script>

import { fromLonLat } from "ol/proj";

const OVERRIDE_MAP_VIEWPORT = {
  FJI: { center: [178.88, -17.15], zoom: 7 },
  RUS: { center: [103.0, 60.0], zoom: 2 },
  USA: { center: [-114.9, 49.0], zoom: 3 },
  NZL: { center: [168.57, -41.0], zoom: 4 },
  KIR: { center: [-168.5, -1.0], zoom: 5 },
};

export default function fitView(view, borderSource, iso3) {
  const override = OVERRIDE_MAP_VIEWPORT[iso3];
  if (override) {
    const center = fromLonLat(override.center, "EPSG:3857");
    view.setCenter(center);
    view.setZoom(override.zoom);
  } else {
    const extent = borderSource.getExtent();
    view.fit(extent, { padding: [10, 10, 10, 10] });
  }
}

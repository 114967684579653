import { gettextLazy as _ } from "../../lib/translations";

export const contextualPurposesDisclaimer = _(
  "The map is provided for contextual purposes only. The secretariat of the" +
  " United Nations Convention to Combat Desertification (UNCCD) is not" +
  " liable and cannot be held liable for any material or information" +
  " contained within the map and is exempt from any disputes arising" +
  " thereof."
);

export const noOpinionDisclaimer = _(
  "The designations employed and the presentation of material on" +
  " this map do not imply the expression of any opinion whatsoever on the" +
  " part of the Secretariat of the United Nations Convention to Combat Desertification (UNCCD) concerning the legal" +
  " status of any country, territory, city or area or of its authorities, or concerning" +
  " the delimitation of its frontiers or boundaries."
);

export const terrestrialAreaDisclaimer = _(
  "All maps represent the terrestrial area of the country; offshore islands," +
  " overseas departments and territories may not be displayed due to" +
  " cartographic limitations."
);

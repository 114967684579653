<template>
  <section>
    <ExposedTable name="t1" :population-type="$gettext('Exposed population')" />
  </section>
</template>

<script>
import ExposedTable from "@/forms/so3/2/ExposedTable";
export default {
  components: { ExposedTable },
};
</script>

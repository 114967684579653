import { translate } from "vue-gettext";

const { gettext: $gettext, gettextInterpolate } = translate;

export default {
  maxTotal({ args }) {
    return gettextInterpolate(
      $gettext("Total must be less than or equal to %{total}"),
      {
        total: args[1],
      }
    );
  },
  uniqueCustomLandcover() {
    return $gettext("Country legend class value is not unique.");
  },
  roundTo2() {
    return $gettext("The value will be rounded to 2 decimal places.");
  },
  number16Bit() {
    return $gettext("Value must be a 16-bit integer number");
  },
  number() {
    return $gettext("Value must be a number.");
  },
  percent() {
    return $gettext("Percent must be less than or equal to 100.");
  },
  year({ args }) {
    let start = args[0];
    let end = args[1];
    if (typeof args[0] === "undefined") {
      start = 2018;
    }
    if (typeof args[1] === "undefined") {
      end = 2100;
    }
    return gettextInterpolate(
      $gettext("Year must be an integer between %{start} and %{end}."),
      { start, end }
    );
  },
  max({ args }) {
    return gettextInterpolate(
      $gettext("Value must be less than %{maxValue}."),
      {
        maxValue: args[0],
      }
    );
  },
  mime({ args }) {
    return gettextInterpolate($gettext("File must be of the type %{values}."), {
      values: args.map((i) => i.split("/")[1]).join(", "),
    });
  },
  required() {
    return $gettext("Value is required");
  },
  integer() {
    return $gettext("Value must be an integer");
  },
};

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t2"
      :column-count="8"
      :label-for-new="$gettext('+ Add Record')"
    >
      <template #table_header>
        <tr>
          <th v-translate colspan="2">Land Conversion</th>
          <th v-translate colspan="6">
            Soil organic carbon (SOC) stock change in the baseline period
          </th>
        </tr>
        <tr>
          <th
            v-for="(column, index) in [
              $gettext('From'),
              $gettext('To'),
              $gettext('Net area change (km²)'),
              $gettext('Initial SOC stock (t/ha)'),
              $gettext('Final SOC stock (t/ha)'),
              $gettext('Initial SOC stock total (t)'),
              $gettext('Final SOC stock total (t)'),
              $gettext('SOC stock change (t)'),
            ]"
            :key="index"
            class="P4-cell"
          >
            {{ column }}
          </th>
        </tr>
      </template>
      <template #cells="{ rowId, rowIndex, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-from'"
            v-model="value.from"
            :default-value-has-changed="
              defaultValueHasChanged(['t2', rowIndex, 'from'], value.from)
            "
            :options="UNCCD_CLASS_MAP"
            @input="handleDropdownChange(value, rowIndex)"
          />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-to'"
            v-model="value.to"
            :default-value-has-changed="
              defaultValueHasChanged(['t2', rowIndex, 'to'], value.to)
            "
            :options="UNCCD_CLASS_MAP"
            @input="handleDropdownChange(value, rowIndex)"
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-net_area_change'"
            v-model="value.net_area_change"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t2', rowIndex, 'net_area_change'],
                value.net_area_change
              )
            "
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4DisplayValue
            v-if="value.net_area_change != 0"
            :value="
              (
                value.initial_soc_stock_total /
                (value.net_area_change * 100)
              ).toFixed(1)
            "
          />
          <P4DisplayValue v-else :value="'-'" />
        </td>
        <td class="P4-cell-right">
          <P4DisplayValue
            v-if="value.net_area_change != 0"
            :value="
              (
                value.final_soc_stock_total /
                (value.net_area_change * 100)
              ).toFixed(1)
            "
          />
          <P4DisplayValue v-else :value="'-'" />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-initial_soc_stock_total'"
            v-model="value.initial_soc_stock_total"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t2', rowIndex, 'initial_soc_stock_total'],
                value.initial_soc_stock_total
              )
            "
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-final_soc_stock_total'"
            v-model="value.final_soc_stock_total"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t2', rowIndex, 'final_soc_stock_total'],
                value.final_soc_stock_total
              )
            "
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4DisplayValue
            :value="value.final_soc_stock_total - value.initial_soc_stock_total"
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { UNCCD_CLASS_MAP } from "../../../lib/constants.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    UNCCD_CLASS_MAP: () => UNCCD_CLASS_MAP,
  },
  methods: {
    handleDropdownChange(value, rowIndex) {
      this.$store.commit("addNewRowTableAutocomplete", {
        t2: {
          value,
          rowIndex,
        },
      });
    },
  },
};
</script>

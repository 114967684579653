var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormulateInput',{attrs:{"type":"multitable","name":"t1","columns":[
    _vm.$gettext('Year'),
    _vm.$gettext('Red List Index'),
    _vm.$gettext('Lower Bound'),
    _vm.$gettext('Upper Bound'),
    _vm.$gettext('Comment'),
  ]},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('td',{staticClass:"P4-cell"},[_vm._v(" "+_vm._s(value.year)+" ")]),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-red_list_index',"default-value-has-changed":_vm.defaultValueHasChanged(
          ['t1', rowIndex, 'red_list_index'],
          value.red_list_index
        ),"numeric":""},model:{value:(value.red_list_index),callback:function ($$v) {_vm.$set(value, "red_list_index", $$v)},expression:"value.red_list_index"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-lower_bound',"default-value-has-changed":_vm.defaultValueHasChanged(
          ['t1', rowIndex, 'lower_bound'],
          value.lower_bound
        ),"numeric":""},model:{value:(value.lower_bound),callback:function ($$v) {_vm.$set(value, "lower_bound", $$v)},expression:"value.lower_bound"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-upper_bound',"default-value-has-changed":_vm.defaultValueHasChanged(
          ['t1', rowIndex, 'upper_bound'],
          value.upper_bound
        ),"numeric":""},model:{value:(value.upper_bound),callback:function ($$v) {_vm.$set(value, "upper_bound", $$v)},expression:"value.upper_bound"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-comment',"textarea":""},model:{value:(value.comment),callback:function ($$v) {_vm.$set(value, "comment", $$v)},expression:"value.comment"}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t1","column-count":7,"columns":Object.values(_vm.OPTIONS.headings).map((value) => `${value}`)},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('th',{staticClass:"P4-cell"},[_vm._v(" "+_vm._s(_vm.OPTIONS.period[value.id])+" ")]),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-total_population_exposed_to_land_degradation',"default-value-has-changed":_vm.defaultValueHasChanged(
              [
                't1',
                rowIndex,
                'total_population_exposed_to_land_degradation',
              ],
              value.total_population_exposed_to_land_degradation
            ),"validate":_vm.isAffectedAreas ? `max:Infinity` : `max:${value.total_population}`,"integer":""},model:{value:(value.total_population_exposed_to_land_degradation),callback:function ($$v) {_vm.$set(value, "total_population_exposed_to_land_degradation", $$v)},expression:"value.total_population_exposed_to_land_degradation"}})],1),(_vm.isAffectedAreas)?_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-percentage_of_total_population_exposed',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t1', rowIndex, 'percentage_of_total_population_exposed'],
              value.percentage_of_total_population_exposed
            ),"percent":""},model:{value:(value.percentage_of_total_population_exposed),callback:function ($$v) {_vm.$set(value, "percentage_of_total_population_exposed", $$v)},expression:"value.percentage_of_total_population_exposed"}})],1):_c('td',{staticClass:"P4-cell-right",attrs:{"id":rowId + '-percentage_of_total_population_exposed'}},[_c('P4DisplayValue',{attrs:{"value":_vm.formatPercent(
              value.total_population_exposed_to_land_degradation,
              value.total_population
            )}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-female_population_exposed_to_land_degradation',"default-value-has-changed":_vm.defaultValueHasChanged(
              [
                't1',
                rowIndex,
                'female_population_exposed_to_land_degradation',
              ],
              value.female_population_exposed_to_land_degradation
            ),"validate":_vm.isAffectedAreas
              ? `max:Infinity`
              : `max:${value.female_population}`,"integer":""},model:{value:(value.female_population_exposed_to_land_degradation),callback:function ($$v) {_vm.$set(value, "female_population_exposed_to_land_degradation", $$v)},expression:"value.female_population_exposed_to_land_degradation"}})],1),(_vm.isAffectedAreas)?_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-percentage_of_female_population_exposed',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t1', rowIndex, 'percentage_of_female_population_exposed'],
              value.percentage_of_female_population_exposed
            ),"percent":""},model:{value:(value.percentage_of_female_population_exposed),callback:function ($$v) {_vm.$set(value, "percentage_of_female_population_exposed", $$v)},expression:"value.percentage_of_female_population_exposed"}})],1):_c('td',{staticClass:"P4-cell-right",attrs:{"id":rowId + '-percentage_of_female_population_exposed'}},[_c('P4DisplayValue',{attrs:{"value":_vm.formatPercent(
              value.female_population_exposed_to_land_degradation,
              value.female_population
            )}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-male_population_exposed_to_land_degradation',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t1', rowIndex, 'male_population_exposed_to_land_degradation'],
              value.male_population_exposed_to_land_degradation
            ),"validate":_vm.isAffectedAreas ? `max:Infinity` : `max:${value.male_population}`,"integer":""},model:{value:(value.male_population_exposed_to_land_degradation),callback:function ($$v) {_vm.$set(value, "male_population_exposed_to_land_degradation", $$v)},expression:"value.male_population_exposed_to_land_degradation"}})],1),(_vm.isAffectedAreas)?_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-percentage_of_male_population_exposed',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t1', rowIndex, 'percentage_of_male_population_exposed'],
              value.percentage_of_male_population_exposed
            ),"percent":""},model:{value:(value.percentage_of_male_population_exposed),callback:function ($$v) {_vm.$set(value, "percentage_of_male_population_exposed", $$v)},expression:"value.percentage_of_male_population_exposed"}})],1):_c('td',{staticClass:"P4-cell-right",attrs:{"id":rowId + '-percentage_of_male_population_exposed'}},[_c('P4DisplayValue',{attrs:{"value":_vm.formatPercent(
              value.male_population_exposed_to_land_degradation,
              value.male_population
            )}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  HEADINGS: {
    year: {
      name: _("Year"),
      tooltip: _(
        "Explain the approach taken in reporting the year (calendar or fiscal years; year of commitment or disbursement)."
      ),
    },
    recipient_provider: {
      name: _("Recipient / Provider"),
      tooltip: _(
        "Explain the approach to reporting the recipient country, " +
          "region or global when reporting bilateral or multi-bilateral " +
          "support provided; the recipient institution when reporting multilateral support provided; " +
          "provider country or countries when reporting bilateral or multi-bilateral support received; " +
          "provider institution or entity when reporting multilateral support received."
      ),
    },
    title_of_project: {
      name: _("Title of project, programme, activity or other"),
      tooltip: _(
        "Select the assessment process which determined the location and extent of the hotspot."
      ),
    },
    total_amount_usd: {
      name: _("Total Amount USD"),
      tooltip: _(
        "Explain the point of measurement of commitments and disbursements, avoiding double counting across years."
      ),
    },
    sector: {
      name: _("Sector"),
      tooltip: _(
        "Explain the sector classification used or whether the suggested categories are used."
      ),
    },
    capacity_building: {
      name: _("Capacity Building"),
      tooltip: _(
        "Explain the approach taken to indicate projects, activities or other as contributing to " +
          "capacity-building and/or technology transfer objectives, or targets gender equality objective. " +
          "No quantification needed."
      ),
    },
    technology_transfer: {
      name: _("Technology Transfer"),
      tooltip: _(
        "Explain the approach taken to indicate projects, activities or other as contributing to " +
          "capacity-building and/or technology transfer objectives, or targets gender equality objective. " +
          "No quantification needed."
      ),
    },
    gender_equality: {
      name: _("Gender Equality"),
      tooltip: _(
        "Explain the approach taken to indicate projects, activities or other as contributing to " +
          "capacity-building and/or technology transfer objectives, or targets gender equality objective. " +
          "No quantification needed."
      ),
    },
    channel: {
      name: _("Channel"),
      tooltip: _(
        "Explain the chosen approach to reporting financial resources provided and/or received through " +
          "bilateral, multi-bilateral, multilateral channels. Indicate the relevant channel to the reported " +
          "project, programme, activity or other. For multilateral resources, indicate whether the amount " +
          "provided or received is core general or DLDD-specific."
      ),
    },
    type_of_flow: {
      name: _("Type of flow"),
      tooltip: _(
        "Explain or refer to the definitions used to report the type of flows (Official Development Assistance," +
          " Other Official Flow or other)."
      ),
    },
    financial_instrument: {
      name: _("Financial Instrument"),
      tooltip: _(
        "Explain or refer to the definitions used to report on financial instruments(e.g. grant, " +
          "concessional loan, non-concessional loan, equity, guarantee, insurance, other (specify))."
      ),
    },
    type_of_support: {
      name: _("Type of support"),
      tooltip: _(
        "Explain the definition used for support being directly or indirectly related to DLDD, " +
          "how the relevance to DLDD is assessed, whether and how the approach is based on the use of " +
          "OECD Rio Markers, if amounts indirectly related to DLDD are discounted."
      ),
    },
    amount_mobilised_through_public_interventions: {
      name: _("Amount mobilised through public interventions"),
      tooltip: _(
        "Indicate whether the amounts are estimated according to the OECD DAC methodology, or explain the methodology used"
      ),
    },
    additional_information: {
      name: _("Additional Information"),
      tooltip: _(
        "Indicate relevant additional information, including the exchange rate between domestic currency " +
          "and United States dollars, the sources of data, project/programme details, executing agency," +
          " and links to relevant documentation"
      ),
    },
  },

  T1: {
    yes_no: {
      yes: _("Yes"),
      no: _("No"),
    },
    channel: {
      "": "",
      bilateral: _("Bilateral"),
      multilateral_core: _("Multilateral (Core contribution)"),
      multilateral_dldd: _("Multilateral (DLDD-specific)"),
      multibilateral: _("Multi-bilateral"),
      other: _("Other(specify)"),
    },
    type_of_flow: {
      "": "",
      oda: _("ODA"),
      oof: _("OOF"),
      other: _("Other(specify)"),
    },
    financial_instrument: {
      grant: _("Grant"),
      concessional_loan: _("Concessional loan"),
      non_concessional_loan: _("Non-concessional loan"),
      equity: _("Equity"),
      guarantee_insurance: _("Guarantee/Insurance"),
      other: _("Other(specify)"),
    },
    type_of_support: {
      "": "",
      directly: _("Directly related to DLDD"),
      indirectly: _("Indirectly related to DLDD"),
    },
  },
};

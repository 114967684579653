import { Text, Fill, Stroke, Style, Circle } from "ol/style";

function borders(color = "#319FD3", width = 2, dash = null, dashOffset = 0) {
  const style = new Style({
    stroke: new Stroke({
      color: color,
      width: width,
      lineDash: dash,
      lineDashOffset: dashOffset,
    }),
    text: vectorText(),
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        color: "#585858",
        width: width,
      }),
    }),
  });

  return () => {
    return style;
  };
}

function vectorText() {
  return new Text({
    textAlign: "center",
    overflow: true,
    font: "12px Noto Sans SC, Roboto, Arial, Helvetica, sans-serif",
    fill: new Fill({ color: "#000" }),
    stroke: new Stroke({
      color: "#fff",
      width: 2,
    }),
  });
}

function select() {
  function createLayerStyle() {
    return new Style({
      stroke: new Stroke({
        color: "magenta",
        width: 4,
      }),
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.2)",
      }),
      image: new Circle({
        radius: 5,
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "magenta",
          width: 4,
        }),
      }),
      text: vectorText(),
    });
  }

  return (feature) => {
    const style = createLayerStyle();
    style.getText().setText(feature.getId());
    return style;
  };
}

function vector(color = "#319FD3", width = 2, dash = null, dashOffset = 0) {
  function createLayerStyle() {
    return new Style({
      stroke: new Stroke({
        color: color,
        width: width,
        lineDash: dash,
        lineDashOffset: dashOffset,
      }),
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.6)",
      }),
      image: new Circle({
        radius: 5,
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.6)",
        }),
        stroke: new Stroke({
          color: color,
          width: width,
        }),
      }),
      text: vectorText(),
    });
  }

  return (feature) => {
    const style = createLayerStyle();
    style.getText().setText(feature.getId());
    return style;
  };
}

export default {
  borders,
  select,
  vector,
};

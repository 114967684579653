<template>
  <section>
    <P4Form v-model="values">
      <h3 v-translate class="P4-caption">SO2-VT.T1</h3>
      <p v-translate class="P4-guidance">
        List any target relevant to this strategic objective that your country has
        set, and indicate the expected year of achievement and level of
        application (e.g. national, subnational).
      </p>
      <CommentAnchor :name="form_id + '-VT'" />
      <VT />

      <h3 v-translate class="P4-section-title">General comments</h3>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input v-model="values.so2_v_general_comments" textarea standalone />
      </div>
    </P4Form>
  </section>
</template>

<script>
import range from "../../../lib/range.js";
import { allOptions } from "../../../lib/translations.js";
import OPTIONS from "../../common/voluntaryTargets/OPTIONS.js";
import VT from "../../common/voluntaryTargets/VT.vue";

function valuesForAllOptions() {
  const { status_of_target_achivement, level_of_application } = OPTIONS;
  const len = (dict) => Object.keys(dict).length;

  const numRows = Math.max(
    len(status_of_target_achivement),
    len(level_of_application)
  );
  return {
    t: range(numRows).map((i) => {
      return {
        status: Object.keys(status_of_target_achivement)[i],
        level: Object.keys(level_of_application)[i],
      };
    }),
  };
}

export default {
  components: {
    VT,
  },
  data() {
    const values = window.hydrate.data || {};

    if (allOptions()) {
      Object.assign(values, valuesForAllOptions());
    }
    return {
      values,
      form_id: window.hydrate.id,
    };
  },
};
</script>

<template>
  <section>
    <div class="P4-comment-thread relative">
      <div
        v-for="anchor in threads_anchor_data"
        :key="anchor.key"
        class="P4-caption font-semibold"
      >
        <h1 class="P4-link text-black cursor:pointer">
          <a
            :href="
              anchor['anchor_page_url'].slice(0, -1) +
              '#' +
              anchor['anchor'] +
              '-comments'
            "
          >
            {{ anchor["anchor"].toUpperCase() }}
          </a>
          <CommentAnchor :name="anchor['anchor']" :is-read-only="true" />
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      threads_anchor_data: window.hydrate.threads_anchor_data,
    };
  },
};
</script>

<template>
  <P4Form v-model="values">
    <section>
      <h2 v-translate class="P4-section-title">
        Sustainable land management practices:
      </h2>

      <p v-translate class="P4-guidance">
        Implementing sustainable land management practices
      </p>

      <h4 v-translate class="P4-question">
        Has your country implemented or is your country implementing sustainable
        land management (SLM) practices to address DLDD?
      </h4>

      <P4Input
        id="if_action_slm"
        v-model="values.implemented_actions_dldd"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !implementedActionsDLDD }">
        <h4 v-translate class="P4-question">
          What types of SLM practices are being implemented?
        </h4>

        <p
          v-translate="{
            html_start_link: `<a class='P4-link' href='https://www.wocat.net/en/global-slm-database/slm-practices-technologies-and-approaches/classifications-technologies'>`,
            html_end_link: `</a>`,
          }"
          class="P4-guidance P4-inline-p"
          render-html="true"
        >
          This list is based on the World Overview of Conservation Approaches
          and Technologies Global SLM Database classifications. Definitions of
          the classifications below can be found at this %{ html_start_link }
          link %{ html_end_link }.
        </p>

        <ul>
          <li v-for="(option, index) in OPTIONS.dldd_actions" :key="index">
            <P4Input
              :id="'if_action_slm-' + index"
              :key="'dldd_actions-' + index"
              v-model="values.dldd_actions_options[index]"
              :name="'dldd_actions-' + index"
              :label="option"
              checkbox
            />
          </li>
          <li>
            <P4Input
              v-if="values.dldd_actions_options['other']"
              id="dldd_actions_other"
              v-model="values['dldd_actions_other']"
            />
          </li>
        </ul>

        <h4 v-translate class="P4-question">
          Use the space below to share more details about your country's
          experience:
        </h4>

        <P4Input
          id="if_action_slm_experience"
          v-model="values.implemented_actions_dldd_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Would you consider the implemented practices successful and what do
          you consider the main factors of success?
        </h4>

        <P4Input
          id="if_action_slm_opinion"
          v-model="values.implemented_actions_dldd_opinion"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_action_slm_challenges"
          v-model="values.implemented_actions_dldd_faced_challenges"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What do you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_action_slm_lessons_learned"
          v-model="values.implemented_actions_dldd_lessons_learned"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          How did you engage women and youth in these activities?
        </h4>

        <P4Input
          id="if_action_slm_engage_women"
          v-model="values.implemented_actions_dldd_engage_women"
          textarea
          standalone
        />
      </div>

      <h4 v-translate class="P4-question">
        Has your country supported other countries in the implementation of SLM
        practices?
      </h4>

      <P4Input
        id="if_action_slm_other_countries"
        v-model="values.implemented_actions_sml"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !implementedActionsSML }">
        <h4 v-translate class="P4-question">
          Use the space below to share more details about your country's
          experience:
        </h4>

        <P4Input
          id="if_action_slm_other_countries_experience"
          v-model="values.implemented_actions_sml_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Would you consider the implemented practices successful and what do
          you consider the main factors of success?
        </h4>

        <P4Input
          id="if_action_slm_other_countries_opinion"
          v-model="values.implemented_actions_sml_opinion"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_action_slm_other_countries_challenges"
          v-model="values.implemented_actions_sml_faced_challenges"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What do you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_action_slm_other_countries_lessons_learned"
          v-model="values.implemented_actions_sml_lessons_learned"
          textarea
          standalone
        />
      </div>
    </section>

    <section>
      <h2 v-translate class="P4-section-title">
        Restoration and Rehabilitation:
      </h2>

      <p v-translate class="P4-guidance">
        Implementing restoration and rehabilitation practices in order to assist
        with the recovery of ecosystem functions and services.
      </p>

      <h4 v-translate class="P4-question">
        Has your country implemented or is your country implementing restoration
        and rehabilitation practices in order to assist with the recovery of
        ecosystem functions and services?
      </h4>

      <P4Input
        id="if_action_restoration"
        v-model="values.ecosystem_recovery"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !ecosystemRecovery }">
        <h4 v-translate class="P4-question">
          What types of rehabilitation and restoration practices are being
          implemented?
        </h4>

        <ul>
          <li
            v-for="(option, index) in SO1_4_OPTIONS.T4.options
              .remediating_actions"
            :key="index"
          >
            <P4Input
              :id="'if_action_restoration-' + index"
              :key="'rehabilitation_practices-' + index"
              v-model="values.rehabilitation_practices_options[index]"
              :name="'rehabilitation_practices-' + index"
              :label="option.label"
              checkbox
            />
          </li>
          <li>
            <P4Input
              v-if="
                values.rehabilitation_practices_options[
                  'other_general_unspecified'
                ]
              "
              id="rehabilitation_practices_other_general_unspecified"
              v-model="
                values['rehabilitation_practices_other_general_unspecified']
              "
            />
          </li>
        </ul>

        <h4 v-translate class="P4-question">
          Use the space below to share more details about your country's
          experience:
        </h4>

        <P4Input
          id="if_action_restoration_experience"
          v-model="values.rehabilitation_practices_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Would you consider the implemented practices successful and what do
          you consider the main factors of success?
        </h4>

        <P4Input
          id="if_action_restoration_opinion"
          v-model="values.rehabilitation_succes_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_action_restoration_challenges"
          v-model="values.rehabilitation_practices_faced_challenges"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What do you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_action_restoration_lessons_learned"
          v-model="values.rehabilitation_practices_lessons_learned"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          How did you engage women and youth in SLM activities?
        </h4>

        <P4Input
          id="if_action_restoration_practices_women"
          v-model="values.rehabilitation_practices_women"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Has your country supported other countries with restoration and
          rehabilitation practices in order to assist with the recovery of
          ecosystem functions and services?
        </h4>

        <P4Input
          id="if_action_restoration_other_countries"
          v-model="values.ecosystem_countries_support"
          radio
          :options="OPTIONS.yes_no"
        />

        <div :class="{ hidden: !ecosystemCountriesSupport }">
          <h4 v-translate class="P4-question">
            Use the space below to describe your country's experience.
          </h4>

          <P4Input
            id="if_action_restoration_other_countries_experience"
            v-model="values.ecosystem_countries_support_experience"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            Do you consider this experience a success and, if so, what do you
            consider the reasons behind this success (or lack thereof)?
          </h4>

          <P4Input
            id="if_action_restoration_other_countries_opinion"
            v-model="values.ecosystem_countries_support_successful_experience"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            What were the challenges faced, if any?
          </h4>

          <P4Input
            id="if_action_restoration_other_countries_challenges"
            v-model="values.ecosystem_countries_support_challenges_faced"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            What would you consider to be the lessons learned?
          </h4>

          <P4Input
            id="if_action_restoration_other_countries_lessons_learned"
            v-model="values.ecosystem_countries_support_lessons_learned"
            textarea
            standalone
          />
        </div>
      </div>
    </section>

    <section>
      <h2 v-translate class="P4-section-title">
        Drought risk management and early warning systems:
      </h2>

      <p v-translate class="P4-guidance">
        Developing and operationalizing drought risk management, monitoring and
        early warning systems and safety net programmes.
      </p>

      <h4 v-translate class="P4-question">
        Is your country developing a drought risk management plan, monitoring or
        early warning systems and safety net programmes to address DLDD?
      </h4>

      <P4Input
        id="if_action_drought"
        v-model="values.drought_risk_management"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !droughtRiskManagement }">
        <h4 v-translate class="P4-question">
          If so, DLDD was mainstreamed into (check all that apply):
        </h4>

        <ul>
          <li
            v-for="(option, index) in OPTIONS.drought_risk_management"
            :key="'drought_risk_management-' + index"
          >
            <P4Input
              :id="'if_action_drought-' + index"
              v-model="values.drought_risk_management_options[index]"
              :name="'drought_risk_management-' + index"
              :label="option"
              checkbox
            />
          </li>
        </ul>

        <h4 v-translate class="P4-question">
          Use the space below to describe your country's experience.
        </h4>

        <P4Input
          id="if_action_drought_experience"
          v-model="values.drought_risk_management_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Do you consider this experience a success and, if so, what do you
          consider the reasons behind this success (or lack thereof)?
        </h4>

        <P4Input
          id="if_action_drought_opinion"
          v-model="values.drought_risk_management_successful_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          If you have or are developing a drought risk management plan as part
          of the Drought Initiative, please share here your experience on
          activities undertaken?
        </h4>

        <P4Input
          id="if_action_drought_activities"
          v-model="values.drought_risk_management_activities_undertaken"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_action_drought_challenges"
          v-model="values.drought_risk_management_challenges_faced"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What would you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_action_drought_lessons_learned"
          v-model="values.drought_risk_management_lessons_learned"
          textarea
          standalone
        />
      </div>

      <h4 v-translate class="P4-question">
        Has your country supported other countries in developing drought risk
        management, monitoring and early warning systems and safety net
        programmes to address DLDD?
      </h4>

      <P4Input
        id="if_action_drought_other_countries"
        v-model="values.drought_risk_management_countries_support"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !droughtRiskManagementCountriesSupport }">
        <h4 v-translate class="P4-question">
          Use the space below to describe your country's experience.
        </h4>

        <P4Input
          id="if_action_drought_other_countries_experience"
          v-model="values.drought_risk_management_countries_support_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Do you consider this experience a success and, if so, what do you
          consider the reasons behind this success (or lack thereof)?
        </h4>

        <P4Input
          id="if_action_drought_other_countries_opinion"
          v-model="
            values.drought_risk_management_countries_support_successful_experience
          "
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_action_drought_other_countries_challenges"
          v-model="
            values.drought_risk_management_countries_support_challenges_faced
          "
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What would you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_action_drought_other_countries_lessons_learned"
          v-model="
            values.drought_risk_management_countries_support_lessons_learned
          "
          textarea
          standalone
        />
      </div>
    </section>

    <section>
      <h2 v-translate class="P4-section-title">Alternative livelihoods:</h2>

      <p v-translate class="P4-guidance">Promoting alternative livelihoods.</p>

      <h4 v-translate class="P4-question">
        Does your country promote alternative livelihoods practice in the
        context of DLDD?
      </h4>

      <P4Input
        id="if_action_livelihoods"
        v-model="values.alternative_livelihoods"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !alternativeLivelihoods }">
        <h4 v-translate class="P4-question">
          Could you list some practices implemented at country level to promote
          alternative livelihoods?
        </h4>

        <ul>
          <li
            v-for="(option, index) in OPTIONS.alternative_livelihoods"
            :key="'alternative_livelihoods-' + index"
          >
            <P4Input
              :id="'if_action_livelihoods-' + index"
              v-model="values.alternative_livelihoods_options[index]"
              :name="'alternative_livelihoods-' + index"
              :label="option"
              checkbox
            />
          </li>
          <li>
            <P4Input
              v-if="values['alternative_livelihoods_options']['other']"
              id="alternative_livelihoods_other"
              v-model="values.alternative_livelihoods_other"
            />
          </li>
        </ul>

        <h4 v-translate class="P4-question">
          Use the space below to describe your country's experience.
        </h4>

        <P4Input
          id="if_action_livelihoods_experience"
          v-model="values.alternative_livelihoods_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Do you consider this experience a success and, if so, what do you
          consider the reasons behind this success (or lack thereof)?
        </h4>

        <P4Input
          id="if_action_livelihoods_opinion"
          v-model="values.alternative_livelihoods_successful_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_action_livelihoods_challenges"
          v-model="values.alternative_livelihoods_challenges_faced"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What would you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_action_livelihoods_lessons_learned"
          v-model="values.alternative_livelihoods_lessons_learned"
          textarea
          standalone
        />
      </div>

      <h4 v-translate class="P4-question">
        Do you consider your country to be taking special measures to engage
        women and youth in promoting alternative livelihoods?
      </h4>

      <P4Input
        id="if_action_livelihoods_women"
        v-model="values.alternative_livelihoods_women"
        radio
        :options="OPTIONS.yes_no"
      />
      <div :class="{ hidden: !alternativeLivelihoodsWomen }">
        <h4 v-translate class="P4-question">Please elaborate</h4>

        <P4Input
          id="if_action_livelihoods_women_elaborate"
          v-model="values.alternative_livelihoods_women_elaborate"
          textarea
          standalone
        />
      </div>
    </section>

    <section>
      <h2 v-translate class="P4-section-title">
        Establishing knowledge sharing systems:
      </h2>

      <p v-translate class="P4-guidance">
        Establishing systems for sharing information and knowledge and
        facilitating networking on best practices and approaches to drought
        management.
      </p>

      <h4 v-translate class="P4-question">
        Has your country established systems for sharing information and
        knowledge and facilitating networking on best practices and approaches
        to drought management?
      </h4>

      <P4Input
        id="if_action_knowledge"
        v-model="values.drought_management_systems"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !droughtManagementSystems }">
        <h4 v-translate class="P4-question">
          Please use this space to share/list the established systems available
          in your country for sharing information and knowledge and facilitating
          networking on best practices and approaches to drought management.
        </h4>

        <P4Input
          id="if_action_knowledge_practices"
          v-model="values.drought_management_systems_practices_implemented"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Do you consider this experience a success and, if so, what do you
          consider the reasons behind this success (or lack thereof)?
        </h4>

        <P4Input
          id="if_action_knowledge_opinion"
          v-model="values.drought_management_systems_successful_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_action_knowledge_challenges"
          v-model="values.drought_management_systems_challenges_faced"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What would you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_action_knowledge_lessons_learned"
          v-model="values.drought_management_systems_lessons_learned"
          textarea
          standalone
        />
      </div>

      <h4 v-translate class="P4-question">
        Do you consider that your country has implemented specific actions that
        promote women’s access to knowledge and technology?
      </h4>

      <P4Input
        id="if_action_knowledge_women"
        v-model="values.women_technology"
        radio
        :options="OPTIONS.yes_no"
      />
      <div :class="{ hidden: !womenTechnology }">
        <h4 v-translate class="P4-question">Please elaborate</h4>

        <P4Input
          id="if_action_knowledge_women_elaborate"
          v-model="values.women_technology_elaborate"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Do you consider this experience a success and, if so, what do you
          consider the reasons behind this success (or lack thereof)?
        </h4>

        <P4Input
          id="if_action_knowledge_women_opinion"
          v-model="values.women_technology_successful_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_action_knowledge_women_challenges"
          v-model="values.women_technology_challenges_faced"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What would you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_action_knowledge_women_lessons_learned"
          v-model="values.women_technology_lessons_learned"
          textarea
          standalone
        />
      </div>
    </section>
  </P4Form>
</template>

<script>
import OPTIONS from "../OPTIONS.js";
import SO1_4_OPTIONS from "../../so1/4/OPTIONS.js";

export default {
  data() {
    const values = {
      dldd_actions_options: {},
      rehabilitation_practices_options: {},
      drought_risk_management_options: {},
      alternative_livelihoods_options: {},
      ...window.hydrate.data,
    };

    return {
      values,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    SO1_4_OPTIONS: () => SO1_4_OPTIONS,

    implementedActionsDLDD() {
      return this.values["implemented_actions_dldd"] === "yes";
    },
    implementedActionsSML() {
      return this.values["implemented_actions_sml"] === "yes";
    },
    ecosystemRecovery() {
      return this.values["ecosystem_recovery"] === "yes";
    },
    ecosystemCountriesSupport() {
      return this.values["ecosystem_countries_support"] === "yes";
    },
    droughtRiskManagement() {
      return this.values["drought_risk_management"] === "yes";
    },
    droughtRiskManagementCountriesSupport() {
      return this.values["drought_risk_management_countries_support"] === "yes";
    },
    alternativeLivelihoods() {
      return this.values["alternative_livelihoods"] === "yes";
    },
    alternativeLivelihoodsWomen() {
      return this.values["alternative_livelihoods_women"] === "yes";
    },
    droughtManagementSystems() {
      return this.values["drought_management_systems"] === "yes";
    },
    womenTechnology() {
      return this.values["women_technology"] === "yes";
    },
  },
};
</script>

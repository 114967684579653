<template>
  <div class="print-hide">
    <a
      v-if="next_button.next_form_id"
      class="mt-5 text-unccdblue"
      :href="next_button.next_form_url"
      >Next: {{ next_button.next_form_id }}</a
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      form_id: window.hydrate.id,
      reporting_forms: window.hydrate.reporting_forms,
    };
  },
  computed: {
    next_button: function () {
      var next_form_id = "";
      var next_form_url = "";
      for (var i = 0; i < this.reporting_forms.length - 1; i++) {
        if (this.reporting_forms[i]["id"] === this.form_id) {
          next_form_id = this.reporting_forms[i + 1]["id"];
          next_form_url = this.reporting_forms[i + 1]["url"];
          break;
        }
      }
      return {
        next_form_id,
        next_form_url,
      };
    },
  },
};
</script>

import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  T2: {
    qualitative_assessment: {
      increasing: _("Increasing"),
      decreasing: _("Decreasing"),
      no_change: _("No Change"),
    },
  },
};

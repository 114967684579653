var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t2","columns":[
      '',
      _vm.$gettext('Total area under drought (km²)'),
      _vm.isAffectedAreas
        ? _vm.$gettext('Proportion of affected area under drought (%)')
        : _vm.$gettext('Proportion of land under drought (%)'),
    ]},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('th',{staticClass:"P4-cell"},[_vm._v(" "+_vm._s(value.year)+" ")]),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-total_area_under_drought',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t2', rowIndex, 'total_area_under_drought'],
            value.total_area_under_drought
          ),"warnings":[
              {
                name: 'exceedMaxTotalWarning',
                args: [value.total_area_under_drought, _vm.getTotalLandArea(value.year), _vm.getNearestYear(value.year)]
              },
          ],"placeholder":_vm.$gettext('No data'),"numeric":""},model:{value:(value.total_area_under_drought),callback:function ($$v) {_vm.$set(value, "total_area_under_drought", $$v)},expression:"value.total_area_under_drought"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4DisplayValue',{attrs:{"value":_vm.formatPercent(
              value.total_area_under_drought,
              _vm.getTotalLandArea(value.year)
            )}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
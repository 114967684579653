<template>
  <div>
    <div v-if="layer.has_data">
      <div id="rasterdata-preview" class="mt-4 w-full h-[50vh]" />

      <div class="my-2">
        <img :src="layer.legend_url" />
      </div>
    </div>

    <div v-else v-translate>No data</div>
  </div>
</template>

<script>
import { BACKGROUND_LAYERS, BORDER_CODE } from "@/lib/constants";
import {
  createMapserverRasterLayer,
  createBackgroundLayer,
  createCropFilter,
} from "@/lib/maps/layers";
import { Map, View } from "ol";
import api from "../lib/api";
import { Vector as VectorSource } from "ol/source";
import { FullScreen, defaults as defaultControls } from "ol/control";
import { geojson } from "@/lib/maps/layers";
import fitView from "@/lib/maps/fitView.js";

export default {
  computed: {
    layer: () => window.hydrate.layer,
  },

  async mounted() {
    const [s2cloudless] = BACKGROUND_LAYERS.filter(
      ({ id }) => id === "s2cloudless-2020"
    );
    const background = createBackgroundLayer(s2cloudless);

    const border = await api.loadVectorData(
      `../../maps/vector_layer/${BORDER_CODE}`
    );

    const cropFilter = createCropFilter(geojson().readFeatures(border));

    const raster = createMapserverRasterLayer(this.layer.mapserver_url, {
      title: this.layer.code,
    });
    raster.addFilter(cropFilter);
    raster.setVisible(true);

    const borderSource = new VectorSource({
      features: geojson().readFeatures(border),
    });

    const layers = [background, raster];

    const view = new View();

    new Map({
      controls: defaultControls().extend([new FullScreen()]),
      target: "rasterdata-preview",
      layers,
      view,
    });

    setTimeout(function () {
      fitView(view, borderSource, window.hydrate.iso3);
    }, 100);
  },
};
</script>

<template>
  <section>
    <p v-translate class="P4-infotext text-lg">
      You can choose to complete only Tier 1 or both.
    </p>
    <P4Form v-model="values">
      <h2 v-translate class="P4-section-title">
        Tier 1: Please provide information on the international public resources
        provided and received for the implementation of the Convention,
        including information on trends.
      </h2>

      <P4Input
        v-model="values.trends_resources_provided"
        radio
        name="trends_resources_provided"
        :label="
          $gettext(
            'Trends in international bilateral and multilateral public resources provided'
          )
        "
        :options="SO5_OPTIONS.STATUS"
      />

      <P4Input
        v-model="values.trends_resources_received"
        radio
        name="trends_resources_received"
        :label="
          $gettext(
            'Trends in international bilateral and multilateral public resources received'
          )
        "
        :options="SO5_OPTIONS.STATUS"
      />

      <p v-translate class="P4-guidance">
        Please provide information, as available, on the policies and measures
        implemented to mobilize international public resources; on the projects,
        activities, regions or countries on which your country has focused to
        the greatest extent; as well as on any funding provided by the national
        government in favour of developing countries.
      </p>
      <P4Input
        id="so5_1_policies_implemented"
        v-model="values.so5_1_tier1"
        textarea
        standalone
      />

      <p v-translate class="P4-guidance">
        Description may include information on national circumstances and
        institutional arrangements relevant to the reporting on international
        public resources provided and received, as well as on effectiveness and
        impacts of resources.
      </p>
      <P4Input
        id="so5_1_national_circumstances"
        v-model="values.information_on_national_circumstances"
        textarea
        standalone
      />

      <h2 v-translate class="P4-sh">
        Tier 2: Table 1 Financial resources provided and received
      </h2>
      <p v-translate class="P4-guidance">
        Please compile the table with data at the level of disaggregation
        available. Parties are encouraged to provide activity-level data.
        Compiled default data are derived from information reported to the
        Organization for Economic Co-operation and Development (OECD)
        Development Assistance Committee (DAC) by OECD country members, based on
        the desertification Rio Marker; default data can be amended by the
        reporting Party as appropriate.
      </p>
      <CommentAnchor :name="form_id + '-T1'" />
      <T1 />
      <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />
      <p v-translate class="P4-guidance">
        In case disaggregated information is not available, the Party can report
        only the total amount or the total amounts per year.
      </p>
      <h2 v-translate class="P4-section-title">Documentation box</h2>
      <p v-translate class="P4-guidance">
        Please provide information on definitions or methodologies used for
        reporting information in the following reporting parameters:
      </p>
      <CommentAnchor :name="form_id + '-T2'" />
      <T2 />

      <h3 v-translate class="P4-section-title">General comments</h3>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input
          id="so5_1_general_comments"
          v-model="values.so5_1_general_comments"
          textarea
          standalone
        />
      </div>
    </P4Form>
  </section>
</template>

<script>
import T1 from "./T1.vue";
import T2 from "./T2.vue";
import OPTIONS from "./OPTIONS";
import SO5_OPTIONS from "../SO5_OPTIONS";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

export default {
  components: {
    T1,
    T2,
  },
  mixins: [restoreDefaultData],
  data() {
    const values = {
      t2: Object.keys(OPTIONS.HEADINGS).map((key) => ({ id: key })),

      ...window.hydrate.data,
    };

    return {
      values,
      form_id: window.hydrate.id,
      $gettext,
    };
  },
  computed: {
    SO5_OPTIONS: () => SO5_OPTIONS,
  },
};
</script>

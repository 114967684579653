var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t2","column-count":8,"label-for-new":_vm.$gettext('+ Add Record')},scopedSlots:_vm._u([{key:"table_header",fn:function(){return [_c('tr',[_c('th',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"colspan":"2"}},[_vm._v("Land Conversion")]),_c('th',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"colspan":"6"}},[_vm._v(" Soil organic carbon (SOC) stock change in the baseline period ")])]),_c('tr',_vm._l(([
            _vm.$gettext('From'),
            _vm.$gettext('To'),
            _vm.$gettext('Net area change (km²)'),
            _vm.$gettext('Initial SOC stock (t/ha)'),
            _vm.$gettext('Final SOC stock (t/ha)'),
            _vm.$gettext('Initial SOC stock total (t)'),
            _vm.$gettext('Final SOC stock total (t)'),
            _vm.$gettext('SOC stock change (t)'),
          ]),function(column,index){return _c('th',{key:index,staticClass:"P4-cell"},[_vm._v(" "+_vm._s(column)+" ")])}),0)]},proxy:true},{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-from',"default-value-has-changed":_vm.defaultValueHasChanged(['t2', rowIndex, 'from'], value.from),"options":_vm.UNCCD_CLASS_MAP},on:{"input":function($event){return _vm.handleDropdownChange(value, rowIndex)}},model:{value:(value.from),callback:function ($$v) {_vm.$set(value, "from", $$v)},expression:"value.from"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-to',"default-value-has-changed":_vm.defaultValueHasChanged(['t2', rowIndex, 'to'], value.to),"options":_vm.UNCCD_CLASS_MAP},on:{"input":function($event){return _vm.handleDropdownChange(value, rowIndex)}},model:{value:(value.to),callback:function ($$v) {_vm.$set(value, "to", $$v)},expression:"value.to"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-net_area_change',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t2', rowIndex, 'net_area_change'],
              value.net_area_change
            ),"numeric":""},model:{value:(value.net_area_change),callback:function ($$v) {_vm.$set(value, "net_area_change", $$v)},expression:"value.net_area_change"}})],1),_c('td',{staticClass:"P4-cell-right"},[(value.net_area_change != 0)?_c('P4DisplayValue',{attrs:{"value":(
              value.initial_soc_stock_total /
              (value.net_area_change * 100)
            ).toFixed(1)}}):_c('P4DisplayValue',{attrs:{"value":'-'}})],1),_c('td',{staticClass:"P4-cell-right"},[(value.net_area_change != 0)?_c('P4DisplayValue',{attrs:{"value":(
              value.final_soc_stock_total /
              (value.net_area_change * 100)
            ).toFixed(1)}}):_c('P4DisplayValue',{attrs:{"value":'-'}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-initial_soc_stock_total',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t2', rowIndex, 'initial_soc_stock_total'],
              value.initial_soc_stock_total
            ),"numeric":""},model:{value:(value.initial_soc_stock_total),callback:function ($$v) {_vm.$set(value, "initial_soc_stock_total", $$v)},expression:"value.initial_soc_stock_total"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-final_soc_stock_total',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t2', rowIndex, 'final_soc_stock_total'],
              value.final_soc_stock_total
            ),"numeric":""},model:{value:(value.final_soc_stock_total),callback:function ($$v) {_vm.$set(value, "final_soc_stock_total", $$v)},expression:"value.final_soc_stock_total"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4DisplayValue',{attrs:{"value":value.final_soc_stock_total - value.initial_soc_stock_total}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t1"
      :columns="[
        $gettext('Target'),
        $gettext('Year'),
        $gettext('Level of application'),
        $gettext('Status of target achievement'),
        $gettext('Comments'),
      ]"
      :column-count="5"
      :label-for-new="$gettext('+ Add Record')"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input :id="rowId + '-target'" v-model="value.target" textarea/>
        </td>

        <td class="P4-cell">
          <P4Input :id="rowId + '-year'" v-model="value.year" year />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-level_of_application'"
            v-model="value.level_of_application"
            :options="OPTIONS.level_of_application"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-status_of_target_achievement'"
            v-model="value.status_of_target_achievement"
            :options="OPTIONS.status_of_target_achievement"
          />
        </td>

        <td class="P4-cell">
          <P4Input :id="rowId + '-comments'" v-model="value.comments" textarea />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS() {
      return OPTIONS;
    },
  },
};
</script>

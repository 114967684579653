var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":_vm.name,"columns":[_vm.$gettext('Original/ Final')].concat(_vm.headingNames)},scopedSlots:_vm._u([(!_vm.T3HasEmptyCell)?{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('th',{staticClass:"P4-cell"},[_vm._v(" "+_vm._s(_vm.headingNames[rowIndex])+" ")]),_vm._l((_vm.headingKeys),function(transition_to,index){return _c('td',{key:`${transition_to}-${index}`,staticClass:"P4-cell-transition-matrix text-center",class:{
          'bg-green-300': value[transition_to] === 'improvement',
          'bg-red-300': value[transition_to] === 'degradation',
          'bg-yellow-100': value[transition_to] === 'stable',
          'bg-gray-100':
            value[transition_to] === '' ||
            value['unlikely_transition-' + transition_to] === true,
        }},[_c('div',{staticClass:"transition-cell print-hide"},[_c('div',{staticClass:"text-right"},[(!value['unlikely_transition-' + transition_to])?_c('P4Input',{attrs:{"id":rowId + '-' + transition_to,"default-value-has-changed":_vm.defaultValueHasChanged(
                  ['t4', rowIndex, transition_to],
                  value[transition_to]
                ),"options":_vm.OPTIONS.T4},model:{value:(value[transition_to]),callback:function ($$v) {_vm.$set(value, transition_to, $$v)},expression:"value[transition_to]"}}):_vm._e()],1),_c('Tooltip',{attrs:{"content":_vm.$gettext('Unlikely Transition')}},[_c('P4Input',{attrs:{"id":rowId + '-unlikely_transition-' + transition_to,"default-value-has-changed":_vm.defaultValueHasChanged(
                  ['t4', rowIndex, 'unlikely_transition-' + transition_to],
                  value['unlikely_transition-' + transition_to]
                ),"checkbox":""},model:{value:(value['unlikely_transition-' + transition_to]),callback:function ($$v) {_vm.$set(value, 'unlikely_transition-' + transition_to, $$v)},expression:"value['unlikely_transition-' + transition_to]"}})],1)],1),_c('div',{staticClass:"print-show mr-2 ml-2"},[(!value['unlikely_transition-' + transition_to])?_c('span',[_vm._v(" "+_vm._s(_vm.OPTIONS.T4[value[transition_to]])+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$gettext("Unlikely Transition"))+" ")])])])})]}}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t1","column-count":10,"columns":Object.values(_vm.OPTIONS.VT_T1),"label-for-new":_vm.$gettext('+ Add New'),"row-factory":_vm.rowFactory},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, value }){return [_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-target',"textarea":""},model:{value:(value.target),callback:function ($$v) {_vm.$set(value, "target", $$v)},expression:"value.target"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-year',"year":""},model:{value:(value.year),callback:function ($$v) {_vm.$set(value, "year", $$v)},expression:"value.year"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-location'},model:{value:(value.location),callback:function ($$v) {_vm.$set(value, "location", $$v)},expression:"value.location"}})],1),_c('td',{staticClass:"P4-cell"},[(!!value.polygon && value.polytype === 'Polygon')?_c('P4DisplayValue',{attrs:{"value":value.area}}):_c('P4Input',{attrs:{"numeric":""},model:{value:(value.area),callback:function ($$v) {_vm.$set(value, "area", $$v)},expression:"value.area"}})],1),_c('td',{staticClass:"P4-cell"},[_c('ActionsTakenCheckboxes',{attrs:{"row-id":rowId,"options":_vm.SO1_4_OPTIONS.T4.options.action_taken},model:{value:(value.overarching_type_of_ldn_intervention),callback:function ($$v) {_vm.$set(value, "overarching_type_of_ldn_intervention", $$v)},expression:"value.overarching_type_of_ldn_intervention"}})],1),_c('td',{staticClass:"P4-cell"},[_c('button',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"rounded-empty-btn-sm m-2 print-hide",attrs:{"id":rowId + '-actions-button',"type":"button"},on:{"click":function($event){_vm.actionModalData = value.remediating_actions;
            _vm.isActionModalVisible = true;}}},[_vm._v(" Choose targeted actions ")]),_vm._l((value.remediating_actions),function(actionValue,action){return _c('div',{key:action,staticClass:"mt-4"},[(actionValue === true)?_c('ul',{staticClass:"P4-ul"},[_c('li',[_vm._v(" "+_vm._s(_vm.SO1_4_OPTIONS.T4.options.remediating_actions[action].label)+" "),_vm._l((value
                  .remediating_actions['items_for_' + action]),function(subActionValue,subAction){return _c('ul',{key:subAction,staticClass:"P4-ul italic"},[(subActionValue === true)?_c('li',[_vm._v(" "+_vm._s(_vm.SO1_4_OPTIONS.T4.options.remediating_actions[action] .options[subAction])+" ")]):_vm._e()])})],2)]):_vm._e()])})],2),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-status',"options":_vm.SO1_4_OPTIONS.T4.options.status_of_target_achivement},model:{value:(value.status),callback:function ($$v) {_vm.$set(value, "status", $$v)},expression:"value.status"}}),(value.partial_percent)?_c('span',[_vm._v(" ("+_vm._s(value.partial_percent)+"%) ")]):_vm._e()],1),_c('td',{staticClass:"P4-cell"},[_c('FormulateInput',{attrs:{"id":rowId + '-ldn_target',"options":{ yes: _vm.$gettext('Yes'), no: _vm.$gettext('No') },"type":"radio","ignored":""},model:{value:(value.ldn_target),callback:function ($$v) {_vm.$set(value, "ldn_target", $$v)},expression:"value.ldn_target"}}),(value.ldn_target === 'yes')?_c('ul',[_c('li',[_c('P4Input',{attrs:{"id":rowId + '-ldn_target_answer_yes',"options":_vm.SO1_4_OPTIONS.T4.options.ldn_target_answer_yes},model:{value:(value.ldn_target_answer_yes),callback:function ($$v) {_vm.$set(value, "ldn_target_answer_yes", $$v)},expression:"value.ldn_target_answer_yes"}})],1),(value.ldn_target_answer_yes === 'other')?_c('li',[_c('P4Input',{attrs:{"id":rowId + '-ldn_target_answer_yes_other'},model:{value:(value['ldn_target_answer_yes_other']),callback:function ($$v) {_vm.$set(value, 'ldn_target_answer_yes_other', $$v)},expression:"value['ldn_target_answer_yes_other']"}})],1):_vm._e()]):_vm._e()],1),_c('td',{staticClass:"P4-cell"},[_c('button',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"rounded-empty-btn-sm m-2 print-hide",attrs:{"id":rowId + '-other-goals-button',"type":"button"},on:{"click":function($event){_vm.goalsModalData = value.other_important_goals_for_target;
            _vm.isGoalsModalVisible = true;}}},[_vm._v(" Choose goals ")]),_vm._l((value.other_important_goals_for_target),function(val,goal){return _c('div',{key:goal,staticClass:"mb-2"},[(val === true)?_c('ul',{staticClass:"P4-ul"},[(goal === 'other')?_c('li',[_vm._v(" "+_vm._s(_vm.SO1_4_OPTIONS.T4.options.other_important_goals_for_target[ goal ])+": "+_vm._s(value.other_important_goals_for_target.items_for_other[ "other" ])+" ")]):_c('li',[_vm._v(" "+_vm._s(_vm.SO1_4_OPTIONS.T4.options.other_important_goals_for_target[ goal ])+" ")])]):_vm._e()])})],2),_c('polygon-cell',{attrs:{"value":value,"table-id":"t1"}})]}},{key:"table_footer",fn:function({ values }){return [_c('tr',[_c('td',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-cell"},[_vm._v("Total")]),_c('td',{staticClass:"P4-cell",attrs:{"colspan":"2"}}),_c('td',{staticClass:"P4-cell",attrs:{"colspan":"6"}},[_c('p',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Sum of all targeted areas")]),_c('P4DisplayValue',{attrs:{"value":_vm.calculate_total_of_all_targeted_area(values)}})],1)])]}}])}),(_vm.isActionModalVisible)?_c('InputDetailModal',{attrs:{"id":"targeted-actions-modal"},on:{"close":function($event){_vm.isActionModalVisible = false;
      _vm.actionModalData = null;}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Targeted actions")])]},proxy:true},{key:"body",fn:function(){return [_c('ul',_vm._l((_vm.SO1_4_OPTIONS.T4.options
            .remediating_actions),function({ label, options },action){return _c('li',{key:action},[_c('P4Input',{attrs:{"id":action,"label":label,"checkbox":""},model:{value:(_vm.actionModalData[action]),callback:function ($$v) {_vm.$set(_vm.actionModalData, action, $$v)},expression:"actionModalData[action]"}}),(_vm.actionModalData[action])?_c('ul',_vm._l((options),function(subLabel,subAction){return _c('li',{key:subAction,staticClass:"w-full text-gray-500 text-base pl-6"},[_c('P4Input',{attrs:{"id":action + '-' + subAction,"checkbox":"","label":subLabel},model:{value:(_vm.actionModalData['items_for_' + action][subAction]),callback:function ($$v) {_vm.$set(_vm.actionModalData['items_for_' + action], subAction, $$v)},expression:"actionModalData['items_for_' + action][subAction]"}})],1)}),0):_vm._e()],1)}),0)]},proxy:true}],null,false,382025932)}):_vm._e(),(_vm.isGoalsModalVisible)?_c('InputDetailModal',{attrs:{"id":"important-goals-modal"},on:{"close":function($event){_vm.isGoalsModalVisible = false;
      _vm.goalsModalData = null;}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Important goals")])]},proxy:true},{key:"body",fn:function(){return [_c('ul',[_vm._l((_vm.SO1_4_OPTIONS.T4.options
            .other_important_goals_for_target),function(label,goal){return _c('li',{key:goal},[_c('P4Input',{attrs:{"id":goal,"label":label,"checkbox":""},model:{value:(_vm.goalsModalData[goal]),callback:function ($$v) {_vm.$set(_vm.goalsModalData, goal, $$v)},expression:"goalsModalData[goal]"}})],1)}),_c('li',[(_vm.goalsModalData.other)?_c('P4Input',{attrs:{"id":"other_important_goals_for_target_other"},model:{value:(_vm.goalsModalData['items_for_other']['other']),callback:function ($$v) {_vm.$set(_vm.goalsModalData['items_for_other'], 'other', $$v)},expression:"goalsModalData['items_for_other']['other']"}}):_vm._e()],1)],2)]},proxy:true}],null,false,2599130537)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
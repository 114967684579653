<template>
  <p
    v-translate="{
      html_start_link: `<a class='P4-link' href='https://trends.earth/docs/en/about/layers.html'>`,
      html_end_link: `</a>`,
    }"
    render-html="true"
  >
    Please upload either a GeoTIFF file or a zip file that contains a .vrt file for a dataset contains
    multiple GeoTIFF files. The GeoTIFF files must use the World Geodetic System 1984 (WGS84) coordinate
    reference system. The dataset format must match exactly the %{ html_start_link } format used by
    Trends.Earth %{ html_end_link }. Please format your raster dataset accordingly before uploading it
    to the viewer. Please do not upload multispectral imagery or any other raster file format.
    The image should be a 16-bit signed integers single band raster.
  </p>
</template>

<script>
export default {
  name: "RasterHelp",
};
</script>

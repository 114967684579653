<template>
  <FormulateForm v-model="values" @submit="submit">
    <h2 v-translate class="P4-section-title">Spatial data License</h2>
    <h3 v-translate class="P4-caption font-semibold">Choose license</h3>

    <p
      v-translate="{
        html_start_link: `<a class='P4-link' href='https://creativecommons.org/licenses/by-nc/2.0/'>`,
        html_end_link: `</a>`,
      }"
      class="P4-guidance P4-inline-p"
      render-html="true"
    >
      As described in the reporting manual, UNCCD has an open data commitment
      and intends to share data provided by Parties with as few constraints and
      restrictions on its use as possible. Therefore, the data shared by country
      Parties will carry the Creative Commons Attribution-NonCommercial 2.0
      Generic (CC BY-NC 2.0) license. To find out more about this licence
      (translated in all UN languages),please visit this %{ html_start_link }
      link. %{ html_end_link }
    </p>

    <div class="pt-6">
      <p
        v-translate="{
          html_start_link: `<a class='P4-link' href='https://creativecommons.org/licenses/'>`,
          html_end_link: `</a>`,
        }"
        class="P4-inline-p"
        render-html="true"
      >
        If you do not intend to share your data under the terms of this licence,
        please select an alternative Creative Commons Licence (learn more %{
        html_start_link } here %{ html_end_link } about the Creative Commons
        licenses):
      </p>

      <P4Input
        id="gis_options_cc_license"
        v-model="values.cc_license"
        :label="$gettext('Creative Commons license')"
        :options="license_options"
      />

      <p v-translate>
        Alternatively provide the terms of use according to your own licence.
        Please state the licence terms and additional restrictions on the
        dataset here:
      </p>

      <P4Input
        id="gis_options_custom_license"
        v-model="values.custom_license"
        :label="$gettext('Custom license')"
        textarea
        standalone
      />
    </div>

    <FormSave ref="save" />

    <FormDebug :values="values" />
  </FormulateForm>
</template>

<script>
import { gettextLazy as _ } from "../lib/translations.js";
import { gettextLazy as $gettext } from "../lib/translations.js";

export default {
  data() {
    return {
      values: window.hydrate.data || {},
      languages: window.hydrate.languages,
      $gettext,
    };
  },

  computed: {
    license_options() {
      return {
        "": _("Choose an item"),
        cc_by_nc_sa: _(
          "Attribution-NonCommercial-ShareAlike 2.0 Generic (CC BY-NC-SA 2.0)"
        ),
        cc_by_nc_nd: _(
          "Attribution-NonCommercial-NoDerivs 2.0 Generic (CC BY-NC-ND 2.0)"
        ),
      };
    },
  },

  methods: {
    submit(data) {
      return this.$refs.save.submit(data);
    },
  },
};
</script>

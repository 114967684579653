<template>
  <div>
    <div @mouseleave="hover = false" @mouseenter="hover = true">
      <h4 v-translate class="text-white text-center">Basemaps</h4>

      <ul id="background-layers-select" class="bg-unccdblue-dark text-white">
        <li
          v-for="layer in visibleLayers"
          :key="layer.id"
          :data-layer-id="layer.id"
          class="p-2 leading-tight cursor-pointer"
          :class="{ 'bg-selectedraster': selected === layer.id }"
          @click="selectLayer(layer)"
        >
          {{ layer.title }}
        </li>
      </ul>
    </div>
    <disclaimer
      v-if="showDisclaimerModal && !dontShowDisclaimer"
      :primary-text="disclaimerPrimaryText"
      :secondary-text="disclaimerSecondaryText.toString()"
      hideable
      @close="showDisclaimerModal = false"
      @dont-show-again="dontShowAgain"
    />
    <disclaimer
      v-if="showSpecialDisclaimerModal"
      :primary-text="specialDisclaimerText"
      @close="showSpecialDisclaimerModal = false"
    />
  </div>
</template>

<script>
import { BACKGROUND_LAYERS } from "@/lib/constants";
import { createBackgroundLayer } from "@/lib/maps/layers";
import { Group } from "ol/layer";
import api from "@/lib/api";
import specialAreas from "./data/specialAreas";
import Disclaimer from "./Disclaimer";
import {
  contextualPurposesDisclaimer,
  noOpinionDisclaimer,
  terrestrialAreaDisclaimer,
} from "./data/disclaimers";

export default {
  components: { Disclaimer },
  props: {
    olMap: {
      type: Object,
      required: true,
    },
  },

  data() {
    const showSpecialDisclaimerRepeat = {};
    Object.keys(specialAreas).forEach((key) => {
      showSpecialDisclaimerRepeat[key] = true;
    });
    const disclaimerPrimaryText =
      contextualPurposesDisclaimer + " " + noOpinionDisclaimer;
    return {
      selected: null,
      hover: false,
      showDisclaimerModal: false,
      disclaimerPrimaryText,
      disclaimerSecondaryText: terrestrialAreaDisclaimer,
      dontShowDisclaimer: window.hydrate.dontShowDisclaimer,
      showSpecialDisclaimerModal: false,
      showSpecialDisclaimerRepeat,
      specialDisclaimerText: "",
      minZoomToShowSpecialDisclaimer: 5,
    };
  },

  computed: {
    isClearMap() {
      return (
        BACKGROUND_LAYERS.find((l) => l.id === this.selected).type ===
        "clearmap"
      );
    },
    layersWithDisclaimer() {
      return new Set(
        BACKGROUND_LAYERS.filter((layer) => layer.disclaimer).map(
          (layer) => layer.id
        )
      );
    },
    visibleLayers() {
      if (this.hover) {
        return BACKGROUND_LAYERS;
      } else {
        return BACKGROUND_LAYERS.filter((l) => l.id === this.selected);
      }
    },
  },

  watch: {
    selected: {
      handler() {
        for (const layer of BACKGROUND_LAYERS) {
          const isVisible = layer.id === this.selected;
          this.backgroundLayers[layer.id].setVisible(isVisible);
        }

        this.showDisclaimerModal = this.layersWithDisclaimer.has(this.selected);
      },
    },
  },

  mounted() {
    this.backgroundLayers = {};
    BACKGROUND_LAYERS.forEach((layer) => {
      this.backgroundLayers[layer.id] = createBackgroundLayer(layer);
    });

    this.olMap.addLayer(
      new Group({
        layers: Object.values(this.backgroundLayers),
      })
    );

    this.selected = BACKGROUND_LAYERS[0].id;

    this.olMap.on("moveend", () => {
      if (
        this.isClearMap &&
        this.olMap.getView().getZoom() > this.minZoomToShowSpecialDisclaimer
      ) {
        const visibleExtent = this.olMap.getView().calculateExtent();

        Object.keys(specialAreas).forEach((key) => {
          if (
            this.showSpecialDisclaimerRepeat[key] &&
            specialAreas[key].geometry.intersectsExtent(visibleExtent)
          ) {
            this.specialDisclaimerText =
              specialAreas[key].disclaimerText.toString();
            this.showSpecialDisclaimerModal = true;
            this.showSpecialDisclaimerRepeat[key] = false;
          }
        });
      }
    });
  },
  methods: {
    selectLayer(layer) {
      this.selected = layer.id;
      this.hover = false;
    },
    async dontShowAgain() {
      this.dontShowDisclaimer = true;
      this.showDisclaimerModal = false;
      await api.dismissDisclaimer();
    },
  },
};
</script>

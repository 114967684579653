<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t8"
      :columns="[
        '',
        $gettext('Area (km²)'),
        isAffectedAreas
          ? $gettext('Percent of total affected area (%)')
          : $gettext('Percent of total land area (%)'),
      ]"
      :warnings="[
        { name: 'maxTotalCountryWarning', args: ['baseline_period', total, year] },
      ]"
    >
      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          <Tooltip :content="OPTIONS.BASELINE[value.id].tooltip">
            {{ OPTIONS.BASELINE[value.id].name }}
            <span class="tooltip-icon">ⓘ</span>
          </Tooltip>
        </th>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-baseline_period'"
            v-model="value.baseline_period"
            :default-value-has-changed="defaultValueHasChanged(
              ['t8', rowIndex, 'baseline_period'],
              value.baseline_period
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
           <P4DisplayValue :value="formatPercent(value.baseline_period, total) "/>
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import formatPercent from "@/lib/formatPercent";
import defaultValuesChange from '../../../lib/mixins/defaultValuesChange';

export default {
  mixins: [defaultValuesChange],
  props: {
    total: {
      type: [Number, String],
      required: false,
      default: null,
    },
    year: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      $gettext,
      isAffectedAreas: window.hydrate.is_aa_form,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
  },
  methods: {
    formatPercent,
  },
};
</script>

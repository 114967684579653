import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  Form: {
    yes_no: {
      yes: _("Yes"),
      no: _("No"),
    },
  },
  T2: {
    degradation: {
      "": "",
      urban_expansion: _("Urban Expansion"),
      deforestation: _("Deforestation"),
      vegetation_loss: _("Vegetation Loss"),
      inundation: _("Inundation"),
      woody_encroachment: _("Woody Encroachment"),
      wetland_drainage: _("Wetland Drainage"),
      other: _("Other"),
    },
  },
  BASELINE: {
    land_area_with_degraded_land_productivity: {
      name: _("Land area with degraded land cover"),
      tooltip: _(
        "Area degraded due to land cover change in the baseline period"
      ),
    },
    land_area_with_land_productivity_not_degraded: {
      name: _("Land area with non-degraded land cover"),
      tooltip: _(
        "Area not degraded based on land cover change in the baseline period"
      ),
    },
    land_area_with_no_data_for_land_productivity: {
      name: _("Land area with no land cover data"),
      tooltip: _("Area not reported due to a lack of valid land cover data"),
    },
  },
  REPORTING: {
    land_area_with_improved_land_productivity: {
      name: _("Land area with improved land cover"),
      tooltip: _(
        "Area improved with respect to land cover change degradation in the reporting period"
      ),
    },
    land_area_with_stable_land_productivity: {
      name: _("Land area with stable land cover"),
      tooltip: _(
        "Area stable with respect to land cover change degradation in the reporting period"
      ),
    },
    land_area_with_degraded_land_productivity: {
      name: _("Land area with degraded land cover"),
      tooltip: _(
        "Area degraded due to land cover change degradation in the reporting period"
      ),
    },
    land_area_with_no_data_for_land_productivity: {
      name: _("Land area with no land cover data"),
      tooltip: _("Area not reported due to a lack of valid land cover data"),
    },
  },
  T4: {
    "": "",
    degradation: "-",
    improvement: "+",
    stable: "0",
  },
};

export default function () {
  const queryString = window.location.search;
  const query = {};
  const pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (const [k, v] of pairs.map((p) => p.split("="))) {
    query[decodeURIComponent(k)] = decodeURIComponent(v || "");
  }
  return query;
}

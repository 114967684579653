function parseNumber(val) {
  // JS is REALLY fun and converts null to 0.
  return val === null ? NaN : Number(val);
}

export default {
  /**
   * Extract the specified property from each row of the table, and
   * check if the sum is less than or equal to the specified amount.
   *
   * Usage example:
   *
   *     <FormulateInput
   *       type="multitable"
   *       :validation="[
   *         ['maxTotal', 'value', 12345]
   *       ]"
   *       show-errors
   *     >
   *
   * OR
   *
   *     <FormulateInput
   *       type="multitable"
   *       validation="maxTotal:value,12345"
   *       show-errors
   *     >
   *
   * If the sum or the specified maxValue is not a number the validation
   * check is not performed.
   *
   */
  maxTotal(context, propertyName, maxValue) {
    const parsedMaxValue = parseNumber(maxValue);
    const computedTotal = context.value.reduce(
      (sum, item) => sum + parseNumber(item[propertyName]),
      0
    );
    return (
      isNaN(computedTotal) ||
      isNaN(parsedMaxValue) ||
      parsedMaxValue == 0 ||
      computedTotal <= parsedMaxValue
    );
  },

  exceedTotal(currentValue, maxValue) {
    const parsedCurrentValue = parseNumber(currentValue);
    const parsedMaxValue = parseNumber(maxValue);
    return (
      isNaN(parsedCurrentValue) ||
      isNaN(parsedMaxValue) ||
      parsedMaxValue == 0 ||
      parsedCurrentValue <= parsedMaxValue
    );
  },
  /**
   * Will consider that a value is unique if it exists only once in the contextModel
   * @param {Object} item
   * @param {string|number} item.value - value of Country legend class or Country legend class code
   * @param {string} propertyName - Country legend class or Country legend class code
   * @param {Object[][]} contextModel - all the values inside the table
   * @returns {boolean} - classIsDuplicate
   */
  uniqueCustomLandcover(item, propertyName, contextModel) {
    const classFoundInstancesInModel = contextModel.filter(
      (classItem) => classItem[propertyName] === item.value
    );
    const classIsDuplicate = !(classFoundInstancesInModel.length > 1);

    return classIsDuplicate;
  },

  roundTo2(context) {
    let regex = /^\d+\.?\d{0,2}?$/;
    return regex.test(context.value);
  },

  percent(context) {
    let value = Number(context.value);
    return isNaN(value) || (value >= 0 && value <= 100);
  },

  integer(context) {
    let value = context.value;
    if (value.indexOf(".") != -1) return false;
    return !isNaN(Number(value));
  },

  number16Bit(context) {
    let value = Number(context.value);
    return isNaN(value) || (value >= -32768 && value <= 32767);
  },

  year(context, start = 2018, end = 2100) {
    let value = Number(context.value);
    return (
      !isNaN(value) &&
      !context.value.includes(".") &&
      value >= start &&
      value <= end
    );
  },
};

<template>
  <section>
    <P4Form v-model="values">
      <h2 v-translate class="P4-section-title print-hide">
        Average proportion of Terrestrial Key Biodiversity Areas covered by
        protected areas
      </h2>

      <p v-translate class="P4-guidance">
        This indicator uses the average proportion of Terrestrial Key Biodiversity
        Areas (KBAs) covered by protected areas as a metric. The average
        proportion of Terrestrial KBAs covered by protected areas is Sustainable
        Development Goal (SDG) indicator 15.1.2 (b).
      </p>

      <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
        SO4-3.T1: National estimates of the average proportion of Terrestrial
        KBAs covered by protected areas (%)
      </h3>

      <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
        SO4-3.T1: Affected area estimates of the average proportion of
        Terrestrial KBAs covered by protected areas (%)
      </h3>

      <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
        Report the average proportion of Terrestrial KBAs covered by protected
        areas for your country in the table below. This table is pre-filled from
        the SDG database, including the national level value as well as the
        upper and lower bounds of uncertainty around the national estimate. Any
        deviation from the guidance provided should be reported and justified in
        the ‘comment’ column of the reporting table.
      </p>
      <p v-if="isAffectedAreas" v-translate class="P4-guidance">
        Report the average proportion of Terrestrial KBAs covered by protected
        areas for affected areas in your country in the table below, including
        the the upper and lower bounds of uncertainty around the affected area
        estimate. Any deviation from the guidance provided should be reported
        and justified in the ‘comment’ column of the reporting table.
      </p>
      <CommentAnchor :name="form_id + '-T1'" />
      <T1 />
      <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />

      <h2 v-translate class="P4-section-title">Qualitative assessment</h2>
      <h3 v-translate class="P4-caption">
        SO4-3.T2: Interpretation of the indicator
      </h3>
      <p v-translate class="P4-guidance">
        Based on the quantitative data, please leave comments on its
        interpretation, including the direction in which the indicator changes.
        While it may be difficult to attribute specific causal factors to
        changes in the indicator, countries are encouraged to indicate which
        direct and/or indirect drivers are presumably behind the observed
        changes using the comments box in the table below.
      </p>
      <CommentAnchor :name="form_id + '-T2'" />
      <T2 />
      <h2 v-translate class="P4-section-title">General comments</h2>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input v-model="values.so4_3_general_comments" textarea standalone />
      </div>
    </P4Form>
  </section>
</template>

<script>
import { allOptions } from "../../../lib/translations.js";
import OPTIONS from "./OPTIONS.js";
import T1 from "./T1.vue";
import T2 from "./T2.vue";
import getYears from "../../../lib/getYears.js";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

function valuesForAllOptions() {
  const keys = Object.keys(OPTIONS.T2.qualitative_assessment);

  return {
    t2: keys.map((qualitative_assessment) => ({ qualitative_assessment })),
  };
}

export default {
  components: {
    T1,
    T2,
  },
  mixins: [restoreDefaultData],

  data() {
    const values = {
      t1: getYears(2000, 2020).map((y) => ({ year: y.toString() })),
      ...window.hydrate.data,
    };

    if (allOptions()) {
      Object.assign(values, valuesForAllOptions());
    }

    return {
      values,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
    };
  },
};
</script>

const CHARACTERS = "abcdefghijklmnopqrstuvwxyz0123456789";

function randCh(length) {
  var result = "";
  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * CHARACTERS.length);
    result += CHARACTERS.charAt(index);
  }
  return result;
}

export default function randomId() {
  // Generates ~103 bits of randomness
  // log2(36 ** (4 * 5)) ~= 103
  return `${randCh(5)}-${randCh(5)}-${randCh(5)}-${randCh(5)}`;
}

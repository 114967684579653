<template>
  <pre id="debug-model">{{ values }}</pre>
</template>

<script>
export default {
  props: {
    values: {
      type: Object,
      required: true,
    },
  },
};
</script>

import { mapState } from 'vuex';

export default {
  computed: mapState({
    defaultFormData: state => state.defaultFormData
  }),
  methods: {
    /**
     * Will use reduce on the keys to search through defaultFormData until it finds the value on the last key in the array of keys
     * @param {string[]} inputDefaultValueKeys - array of keys to be used to find the default value of the input
     * @param {*} inputCurrentValue - current value in the field to be checked if it has been changed from the default
     * @returns {boolean}
     */
    defaultValueHasChanged(inputDefaultValueKeys, inputCurrentValue) {
      const inputDefaultValue = inputDefaultValueKeys?.reduce ? inputDefaultValueKeys?.reduce((accumulator, currentValue) => {
        return accumulator?.[currentValue] || null
      }, this.defaultFormData) : null

      return (inputDefaultValue && inputDefaultValue !== inputCurrentValue) || false;
    },
  }
};

<template>
  <section>
    <ExposedTable
      name="t2"
      :population-type="$gettext('Exposed female population')"
    />
  </section>
</template>

<script>
import ExposedTable from "@/forms/so3/2/ExposedTable";
export default {
  components: { ExposedTable },
};
</script>

import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  VT_T1: {
    target: {
      name: _("Target"),
      tooltip: _(
        "Articulate, in quantifiable and time-bound terms, voluntary targets set by your country that contribute to avoiding, reducing and reversing land degradation."
      ),
    },
    year: {
      name: _("Year"),
      tooltip: _(
        "Expected year of target achievement or actual year (if already achieved), from 2018 onwards."
      ),
    },
    locations: {
      name: _("Location(s)"),
      tooltip: _(
        "State the location of the target area, e.g. by placename. Multiple entries allowed, e.g. if the target concerns more than one location."
      ),
    },
    total_target_area: {
      name: _("Total Target Area (km²)"),
      tooltip: _(
        "The spatial extent of the target area. This will be auto populated if spatial data is provided in PRAIS."
      ),
    },
    overarching: {
      name: _(
        "Overarching type of Land Degradation Neutrality (LDN) intervention"
      ),
      tooltip: _(
        "Relevance of the target to the LDN response hierarchy. Select more than 1 option if the target is aimed at two levels of the hierarchy simultaneously."
      ),
    },
    targeted_actions: {
      name: _("Targeted action(s)"),
      tooltip: _(
        "Describe the actions outlined in the target. First, select one or more relevant groups of measures, then select all specific restoration measures that apply."
      ),
    },
    status_of_target_achievement: {
      name: _("Status of target achievement"),
      tooltip: _(
        "Indicate if the target has been achieved, is ongoing, extended, not achieved or partially achieved. If partially achieved, provide the completion percentage of the LDN targets, as this would be a valuable and practical approach to measure progress quantitatively."
      ),
    },
    ldn_target: {
      name: _(
        "Is this an LDN target? If so, under which process was it defined/adopted?"
      ),
      tooltip: _(
        "State if this target has been set in the context of the LDN initiative. If this is an LDN target, define under which process it was defined/adopted. This will enable the UNCCD to get a full overview of LDN targets after the reporting process is completed."
      ),
    },
    important_goals: {
      name: _(
        "Which other important goals are also being addressed by this target?"
      ),
      tooltip: _(
        "Targets set under the UNCCD can also address commitments made in other Rio Conventions and associated initiatives. Please select other goals of relevance for this target."
      ),
    },
    polygon: {
      name: _("Edit Polygon"),
      tooltip: _(
        "A button will appear in this column after adding a feature in the Spatial data viewer"
      ),
    },
  },
  AI_T1: {
    relevant_target: {
      name: _("Relevant Target"),
      tooltip: _(
        "Select one of the targets entered in the table above for which you are reporting on specific actions. If possible, provide spatial data using PRAIS to delineate the areas of implemented action. It is still possible to document the actions without spatial data."
      ),
    },
    implemented_action: {
      name: _("Implemented Action"),
      tooltip: _(
        "Describe the implemented action – is it the same as the targeted action? If not, select ‘’Other” and provide information in the textbox."
      ),
    },
    location: {
      name: _("Location (placename)"),
      tooltip: _(
        "State the location of action areas, e.g. by giving a placename."
      ),
    },
    date_action_started: {
      name: _("Action start date"),
      tooltip: _("Please provide an action start date."),
    },
    extent_of_action: {
      name: _("Extent of action"),
      tooltip: _(
        "The spatial extent of the implemented action. This will be auto populated if spatial data is provided in PRAIS. Otherwise, estimate the extent and enter the area (in km²)."
      ),
    },
    total_area_implemented: {
      name: _("Total Area Implemented So Far (km²)"),
      tooltip: _(
        "Sum of all areas relevant to actions under a specific target."
      ),
    },
    polygon: {
      name: _("Edit Polygon"),
      tooltip: _(
        "A button will appear in this column after adding a feature in the Spatial data viewer"
      ),
    },
  },
};

import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  T2: {
    change: {
      negative: _("Negative"),
      positive: _("Positive"),
    },
    drivers_direct: {
      "": "",
      land_use_change: _("Land-use change"),
      overexploitation: _("Overexploitation"),
      climate_change: _("Climate change"),
      pollution: _("Pollution"),
      invasive_alien_species: _("Invasive alien species"),
    },
    drivers_indirect: {
      "": "",
      production_and_consumption_patterns: _(
        "Production and Consumption Patterns"
      ),
      human_population_dynamics_and_trends: _(
        "Human Population Dynamics and Trends"
      ),
      trade: _("Trade"),
      technological_innovations: _("Technological Innovations"),
      local_to_global_governance: _("Local to Global Governance"),
    },
    change_levers: {
      "": "",
      incentives_and_capacity_building: _("Incentives and Capacity-Building"),
      cross_sectoral_cooperation: _("Cross-Sectoral Cooperation"),
      pre_emptive_action: _("Pre-Emptive Action"),
      decision_making_in_the_context_of_resilience_and_uncertainty: _(
        "Decision-making in the Context of Resilience and Uncertainty"
      ),
      environmental_law_and_implementation: _(
        "Environmental Law and Implementation"
      ),
    },
    change_responses: {
      "": "",
      land_water_management: _("Land / Water Management"),
      species_management: _("Species Management"),
      awareness_raising: _("Awareness Raising"),
      law_enforcement_prosecution: _("Law Enforcement & Prosecution"),
      livelihood_economic_moral_incentives: _(
        "Livelihood, Economic & Moral Incentives"
      ),
      conservation_designation_planning: _(
        "Conservation Designation & Planning"
      ),
      legal_policy_frameworks: _("Legal & Policy Frameworks"),
      research_monitoring: _("Research & Monitoring"),
      education_training: _("Education & Training"),
      institutional_development: _("Institutional Development"),
    },
  },
};

import Form_1 from "./1/Form.vue";
import Form_2 from "./2/Form.vue";
import Form_3 from "./3/Form.vue";
import Form_4 from "./4/Form.vue";
import Form_v from "./v/Form.vue";

export default {
  1: Form_1,
  2: Form_2,
  3: Form_3,
  4: Form_4,
  v: Form_v,
};

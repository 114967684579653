<template>
  <div :id="comment.id" :class="{ 'P4-comment-reply': index !== 0 }">
    <div :class="{ 'P4-comment ml-6': index !== 0, 'P4-comment': index === 0 }">
      <div
        v-translate="{
          html_start_small_author: `<small class='P4-comment-author'>`,
          html_start_small_date: `<small class='P4-comment-date'>`,
          html_start_small: `<small>`,
          html_end_small: `</small>`,
          email: comment.author.email,
          nameTag: nameTag,
          created: comment.created,
        }"
        class="mx-8"
        render-html="true"
      >
        %{ html_start_small_author } %{ email } %{ html_end_small } %{
        html_start_small } %{ nameTag } %{ html_end_small } commented on %{
        html_start_small_date } %{ created } %{ html_end_small }
      </div>

      <div class="P4-comment-content">
        <div v-if="edit && !isReadOnly">
          <FormulateForm
            class="bg-white text-black px-4 py-3"
            @submit="submitEditComment"
          >
            <FormulateInput
              type="textarea"
              name="message"
              :value="comment.message"
              validation="required"
            />
            <FormulateInput type="hidden" name="id" :value="comment.id" />
            <FormulateInput type="submit" :label="$gettext('Save')" />
          </FormulateForm>
        </div>
        <span v-else>{{ comment.message }}</span>
      </div>

      <button
        v-if="loggedUser === comment.author.email"
        class="font-semibold hover:bg-blue-400"
        @click.prevent="edit = !edit"
      >
        <span v-if="edit" v-translate> Cancel </span>
        <span v-else v-translate> Edit </span>
      </button>
    </div>
  </div>
</template>

<script>
import api from "../api";
import { gettextLazy as $gettext } from "../translations.js";

export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    comment: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    loggedUser: {
      type: String,
      default: "",
    },
    isReadOnly: Boolean,
  },
  data() {
    return {
      context: this.$attrs.context,
      isHiddenNewReply: true,
      edit: false,
      $gettext,
    };
  },
  computed: {
    isEditMode() {
      return this.edit === true;
    },
    nameTag() {
      if (this.comment.author.is_reporting_officer)
        return $gettext("Reporting Officer");
      else if (this.comment.author.is_staff)
        return $gettext("UNCCD Secretariat");
      else if (this.comment.author.is_unccd_consultant)
        return $gettext("UNCCD Consultant");
      else return "";
    },
  },

  created() {},

  methods: {
    async submitEditComment(data) {
      try {
        await api.updateComment("comment", data);
        this.$parent.$emit("changeComment", true);
        this.edit = false;
      } catch (e) {
        this.$emit("errorMessage", e);
        throw e;
      }
    },
  },
};
</script>

<template>
  <section>
    <FormulateInput
      type="multitable"
      :name="name"
      :columns="[$gettext('Original/ Final')].concat(headingNames)"
    >
      <template v-if="!T3HasEmptyCell" #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          {{ headingNames[rowIndex] }}
        </th>
        <td
          v-for="(transition_to, index) in headingKeys"
          :key="`${transition_to}-${index}`"
          class="P4-cell-transition-matrix text-center"
          :class="{
            'bg-green-300': value[transition_to] === 'improvement',
            'bg-red-300': value[transition_to] === 'degradation',
            'bg-yellow-100': value[transition_to] === 'stable',
            'bg-gray-100':
              value[transition_to] === '' ||
              value['unlikely_transition-' + transition_to] === true,
          }"
        >
          <div class="transition-cell print-hide">
            <div class="text-right">
              <P4Input
                v-if="!value['unlikely_transition-' + transition_to]"
                :id="rowId + '-' + transition_to"
                v-model="value[transition_to]"
                :default-value-has-changed="
                  defaultValueHasChanged(
                    ['t4', rowIndex, transition_to],
                    value[transition_to]
                  )
                "
                :options="OPTIONS.T4"
              />
            </div>
            <Tooltip :content="$gettext('Unlikely Transition')">
              <P4Input
                :id="rowId + '-unlikely_transition-' + transition_to"
                v-model="value['unlikely_transition-' + transition_to]"
                :default-value-has-changed="
                  defaultValueHasChanged(
                    ['t4', rowIndex, 'unlikely_transition-' + transition_to],
                    value['unlikely_transition-' + transition_to]
                  )
                "
                checkbox
              />
            </Tooltip>
          </div>
          <!-- The default print version is not really readable, so have this custom version here -->
          <div class="print-show mr-2 ml-2">
            <span v-if="!value['unlikely_transition-' + transition_to]">
              {{ OPTIONS.T4[value[transition_to]] }}
            </span>
            <span v-else>
              {{ $gettext("Unlikely Transition") }}
            </span>
          </div>
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { gettextLazy as $gettext } from "@/lib/translations.js";
import T3HeadingsMixIn from "@/forms/so1/1/customT3HeadingsMixIn";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange, T3HeadingsMixIn],
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    T3HasEmptyCell() {
      return this.headingKeys.filter(key => typeof key === "undefined" || key === "").length > 0;
    },
  },
};
</script>

<style scoped>
.transition-cell {
  display: grid;
  padding: 0.5rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  align-items: center;
}
</style>

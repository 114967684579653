<template>
  <div class="flex-grow overflow-y-hidden">
    <div class="absolute z-10 flex flex-row left-1/3 pointer-events-none">
      <div class="text-right">
        <div
          class="w-64 hover:border-l-8 hover:border-r-8 border-unccdorange inline-block text-left m-1 bg-unccdblue rounded p-1 pointer-events-auto"
        >
          <BackgroundLayers :ol-map="olMap" />
        </div>
      </div>

      <div class="text-right">
        <div
          class="w-80 hover:border-l-8 hover:border-r-8 border-unccdorange inline-block text-left m-1 bg-unccdblue rounded p-1 pointer-events-auto"
        >
          <RasterLayers
            v-if="border"
            :ol-map="olMap"
            :border="border"
            @change="selectRasterLayer"
          />
        </div>
      </div>

      <div class="text-right">
        <div
          class="w-64 hover:border-l-8 hover:border-r-8 border-unccdorange inline-block text-left m-1 bg-unccdblue rounded p-1 pointer-events-auto"
        >
          <VectorLayers
            v-if="border"
            v-model="selectedVectorLayer"
            :ol-map="olMap"
            :vector-layers="vectorLayers"
            :border="border"
          />
        </div>
      </div>
    </div>
    <div id="map-component" class="relative mt-24">
      <div id="map" class="map" />
      <div class="spinner" />

      <section class="map-edit-panel">
        <div
          class="m-1 bg-gray-400 bg-opacity-90 rounded p-1 pointer-events-auto"
        >
          <EditPanel
            v-if="selectedVectorLayer"
            :ol-map="olMap"
            :layer="selectedVectorLayer"
            :is-false-positives-negatives-with-te-origin="
              isFalsePositivesNegativesWithTeOrigin
            "
            :raster="selectedRasterLayer"
          />

          <div id="legend" class="mt-3">
            <p v-translate class="font-bold mb-2">Legend</p>

            <img
              v-if="selectedRasterLayer && selectedRasterLayer.legend_url"
              :src="selectedRasterLayer.legend_url"
              alt="Legend"
            />
            <p v-else v-translate class="mt-2">No raster data available</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BackgroundLayers from "./BackgroundLayers.vue";
import RasterLayers from "./RasterLayers.vue";
import VectorLayers from "./VectorLayers.vue";
import EditPanel from "./EditPanel.vue";
import { Attribution, FullScreen, ScaleLine, Zoom } from "ol/control";
import { Map, View } from "ol";
import { BORDER_CODE } from "../lib/constants";
import api from "../lib/api";

export default {
  components: {
    BackgroundLayers,
    RasterLayers,
    VectorLayers,
    EditPanel,
  },
  data() {
    return {
      olMap: null,
      selectedVectorLayer: null,
      selectedRasterLayer: null,
      uploadRaster: false,
      vectorLayers: window.hydrate.vectorLayers,
      isFalsePositivesNegativesWithTeOrigin:
        window.hydrate.is_false_positives_negatives_with_te_origin,
      border: null,
    };
  },
  created() {
    this.olMap = new Map({
      view: new View(),
      controls: [
        new FullScreen(),
        new Zoom({
          className: "custom-zoom",
        }),
        new ScaleLine({
          units: "metric",
          bar: true,
          steps: 4,
          text: true,
          minWidth: 140,
          className: "scale-bar",
        }),
        new Attribution({
          collapsed: false,
        }),
      ],
    });
  },

  async mounted() {
    this.olMap.setTarget("map");
    await this.getBorder();
  },

  updated() {
    this.olMap.updateSize();
  },
  methods: {
    selectRasterLayer(selectedRaster) {
      this.selectedRasterLayer = window.hydrate.rasters.find((raster) => {
        return raster.code === selectedRaster.code;
      });
      this.selectedRasterLayer.data = selectedRaster.data;
    },
    async getBorder() {
      this.border = await api.loadVectorData(`./vector_layer/${BORDER_CODE}`);
    },
  },
};
</script>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t3"
      :column-count="3"
      :columns="[
        $gettext('Indicator metric'),
        $gettext('Change in the indicator'),
        $gettext('Comments'),
      ]"
      :label-for-new="$gettext('+ Add Record')"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-indicator_metric'"
            v-model="value.indicator_metric"
            :options="OPTIONS.relevant_metric"
          />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-change_in_the_indicator'"
            v-model="value.change_in_the_indicator"
            :options="CHANGES_IN_THE_INDICATOR"
          />
        </td>
        <td class="P4-cell">
          <P4Input :id="rowId + '-comments'" v-model="value.comments" textarea/>
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { CHANGES_IN_THE_INDICATOR } from "../../../lib/constants.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS() {
      return OPTIONS;
    },
    CHANGES_IN_THE_INDICATOR() {
      return CHANGES_IN_THE_INDICATOR;
    },
  },
};
</script>

<template>
  <P4Form v-model="values">
    <section>
      <h2 v-translate class="P4-section-title">
        Increasing the mobilization of resources:
      </h2>

      <p v-translate class="P4-guidance">
        Increasing the mobilization of financial and non-financial resources for
        the implementation of the Convention from international and domestic,
        public and private sources as well as from local communities, including
        non-traditional funding sources, and climate finance.
      </p>

      <h4 v-translate class="P4-question">
        Would you like to share an experience on how your country has increased
        the mobilization of resources within the reporting period?
      </h4>

      <P4Input
        id="increase_mobilisation_of_resources"
        v-model="values.increase_mobilisation_of_resources"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !increaseMobilisationOfResources }">
        <h4 v-translate class="P4-question">
          What type of resources were mobilized (check all that apply)?
        </h4>

        <P4Input
          v-for="(option, index) in OPTIONS.financial"
          :id="'if_financial_mobilization-' + index"
          :key="'for_increase_mobilisation_of_resources-' + index"
          v-model="values.for_increase_mobilisation_of_resources[index]"
          :name="'for_increase_mobilisation_of_resources-' + index"
          :label="option"
          checkbox
        />

        <div>
          <h4 v-translate class="P4-question">Which sources were mobilized?</h4>

          <ul>
            <li
              v-for="(option, index) in OPTIONS.mobilized_sources"
              :key="'mobilized_sources-' + index"
            >
              <P4Input
                :id="'if_financial_mobilized_sources-' + index"
                v-model="values.mobilized_sources[index]"
                :name="'mobilized_sources-' + index"
                :label="option"
                checkbox
              />
            </li>
            <li>
              <P4Input
                v-if="values['mobilized_sources']['other']"
                id="mobilized_sources_other"
                v-model="values.mobilized_sources_other"
              />
            </li>
          </ul>
        </div>

        <h4 v-translate class="P4-question">
          Use this space to describe the experience:
        </h4>

        <P4Input
          id="if_financial_mobilized_sources_experience"
          v-model="values.mobilized_sources_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_financial_mobilized_sources_challenges"
          v-model="values.mobilized_sources_faced_challenges"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What do you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_financial_mobilized_sources_lessons_learned"
          v-model="values.mobilized_sources_lessons_learned"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          How did you ensure that women benefited from/got access to this
          funding?
        </h4>

        <P4Input
          id="if_financial_mobilized_sources_women_funding"
          v-model="values.mobilized_sources_women_funding"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Use this space to provide any further complementary information you
          deem relevant:
        </h4>

        <P4Input
          id="if_financial_mobilized_sources_complementary_info"
          v-model="values.mobilized_sources_complementary_info"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Has your country supported other countries in the mobilization of
          financial and non-financial resources for the implementation of the
          Convention?
        </h4>

        <P4Input
          id="if_financial_supported_other_countries"
          v-model="values.supported_other_countries"
          radio
          :options="OPTIONS.yes_no"
        />

        <div :class="{ hidden: !supportedOtherCountries }">
          <h4 v-translate class="P4-question">
            Use this space to describe the experience:
          </h4>

          <P4Input
            id="if_financial_supported_other_countries_experience"
            v-model="values.supported_other_countries_experience"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            What were the challenges faced, if any?
          </h4>
          <P4Input
            id="if_financial_supported_other_countries_challenges"
            v-model="values.supported_other_countries_faced_challenges"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            Was part of the funding earmarked for women and/or women led
            activities/businesses?
          </h4>

          <P4Input
            id="if_financial_supported_other_countries_women_funding"
            v-model="values.supported_other_countries_women_funding"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            What do you consider to be the lessons learned?
          </h4>

          <P4Input
            id="if_financial_supported_other_countries_lessons_learned"
            v-model="values.supported_other_countries_lessons_learned"
            textarea
            standalone
          />
        </div>
      </div>
    </section>

    <section>
      <h2 v-translate class="P4-section-title">
        Using Land Degradation Neutrality as a framework to increase investment:
      </h2>

      <p v-translate class="P4-guidance">
        Taking advantage of the opportunity to use Land Degradation Neutrality
        (LDN) as a framework to enhance the coherence, effectiveness and
        multiple benefits of investments.
      </p>

      <h4 v-translate class="P4-question">
        From your perspective, would you consider that you have taken advantage
        of the LDN concept to enhance the coherence, effectiveness and multiple
        benefits of investments?
      </h4>

      <P4Input
        id="if_financial_ldn_enhance"
        v-model="values.ldn_enhance_coherence"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !ldnEnhanceCoherence }">
        <h4 v-translate class="P4-question">
          Use this space to describe the experience:
        </h4>

        <P4Input
          id="if_financial_ldn_experience"
          v-model="values.ldn_enhance_coherence_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_financial_ldn_challenges"
          v-model="values.ldn_enhance_coherence_faced_challenges"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What do you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_financial_ldn_lessons_learned"
          v-model="values.ldn_enhance_coherence_lessons_learned"
          textarea
          standalone
        />
      </div>
    </section>

    <section>
      <h2 v-translate class="P4-section-title">
        Improving existing and/or innovative financial processes and
        institutions
      </h2>

      <p v-translate class="P4-guidance">
        Improving the use of existing and/or innovative financial processes and
        institutions (such as the Global Environment Facility (GEF) or other
        newer funds).
      </p>

      <h4 v-translate class="P4-question">
        From your perspective, do you consider that your country has improved
        the use of existing and/or innovative financial processes and
        institutions?
      </h4>

      <P4Input
        id="if_financial_improved_financial_processes"
        v-model="values.improved_innovative_financial_processes"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !improvedInnovativeFinancialProcesses }">
        <h4 v-translate class="P4-question">
          Was this through any of the following (check all that apply)?
        </h4>

        <ul>
          <li
            v-for="(
              option, index
            ) in OPTIONS.improved_innovative_financial_processes"
            :key="index"
          >
            <P4Input
              :id="'if_financial_improved_financial_processes-' + index"
              :key="'improved_innovative_financial_processes-' + index"
              v-model="
                values.improved_innovative_financial_processes_options[index]
              "
              :name="'improved_innovative_financial_processes-' + index"
              :label="option"
              checkbox
            />
          </li>
          <li>
            <P4Input
              v-if="
                values.improved_innovative_financial_processes_options[
                  'other_funds'
                ]
              "
              id="improved_innovative_financial_processes_other_funds"
              v-model="
                values.improved_innovative_financial_processes_other_funds
              "
            />
          </li>
        </ul>

        <h4 v-translate class="P4-question">
          Use this space to describe the experience:
        </h4>

        <P4Input
          id="if_financial_improved_financial_processes_experience"
          v-model="values.improved_innovative_financial_processes_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_financial_improved_financial_processes_challenges"
          v-model="
            values.improved_innovative_financial_processes_faced_challenges
          "
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What do you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_financial_improved_financial_processes_lessons_learned"
          v-model="
            values.improved_innovative_financial_processes_lessons_learned
          "
          textarea
          standalone
        />
        <h4 v-translate class="P4-question">
          Did your country support other countries in the improvement of
          existing or innovative financial processes and institutions?
        </h4>

        <P4Input
          id="if_financial_improved_innovative_supported_other_countries"
          v-model="values.improved_innovative_supported_other_countries"
          radio
          :options="OPTIONS.yes_no"
        />

        <div :class="{ hidden: !improvedInnovativeSupportedOtherCountries }">
          <h4 v-translate class="P4-question">
            Use this space to describe the experience:
          </h4>

          <P4Input
            id="if_financial_improved_other_countries_experience"
            v-model="values.improved_innovative_experience"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            What were the challenges faced, if any?
          </h4>

          <P4Input
            id="if_financial_improved_other_countries_challenges"
            v-model="values.improved_innovative_faced_challenges"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            What do you consider to be the lessons learned?
          </h4>

          <P4Input
            id="if_financial_improved_other_countries_lessons_learned"
            v-model="values.improved_innovative_lessons_learned"
            textarea
            standalone
          />
        </div>
      </div>
    </section>
  </P4Form>
</template>

<script>
import OPTIONS from "../OPTIONS.js";

export default {
  data() {
    const values = {
      for_increase_mobilisation_of_resources: {},
      mobilized_sources: {},
      improved_innovative_financial_processes_options: {},

      ...window.hydrate.data,
    };

    return {
      values,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,

    increaseMobilisationOfResources() {
      return this.values["increase_mobilisation_of_resources"] === "yes";
    },
    supportedOtherCountries() {
      return this.values["supported_other_countries"] === "yes";
    },
    ldnEnhanceCoherence() {
      return this.values["ldn_enhance_coherence"] === "yes";
    },
    improvedInnovativeFinancialProcesses() {
      return this.values["improved_innovative_financial_processes"] === "yes";
    },
    improvedInnovativeSupportedOtherCountries() {
      return (
        this.values["improved_innovative_supported_other_countries"] === "yes"
      );
    },
  },
};
</script>

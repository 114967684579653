<template>
  <section>
    <p v-translate class="P4-guidance">
      Changes in methodologies and availability of new and improved data sources
      may require periodic recalculation of previously submitted national
      estimates on commencement of a new reporting process. Recalculations
      should ensure the consistency and integrity of the time series so that the
      baseline and reporting process monitoring data are intercomparable.
    </p>

    <P4Form v-model="values">
      <h3 v-translate class="P4-caption">
        RC.T1: Recalculation of the baseline period, as reported in 2018.
      </h3>

      <p v-translate class="P4-guidance">
        In case baseline estimates of the indicator were recalculated, please
        provide a justification with explanatory information, and discuss any
        impact of the recalculation on the baseline estimates (compared with the
        baseline reported in 2018) and on previously submitted national targets.
      </p>

      <T1 :indicators="indicators" />
    </P4Form>
  </section>
</template>

<script>
import { allOptions } from "../../lib/translations.js";
import T1 from "./T1.vue";

function valuesForAllOptions() {
  return {
    t1: [{ justifications: { methodology: true } }],
  };
}

export default {
  components: {
    T1,
  },

  data() {
    const values = {
      ...window.hydrate.data,
    };

    const indicators = {
      ...window.hydrate.extra_data,
    };

    if (allOptions()) {
      Object.assign(values, valuesForAllOptions());
    }

    return {
      values,
      indicators,
    };
  },
};
</script>

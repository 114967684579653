/**
 * Warnings for the multitable component are implemented similar to VueFormulate validation
 *
 * Usage example:
 *
 *     <FormulateInput
 *       type="multitable"
 *       :warnings="[{name: 'maxTotal', args: ['baseline_period', total]}]"
 *     >
 *
 * The warnings prop takes an array of objects which defines the name and arguments of a
 * "validation" function. The function can be defined in this file and must return a string,
 * which is the warning string that will be shown on the page.
 *
 **/

import { translate } from "vue-gettext";
import customRules from "./validation/customRules.js";

const { gettext: $gettext, gettextInterpolate } = translate;

export function maxTotalWarning(tableInputs, propertyName, maxValue, year) {
  return !customRules.maxTotal({ value: tableInputs }, propertyName, maxValue)
    ? gettextInterpolate(
        $gettext(
          "Total should be less than or equal to the total land area reported in year %{year}: %{maxValue} km²"
        ),
        {
          maxValue: maxValue,
          year: year,
        }
      )
    : "";
}

export function maxTotalCountryWarning(
  tableInputs,
  propertyName,
  maxValue,
  year
) {
  return !customRules.maxTotal({ value: tableInputs }, propertyName, maxValue)
    ? gettextInterpolate(
        $gettext(
          "Total should be less than or equal to the total country area reported in year %{year}: %{maxValue} km²"
        ),
        {
          maxValue: maxValue,
          year: year,
        }
      )
    : "";
}

export function exceedMaxTotalWarning(
  tableInputs,
  currentValue,
  maxValue,
  year
) {
  return !customRules.exceedTotal(currentValue, maxValue)
    ? gettextInterpolate(
        $gettext(
          "Value should be less than or equal to the total land area reported in year %{year}: %{maxValue} km²"
        ),
        {
          maxValue: maxValue,
          year: year,
        }
      )
    : "";
}

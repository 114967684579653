import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  T1: {
    financial_instrument: {
      grant: _("Charitable grant"),
      commercial_loans: _("Commercial loans"),
      non_concessional_loan: _("Non-concessional loan"),
      private_export: _("Private Export"),
      credit: _("Credit"),
      private_equities: _("Private Equities"),
      private_insurance: _("Private Insurance"),
      other: _("Other(specify)"),
    },
    type_of_institution: {
      philanthropic_foundation: _("Philanthropic Foundation"),
      non_profit_institution: _("Non-profit institution"),
      pension_fund: _("Pension fund"),
      private_corporation: _("Private corporation"),
      other: _("Other (specify)"),
    },
    recipient: {
      country_region: _("Country/Region TBA"),
      domestic: _("Domestic mobilization"),
    },
  },
};

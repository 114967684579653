<template>
  <div class="P4-comment-thread relative">
    <div
      v-translate="{
        html_start_link1: `<span class='text-blue-800 font-bold'>`,
        html_start_link2: `<span class='italic'>`,
        html_end_link: `</span>`,
        html_start_small: `<small>`,
        html_end_small: `</small>`,
        email: thread[anchor].author.email,
        nameTag: nameTag,
        created: thread[anchor].created,
      }"
      render-html="true"
    >
      Opened by %{ html_start_link1 } %{ email } %{ html_end_link } %{
      html_start_small } %{ nameTag } %{ html_end_small } on %{ html_start_link2
      } %{ created } %{ html_end_link }
    </div>

    <div class="P4-thread-resolve">
      <small v-if="thread[anchor].resolved" v-translate class="text-green-500">
        ✅ Resolved
      </small>
      <small v-if="!thread[anchor].resolved" v-translate class="text-red-500">
        ❌ Unresolved
      </small>

      <button
        v-if="!thread[anchor].resolved && !isReadOnly"
        v-translate
        class="P4-comment-btn-reverse"
        @click.prevent="resolveCommentThread(thread[anchor].id)"
      >
        Resolve Thread
      </button>
    </div>

    <div v-for="(comment, index) in thread[anchor].comments" :key="comment.id">
      <Comment
        :index="index"
        :comment="comment"
        :logged-user="loggedUser"
        :is-read-only="isReadOnly"
      />
    </div>

    <div v-if="!isReadOnly">
      <span
        class="P4-comment-btn-reverse P4-comment-reply-btn"
        @click="isHiddenNewReply = !isHiddenNewReply"
      >
        Reply
      </span>
    </div>
    <div v-if="!isHiddenNewReply">
      <div v-translate>New reply</div>
      <CommentThreadSubmit
        :thread-id="thread[anchor].id"
        :anchor="anchor"
        :is-hidden-new-reply="isHiddenNewReply"
        @toggleReply="isHiddenNewReply = !isHiddenNewReply"
      />
    </div>
  </div>
</template>

<script>
import api from "../api";
import CommentThreadSubmit from "./CommentThreadSubmit";
import Comment from "./Comment";
import { gettextLazy as $gettext } from "../translations.js";

export default {
  components: {
    CommentThreadSubmit,
    Comment,
  },
  props: {
    thread: {
      type: Object,
      required: true,
    },
    anchor: {
      type: String,
      required: true,
    },
    loggedUser: {
      type: String,
      default: "",
    },
    isReadOnly: Boolean,
  },
  data() {
    return {
      context: this.$attrs.context,
      isHiddenNewReply: true,
      $gettext,
    };
  },
  computed: {
    nameTag() {
      if (this.thread[this.anchor].author.is_reporting_officer)
        return $gettext("Reporting Officer");
      else if (this.thread[this.anchor].author.is_staff)
        return $gettext("UNCCD Secretariat");
      else if (this.thread[this.anchor].author.is_unccd_consultant)
        return $gettext("UNCCD Consultant");
      else return "";
    },
  },

  methods: {
    async resolveCommentThread(thread) {
      try {
        await api.updateCommentThread("comment", thread);
        this.$emit("changeComment", true);
      } catch (e) {
        this.$emit("errorMessage", e);
        throw e;
      }
    },
  },
};
</script>

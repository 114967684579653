<template>
  <section>
    <FormulateInput type="multitable" name="t5">
      <template #table_header>
        <tr class="text-center">
          <th
            v-for="(column, index) in [
              $gettext('Infrastructure Factor'),
              $gettext(
                'Which factors did you use per vulnerability component at national level?'
              ),
            ]"
            :key="index"
            class="P4-cell"
          >
            {{ column }}
          </th>
          <th v-if="tier3">
            <span v-if="!isAffectedAreas" v-translate>
              Select all the factors for which data was available at subnational
              level using the check boxes provided
            </span>
            <span v-if="isAffectedAreas" v-translate>
              Select all the factors for which data were available for the
              affected area using the check boxes provided
            </span>
          </th>
        </tr>
      </template>
      <template #cells="{ rowId, value }">
        <th class="text-left pl-6">
          {{ OPTIONS.infrastructure_factors[value.factor] }}
        </th>
        <td class="P4-cell-padding">
          <P4Input
            :id="rowId + '-national'"
            v-model="value['national']"
            checkbox
          />
          <P4Input
            v-if="value.factor === 'other' && value.national"
            :id="rowId + '-other_national'"
            v-model="value['other_national']"
          />
        </td>
        <td v-if="tier3" class="P4-cell-padding">
          <P4Input
            :id="rowId + '-subnational'"
            v-model="value['subnational']"
            checkbox
          />
          <P4Input
            v-if="value.factor === 'other' && value.subnational"
            :id="rowId + '-other_subnational'"
            v-model="value['other_subnational']"
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  props: {
    tier3: {
      type: Boolean,
    },
  },
  data() {
    return {
      $gettext,
      isAffectedAreas: window.hydrate.is_aa_form,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
  },
};
</script>

export default {
  data() {
    return {
      values: {},
    };
  },
  methods: {
    reloadDefaultData({ tableValue, tableName }) {
      this.values[tableName] = tableValue;
    },
  },
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t1","column-count":4,"columns":[
      _vm.$gettext('Year'),
      _vm.$gettext('Urban (%)'),
      _vm.$gettext('Rural (%)'),
      _vm.$gettext('Total (%)'),
    ]},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('td',{staticClass:"P4-cell"},[_vm._v(" "+_vm._s(value.year)+" ")]),_vm._l((['urban', 'rural', 'total']),function(column,index){return _c('td',{key:index,staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-' + column,"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t1', rowIndex, column],
            value[column]
          ),"placeholder":"No data","percent":""},model:{value:(value[column]),callback:function ($$v) {_vm.$set(value, column, $$v)},expression:"value[column]"}})],1)})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t2"
      :columns="[
        $gettext('Degradation Process'),
        $gettext('Starting Land Cover'),
        $gettext('Ending Land Cover'),
      ]"
      :column-count="3"
      :label-for-new="$gettext('+ Add Record')"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <ul>
            <li>
              <P4Input
                :id="rowId + '-degradation_process'"
                v-model="value.degradation_process"
                :options="OPTIONS.T2.degradation"
              />
            </li>
            <li v-if="value.degradation_process === 'other'">
              <P4Input
                :id="rowId + '-other_degradation_process'"
                v-model="value['other_degradation_process']"
              />
            </li>
          </ul>
        </td>
        <td class="P4-cell">
          <ul>
            <li>
              <P4Input
                :id="rowId + '-starting_land_cover'"
                v-model="value.starting_land_cover"
                :options="
                  Object.assign({}, UNCCD_CLASS_MAP, {
                    other: $gettext('Other'),
                  })
                "
              />
            </li>
            <li v-if="value.starting_land_cover === 'other'">
              <P4Input
                :id="rowId + '-other_starting_land_cover'"
                v-model="value['other_starting_land_cover']"
              />
            </li>
          </ul>
        </td>
        <td class="P4-cell">
          <ul>
            <li>
              <P4Input
                :id="rowId + '-ending_land_cover'"
                v-model="value.ending_land_cover"
                :options="
                  Object.assign({}, UNCCD_CLASS_MAP, {
                    other: $gettext('Other'),
                  })
                "
              />
            </li>
            <li v-if="value.ending_land_cover === 'other'">
              <P4Input
                :id="rowId + '-other_ending_land_cover'"
                v-model="value['other_ending_land_cover']"
              />
            </li>
          </ul>
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { UNCCD_CLASS_MAP } from "../../../lib/constants.js";
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    UNCCD_CLASS_MAP: () => UNCCD_CLASS_MAP,
    OPTIONS: () => OPTIONS,
  },
};
</script>

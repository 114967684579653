<template>
  <FormulateInput
    type="multitable"
    name="t1"
    :columns="[
      $gettext('Year'),
      $gettext('Protected Areas Coverage(%)'),
      $gettext('Lower Bound'),
      $gettext('Upper Bound'),
      $gettext('Comments'),
    ]"
  >
    <template #cells="{ rowId, rowIndex, value }">
      <td class="P4-cell">
        {{ value.year }}
      </td>
      <td class="P4-cell-right">
        <P4Input
          :id="rowId + '-protected_areas_coverage'"
          v-model="value.protected_areas_coverage"
          :default-value-has-changed="defaultValueHasChanged(
            ['t1', rowIndex, 'protected_areas_coverage'],
            value.protected_areas_coverage
          )"
          percent
          validate="roundTo2"
        />
      </td>
      <td class="P4-cell-right">
        <P4Input
          :id="rowId + '-lower_bound'"
          v-model="value.lower_bound"
          :default-value-has-changed="defaultValueHasChanged(
            ['t1', rowIndex, 'lower_bound'],
            value.lower_bound
          )"
          numeric
          validate="roundTo2"
        />
      </td>
      <td class="P4-cell-right">
        <P4Input
          :id="rowId + '-upper_bound'"
          v-model="value.upper_bound"
          :default-value-has-changed="defaultValueHasChanged(
            ['t1', rowIndex, 'upper_bound'],
            value.upper_bound
          )"
          numeric
          validate="roundTo2"
        />
      </td>
      <td class="P4-cell">
        <P4Input :id="rowId + '-comments'" v-model="value.comments" />
      </td>
    </template>
  </FormulateInput>
</template>
<script>
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      $gettext,
    };
  },
};
</script>

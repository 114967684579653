<template>
  <P4Form v-model="values" :submit-handler="submit">
    <h4 v-translate class="P4-question">
      Do you wish to report on affected areas in addition to national reporting?
    </h4>

    <P4Input
      id="enable_affected_areas"
      v-model="values.enable_affected_areas"
      radio
      :options="OPTIONS.yes_no"
    />

    <div :class="{ hidden: !enableAffectedAreas }">
      <div v-translate class="P4-section-title">
        Reporting on affected areas only is an optional reporting element and is
        additional to national reporting.
      </div>
      <h4 v-translate class="P4-question">
        Does your country define “affected areas” as defined in Article 1 of the
        Convention as “arid, semi-arid and/or dry sub-humid areas affected or
        threatened by desertification”?
      </h4>

      <P4Input
        id="aa_defined_in_convention"
        v-model="values.aa_defined_in_convention"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !aaDefinedInConvention }">
        <h4 v-translate class="P4-question">
          How do you define “affected areas”?
        </h4>

        <P4Input
          id="affected_area_definition"
          v-model="values.affected_area_definition"
          textarea
          standalone
        />
      </div>
    </div>
    <div class="print-hide" :class="{ hidden: !showAffectedAreaLinks }">
      <div v-for="(aa, index) in affectedAreasLinks" :key="index" class="p-2">
        <a :id="aa.name" :href="aa.link" class="P4-link">
          {{ aa.title }}
        </a>
      </div>
    </div>
  </P4Form>
</template>

<script>
import OPTIONS from "./OPTIONS.js";

export default {
  data() {
    const values = {
      ...window.hydrate.data,
    };

    const affectedAreasLinks = {
      ...window.hydrate.affected_areas_links,
    };

    const showLinks = values["enable_affected_areas"] === "yes";

    return {
      values,
      affectedAreasLinks,
      showLinks,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    enableAffectedAreas() {
      return this.values["enable_affected_areas"] === "yes";
    },
    aaDefinedInConvention() {
      return this.values["aa_defined_in_convention"] === "no";
    },
    showAffectedAreaLinks() {
      return this.showLinks && this.enableAffectedAreas;
    },
  },

  methods: {
    async submit(save, data) {
      await save(data);
      this.showLinks = data.enable_affected_areas === "yes";
    },
  },
};
</script>

<template>
  <P4Form v-model="values">
    <p v-translate class="P4-guidance">
      Report on data-based, quantifiable and time-bound national voluntary
      targets to achieve LDN in accordance with specific national circumstances
      and development priorities and/or other targets relevant to strategic
      objective 1.
    </p>
    <h3 v-translate class="P4-caption">
      SO1-VT.T1: Voluntary Land Degradation Neutrality targets and other targets
      relevant to strategic objective 1
    </h3>
    <p v-translate class="P4-guidance">
      Declare voluntary LDN targets and other targets relevant to strategic
      objective 1. If possible, provide spatial data in PRAIS to delineate the
      target areas. It is still possible to document the targets without the
      addition of spatial data. However, geographically bound targets for
      achieving a neutral (no net loss) or improved (net gain) state allow
      countries to focus on areas that have been identified as degradation
      hotspots or of high value in terms of achieving LDN.
    </p>
    <CommentAnchor :name="form_id + '-V.T1'" />
    <T1 />

    <h3 v-translate class="P4-caption">
      SO1.IA.T1: Areas of implemented action related to the targets (projects
      and initiatives on the ground).
    </h3>
    <p v-translate class="P4-guidance">
      Provide information in the table below to enable an assessment of the
      status of LDN implementation at national level based on an analysis of
      gaps between implemented actions and established targets (stated in table
      SO1-VT.T1). Please ensure that the information entered here is consistent
      with the reporting on the implementation framework.
    </p>
    <CommentAnchor :name="form_id + '-IA.T1'" />
    <IA_T1 :relevant-targets="relevantTargets" />

    <h2 v-translate class="P4-section-title">General comments</h2>
    <p v-translate class="P4-guidance">
      Provide any additional comments you deem relevant.
    </p>
    <div>
      <P4Input v-model="values.so1_v_general_comments" textarea standalone />
    </div>
  </P4Form>
</template>

<script>
import range from "../../../lib/range.js";
import { allOptions } from "../../../lib/translations.js";
import SO1_4_OPTIONS from "../4/OPTIONS.js";
import T1 from "./T1.vue";
import IA_T1 from "./IA_T1.vue";

function valuesForAllOptions() {
  const {
    status_of_target_achivement,
    ldn_target_answer_yes,
    other_important_goals_for_target,
    implemented_action,
  } = SO1_4_OPTIONS.T4.options;
  const len = (dict) => Object.keys(dict).length;

  const numRows = Math.max(
    ...[
      len(status_of_target_achivement),
      len(ldn_target_answer_yes),
      len(other_important_goals_for_target),
    ]
  );

  return {
    t1: range(numRows).map((i) => {
      return {
        ldn_target: i < len(ldn_target_answer_yes) ? "yes" : "no",
        status: Object.keys(status_of_target_achivement)[i],
        ldn_target_answer_yes: Object.keys(ldn_target_answer_yes)[i],
        other_important_goals_for_target: Object.keys(
          other_important_goals_for_target
        )[i],
      };
    }),
    ia_t1: Object.keys(implemented_action).map((implemented_action) => {
      return { implemented_action };
    }),
  };
}

export default {
  components: {
    T1,
    IA_T1,
  },
  data() {
    const values = window.hydrate.data || {};

    if (allOptions()) {
      Object.assign(values, valuesForAllOptions());
    }

    return {
      values,
      form_id: window.hydrate.id,
      relevantTargets: {},
    };
  },
  computed: {
    T1_TARGETS() {
      return this.values.t1
        ? this.values.t1
            .filter((obj) => obj.target)
            .map(function (obj) {
              return [obj.rowId, obj.target];
            })
        : {};
    },
  },
  watch: {
    T1_TARGETS: {
      deep: true,
      handler(newValue, oldValue) {
        let newRowIds = new Set(Array.from(newValue).map((obj) => obj[0]));
        let oldRowIds = new Set(Array.from(oldValue).map((obj) => obj[0]));
        let deletedItemsIds = this.difference(oldRowIds, newRowIds);

        deletedItemsIds.forEach((rowId) => {
          this.$delete(this.relevantTargets, rowId);
        });

        newValue.forEach(([key, value]) => {
          this.$set(this.relevantTargets, key, value);
        });
      },
    },
  },
  methods: {
    difference(setA, setB) {
      let _difference = new Set(setA);
      for (let elem of setB) {
        _difference.delete(elem);
      }
      return _difference;
    },
  },
};
</script>

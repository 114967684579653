export default {
  data() {
    return {
      language: window.hydrate.language,
    };
  },
  computed: {
    selectedLanguageSeparator() {
      switch (this.language) {
        case "FR":
        case "ES":
          return [" ", ","];
        case "EN":
        default:
          return [" ", "."];
      }
    },
  },
  methods: {
    formatNumber(value, [separator, decimalSeparator]) {
      if (isNaN(value)) return value;
      const stringValue = value + "";
      const [intValue, decimalValue] = stringValue.split(".");

      const natValue = intValue.split("-").slice(-1)[0];
      const sign = intValue.replace(natValue, "");

      const formattedNatValue = natValue
        .split("")
        .reverse()
        .reduce((acc, val, index) => {
          const result = index % 3 === 0 ? val + separator + acc : val + acc;
          return result;
        }, "");
      return decimalValue
        ? sign + formattedNatValue + decimalSeparator + decimalValue
        : sign + formattedNatValue;
    },
  },
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormulateInput',{attrs:{"type":"multitable","name":"t2","column-count":6,"columns":[
    _vm.$gettext('Change in the indicator'),
    _vm.$gettext('Drivers: Direct (Choose one or more items)'),
    _vm.$gettext('Drivers: Indirect (Choose one or more items)'),
    _vm.$gettext(
      'Which levers are being used to reverse negative trends and enable transformative change?'
    ),
    _vm.$gettext('Responses that led to positive RLI trends'),
    _vm.$gettext('Comments'),
  ],"label-for-new":_vm.$gettext('+ Add Record'),"row-factory":_vm.rowFactory},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, value }){return [_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-change',"options":_vm.OPTIONS.T2.change},model:{value:(value.change),callback:function ($$v) {_vm.$set(value, "change", $$v)},expression:"value.change"}})],1),_c('td',{staticClass:"P4-cell"},[(value.change == 'negative')?[_c('RankingSelect',{attrs:{"select-key":rowId + '-drivers_direct-',"select-options":_vm.OPTIONS.T2.drivers_direct},model:{value:(value['drivers_direct']),callback:function ($$v) {_vm.$set(value, 'drivers_direct', $$v)},expression:"value['drivers_direct']"}})]:_vm._e()],2),_c('td',{staticClass:"P4-cell"},[(value.change == 'negative')?[_c('RankingSelect',{attrs:{"select-key":rowId + '-drivers_indirect-',"select-options":_vm.OPTIONS.T2.drivers_indirect},model:{value:(value['drivers_indirect']),callback:function ($$v) {_vm.$set(value, 'drivers_indirect', $$v)},expression:"value['drivers_indirect']"}})]:_vm._e()],2),_c('td',{staticClass:"P4-cell"},[(value.change == 'negative')?[_c('RankingSelect',{attrs:{"select-key":rowId + '-change_levers-',"select-options":_vm.OPTIONS.T2.change_levers},model:{value:(value['change_levers']),callback:function ($$v) {_vm.$set(value, 'change_levers', $$v)},expression:"value['change_levers']"}})]:_vm._e()],2),_c('td',{staticClass:"P4-cell"},[(value.change == 'positive')?[_c('RankingSelect',{attrs:{"select-key":rowId + '-change_responses-',"select-options":_vm.OPTIONS.T2.change_responses},model:{value:(value['change_responses']),callback:function ($$v) {_vm.$set(value, 'change_responses', $$v)},expression:"value['change_responses']"}})]:_vm._e()],2),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-comments',"textarea":""},model:{value:(value.comments),callback:function ($$v) {_vm.$set(value, "comments", $$v)},expression:"value.comments"}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <h2 v-translate class="P4-infotext">(Optional)</h2>
    <p v-translate class="P4-infotext text-lg">
      You can choose to complete only Tier 1 or both.
    </p>
    <P4Form v-model="values">
      <h3 v-translate class="P4-section-title">
        Tier 1: Please provide information on the international and domestic
        private resources mobilized by the private sector of your country for
        the implementation of the Convention, including information on trends.
      </h3>

      <P4Input
        v-model="values.trends_international_private_resources"
        radio
        name="trends_international_private_resources"
        :label="$gettext('Trends in international private resources')"
        :options="SO5_OPTIONS.STATUS"
      />

      <P4Input
        v-model="values.trends_domestic_private_resources"
        radio
        name="trends_domestic_private_resources"
        :label="$gettext('Trends in domestic private resources')"
        :options="SO5_OPTIONS.STATUS"
      />

      <p v-translate class="P4-guidance">
        Please provide information, as available, on the innovative sources of
        finance, the number of co-financing partners and blended finance
        facilities; on the projects, activities, regions or countries on which
        the private sector of your country has focused to the greatest extent;
        as well as on policies and measures implemented to incentivize the
        private sector to support actions to implement the Convention.
      </p>
      <P4Input
        id="so5_3_policies_implemented"
        v-model="values.so5_3_tier1"
        textarea
        standalone
      />

      <p v-translate class="P4-guidance">
        Description may include information on national circumstances and
        institutional arrangements relevant to the reporting on private
        resources mobilized by the private sector of your country in developing
        countries and at national level.
      </p>
      <P4Input
        id="so5_3_national_circumstances"
        v-model="values.information_on_national_circumstances"
        textarea
        standalone
      />

      <h3 v-translate class="P4-section-title">
        Tier 2: Table 3 International and domestic private resources
      </h3>
      <CommentAnchor :name="form_id + '-T3'" />
      <T3 />

      <h3 v-translate class="P4-caption">
        Please provide methodological information relevant to data presented in
        table 3
      </h3>
      <p v-translate class="P4-guidance">
        Include information on underlying assumptions, definitions and
        methodologies used to identify and report on international and domestic
        private financial resources. Please include links to relevant
        documentation.
      </p>
      <P4Input
        id="so5_3_methodologies_info"
        v-model="values.information_on_underlying_assumptions"
        textarea
        standalone
      />

      <h3 v-translate class="P4-caption">
        Has your country taken measures to encourage the private sector as well
        as non-governmental organizations, foundations and academia to provide
        international and domestic resources for the implementation of the
        Convention?
      </h3>
      <P4Input
        id="so5_3_taken_measures"
        v-model="values.taken_measures"
        textarea
        standalone
      />

      <h3 v-translate class="P4-section-title">General comments</h3>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input
          id="so5_3_general_comments"
          v-model="values.so5_3_general_comments"
          textarea
          standalone
        />
      </div>
    </P4Form>
  </section>
</template>

<script>
import T3 from "./T3.vue";
import SO5_OPTIONS from "../SO5_OPTIONS";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  components: {
    T3,
  },
  data() {
    const values = {
      ...window.hydrate.data,
    };

    return {
      values,
      form_id: window.hydrate.id,
      $gettext,
    };
  },
  computed: {
    SO5_OPTIONS: () => SO5_OPTIONS,
  },
};
</script>

import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  T2: {
    qualitative_assessment: {
      increasing: _("Increasing"),
      decreasing: _("Decreasing"),
      no_change: _("No Change"),
    },
    report_level: {
      so3_3_default_dvi: _("SO3-3 (default DVI)"),
      so3_3_country_dvi: _("SO3-3 (country DVI)"),
    },
    report_level_affected_areas: {
      so3_3_country_dvi: _("SO3-3 (country DVI)"),
    },
  },
  tier_levels: {
    tier1: {
      label: _("Tier 1 Vulnerability Assessment"),
      tooltip: _(
        "Uses at least one factor per vulnerability component, represented by country-level data."
      ),
    },
    tier2: {
      label: _("Tier 2 Vulnerability Assessment"),
      tooltip: _(
        "Uses more than one factor per vulnerability component, where the factors are represented by country-level data, with the inclusion of sex-disaggregated data (where applicable)"
      ),
    },
    tier3: {
      label: _("Tier 3 Vulnerability Assessment"),
      tooltip: _(
        "Uses more than one factor per vulnerability component, where factors are represented by sub-national data (which may be gridded or for administrative regions), with the inclusion of sex-disaggregated data (where applicable)."
      ),
    },
  },
  tier_levels_affected_areas: {
    tier3: {
      label: _("Tier 3 Vulnerability Assessment"),
      tooltip: _(
        "Uses more than one factor per vulnerability component, where factors are represented by sub-national data (which may be gridded or for administrative regions), with the inclusion of sex-disaggregated data (where applicable)."
      ),
    },
  },
  social_factors: {
    literacy_rate: _("Literacy rate (% of people aged 15+)"),
    life_expectancy_at_birth: _("Life expectancy at birth (years)"),
    population_aged: _("Population aged 15-64 (%)"),
    government_effectiveness: _("Government effectiveness"),
    refugee_population: _("Refugee population (%)"),
    other: _("Other (Please specify)"),
  },
  economic_factors: {
    proportion_of_population_below_the_international_poverty_line: _(
      "Proportion of the population below the international poverty line"
    ),
    gdp_per_capital: _("GDP per capital"),
    agriculture: _("Agriculture % of GDP"),
    energy_consumption_per_capital: _("Energy consumption per capital"),
    other: _("Other (Please specify)"),
  },
  infrastructure_factors: {
    proportion_of_population_using_safely_managed_drinking_water_services: _(
      "Proportion of the population using safely managed drinking water services"
    ),
    total_renewable_water_resources_per_capital: _(
      "Total renewable water resources per capital"
    ),
    cultivated_area_equipped_for_irrigation: _(
      "Cultivated area equipped for irrigation (%)"
    ),
    other: _("Other (please specify)"),
  },
};

<template>
  <section>
    <P4Form v-model="values">
      <h2 v-translate class="P4-section-title">
        Proportion of the population exposed to land degradation disaggregated
        by sex
      </h2>

      <section>
        <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
          SO2-3.T1: National estimates of the proportion of population exposed
          to land degradation disaggregated by sex.
        </h3>

        <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
          SO2-3.T1: Affected area estimates of the proportion of population
          exposed to land degradation disaggregated by sex.
        </h3>

        <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
          This table is pre-filled with default national estimates derived from
          a spatial overlap between SDG indicator 15.3.1 data and population
          data sourced from the WorldPop Project. Keep the default national
          estimates, or, if data and capacity exist, replace them with national
          estimates of the proportion of the female, male and total overall
          population exposed to land degradation.
        </p>
        <p v-if="isAffectedAreas" v-translate class="P4-guidance">
          Report estimates of the proportion of population exposed to land
          degradation in affected areas. Affected area estimates should be
          provided for the proportion of the female, male and total overall
          population exposed to land degradation.
        </p>
        <CommentAnchor :name="form_id + '-T1'" />
        <T1 />
        <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />
      </section>

      <section>
        <h2 v-translate class="P4-section-title">Qualitative assessment</h2>

        <h3 v-translate class="P4-caption">
          SO2-3.T2: Interpretation of the indicator
        </h3>
        <p v-translate class="P4-guidance">
          Based on the quantitative data, select the dominant change in the
          indicator and indicate which direct and/or indirect drivers are
          presumably behind the observed changes using the comments box in table
          below.
        </p>
        <CommentAnchor :name="form_id + '-T2'" />
        <T2 />
      </section>

      <h3 v-translate class="P4-section-title">General comments</h3>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input v-model="values.so2_3_general_comments" textarea standalone />
      </div>
    </P4Form>
  </section>
</template>

<script>
import { CHANGES_IN_THE_INDICATOR } from "../../../lib/constants.js";
import OPTIONS from "./OPTIONS";
import { allOptions } from "../../../lib/translations.js";
import T1 from "./T1.vue";
import T2 from "./T2.vue";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

function valuesForAllOptions() {
  const keys = Object.keys(CHANGES_IN_THE_INDICATOR);

  return {
    t2: keys.map((change_in_the_indicator) => ({ change_in_the_indicator })),
  };
}

export default {
  components: {
    T1,
    T2,
  },
  mixins: [restoreDefaultData],
  data() {
    const values = {
      t1: Object.keys(OPTIONS.period).map((id) => ({ id })),
      ...window.hydrate.data,
    };

    if (allOptions()) {
      Object.assign(values, valuesForAllOptions());
    }

    return {
      values,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
    };
  },
};
</script>

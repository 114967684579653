<template>
  <FormulateForm @submit="submit">
    <section>
      <ChunkedFileUploader
        v-model="upload"
        :upload-url="urls.upload_layer_chunk"
        :complete-url="urls.upload_layer_complete"
        :validation="fileValidation"
        :accept="fileAccept"
        :help="layerType"
      />
    </section>

    <br />

    <section>
      <P4Input v-model="values.title" :label="$gettext('Title')" />

      <P4Input
        v-model="values.abstract"
        :label="$gettext('Abstract')"
        textarea
      />

      <P4Input v-model="values.date" :label="$gettext('Date')" date />
    </section>

    <br />

    <section v-if="layerType === 'raster'">
      <h3 v-translate class="P4-section-title">Color legends</h3>

      <br />

      <P4Input
        id="default_categorical_type"
        v-model="spec.legend_type"
        radio
        :options="{
          default: $gettext('Default'),
          categorical: $gettext('Categorical'),
        }"
      />

      <template v-if="spec['legend_type'] === 'categorical'">
        <FormulateForm v-model="spec.categories">
          <ColorLegendCategorical />
        </FormulateForm>
      </template>

      <P4Input
        id="ramp_type"
        v-model="spec.legend_type"
        radio
        :options="{ ramp: $gettext('Ramp') }"
      />
      <div v-if="spec['legend_type'] === 'ramp'" class="ml-5">
        <p v-translate class="font-bold mb-2">Ramp type</p>
        <P4Input
          id="stretch_type"
          v-model="spec.stretch_type"
          radio
          :options="{
            zero_stretch: $gettext('Zero-centered stretch'),
            min_stretch: $gettext('Minimum zero stretch'),
          }"
        />

        <P4Input
          id="percent-stretch"
          v-model="spec.percent_stretch"
          :label="$gettext('Percent stretch')"
          numeric
          percent
          required
        />

        <div class="flex space-x-4 my-4">
          <div class="w-28">
            <P4Input
              id="no-data-color"
              v-model="spec.no_data_color"
              :label="$gettext('No data color')"
              color
            />
          </div>
          <P4Input
            id="no-data-pixel"
            v-model="spec.no_data_pixel"
            :label="$gettext('No data pixel value')"
            numeric
            validate="number16Bit"
            required
          />
        </div>

        <div class="flex space-x-4 my-4">
          <div class="w-28">
            <P4Input
              id="min-color"
              v-model="spec.min_color"
              :label="$gettext('Minimum color')"
              color
            />
          </div>
          <P4Input
            id="min-color-label"
            v-model="spec.min_color_label"
            :label="$gettext('Minimum color label')"
            required
          />
        </div>

        <template v-if="spec['stretch_type'] === 'min_stretch'">
          <P4Input
            id="mid-color"
            v-model="spec.mid_color"
            :label="$gettext('Middle color')"
            color
          />
        </template>

        <template v-else-if="spec['stretch_type'] === 'zero_stretch'">
          <div class="flex space-x-4 my-4">
            <div class="w-28">
              <P4Input
                id="zero-color"
                v-model="spec.zero_color"
                :label="$gettext('Zero color')"
                color
              />
            </div>
            <P4Input
              id="zero-color-label"
              v-model="spec.zero_color_label"
              :label="$gettext('Zero color label')"
              required
            />
          </div>
        </template>

        <div class="flex space-x-4 my-4">
          <div class="w-28">
            <P4Input
              id="max-color"
              v-model="spec.max_color"
              :label="$gettext('Maximum color')"
              color
            />
          </div>
          <P4Input
            id="max-color-label"
            v-model="spec.max_color_label"
            :label="$gettext('Maximum color label')"
            required
          />
        </div>
      </div>

      <template v-if="spec['legend_type'] === 'default'">
        <p v-translate class="italic text-xs">
          Trends Earth styles will be used.
        </p>
      </template>
    </section>

    <section>
      <h3 v-translate class="P4-section-title">Topics</h3>

      <br />

      <ul>
        <li v-for="(option, index) in OPTIONS.topics" :key="index">
          <P4Input
            v-model="values.topics[index]"
            :name="'topics-' + index"
            :label="option"
            checkbox
          />
        </li>
      </ul>
    </section>

    <section>
      <h3 v-translate class="P4-section-title">Contact point</h3>

      <br />

      <P4Input v-model="values.contact.name" :label="$gettext('Name')" />

      <P4Input
        v-model="values.contact.role"
        :label="$gettext('Role')"
        :options="OPTIONS.contact_role"
      />

      <P4Input
        v-model="values.contact.organization"
        :label="$gettext('Organization')"
      />

      <P4Input v-model="values.contact.email" :label="$gettext('Email')" />

      <P4Input v-model="values.contact.phone" :label="$gettext('Phone')" />

      <P4Input
        v-model="values.contact.address"
        :label="$gettext('Address')"
        textarea
      />

      <P4Input
        v-model="values.geographic_location"
        :label="$gettext('Geographic Location')"
      />

      <!-- TODO encoding: full list of encodings, default utf8 -->

      <P4Input
        v-model="values.encoding"
        :label="$gettext('Character set encoding')"
        :options="OPTIONS.encodings"
      />

      <P4Input
        v-model="values.citation"
        :label="$gettext('Citation')"
        :help="
          $gettext(
            'Specify the source of the data will appear as a credit in the map template'
          )
        "
        textarea
      />

      <FormSave ref="save" :save-handler="saveHandler" />
    </section>

    <FormDebug :values="{ values, spec }" />
  </FormulateForm>
</template>

<script>
import ChunkedFileUploader from "@/lib/forms/ChunkedFileUploader";
import OPTIONS from "@/map/metadata/OPTIONS";
import api from "@/lib/api";
import { RASTER, VECTOR } from "@/lib/constants";
import ColorLegendCategorical from "./ColorLegendCategorical";

export default {
  name: "RasterUpload",
  components: { ColorLegendCategorical, ChunkedFileUploader },
  data() {
    return {
      urls: window.hydrate.urls,
      layerType: window.hydrate.layer_type,
      upload: null,
      values: {
        topics: {},
        contact: {},
        encoding: "UTF-8",
      },
      spec: {
        percent_stretch: "0",
        legend_type: "default",
        no_data_color: "#000000",
        min_color: "#000000",
        max_color: "#000000",
        zero_color: "#000000",
        mid_color: "#000000",
      },
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    defaultColors() {
      return this.spec["legend_type"] === "default";
    },
    specifyPlacename() {
      return this.values.geographic_location === "placename";
    },
    fileValidation() {
      if (this.layerType === RASTER) {
        return "mime:image/tiff,application/zip";
      }
      return "";
    },
    fileAccept() {
      if (this.layerType === VECTOR) {
        return ".json, .geojson, .zip, .kml";
      } else if (this.layerType === RASTER) {
        return "image/tiff, application/zip";
      }
      return "";
    },
  },
  methods: {
    async saveHandler(data) {
      await api.saveLayerUpload(this.urls.upload_layer_save, data);
      const nextLink = `${this.layerType}_layer_detail`;
      this.$nextTick(() => {
        window.location.href = this.urls[nextLink];
      });
    },

    createFinalSpec(spec) {
      let newSpec;
      if (spec.legend_type === "categorical") {
        newSpec = {
          custom: true,
          ramp: {
            type: "categorical",
            items: spec.categories.items,
          },
        };
      } else if (spec.stretch_type === "zero_stretch") {
        newSpec = {
          custom: true,
          ramp: {
            type: "zero-centered stretch",
            "percent stretch": spec.percent_stretch,
            min: {
              color: spec.min_color,
              label_text: spec.min_color_label,
            },
            zero: {
              color: spec.zero_color,
              label_text: spec.zero_color_label,
            },
            max: {
              color: spec.max_color,
              label_text: spec.max_color_label,
            },
            "no data": {
              value: spec.no_data_pixel,
              color: spec.no_data_color,
              "label:": "No data",
            },
          },
          scale: 10,
          integer: true,
          zero_color: true,
          absolute: true,
        };
      } else if (spec.stretch_type === "min_stretch") {
        newSpec = {
          custom: true,
          ramp: {
            type: "min zero stretch",
            "percent stretch": spec.percent_stretch,
            zero: {
              color: spec.min_color,
              label_text: spec.min_color_label,
            },
            mid: {
              color: spec.mid_color,
            },
            max: {
              color: spec.max_color,
              label_text: spec.max_color_label,
            },
            "no data": {
              value: spec.no_data_pixel,
              color: spec.no_data_color,
              "label:": "No data",
            },
          },
        };
      }
      return newSpec;
    },
    submit() {
      return this.$refs.save.submit({
        upload: this.upload,
        metadata: this.values,
        spec: this.defaultColors ? null : this.createFinalSpec(this.spec),
      });
    },
  },
};
</script>

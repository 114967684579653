<template>
  <div>
    <FormulateInput
      v-model="uploadModel"
      type="file"
      :label="label"
      :validation="validation"
      :accept="accept"
      :uploader="uploadFile"
      :disabled="disabled"
      @file-removed="resetUpload"
    >
      <!-- Overwrite help attribute to display text with link inside -->
      <template #help="{ id, classes }">
        <div
          :id="`${id}-help`"
          :class="classes.help"
          class="max-w-xs"
        >
          <component :is="help"></component>
        </div>
      </template>
    </FormulateInput>
    <FormulateInput
      v-if="status.loading"
      key="cancel-upload"
      type="button"
      :label="$gettext('Cancel')"
      @click="status.canceled = true"
    />
    <FormulateInput
      v-else-if="uploadModel && uploadModel.files.length > 0 && status.canceled"
      key="continue-upload"
      type="button"
      :label="$gettext('Continue')"
      @click="continueUpload"
    />
    <FormulateInput
      v-else-if="uploadModel && uploadModel.files.length > 0 && status.failed"
      key="retry-upload"
      type="button"
      :label="$gettext('Retry')"
      @click="retryUpload"
    />
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import api from "@/lib/api";
import raster from "./RasterHelp.vue";
import vector from "./VectorHelp.vue";
import tearchive from "./TrendsEarthArchiveUploadHelp.vue";

/**
 * Uploads a single file in chunks to the backend. After the upload is
 * finished (and integrity checked) the model is updated with the backend
 * ID for the upload.
 *
 * XXX Multiple file upload is not supported.
 */
export default {
  name: "ChunkedFileUploader",
  components: {
    raster,
    vector,
    tearchive,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    uploadUrl: {
      type: String,
      required: true,
    },
    completeUrl: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Boolean],
      default: false,
    },
    help: {
      type: String,
      default: "",
    },
    validation: {
      type: [String, Boolean, Array],
      default: false,
    },
    accept: {
      type: [String, Boolean],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    chunkSize: {
      type: Number,
      default: 1024 * 1024, // 1MB,
      validator(value) {
        return value > 0;
      },
    },
  },
  data() {
    return {
      uploadModel: null,
      status: this.initialStatus(),
    };
  },
  created() {
    // Create the MD5 Object here, as it doesn't do well with
    // vue reactivity and causes maximum recursion exceeded.
    this.md5 = CryptoJS.algo.MD5.create();
  },
  methods: {
    initialStatus() {
      return {
        offset: 0,
        uploadId: null,
        done: false,
        failed: false,
        loading: false,
        canceled: false,
      };
    },
    resetUpload() {
      this.status = this.initialStatus();
      this.md5 = CryptoJS.algo.MD5.create();
      this.$emit("input", null);
    },
    /**
     * Continue the upload. Can be used after the user paused it.
     */
    continueUpload() {
      this.uploadModel.uploadPromise = null;
      this.uploadModel.upload();
    },
    /**
     * Reset the upload status, and retry from the start.
     */
    retryUpload() {
      // TODO: We could theoretically resume the upload from where it failed.
      // TODO: This will require synchronizing the backend offset. However the
      // TODO: md5 might already be ahead/behind. So special care is needed.
      this.resetUpload();
      this.continueUpload();
    },
    /**
     * Send confirmation with a checksum to the backend, to validate
     * the file.
     */
    async confirmUpload() {
      const body = new FormData();
      body.append("upload_id", this.status.uploadId);
      body.append("md5", this.md5.finalize().toString());

      return api.call(this.completeUrl, body, "POST");
    },
    /**
     * @param file {File}
     * @param chunk {Blob}
     * @return {{"Content-Range": string}}
     */
    getChunkHeaders(file, chunk) {
      // Content-Range is inclusive on both ends, so subtract 1 here
      const end = this.status.offset + chunk.size - 1;
      const start = this.status.offset;
      return {
        "Content-Range": `bytes ${start}-${end}/${file.size}`,
      };
    },
    /**
     * Send a single chunk to the backend. If this is the first
     * chunk, uploadId will be updated.
     *
     * @param file {File}
     * @param chunk {Blob}
     * @return {Promise<void>}
     */
    async postChunk(file, chunk) {
      // Update the MD5 hash
      const buff = await chunk.arrayBuffer();
      this.md5.update(CryptoJS.lib.WordArray.create(buff));

      const body = new FormData();
      body.append("file", chunk, file.name);
      if (this.status.uploadId) {
        body.append("upload_id", this.status.uploadId);
      }

      const resp = await api.call(
        this.uploadUrl,
        body,
        "POST",
        this.getChunkHeaders(file, chunk)
      );
      this.status.uploadId = resp.upload_id;
    },
    async uploadFile(file, progress, error) {
      this.status.done = false;
      this.status.failed = false;
      this.status.canceled = false;

      try {
        this.status.loading = true;

        while (!this.status.canceled) {
          progress((this.status.offset / file.size) * 100);

          const chunk = file.slice(
            this.status.offset,
            this.status.offset + this.chunkSize
          );
          if (chunk.size === 0) {
            await this.confirmUpload();
            this.$emit("input", this.status.uploadId);
            this.status.done = true;

            return {
              upload_id: this.status.uploadId,
            };
          }

          await this.postChunk(file, chunk);
          this.status.offset += chunk.size;
        }
      } catch (e) {
        console.error(e);
        this.status.failed = true;
        error(this.$gettext("Unable to upload file"));
      } finally {
        this.status.loading = false;

        if (this.status.canceled) {
          error(this.$gettext("Upload paused"));
        }
        progress(100);
      }
    },
  },
};
</script>

import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  level_of_application: {
    national: _("National"),
    subnational: _("Subnational"),
  },
  status_of_target_achievement: {
    achieved: _("Achieved"),
    ongoing: _("Ongoing"),
    extended: _("Extended or postponed"),
    not_achived: _("Not achieved"),
    partially_achieved: _("Partially achieved"),
  },
};

<template>
  <section>
    <FormulateInput type="multitable" name="t1">
      <template #table_header>
        <tr>
          <th
            v-for="(column, index) in [
              $gettext('Year'),
              $gettext('Total country-level DVI value (tier 1)'),
              $gettext('Male DVI value (tiers 2 and 3 only)'),
              $gettext('Female DVI value (tiers 2 and 3 only)'),
            ]"
            :key="index"
            class="P4-cell"
          >
            {{ column }}
          </th>
        </tr>
      </template>
      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          {{ value.year }}
        </th>
        <td
          v-for="(column, index) in [
            'total_country_level_dvi',
            'male_dvi_value',
            'female_dvi_value',
          ]"
          :key="index"
          class="P4-cell-right"
        >
          <P4Input
            :id="rowId + '-' + column"
            v-model="value[column]"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, column],
              value[column]
            )"
            placeholder="No data"
            numeric
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>
<script>
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      $gettext,
    };
  },
};
</script>

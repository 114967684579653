<template>
  <FormulateForm
    :formulate-value="value"
    :keep-model-data="keepModelData"
    class="P4-form"
    @input="$emit('input', $event)"
    @submit="submit"
  >
    <thead class="print-section-header print-show">
      <tr>
        <td>
          {{ sectionTitle }}
        </td>
      </tr>
    </thead>

    <h1 v-if="formTitle" class="P4-page-title">
      {{ formTitle }}
    </h1>
    <slot name="default"></slot>
    <FormSave ref="save" :on-before-unload-handler="onBeforeUnloadHandler"/>
    <NextButton />
    <FormDebug :values="value"
    />
  </FormulateForm>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "P4Form",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    submitHandler: {
      type: Function,
      required: false,
      default: null,
    },
    keepModelData: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sectionTitle: window.hydrate.report_section_title,
      formTitle: window.hydrate.report_form_title,
      initialValue: null,
      isLoading: null,
      isDirtyForm: false,
      validationEvent: {}
    };
  },
  computed: mapState({
    formErrors: state => state.formErrors
  }),
  watch: {
    value: {
      handler: function () {
        if (this.isLoading === false) {
          this.setDirtyFormWarning();
        }
      },
      deep: true,
    },
  },
  created() {
    this.isLoading = true;
    this.initialValue = this.value;
    this.$store.commit('removeformErrors');
    this.$store.commit('addDefaultFormData', window.hydrate.default_data);
    this.onBeforeUnloadHandler = function(e) {
      e.preventDefault();
      e.returnValue = "";
    };
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isLoading = false;
      }
    };
  },
  methods: {
    async save(data) {
      return await this.$refs.save.submit(data);
    },
    defaultSubmitHandler(save, data) {
      return save(data);
    },
    // will save only if there are no form validation errors in the store
    submit(data) {
      if (this.formErrors) return;
      return (this.submitHandler || this.defaultSubmitHandler)(this.save, data);
    },
    setDirtyFormWarning() {
      if (!this.isDirtyForm) {
        this.isDirtyForm = true;
        window.addEventListener("beforeunload", this.onBeforeUnloadHandler);
      }
    },
  },
};
</script>

<style scoped>
/*
  Display the form as a table while printing to take advantage of the
  repeating headers
*/
@media print {
  .P4-form {
    display: table !important;
  }

  .P4-form .P4-page-title {
    padding: 10px;
    font-size: 16px;
    line-height: 1.3;
    background-color: #ffe76b;
    margin-bottom: 10px;
  }
}
</style>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t1"
      :allow-row-removal="allowRowRemoval"
      :column-count="5"
      :columns="[
        $gettext('Year'),
        isAffectedAreas
          ? $gettext('Total affected area (km²)')
          : $gettext('Total land area (km²)'),
        $gettext('Water bodies (km²)'),
        $gettext('Total country area (km²)'),
        $gettext('Comments'),
      ]"
      :label-for-new="$gettext('+ Add New')"
      :row-factory="rowFactory"
    >
      <template #cells="{ rowId, rowIndex, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-year'"
            v-model="value.year"
            :default-value-has-changed="
              defaultValueHasChanged(['t1', rowIndex, 'year'], value.year)
            "
            :validate="[['year', 1990, 2022]]"
            numeric
            required
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-total_land_area'"
            v-model="value.total_land_area"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t1', rowIndex, 'total_land_area'],
                value.total_land_area
              )
            "
            numeric
            @input="() => updateTotalCountryArea(value)"
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-water_bodies'"
            v-model="value.water_bodies"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t1', rowIndex, 'water_bodies'],
                value.water_bodies
              )
            "
            numeric
            @input="() => updateTotalCountryArea(value)"
          />
        </td>
        <td class="P4-cell-right">
          <P4DisplayValue :value="value.total_country_area" />
        </td>
        <td class="P4-cell">
          <P4Input :id="rowId + '-comment'" v-model="value.comment" textarea />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { round } from "@/lib/calculateTotal";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

function rowFactory() {
  return {
    indicators: [],
  };
}

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      $gettext,
      isAffectedAreas: window.hydrate.is_aa_form,
    };
  },
  computed: {
    rowFactory,
    magicSum: () =>
      function (a, b) {
        let sum = 0;
        sum = (+a || 0) + (+b || 0);
        return round(sum);
      },
  },
  methods: {
    allowRowRemoval() {
      return false;
    },
    updateTotalCountryArea(value) {
      value.total_country_area = this.magicSum(
        value.total_land_area,
        value.water_bodies
      ).toString();
    },
  },
};
</script>

<template>
  <span>
    <Tooltip
      :content="$gettext('Highlighted values are not default')"
      :hide="!defaultValueHasChanged"
    >
      <FormulateInput
        v-if="!unstyled"
        :id="id"
        :type="type"
        :options="cleanOptions"
        :formulate-value="value"
        ignored
        :validation="validation"
        :validation-name="validationName"
        :input-class="inputClass"
        :placeholder="placeholder"
        error-behavior="live"
        :label="label && `${label}`"
        :disabled="isDisabled"
        :help="help"
        :debounce="debounce"
        :class="{
          'print-hide': type !== 'radio',
          'is-not-default-value': defaultValueHasChanged,
        }"
        :maxlength="percent ? 5 : ''"
        @validation="validationEvent = $event"
        @input="input"
        @keydown.enter="onKeydown"
      />
      <span v-if="unstyled">
        {{ options[value] }}
      </span>
    </Tooltip>

    <!-- Special case for radio groups as they look good on print already -->
    <span
      v-if="type !== 'radio'"
      class="input-print-display print-show"
      :data-input-type="type"
    >
      {{ printDisplay }}
    </span>
    <div
      v-for="(warning, index) in customWarnings"
      :key="index"
      class="P4-text-lightblue-info text-xs"
    >
      <p class="print-hide">
        <i v-if="warning" class="fas fa-exclamation-circle"></i> {{ warning }}
      </p>
    </div>
  </span>
</template>

<script>
import { unstyled, recursiveTranslate, LazyString } from "../translations.js";
import printNumberFormat from "@/lib/mixins/printNumberFormat";
import * as utils from "../warnings.js";

export default {
  mixins: [printNumberFormat],
  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: () => {},
    },
    options: {
      type: [Object, Array],
      default: () => {},
    },
    defaultValueHasChanged: Boolean,
    required: Boolean,
    numeric: Boolean,
    checkbox: Boolean,
    label: {
      type: [String, LazyString],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    radio: Boolean,
    textarea: Boolean,
    date: Boolean,
    color: Boolean,
    validate: {
      type: [String, Array],
      default: () => {},
    },
    percent: Boolean,
    integer: Boolean,
    disabled: Boolean,
    year: Boolean,
    standalone: Boolean,
    help: {
      type: String,
      default: "",
    },
    debounce: {
      type: [Boolean, Number],
      default: false,
    },
    warnings: Array,
  },
  data() {
    return {
      isReadOnly: window.hydrate.readonly,
      isCompleted: window.hydrate.is_completed,
      validationEvent: {},
    };
  },
  computed: {
    customWarnings() {
      const result =
        this.warnings?.reduce((accumulator, currentValue) => {
          const warning = utils[currentValue.name](
            this.inputs,
            ...currentValue.args
          );
          return warning ? [...accumulator, warning] : accumulator;
        }, []) || [];
      return result;
    },
    type() {
      if (this.checkbox) {
        return "checkbox";
      }
      if (this.radio) {
        return "radio";
      }
      if (this.options) {
        return "select";
      }
      if (this.textarea) {
        return "textarea";
      }
      if (this.date) {
        return "date";
      }
      if (this.color) {
        return "color";
      }
      return "text";
    },

    printDisplay() {
      switch (this.type) {
        case "checkbox":
          return (
            (this.value ? "☒" : "☐") + (this.label ? " " + this.label : "")
          );
        case "radio":
        case "select":
          return this.cleanOptions[this.value];
        case "date":
        case "text":
        case "textarea":
        case "color":
        default:
          if (
            // Not the same as global isNaN (which checks if value is Number)
            // Here it checks if the value is NaN (eg: 2 - "1")
            Number.isNaN(this.value) ||
            this.value === null ||
            this.value === undefined
          )
            return "";

          // Avoid formatting years (with type text)
          if (this.numeric)
            return this.formatNumber(
              this.value,
              this.selectedLanguageSeparator
            );
          return this.value;
      }
    },

    cleanOptions() {
      return recursiveTranslate(this.options);
    },

    validation() {
      const makeStringValidators = () => {
        const numeric = this.numeric ? ["number"] : [];
        const percent = this.percent ? ["number", "percent"] : [];
        const year = this.year ? ["number", "year"] : [];
        const required = this.required ? ["required"] : ["optional"];
        const integer = this.integer ? ["integer"] : [];

        // XXX This is meant to be used for validations that are not numeric, percent, year or required.
        // Otherwise, validators could override each other.
        const validate = this.validate ? [this.validate] : [];

        return [
          "bail",
          ...required,
          ...validate,
          ...numeric,
          ...percent,
          ...year,
          ...integer,
        ].join("|");
      };
      const makeArrayValidators = () => {
        const numeric = this.numeric ? [["number"]] : [];
        const percent = this.percent ? [["number"], ["percent"]] : [];
        const year = this.year ? [["number"], ["year"]] : [];
        const required = this.required ? [["required"]] : [["optional"]];
        const integer = this.integer ? [["integer"]] : [];

        return [
          ["bail"],
          ...required,
          ...this.validate,
          ...numeric,
          ...percent,
          ...year,
          ...integer,
        ];
      };

      const validators = Array.isArray(this.validate)
        ? makeArrayValidators()
        : makeStringValidators();

      return validators;
    },

    validationName() {
      if (this.integer) {
        return "Integer";
      }
      if (this.year) {
        return "Year";
      }
      if (this.number) {
        return "Number";
      }
      if (this.percent) {
        return "Percent";
      }
      return "Value";
    },

    inputClass() {
      if (this.numeric || this.percent || this.integer) return "text-right";
      else if (this.type === "text") return "P4-text-input";

      if (this.standalone) return "P4-standalone-textarea";
      return "";
    },

    unstyled,

    isDisabled() {
      return this.disabled || this.isReadOnly || this.isCompleted;
    },
  },
  watch: {
    // commit store action to add or remove validation error
    validationEvent: {
      deep: true,
      handler(newValue) {
        if (newValue.hasErrors) {
          this.$store.commit("addFormError", {
            [newValue.name]: { ...newValue },
          });
        } else {
          this.$store.commit("removeFormError", newValue.name);
        }
      },
    },
  },
  methods: {
    input(value) {
      // XXX Avoid obscure bug with initial values. Bug does not affect checkboxes,
      // and the workaround would interfere when the checkbox is unchecked.
      if (!this.checkbox) {
        if (value === false) return;
      }
      if (value === "" && !this.value) return;

      let cleanValue = value;
      if ((this.numeric || this.year || this.percent) && this.value) {
        cleanValue = value.trim();
      }
      this.$emit("input", cleanValue);
    },

    onKeydown(event) {
      if (this.type == "textarea") return;
      event.preventDefault();
    },
  },
};
</script>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t2"
      :column-count="2"
      :columns="[$gettext('Year'), $gettext('Income inequality (Gini Index)')]"
    >
      <template #cells="{ rowId, rowIndex, value }">
        <td class="P4-cell">
          {{ value.year }}
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-income_inequality'"
            v-model="value.income_inequality"
            :default-value-has-changed="defaultValueHasChanged(
              ['t2', rowIndex, 'income_inequality'],
              value.income_inequality
            )"
            placeholder="No data"
            numeric
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      $gettext,
    };
  },
};
</script>

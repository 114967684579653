<template>
  <div @mouseleave="hover = false" @mouseenter="mouseEnterRasterList">
    <h4 v-translate class="text-white text-center">Reporting Data</h4>

    <ul
      id="raster-scrollbar"
      class="bg-unccdblue-dark text-white max-h-80 overflow-y-auto"
    >
      <li
        v-for="layer in raster_layers"
        :key="layer.code"
        :ref="layer.code"
        class="p-2 break-normal leading-tight cursor-pointer"
        :class="{ 'bg-selectedraster': selected === layer.code }"
        @click="selectLayer(layer)"
      >
        {{ layer.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import { Group } from "ol/layer";
import {
  geojson,
  createCropFilter,
  createMapserverRasterLayer,
} from "../lib/maps/layers";

export default {
  props: {
    olMap: {
      type: Object,
      required: true,
    },
    border: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selected: "none",
      hover: false,
      rasters: window.hydrate.rasters,
    };
  },

  computed: {
    allRasterLayers() {
      return [{ code: "none", title: "(none)" }].concat(this.rasters);
    },
    raster_layers() {
      if (this.hover) {
        return this.allRasterLayers;
      } else {
        return this.allRasterLayers.filter((l) => l.code === this.selected);
      }
    },
  },

  watch: {
    selected: {
      handler() {
        for (const layer of this.rasters) {
          const isVisible = layer.code === this.selected;
          this.rasterLayers[layer.code].setVisible(isVisible);
        }
        const raster = {
          code: this.selected,
          data: this.rasterLayers[this.selected],
        };
        this.$emit("change", raster);
      },
    },
  },

  async mounted() {
    const cropFilter = createCropFilter(geojson().readFeatures(this.border));

    this.rasterLayers = {};
    this.rasters.forEach((layer) => {
      const raster = createMapserverRasterLayer(layer.mapserver_url, layer);
      raster.addFilter(cropFilter);
      this.rasterLayers[layer.code] = raster;
    });

    this.olMap.addLayer(
      new Group({
        layers: Object.values(this.rasterLayers),
      })
    );
  },
  methods: {
    selectLayer(layer) {
      this.selected = layer.code;
      this.hover = false;
    },
    mouseEnterRasterList() {
      this.hover = true;
      this.$nextTick(() => {
        this.$refs[this.selected][0].scrollIntoView({ block: "center" });
      });
    },
  },
};
</script>

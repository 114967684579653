var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t1","allow-row-removal":_vm.allowRowRemoval,"column-count":5,"columns":[
      _vm.$gettext('Year'),
      _vm.isAffectedAreas
        ? _vm.$gettext('Total affected area (km²)')
        : _vm.$gettext('Total land area (km²)'),
      _vm.$gettext('Water bodies (km²)'),
      _vm.$gettext('Total country area (km²)'),
      _vm.$gettext('Comments'),
    ],"label-for-new":_vm.$gettext('+ Add New'),"row-factory":_vm.rowFactory},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-year',"default-value-has-changed":_vm.defaultValueHasChanged(['t1', rowIndex, 'year'], value.year),"validate":[['year', 1990, 2022]],"numeric":"","required":""},model:{value:(value.year),callback:function ($$v) {_vm.$set(value, "year", $$v)},expression:"value.year"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-total_land_area',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t1', rowIndex, 'total_land_area'],
              value.total_land_area
            ),"numeric":""},on:{"input":() => _vm.updateTotalCountryArea(value)},model:{value:(value.total_land_area),callback:function ($$v) {_vm.$set(value, "total_land_area", $$v)},expression:"value.total_land_area"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-water_bodies',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t1', rowIndex, 'water_bodies'],
              value.water_bodies
            ),"numeric":""},on:{"input":() => _vm.updateTotalCountryArea(value)},model:{value:(value.water_bodies),callback:function ($$v) {_vm.$set(value, "water_bodies", $$v)},expression:"value.water_bodies"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4DisplayValue',{attrs:{"value":value.total_country_area}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-comment',"textarea":""},model:{value:(value.comment),callback:function ($$v) {_vm.$set(value, "comment", $$v)},expression:"value.comment"}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
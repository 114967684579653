<template>
  <div>
    <FormulateForm :values="value" @input="$emit('input', $event)">
      <ul>
        <li v-for="field in schema" :key="field.name">
          <div v-if="!isFalsePositivesNegativesWithTeOrigin">
            <FormulateInput
              :name="field.name"
              :label="field.label"
              :type="field.type"
              :options="field.options"
            />
          </div>
          <div v-else>
            <span class="font-bold">{{ field.label }}</span>
            <Tooltip :content="typeTooltip">
              <div class="P4-readonly-spatial-viewer-type">
                {{ TEType(value.type) }}
              </div>
            </Tooltip>
          </div>
        </li>
      </ul>
    </FormulateForm>
  </div>
</template>

<script>
import {
  isFalseNegative,
  isFalsePositive,
  TEType,
} from "@/lib/falsePositivesNegativesHelpers";
import { gettextLazy as $gettext } from "@/lib/translations.js";

export default {
  props: {
    schema: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    isFalsePositivesNegativesWithTeOrigin: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    typeTooltip() {
      return $gettext(
        "Vector Layers imported from T.E. archive cannot be modified."
      );
    },
  },
  methods: {
    isFalseNegative,
    isFalsePositive,
    TEType,
  },
};
</script>

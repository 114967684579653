var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.question)?_c('Tooltip',{staticClass:"font-bold",attrs:{"content":_vm.question.tooltipText}},[_vm._v(" "+_vm._s(`${_vm.question.text}`)+" "),_c('span',{staticClass:"tooltip-icon"},[_vm._v("ⓘ")])]):_vm._e(),(
      !_vm.editing &&
      (Object.keys(_vm.value).length === 0 ||
        Object.values(_vm.value).every((driver) => driver === ''))
    )?_c('div',[_vm._v(" None ")]):_c('ol',{staticClass:"text-sm pl-2",class:[
      { 'm-5': !!_vm.question },
      _vm.nonEmptyValuesCount === 1 ? 'list-none' : 'list-decimal',
    ]},[_vm._l((_vm.range(_vm.selectCount, 1)),function(i){return [(
          _vm.editing || (_vm.value[_vm.selectKey + i] && _vm.value[_vm.selectKey + i] !== '')
        )?_c('li',{key:_vm.selectKey + i},[_c('P4Input',{attrs:{"id":_vm.selectKey + i,"value":_vm.value[_vm.selectKey + i],"options":_vm.uniqueSelectOptions[_vm.selectKey + i]},on:{"input":function($event){return _vm.input(i, $event)}}})],1):_vm._e()]})],2),(_vm.nonEmptyValuesCount !== _vm.selectCount)?_c('div',[_c('i',{staticClass:"fa cursor-pointer print-hide",class:_vm.editing ? 'fa-times' : 'fa-edit',on:{"click":function($event){_vm.editing = !_vm.editing}}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
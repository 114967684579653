<template>
  <div class="text-center">
    <span v-if="isEmpty && !editing"> None </span>
    <ul v-else class="inline-block text-left">
      <li v-for="(label, action) in options" :key="action">
        <P4Input
          :id="rowId + '-' + action"
          :value="value[action]"
          :label="label"
          checkbox
          @input="input(action, $event)"
        />
      </li>
    </ul>
    <i
      v-if="isEmpty || editing"
      class="fa cursor-pointer print-hide block"
      :class="editing ? 'fa-times' : 'fa-edit'"
      @click="editing = !editing"
    ></i>
  </div>
</template>
<script>
export default {
  name: "ActionsTakenCheckboxes",
  props: {
    options: {
      type: Object,
      required: true,
    },
    rowId: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    isEmpty() {
      return (
        Object.keys(this.value).length === 0 ||
        Object.values(this.value).every((action) => !action)
      );
    },
  },
  methods: {
    input(action, value) {
      this.$emit("input", { ...this.value, [action]: value });
    },
  },
};
</script>

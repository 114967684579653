<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t3"
      :column-count="8"
      :label-for-new="$gettext('+ Add Record')"
    >
      <template #table_header>
        <tr>
          <th v-translate colspan="2">Land Conversion</th>
          <th v-translate colspan="6">
            Net land productivity dynamics (km²) for the baseline period
          </th>
        </tr>
        <tr>
          <th
            v-for="(column, index) in [
              $gettext('From'),
              $gettext('To'),
              $gettext('Net area change (km²)'),
              $gettext('Declining (km²)'),
              $gettext('Moderate Decline (km²)'),
              $gettext('Stressed (km²)'),
              $gettext('Stable (km²)'),
              $gettext('Increasing (km²)'),
            ]"
            :key="index"
            class="P4-cell"
          >
            {{ column }}
          </th>
        </tr>
      </template>
      <template #cells="{ rowId, rowIndex, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-from'"
            v-model="value.from"
            :default-value-has-changed="defaultValueHasChanged(
              ['t3', rowIndex, 'from'],
              value.from
            )"
            :options="UNCCD_CLASS_MAP"
            @input="handleDropdownChange(value, rowIndex)"
          />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-to'"
            v-model="value.to"
            :default-value-has-changed="defaultValueHasChanged(
              ['t3', rowIndex, 'to'],
              value.to
            )"
            :options="UNCCD_CLASS_MAP"
            @input="handleDropdownChange(value, rowIndex)"
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-net_area_change'"
            v-model="value.net_area_change"
            :default-value-has-changed="defaultValueHasChanged(
              ['t3', rowIndex, 'net_area_change'],
              value.net_area_change
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-declining'"
            v-model="value.declining"
            :default-value-has-changed="defaultValueHasChanged(
              ['t3', rowIndex, 'declining'],
              value.declining
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-moderate_decline'"
            v-model="value.moderate_decline"
            :default-value-has-changed="defaultValueHasChanged(
              ['t3', rowIndex, 'moderate_decline'],
              value.moderate_decline
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
         <P4Input
            :id="rowId + '-stressed'"
            v-model="value.stressed"
            :default-value-has-changed="defaultValueHasChanged(
              ['t3', rowIndex, 'stressed'],
              value.stressed
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-stable'"
            v-model="value.stable"
            :default-value-has-changed="defaultValueHasChanged(
              ['t3', rowIndex, 'stable'],
              value.stable
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-increasing'"
            v-model="value.increasing"
            :default-value-has-changed="defaultValueHasChanged(
              ['t3', rowIndex, 'increasing'],
              value.increasing
            )"
            numeric
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { UNCCD_CLASS_MAP } from "../../../lib/constants.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    UNCCD_CLASS_MAP: () => UNCCD_CLASS_MAP,
  },
  methods: {
    handleDropdownChange(value, rowIndex) {
      this.$store.commit('addNewRowTableAutocomplete', {
        t3: {
          value,
          rowIndex
        }
      });
    }
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t2a","allow-row-removal":_vm.allowRowRemoval,"label-for-new":_vm.$gettext('+ Other transfers'),"column-count":4,"columns":[
      '',
      _vm.$gettext('Year'),
      _vm.$gettext('Amounts'),
      _vm.$gettext('Additional Information'),
    ]},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, value }){return [(
          _vm.OPTIONS.T2a.headings[value.id] &&
          _vm.OPTIONS.T2a.headings[value.id].is_heading
        )?_c('th',{staticClass:"P4-cell text-left"},[_vm._v(" "+_vm._s(_vm.OPTIONS.T2a.headings[value.id].name)+" ")]):(
          _vm.OPTIONS.T2a.headings[value.id] &&
          !_vm.OPTIONS.T2a.headings[value.id].is_heading
        )?_c('td',{staticClass:"P4-cell text-left"},[_vm._v(" "+_vm._s(_vm.OPTIONS.T2a.headings[value.id].name)+" ")]):_c('td',{staticClass:"P4-cell text-left"},[_c('P4Input',{attrs:{"id":rowId + '-id'},model:{value:(value.id),callback:function ($$v) {_vm.$set(value, "id", $$v)},expression:"value.id"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-year',"validate":[['year', 1990, 2022]]},model:{value:(value['year']),callback:function ($$v) {_vm.$set(value, 'year', $$v)},expression:"value['year']"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-amounts',"numeric":""},model:{value:(value['amounts']),callback:function ($$v) {_vm.$set(value, 'amounts', $$v)},expression:"value['amounts']"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-additional_information',"placeholder":"NA","textarea":""},model:{value:(value.additional_information),callback:function ($$v) {_vm.$set(value, "additional_information", $$v)},expression:"value.additional_information"}})],1)]}},{key:"table_footer",fn:function({ values }){return [_c('tr',[_c('th',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-cell"},[_vm._v(" Total expenditures / total per year ")]),_c('td',{staticClass:"P4-cell",attrs:{"colspan":"4"}},[_c('P4Input',{attrs:{"id":'total_expenditures',"numeric":""},model:{value:(values.total_expenditures),callback:function ($$v) {_vm.$set(values, "total_expenditures", $$v)},expression:"values.total_expenditures"}})],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
/**
 * Group Array of Objects using the values from the specified property.
 * Function assumes that values are already unique, and makes no
 * attempt to validate this.
 *
 * @param array {Object[]}
 * @param propertyName {String}
 * @return {{}}
 */
export default function groupByUnique(array, propertyName) {
  const result = {};
  (array || []).forEach((row) => {
    const value = row[propertyName];
    result[value] = row;
  });
  return result;
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t1","columns":[
      _vm.$gettext('Target'),
      _vm.$gettext('Year'),
      _vm.$gettext('Level of application'),
      _vm.$gettext('Status of target achievement'),
      _vm.$gettext('Comments'),
    ],"column-count":5,"label-for-new":_vm.$gettext('+ Add Record')},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, value }){return [_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-target',"textarea":""},model:{value:(value.target),callback:function ($$v) {_vm.$set(value, "target", $$v)},expression:"value.target"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-year',"year":""},model:{value:(value.year),callback:function ($$v) {_vm.$set(value, "year", $$v)},expression:"value.year"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-level_of_application',"options":_vm.OPTIONS.level_of_application},model:{value:(value.level_of_application),callback:function ($$v) {_vm.$set(value, "level_of_application", $$v)},expression:"value.level_of_application"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-status_of_target_achievement',"options":_vm.OPTIONS.status_of_target_achievement},model:{value:(value.status_of_target_achievement),callback:function ($$v) {_vm.$set(value, "status_of_target_achievement", $$v)},expression:"value.status_of_target_achievement"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-comments',"textarea":""},model:{value:(value.comments),callback:function ($$v) {_vm.$set(value, "comments", $$v)},expression:"value.comments"}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
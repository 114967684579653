<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t2"
      :column-count="2"
      :columns="[$gettext('Change in the indicator'), $gettext('Comments')]"
      :label-for-new="$gettext('+ Add Record')"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-change_in_the_indicator'"
            v-model="value.change_in_the_indicator"
            :options="CHANGES_IN_THE_INDICATOR"
          />
        </td>
        <td class="P4-cell">
          <P4Input :id="rowId + '-comments'" v-model="value.comments" textarea/>
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { CHANGES_IN_THE_INDICATOR } from "../../../lib/constants.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    CHANGES_IN_THE_INDICATOR() {
      return CHANGES_IN_THE_INDICATOR;
    },
  },
};
</script>

<template>
  <FormulateInput
    type="multitable"
    name="t2"
    :column-count="2"
    :columns="[$gettext('Qualitative Assessment'), $gettext('Comment')]"
    :label-for-new="$gettext('+ Add Record')"
  >
    <template #cells="{ rowId, value }">
      <td class="P4-cell">
        <P4Input
          :id="rowId + '-qualitative_assessment'"
          v-model="value.qualitative_assessment"
          :options="OPTIONS.T2.qualitative_assessment"
        />
      </td>

      <td class="P4-cell">
        <P4Input :id="rowId + '-comment'" v-model="value.comment" textarea />
      </td>
    </template>
  </FormulateInput>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS() {
      return OPTIONS;
    },
  },
};
</script>

<template>
  <div ref="tooltipText" class="tooltip" @onload="calculatePosition">
    <slot></slot>
    <span v-if="!hide" :class="textClass">
      {{ `${content}` }}
    </span>
  </div>
</template>

<script>
import { unstyled, LazyString } from "../translations.js";

export default {
  props: {
    content: {
      type: [String, LazyString],
      required: true,
    },
    hide: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      textClass: "",
    };
  },
  mounted: function () {
    this.calculatePosition();
  },
  methods: {
    calculatePosition() {
      const leftOffset = this.$refs.tooltipText.getBoundingClientRect().left;
      if (leftOffset > screen.width / 2) {
        this.textClass = unstyled()
          ? "tooltip-text-showall"
          : "tooltip-text tooltip-text-right";
        return this.textClass;
      } else {
        this.textClass = unstyled() ? "tooltip-text-showall" : "tooltip-text";
        return this.textClass;
      }
    },
  },
};
</script>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t1"
      :column-count="13"
      :label-for-new="$gettext('+ Add New')"
      :row-factory="rowFactory"
      :columns="[
        $gettext('Provided Received'),
        $gettext('Year'),
        $gettext('Title of project, programme, activity or other'),
        $gettext('Amount'),
        $gettext('Recipient Provider'),
        $gettext('Description and objectives'),
        $gettext('Sector'),
        $gettext('Type of technology'),
        $gettext('Activities undertaken by'),
        $gettext('Status of measure or activity'),
        $gettext('Timeframe of measure or activity'),
        $gettext('Use, impact and estimated results'),
        $gettext('Additional Information'),
      ]"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-provided_received'"
            v-model="value.provided_received"
            :options="SO5_OPTIONS.PROVIDED_RECEIVED"
            radio
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-year'"
            v-model="value['year']"
            :validate="[['year', 1990, 2022]]"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-title_of_project'"
            v-model="value['title_of_project']"
          />
        </td>

        <td class="P4-cell">
          <P4Input :id="rowId + '-amount'" v-model="value['amount']" numeric />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-provider_or_recipient'"
            v-model="value.provider_or_recipient"
            :options="SO5_OPTIONS.OECD_DAC_RECIPIENT_COUNTRIES"
          />
          <P4Input
            v-if="value.provider_or_recipient === 'other'"
            id="provider_or_recipient_other"
            v-model="value['provider_or_recipient_other']"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-description_and_objectives'"
            v-model="value['description_and_objectives']"
          />
        </td>

        <td class="P4-cell">
          <ul>
            <li
              v-for="(option, index) in SO5_OPTIONS.SECTOR"
              :key="index"
              class="max-w-max"
            >
              <P4Input
                :key="'sector-' + index"
                v-model="value.sector[index]"
                :name="'sector-' + index"
                :label="option"
                checkbox
              />
            </li>
            <li>
              <P4Input
                v-if="value.sector['other']"
                id="sector_other"
                v-model="value['sector_other']"
              />
            </li>
          </ul>
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-type_of_technology'"
            v-model="value['type_of_technology']"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-activities_undertaken_by'"
            v-model="value['activities_undertaken_by']"
            :options="OPTIONS.T1.activities_undertaken"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-status_of_measure_or_activity'"
            v-model="value['status_of_measure_or_activity']"
            :options="OPTIONS.T1.status_of_measure_or_activity"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-timeframe_of_measure_or_activity'"
            v-model="value['timeframe_of_measure_or_activity']"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-estimated_results'"
            v-model="value['estimated_results']"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-additional_information'"
            v-model="value.additional_information"
            textarea
          />
        </td>
      </template>

      <template #table_footer="{ values }">
        <tr>
          <th v-translate class="P4-cell" colspan="3">Total provided:</th>
          <td colspan="2" class="P4-cell">
            <P4DisplayValue :value="calculate_total_provided(values)" />
          </td>

          <th v-translate class="P4-cell" colspan="3">Total received:</th>
          <td colspan="2" class="P4-cell">
            <P4DisplayValue :value="calculate_total_received(values)" />
          </td>
        </tr>

        <tr v-for="year in get_total_years(values)" :key="year">
          <th
            v-translate="{ year: year == null ? 'year' : year }"
            class="P4-cell"
            colspan="3"
          >
            Total per year %{year} provided:
          </th>
          <td colspan="2" class="P4-cell">
            <P4DisplayValue
              :value="calculate_total_per_year_provided(values, year)"
            />
          </td>

          <th
            v-translate="{ year: year == null ? 'year' : year }"
            class="P4-cell"
            colspan="3"
          >
            Total per year %{year} received:
          </th>
          <td colspan="2" class="P4-cell">
            <P4DisplayValue
              :value="calculate_total_per_year_received(values, year)"
            />
          </td>
        </tr>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import SO5_OPTIONS from "../SO5_OPTIONS";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import {
  calculateTotalYearProvidedReceived,
  getTotalYears,
} from "../../../lib/calculateTotalPerYear.js";
import { calculateTotalProvidedReceived } from "../../../lib/calculateTotal.js";

function rowFactory() {
  return {
    sector: {},
    provider_or_recipient: {},
  };
}
export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    SO5_OPTIONS: () => SO5_OPTIONS,
    rowFactory,
    get_total_years() {
      return function (values) {
        return getTotalYears(values);
      };
    },
    calculate_total_per_year_provided() {
      return function (values, year) {
        return calculateTotalYearProvidedReceived(
          values,
          year,
          "amount",
          "provided_received",
          "provided"
        );
      };
    },
    calculate_total_per_year_received() {
      return function (values, year) {
        return calculateTotalYearProvidedReceived(
          values,
          year,
          "amount",
          "provided_received",
          "received"
        );
      };
    },
    calculate_total_provided() {
      return function (values) {
        return calculateTotalProvidedReceived(
          values,
          "amount",
          "provided_received",
          "provided"
        );
      };
    },
    calculate_total_received() {
      return function (values) {
        return calculateTotalProvidedReceived(
          values,
          "amount",
          "provided_received",
          "received"
        );
      };
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t5","column-count":7,"columns":_vm.isAffectedAreas
        ? Object.values(_vm.OPTIONS.T5.headings_affected_areas)
        : Object.values(_vm.OPTIONS.T5.headings),"label-for-new":_vm.$gettext('+ Add New'),"row-factory":_vm.rowFactory},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, value }){return [_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-brightspots',"textarea":""},model:{value:(value.brightspots),callback:function ($$v) {_vm.$set(value, "brightspots", $$v)},expression:"value.brightspots"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-location'},model:{value:(value.location),callback:function ($$v) {_vm.$set(value, "location", $$v)},expression:"value.location"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-area',"numeric":"","disabled":!!value.polygon && value.polytype === 'Polygon'},model:{value:(value.area),callback:function ($$v) {_vm.$set(value, "area", $$v)},expression:"value.area"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-assessment_process',"options":_vm.OPTIONS.T4.options.assessment_process},model:{value:(value.assessment_process),callback:function ($$v) {_vm.$set(value, "assessment_process", $$v)},expression:"value.assessment_process"}})],1),_c('td',{staticClass:"P4-cell"},[_c('ActionsTakenCheckboxes',{attrs:{"options":_vm.OPTIONS.T4.options.action_taken,"row-id":rowId},model:{value:(value.action_taken_to_redress_degradation),callback:function ($$v) {_vm.$set(value, "action_taken_to_redress_degradation", $$v)},expression:"value.action_taken_to_redress_degradation"}})],1),_c('td',{staticClass:"P4-cell"},[_c('button',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"rounded-empty-btn-sm m-2 print-hide",attrs:{"id":rowId + '-implementing-actions-button',"type":"button"},on:{"click":function($event){_vm.showRowId = rowId;
            _vm.isImplementingModalVisible = true;}}},[_vm._v(" Choose implementing actions ")]),_vm._l((value.implementing_actions),function(actionValue,action){return _c('div',{key:action,staticClass:"mt-4"},[(actionValue === true)?_c('ul',{staticClass:"P4-ul"},[_c('li',[_vm._v(" "+_vm._s(_vm.OPTIONS.T4.options.remediating_actions[action].label)+" "),_vm._l((value
                  .implementing_actions['items_for_' + action]),function(subActionValue,subAction){return _c('ul',{key:subAction,staticClass:"P4-ul italic"},[(subActionValue === true)?_c('li',[_vm._v(" "+_vm._s(_vm.OPTIONS.T4.options.remediating_actions[action].options[ subAction ])+" ")]):_vm._e()])})],2)]):_vm._e()])}),_c('InputDetailModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRowId === rowId && _vm.isImplementingModalVisible),expression:"showRowId === rowId && isImplementingModalVisible"}],attrs:{"id":rowId + '-implementing-actions-modal'},on:{"close":function($event){_vm.isImplementingModalVisible = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Implementing action(s)")])]},proxy:true},{key:"body",fn:function(){return [_c('ul',_vm._l((_vm.OPTIONS.T4.options
                  .remediating_actions),function({ label, options },action){return _c('li',{key:action},[_c('P4Input',{attrs:{"id":rowId + '-' + action,"label":label,"checkbox":""},model:{value:(value.implementing_actions[action]),callback:function ($$v) {_vm.$set(value.implementing_actions, action, $$v)},expression:"value.implementing_actions[action]"}}),(value.implementing_actions[action])?_c('ul',_vm._l((options),function(subLabel,subAction){return _c('li',{key:subAction,staticClass:"w-full text-gray-500 text-base pl-6"},[_c('P4Input',{attrs:{"id":rowId + '-' + action + '-' + subAction,"checkbox":"","label":subLabel},model:{value:(
                        value.implementing_actions['items_for_' + action][
                          subAction
                        ]
                      ),callback:function ($$v) {_vm.$set(value.implementing_actions['items_for_' + action], 
                          subAction
                        , $$v)},expression:"\n                        value.implementing_actions['items_for_' + action][\n                          subAction\n                        ]\n                      "}})],1)}),0):_vm._e()],1)}),0)]},proxy:true}],null,true)})],2),_c('polygon-cell',{attrs:{"value":value,"table-id":"t5"}})]}},{key:"table_footer",fn:function({ values }){return [_c('tr',[_c('th',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-cell",attrs:{"colspan":"2"}},[_vm._v(" Total no. of brightpots ")]),_c('td',{staticClass:"P4-cell",attrs:{"colspan":"5"}},[_c('P4DisplayValue',{attrs:{"value":_vm.calculate_total_no_of_brightspots(values)}})],1)]),_c('tr',[_c('th',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-cell",attrs:{"colspan":"2"}},[_vm._v("Total brightspot area")]),_c('td',{staticClass:"P4-cell",attrs:{"colspan":"3"}},[_c('P4DisplayValue',{attrs:{"value":_vm.calculate_total_brightspot_area(values)}})],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <FormulateInput type="multitable" name="t1">
      <template #table_header>
        <tr>
          <th rowspan="2"></th>
          <th v-translate colspan="5">Drought intensity classes</th>
        </tr>
        <tr>
          <th
            v-for="(column, index) in [
              $gettext('Mild drought (km²)'),
              $gettext('Moderate drought (km²)'),
              $gettext('Severe drought (km²)'),
              $gettext('Extreme drought (km²)'),
              $gettext('Non-drought (km²)'),
            ]"
            :key="index"
            class="P4-cell"
          >
            {{ column }}
          </th>
        </tr>
      </template>
      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          {{ value.year }}
        </th>
        <td
          v-for="(column, index) in [
            'mild_drought',
            'moderate_drought',
            'severe_drought',
            'extreme_drought',
            'non_drought',
          ]"
          :key="index"
          class="P4-cell-right"
        >
          <P4Input
            :id="rowId + '-' + column"
            v-model="value[column]"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, column],
              value[column]
            )"
            placeholder="No data"
            numeric
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>
<script>
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      $gettext,
    };
  },
};
</script>

<template>
  <p4-modal>
    <div
      class="absolute top-0 right-2 cursor-pointer"
      @click.prevent="$emit('close')"
    >
      x
    </div>
    <p>
      {{ primaryText }}
    </p>
    <br />
    <p>
      {{ secondaryText }}
    </p>
    <hr class="mt-4 mb-4" />
    <div class="flex items-center justify-end">
      <button
        v-if="hideable"
        id="dont-show-disclaimer"
        v-translate
        class="rounded-empty-btn-lg m-2"
        @click="$emit('dont-show-again')"
      >
        Never show this message again
      </button>
      <button
        id="close-disclaimer"
        v-translate
        class="rounded-empty-btn-lg m-2"
        @click="$emit('close')"
      >
        Okay
      </button>
    </div>
  </p4-modal>
</template>

<script>
export default {
  name: "Disclaimer",
  props: {
    primaryText: {
      type: String,
      required: true,
    },
    secondaryText: {
      type: String,
      default: "",
    },
    hideable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

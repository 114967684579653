<template>
  <P4Form v-model="values">
    <h2 v-translate class="P4-section-title">Drought hazard indicator</h2>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Hazard refers to a drought event caused by a natural hydrometeorological
      deficit which has the potential to impact exposed and vulnerable
      populations and ecosystems. This section is pre-filled with default
      information derived from the Global Precipitation Climatology Centre
      (GPCC) Monitoring Product v6. Keep the default national estimates or, in
      the event of data and capacity, replace them with national data.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Hazard refers to a drought event caused by a natural hydrometeorological
      deficit which has potential to impact exposed and vulnerable populations
      and ecosystems. Precipitation data can be derived from the Global
      Precipitation Climatology Centre (GPCC) Monitoring Product v6.
    </p>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO3-1.T1: National estimates of the land area in each drought intensity
      class as defined by the Standardized Precipitation Index (SPI) or other
      nationally relevant drought indices
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO3-1.T1: Affected area estimates of the land area in each drought
      intensity class as defined by the Standardised Precipitation Index (SPI)
      or other nationally relevant drought indices
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Provide the area affected by meteorological drought hazard on an annual
      basis, using the Standardized Precipitation Index (SPI), drought intensity
      classes (defined by the World Meteorological Organization 2012) or another
      drought index in use at country level. The unit of measurement for SO3-1
      indicator is spatial extent (km²) expressed as the proportion (percentage
      or %) of the total land area of the country under each drought intensity
      class in each reporting year.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Provide the area affected by meteorological drought hazard on an annual
      basis, using the Standardized Precipitation Index (SPI), drought intensity
      classes (defined by the World Meteorological Organization 2012) or another
      drought index in use at country level. The unit of measurement for the
      SO3-1 indicator is spatial extent (km²) expressed as the proportion
      (percentage or %) of the total land area of the country under each drought
      intensity class in each reporting year but it will need to be adapted for
      the affected area of the country only.
    </p>
    <CommentAnchor :name="form_id + '-T1'" />
    <T1 />
    <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />

    <h3 v-translate class="P4-caption">
      SO3-1.T2: Summary table for land area under drought without class break
      down
    </h3>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Provide the total area under drought, i.e., the sum of the area under all
      drought intensity classes in the table above, as well as the proportion of
      the total land area under drought, on an annual basis.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Provide the total affected area under drought, i.e., the sum of the area
      under all drought intensity classes in the table above, as well as the
      proportion of the total affected land area that is under drought, on an
      annual basis.
    </p>
    <CommentAnchor :name="form_id + '-T2'" />
    <T2 />
    <RestoreDefaultdata name="t2" @reloadDefaultData="reloadDefaultData" />

    <h2 v-translate class="P4-section-title">Qualitative assessment:</h2>
    <p v-translate class="P4-guidance">
      Provide a qualitative assessment of the indicator. However, please note
      that due to the effect of natural climate variability on the occurrence of
      droughts, any observed changes or trends in the proportion of land under
      drought over this short time-frame should be interpreted with caution.
    </p>
    <P4Input v-model="values.qualitative_assessments" textarea standalone />

    <h2 v-translate class="P4-section-title">General comments</h2>
    <p v-translate class="P4-guidance">
      Provide any additional comments you deem relevant.
    </p>
    <div>
      <P4Input v-model="values.so3_1_general_comments" textarea standalone />
    </div>
  </P4Form>
</template>

<script>
import T1 from "./T1.vue";
import T2 from "./T2.vue";
import getYearsBetween from "../../../lib/getYears.js";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

export default {
  components: {
    T1,
    T2,
  },
  mixins: [restoreDefaultData],
  data() {
    const values = {
      t1: getYearsBetween(2000, 2021).map((year) => ({ year })),
      t2: getYearsBetween(2000, 2021).map((year) => ({ year })),

      ...window.hydrate.data,
    };
    return {
      values,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
    };
  },
};
</script>

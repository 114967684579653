<template>
  <div>
    <Tooltip v-if="question" class="font-bold" :content="question.tooltipText">
      {{ `${question.text}` }}
      <span class="tooltip-icon">ⓘ</span>
    </Tooltip>
    <div
      v-if="
        !editing &&
        (Object.keys(value).length === 0 ||
          Object.values(value).every((driver) => driver === ''))
      "
    >
      None
    </div>
    <ol
      v-else
      class="text-sm pl-2"
      :class="[
        { 'm-5': !!question },
        nonEmptyValuesCount === 1 ? 'list-none' : 'list-decimal',
      ]"
    >
      <template v-for="i in range(selectCount, 1)">
        <li
          v-if="
            editing || (value[selectKey + i] && value[selectKey + i] !== '')
          "
          :key="selectKey + i"
        >
          <P4Input
            :id="selectKey + i"
            :value="value[selectKey + i]"
            :options="uniqueSelectOptions[selectKey + i]"
            @input="input(i, $event)"
          />
        </li>
      </template>
    </ol>
    <div v-if="nonEmptyValuesCount !== selectCount">
      <i
        class="fa cursor-pointer print-hide"
        :class="editing ? 'fa-times' : 'fa-edit'"
        @click="editing = !editing"
      />
    </div>
  </div>
</template>

<script>
import Tooltip from "@/lib/forms/Tooltip";
import range from "@/lib/range";

export default {
  name: "RankingSelect",
  components: {
    Tooltip,
  },
  props: {
    question: {
      type: Object,
      required: false,
    },
    selectKey: {
      type: String,
      required: true,
    },
    selectOptions: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    nonEmptyValuesCount() {
      return Object.values(this.value).filter((v) => v).length;
    },
    selectCount() {
      // -1 for an empty option
      return Object.keys(this.selectOptions).length - 1;
    },
    uniqueSelectOptions() {
      // Initialise the options for every input with the default options
      const opt = {};
      range(this.selectCount, 1).forEach((idx) => {
        opt[this.selectKey + idx] = { ...this.selectOptions };
      });

      // Delete the options that are selected in the other inputs
      Object.entries(this.value).forEach(([key, val]) => {
        if (val) {
          range(this.selectCount, 1)
            .filter((i) => this.selectKey + i !== key)
            .forEach((i) => {
              delete opt[this.selectKey + i][val];
            });
        }
      });
      return opt;
    },
  },
  methods: {
    range,
    input(idx, eventValue) {
      const valueCopy = { ...this.value };
      valueCopy[this.selectKey + idx] = eventValue;
      this.$emit("input", valueCopy);
    },
  },
};
</script>

<style scoped>
ol li span {
  display: block;
}
</style>

import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  headings: {
    time_period: _("Time period"),
    population_exposed_to_land_degradation: _("Population exposed (count)"),
    percentage_of_total_population_exposed: _(
      "Percentage of total population exposed (%)"
    ),
    female_population_exposed_to_land_degradation: _(
      "Female population exposed (count)"
    ),
    percentage_of_female_population: _(
      "Percentage of total female population exposed (%)"
    ),
    male_population_exposed_to_land_degradation: _(
      "Male population exposed (count)"
    ),
    percentage_of_male_population: _(
      "Percentage of total male population exposed (%)"
    ),
  },
  period: {
    baseline_period: _("Baseline period"),
    reporting_period: _("Reporting period"),
  },
};

import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  T1: {
    recipient_provider: {
      recipient: _("Recipient entity, country, region"),
      global: _("Global"),
    },
    activities_undertaken: {
      public_sector: _("Public sector"),
      public_and_or_private_sector: _("Public and/or private sector"),
      private_sector: _("Private sector"),
    },
    status_of_measure_or_activity: {
      planned: _("Planned"),
      ongoing: _("Ongoing"),
      completed: _("Completed"),
    },
  },
};

<template>
  <P4Form v-model="values">
    <section>
      <h3 v-translate class="P4-caption">
        Which additional indicator is your country using to measure progress
        towards strategic objectives 1, 2, 3 and 4?
      </h3>

      <h3 v-translate class="P4-guidance">
        Countries are encouraged to identify complementary indicators that
        address their national and sub-national specificities and will
        strengthen the interpretation of the common global indicators.
      </h3>
      <T1 />
    </section>
  </P4Form>
</template>

<script>
import T1 from "./T1.vue";

export default {
  components: {
    T1,
  },
  data() {
    const values = {
      ...window.hydrate.data,
    };

    return {
      values,
    };
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormulateForm',{on:{"submit":_vm.submit},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}},[_c('h2',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-section-title"},[_vm._v("Spatial data License")]),_c('h3',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-caption font-semibold"},[_vm._v("Choose license")]),_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({
      html_start_link: `<a class='P4-link' href='https://creativecommons.org/licenses/by-nc/2.0/'>`,
      html_end_link: `</a>`,
    }),expression:"{\n      html_start_link: `<a class='P4-link' href='https://creativecommons.org/licenses/by-nc/2.0/'>`,\n      html_end_link: `</a>`,\n    }"}],staticClass:"P4-guidance P4-inline-p",attrs:{"render-html":"true"}},[_vm._v(" As described in the reporting manual, UNCCD has an open data commitment and intends to share data provided by Parties with as few constraints and restrictions on its use as possible. Therefore, the data shared by country Parties will carry the Creative Commons Attribution-NonCommercial 2.0 Generic (CC BY-NC 2.0) license. To find out more about this licence (translated in all UN languages),please visit this %{ html_start_link } link. %{ html_end_link } ")]),_c('div',{staticClass:"pt-6"},[_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({
        html_start_link: `<a class='P4-link' href='https://creativecommons.org/licenses/'>`,
        html_end_link: `</a>`,
      }),expression:"{\n        html_start_link: `<a class='P4-link' href='https://creativecommons.org/licenses/'>`,\n        html_end_link: `</a>`,\n      }"}],staticClass:"P4-inline-p",attrs:{"render-html":"true"}},[_vm._v(" If you do not intend to share your data under the terms of this licence, please select an alternative Creative Commons Licence (learn more %{ html_start_link } here %{ html_end_link } about the Creative Commons licenses): ")]),_c('P4Input',{attrs:{"id":"gis_options_cc_license","label":_vm.$gettext('Creative Commons license'),"options":_vm.license_options},model:{value:(_vm.values.cc_license),callback:function ($$v) {_vm.$set(_vm.values, "cc_license", $$v)},expression:"values.cc_license"}}),_c('p',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Alternatively provide the terms of use according to your own licence. Please state the licence terms and additional restrictions on the dataset here: ")]),_c('P4Input',{attrs:{"id":"gis_options_custom_license","label":_vm.$gettext('Custom license'),"textarea":"","standalone":""},model:{value:(_vm.values.custom_license),callback:function ($$v) {_vm.$set(_vm.values, "custom_license", $$v)},expression:"values.custom_license"}})],1),_c('FormSave',{ref:"save"}),_c('FormDebug',{attrs:{"values":_vm.values}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="mb-4 print-hide">
    <FormulateErrors />

    <br />

    <p v-if="isCompleted" v-translate>
      Form is marked as completed, therefore it can't be changed.
    </p>

    <FormulateInput
      id="form-save-button"
      type="submit"
      :label="label"
      ignored
      :disabled="isDisabled"
      :input-class="state === 'failed' ? 'P4-failed' : ''"
    />
  </div>
</template>

<script>
import api from "../api.js";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    saveHandler: {
      type: Function,
      default: null,
    },
    onBeforeUnloadHandler: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      state: "default",
      isReadOnly: window.hydrate.readonly,
      isCompleted: window.hydrate.is_completed,
      isLocked: window.hydrate.is_locked,
    };
  },
  computed: {
    label() {
      switch (this.state) {
        case "default":
          return this.$gettext("Save");
        case "saving":
          return this.$gettext("Saving ...");
        case "success":
          return this.$gettext("Saved ✔");
        case "failed":
          return this.$gettext("Save (failed)");
      }
      throw new Error(`Unknown state ${this.state}`);
    },
    isDisabled() {
      return (
        this.isReadOnly ||
        this.isCompleted ||
        this.state === "saving" ||
        this.disabled ||
        this.isLocked
      );
    },
  },
  methods: {
    async defaultSaveHandler(data) {
      return await api.saveForm("", data);
    },
    async submit(data) {
      const handler = this.saveHandler || this.defaultSaveHandler;
      this.state = "saving";

      try {
        const result = await handler(data);
        this.state = "success";
        if (this.onBeforeUnloadHandler)
          window.removeEventListener(
            "beforeunload",
            this.onBeforeUnloadHandler
          );

        return result;
      } catch (e) {
        this.state = "failed";
        throw e;
      }
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t1"},scopedSlots:_vm._u([{key:"table_header",fn:function(){return [_c('tr',[_vm._l(([
            '',
            _vm.isAffectedAreas
              ? _vm.$gettext('Total area of degraded affected area (km²)')
              : _vm.$gettext('Total area of degraded land (km²)'),
          ]),function(column,index){return _c('th',{key:index,staticClass:"P4-cell"},[_vm._v(" "+_vm._s(column)+" ")])}),_c('th',[_c('Tooltip',{attrs:{"content":_vm.$gettext(
                'Report the Sustainable Development Goal as a single figure quantifying the area of degraded land as a proportion of total land area, defined as the total surface area of a country excluding the area covered by inland waters, such as major rivers and lakes.'
              )}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Proportion of degraded land over the total land area (%) ")]),_c('span',{staticClass:"tooltip-icon"},[_vm._v("ⓘ")])])],1)],2)]},proxy:true},{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('th',{staticClass:"P4-cell"},[_c('Tooltip',{attrs:{"content":_vm.OPTIONS.T1[value.id].tooltip}},[_vm._v(" "+_vm._s(_vm.OPTIONS.T1[value.id].name)+" "),_c('span',{staticClass:"tooltip-icon"},[_vm._v("ⓘ")])])],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-degraded_area',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t1', rowIndex, 'degraded_area'],
              value.degraded_area
            ),"numeric":"","warnings":[
            {
              name: 'exceedMaxTotalWarning',
              args: [value.degraded_area, _vm.getTotal(value), _vm.getYear(value)],
            },
          ]},model:{value:(value.degraded_area),callback:function ($$v) {_vm.$set(value, "degraded_area", $$v)},expression:"value.degraded_area"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4DisplayValue',{attrs:{"value":_vm.formatPercent(value.degraded_area, _vm.getTotal(value))}})],1)]}},{key:"table_footer",fn:function({ values }){return [_c('T1TableFooter',{attrs:{"values":values}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t2"},scopedSlots:_vm._u([{key:"table_header",fn:function(){return [_c('tr',[_c('th',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"rowspan":"2"}},[_vm._v("Land cover class")]),_c('th',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"colspan":"7"}},[_vm._v(" Net land productivity dynamics (km²) for the reporting period ")])]),_c('tr',_vm._l(([
            _vm.$gettext('Declining (km²)'),
            _vm.$gettext('Moderate Decline (km²)'),
            _vm.$gettext('Stressed (km²)'),
            _vm.$gettext('Stable (km²)'),
            _vm.$gettext('Increasing (km²)'),
            _vm.$gettext('No Data (km²)'),
          ]),function(column,index){return _c('th',{key:index,staticClass:"P4-cell"},[_vm._v(" "+_vm._s(column)+" ")])}),0)]},proxy:true},{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('th',{staticClass:"P4-cell"},[_vm._v(" "+_vm._s(Object.values(_vm.UNCCD_CLASS_MAP)[rowIndex])+" ")]),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-declining',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t2', rowIndex, 'declining'],
            value.declining
          ),"numeric":""},model:{value:(value.declining),callback:function ($$v) {_vm.$set(value, "declining", $$v)},expression:"value.declining"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-moderate_decline',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t2', rowIndex, 'moderate_decline'],
            value.moderate_decline
          ),"numeric":""},model:{value:(value.moderate_decline),callback:function ($$v) {_vm.$set(value, "moderate_decline", $$v)},expression:"value.moderate_decline"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-stressed',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t2', rowIndex, 'stressed'],
            value.stressed
          ),"numeric":""},model:{value:(value.stressed),callback:function ($$v) {_vm.$set(value, "stressed", $$v)},expression:"value.stressed"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-stable',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t2', rowIndex, 'stable'],
            value.stable
          ),"numeric":""},model:{value:(value.stable),callback:function ($$v) {_vm.$set(value, "stable", $$v)},expression:"value.stable"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-increasing',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t2', rowIndex, 'increasing'],
            value.increasing
          ),"numeric":""},model:{value:(value.increasing),callback:function ($$v) {_vm.$set(value, "increasing", $$v)},expression:"value.increasing"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-no_data',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t2', rowIndex, 'no_data'],
            value.no_data
          ),"numeric":""},model:{value:(value.no_data),callback:function ($$v) {_vm.$set(value, "no_data", $$v)},expression:"value.no_data"}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
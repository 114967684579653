import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  level_of_application: {
    national: _("National"),
    subnational: _("Subnational"),
  },
  status_of_target_achivement: {
    achieved: _("Achieved"),
    ongoing: _("Ongoing"),
    extended: _("Extended or postponed"),
    not_achived: _("Not achieved"),
    partially_achieved: _("Partially achieved"),
  },

  headings: {
    target: {
      name: _("Target"),
    },
    year: {
      name: _("Year"),
      tooltip: _(
        "Expected year of achievement or actual year (if already achieved). It must be later than 2018"
      ),
    },
    level_of_application: {
      name: _("Level of application"),
    },
    status_of_target_achievement: {
      name: _("Status of target achievement"),
    },
    comment: {
      name: _("Comments"),
    },
  },
};

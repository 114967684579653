var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t4","column-count":8,"label-for-new":_vm.$gettext('+ Add Record')},scopedSlots:_vm._u([{key:"table_header",fn:function(){return [_c('tr',[_c('th',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"colspan":"2"}},[_vm._v("Land Conversion")]),_c('th',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"colspan":"6"}},[_vm._v(" Net land productivity dynamics (km²) for the reporting period ")])]),_c('tr',_vm._l(([
            _vm.$gettext('From'),
            _vm.$gettext('To'),
            _vm.$gettext('Net area change (km²)'),
            _vm.$gettext('Declining (km²)'),
            _vm.$gettext('Moderate Decline (km²)'),
            _vm.$gettext('Stressed (km²)'),
            _vm.$gettext('Stable (km²)'),
            _vm.$gettext('Increasing (km²)'),
          ]),function(column,index){return _c('th',{key:index,staticClass:"P4-cell"},[_vm._v(" "+_vm._s(column)+" ")])}),0)]},proxy:true},{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-from',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t4', rowIndex, 'from'],
            value.from
          ),"options":_vm.UNCCD_CLASS_MAP},on:{"input":function($event){return _vm.handleDropdownChange(value, rowIndex)}},model:{value:(value.from),callback:function ($$v) {_vm.$set(value, "from", $$v)},expression:"value.from"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-to',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t4', rowIndex, 'to'],
            value.to
          ),"options":_vm.UNCCD_CLASS_MAP},on:{"input":function($event){return _vm.handleDropdownChange(value, rowIndex)}},model:{value:(value.to),callback:function ($$v) {_vm.$set(value, "to", $$v)},expression:"value.to"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-net_area_change',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t4', rowIndex, 'net_area_change'],
            value.net_area_change
          ),"numeric":""},model:{value:(value.net_area_change),callback:function ($$v) {_vm.$set(value, "net_area_change", $$v)},expression:"value.net_area_change"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-declining',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t4', rowIndex, 'declining'],
            value.declining
          ),"numeric":""},model:{value:(value.declining),callback:function ($$v) {_vm.$set(value, "declining", $$v)},expression:"value.declining"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-moderate_decline',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t4', rowIndex, 'moderate_decline'],
            value.moderate_decline
          ),"numeric":""},model:{value:(value.moderate_decline),callback:function ($$v) {_vm.$set(value, "moderate_decline", $$v)},expression:"value.moderate_decline"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-stressed',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t4', rowIndex, 'stressed'],
            value.stressed
          ),"numeric":""},model:{value:(value.stressed),callback:function ($$v) {_vm.$set(value, "stressed", $$v)},expression:"value.stressed"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-stable',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t4', rowIndex, 'stable'],
            value.stable
          ),"numeric":""},model:{value:(value.stable),callback:function ($$v) {_vm.$set(value, "stable", $$v)},expression:"value.stable"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-increasing',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t4', rowIndex, 'increasing'],
            value.increasing
          ),"numeric":""},model:{value:(value.increasing),callback:function ($$v) {_vm.$set(value, "increasing", $$v)},expression:"value.increasing"}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
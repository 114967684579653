<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t1"
      :column-count="10"
      :columns="Object.values(OPTIONS.VT_T1)"
      :label-for-new="$gettext('+ Add New')"
      :row-factory="rowFactory"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input :id="rowId + '-target'" v-model="value.target" textarea />
        </td>
        <td class="P4-cell">
          <P4Input :id="rowId + '-year'" v-model="value.year" year />
        </td>
        <td class="P4-cell">
          <P4Input :id="rowId + '-location'" v-model="value.location" />
        </td>
        <td class="P4-cell">
          <P4DisplayValue
            v-if="!!value.polygon && value.polytype === 'Polygon'"
            :value="value.area"
          />
          <P4Input v-else v-model="value.area" numeric />
        </td>
        <td class="P4-cell">
          <ActionsTakenCheckboxes
            v-model="value.overarching_type_of_ldn_intervention"
            :row-id="rowId"
            :options="SO1_4_OPTIONS.T4.options.action_taken"
          />
        </td>
        <td class="P4-cell">
          <button
            :id="rowId + '-actions-button'"
            v-translate
            type="button"
            class="rounded-empty-btn-sm m-2 print-hide"
            @click="
              actionModalData = value.remediating_actions;
              isActionModalVisible = true;
            "
          >
            Choose targeted actions
          </button>

          <div
            v-for="(actionValue, action) in value.remediating_actions"
            :key="action"
            class="mt-4"
          >
            <ul v-if="actionValue === true" class="P4-ul">
              <li>
                {{ SO1_4_OPTIONS.T4.options.remediating_actions[action].label }}
                <ul
                  v-for="(subActionValue, subAction) in value
                    .remediating_actions['items_for_' + action]"
                  :key="subAction"
                  class="P4-ul italic"
                >
                  <li v-if="subActionValue === true">
                    {{
                      SO1_4_OPTIONS.T4.options.remediating_actions[action]
                        .options[subAction]
                    }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-status'"
            v-model="value.status"
            :options="SO1_4_OPTIONS.T4.options.status_of_target_achivement"
          />
          <span v-if="value.partial_percent">
            ({{ value.partial_percent }}%)
          </span>
        </td>
        <td class="P4-cell">
          <FormulateInput
            :id="rowId + '-ldn_target'"
            v-model="value.ldn_target"
            :options="{ yes: $gettext('Yes'), no: $gettext('No') }"
            type="radio"
            ignored
          />
          <ul v-if="value.ldn_target === 'yes'">
            <li>
              <P4Input
                :id="rowId + '-ldn_target_answer_yes'"
                v-model="value.ldn_target_answer_yes"
                :options="SO1_4_OPTIONS.T4.options.ldn_target_answer_yes"
              />
            </li>
            <li v-if="value.ldn_target_answer_yes === 'other'">
              <P4Input
                :id="rowId + '-ldn_target_answer_yes_other'"
                v-model="value['ldn_target_answer_yes_other']"
              />
            </li>
          </ul>
        </td>
        <td class="P4-cell">
          <button
            :id="rowId + '-other-goals-button'"
            v-translate
            type="button"
            class="rounded-empty-btn-sm m-2 print-hide"
            @click="
              goalsModalData = value.other_important_goals_for_target;
              isGoalsModalVisible = true;
            "
          >
            Choose goals
          </button>
          <div
            v-for="(val, goal) in value.other_important_goals_for_target"
            :key="goal"
            class="mb-2"
          >
            <ul v-if="val === true" class="P4-ul">
              <li v-if="goal === 'other'">
                {{
                  SO1_4_OPTIONS.T4.options.other_important_goals_for_target[
                    goal
                  ]
                }}:
                {{
                  value.other_important_goals_for_target.items_for_other[
                    "other"
                  ]
                }}
              </li>
              <li v-else>
                {{
                  SO1_4_OPTIONS.T4.options.other_important_goals_for_target[
                    goal
                  ]
                }}
              </li>
            </ul>
          </div>
        </td>
        <polygon-cell :value="value" table-id="t1" />
      </template>
      <template #table_footer="{ values }">
        <tr>
          <td v-translate class="P4-cell">Total</td>
          <td class="P4-cell" colspan="2"></td>
          <td class="P4-cell" colspan="6">
            <p v-translate>Sum of all targeted areas</p>
            <P4DisplayValue
              :value="calculate_total_of_all_targeted_area(values)"
            />
          </td>
        </tr>
      </template>
    </FormulateInput>
    <InputDetailModal
      v-if="isActionModalVisible"
      id="targeted-actions-modal"
      @close="
        isActionModalVisible = false;
        actionModalData = null;
      "
    >
      <template #header>
        <p v-translate>Targeted actions</p>
      </template>
      <template #body>
        <ul>
          <li
            v-for="({ label, options }, action) in SO1_4_OPTIONS.T4.options
              .remediating_actions"
            :key="action"
          >
            <P4Input
              :id="action"
              v-model="actionModalData[action]"
              :label="label"
              checkbox
            />
            <ul v-if="actionModalData[action]">
              <li
                v-for="(subLabel, subAction) in options"
                :key="subAction"
                class="w-full text-gray-500 text-base pl-6"
              >
                <P4Input
                  :id="action + '-' + subAction"
                  v-model="actionModalData['items_for_' + action][subAction]"
                  checkbox
                  :label="subLabel"
                />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </InputDetailModal>
    <InputDetailModal
      v-if="isGoalsModalVisible"
      id="important-goals-modal"
      @close="
        isGoalsModalVisible = false;
        goalsModalData = null;
      "
    >
      <template #header>
        <p v-translate>Important goals</p>
      </template>
      <template #body>
        <ul>
          <li
            v-for="(label, goal) in SO1_4_OPTIONS.T4.options
              .other_important_goals_for_target"
            :key="goal"
          >
            <P4Input
              :id="goal"
              v-model="goalsModalData[goal]"
              :label="label"
              checkbox
            />
          </li>
          <li>
            <P4Input
              v-if="goalsModalData.other"
              id="other_important_goals_for_target_other"
              v-model="goalsModalData['items_for_other']['other']"
            />
          </li>
        </ul>
      </template>
    </InputDetailModal>
  </section>
</template>

<script>
import ActionsTakenCheckboxes from "@/lib/forms/ActionsTakenCheckboxes.vue";
import OPTIONS from "./OPTIONS.js";
import SO1_4_OPTIONS from "../4/OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import { calculateTotal } from "@/lib/calculateTotal.js";
import PolygonCell from "@/forms/common/PolygonCell";
import InputDetailModal from "@/lib/forms/InputDetailModal.vue";
import randomId from "@/lib/randomId.js";

function rowFactory() {
  const overarching_type_of_ldn_intervention = {};
  const other_important_goals_for_target = {};
  const remediating_actions = {};
  const rowId = randomId();

  for (const action of Object.keys(
    SO1_4_OPTIONS.T4.options.other_important_goals_for_target
  )) {
    if (action === "other") {
      other_important_goals_for_target[`items_for_${action}`] = {};
    }
  }

  for (const action of Object.keys(
    SO1_4_OPTIONS.T4.options.remediating_actions
  )) {
    remediating_actions[`items_for_${action}`] = {};
  }

  return {
    other_important_goals_for_target,
    overarching_type_of_ldn_intervention,
    remediating_actions,
    rowId,
  };
}
export default {
  components: { ActionsTakenCheckboxes, PolygonCell, InputDetailModal },
  data() {
    return {
      $gettext,
      isActionModalVisible: false,
      actionModalData: null,
      isGoalsModalVisible: false,
      goalsModalData: null,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    SO1_4_OPTIONS: () => SO1_4_OPTIONS,
    calculate_total_of_all_targeted_area() {
      return function (values) {
        return calculateTotal(values, "area");
      };
    },
  },
  methods: {
    rowFactory,
  },
};
</script>

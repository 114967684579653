<template>
  <FormulateInput
    type="multitable"
    name="items"
    :column-count="3"
    :columns="[
      $gettext('Category'),
      $gettext('Pixel value'),
      $gettext('Color'),
    ]"
    :label-for-new="$gettext('+ Add New Category')"
    :row-factory="rowFactory"
  >
    <template #cells="{ rowId, value }">
      <td class="P4-cell">
        <P4Input :id="rowId + '-category'" v-model="value.label" required />
      </td>
      <td class="P4-cell">
        <P4Input
          :id="rowId + '-pixel_value'"
          v-model="value.value"
          numeric
          validate="number16Bit"
          required
        />
      </td>
      <td class="P4-cell">
        <P4Input :id="rowId + '-color_picker'" v-model="value.color" color />
      </td>
    </template>
  </FormulateInput>
</template>

<script>
import { gettextLazy as $gettext } from "@/lib/translations.js";

function rowFactory() {
  return {
    color: "#000000",
  };
}

export default {
  name: "ColorLegendCategorical",
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    rowFactory,
  },
};
</script>

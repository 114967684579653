<template>
  <section>
    <FormulateInput type="multitable" name="t1">
      <template #table_header>
        <tr>
          <th v-translate rowspan="2">Land cover class</th>
          <th v-translate colspan="7">
            Net land productivity dynamics (km²) for the baseline period
          </th>
        </tr>
        <tr>
          <th
            v-for="(column, index) in [
              $gettext('Declining (km²)'),
              $gettext('Moderate Decline (km²)'),
              $gettext('Stressed (km²)'),
              $gettext('Stable (km²)'),
              $gettext('Increasing (km²)'),
              $gettext('No Data (km²)'),
            ]"
            :key="index"
            class="P4-cell"
          >
            {{ column }}
          </th>
        </tr>
      </template>
      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          {{ Object.values(UNCCD_CLASS_MAP)[rowIndex] }}
        </th>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-declining'"
            v-model="value.declining"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, 'declining'],
              value.declining
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-moderate_decline'"
            v-model="value.moderate_decline"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, 'moderate_decline'],
              value.moderate_decline
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-stressed'"
            v-model="value.stressed"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, 'stressed'],
              value.stressed
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-stable'"
            v-model="value.stable"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, 'stable'],
              value.stable
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-increasing'"
            v-model="value.increasing"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, 'increasing'],
              value.increasing
            )"
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-no_data'"
            v-model="value.no_data"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, 'no_data'],
              value.no_data
            )"
            numeric
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { UNCCD_CLASS_MAP } from "../../../lib/constants.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    UNCCD_CLASS_MAP: () => UNCCD_CLASS_MAP,
  },
};
</script>

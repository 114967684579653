<template>
  <P4Form v-model="values" :submit-handler="submit">
    <h2 v-if="!isAffectedAreas" v-translate class="P4-section-title">
      Proportion of degraded land over the total land area (Sustainable
      Development Goal Indicator 15.3.1)
    </h2>
    <h2 v-if="isAffectedAreas" v-translate class="P4-section-title">
      Proportion of degraded land over the total affected area
    </h2>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This section is pre-filled with national estimates derived from global
      data sources. Keep the default national estimates or, in the event of data
      and capacity, replace them with national data.
    </p>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-4.T1: National estimates of the total area of degraded land (in km²),
      and the proportion of degraded land relative to the total land area
    </h3>
    <div
      v-if="
        !isAffectedAreas &&
        isReanalysed &&
        !isFalsePositivesNegativesWithTeOrigin
      "
      v-translate
      class="P4-notice print-hide"
    >
      ⚠ The data for Reporting Period was automatically updated after
      Trends.Earth Reanalysis. Before T.E Reanalysis the total area of degraded
      land had the default value of
      <i class="font-bold print-hide">{{ degradedAreaBeforeReanalysis }} km²</i
      >.
    </div>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-4.T1: Affected area estimates of the total area of degraded land (in
      km²), and the proportion of degraded land relative to the total affected
      area
    </h3>
    <CommentAnchor :name="form_id + '-T1'" />
    <T1 />
    <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />

    <h2 v-translate class="P4-section-title">Method</h2>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      The one-out, all-out approach is recommended as Good Practice to integrate
      the SO1-1, SO1-2 and SO1-3 indicator values in order to compute
      Sustainable Development Goal (SDG) Indicator 15.3.1, consistent with the
      precautionary principle. If alternative methods were employed to compute
      the SDG indicator, countries should document them in this section of the
      reporting form.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      The one-out, all-out approach is recommended as Good Practice to integrate
      the SO1-1, SO1-2 and SO1-3 indicator values in order to compute the
      proportion of land that is degraded over the total affected area,
      consistent with the precautionary principle. If alternative methods were
      employed to compute the indicator, countries should document them in this
      section of the reporting form.
    </p>
    <h3 v-translate class="text-lg">
      Did you use the SO1-1, SO1-2 and SO1-3 indicators (i.e. land cover, land
      productivity dynamics and soil organic carbon stock) to compute the
      proportion of degraded land?
    </h3>
    <br />
    <p v-translate class="font-bold">Which indicators did you use?</p>
    <br />
    <P4Input
      v-for="(label, indicator) in OPTIONS.Form.sub_indicators"
      :key="'sub_indicators-' + indicator"
      v-model="values.sub_indicators[indicator]"
      :name="'sub_indicators-' + indicator"
      :label="label"
      checkbox
    />

    <h3 v-translate class="text-lg">
      Did you apply the one-out, all-out principle to compute the proportion of
      degraded land?
    </h3>
    <br />
    <P4Input
      v-model="values.one_out_all_out_answer"
      :options="OPTIONS.Form.yes_no"
      radio
    />
    <p
      v-if="values['one_out_all_out_answer'] === 'no'"
      v-translate
      class="P4-guidance text-lg"
    >
      If not, indicate the method used to assess the proportion of degraded
      land.
    </p>
    <P4Input
      v-if="values['one_out_all_out_answer'] === 'no'"
      v-model="values.one_out_all_out_answer_no_method"
      textarea
      standalone
    />

    <h2 v-translate class="P4-section-title">Level of Confidence</h2>

    <h3 v-translate class="P4-caption">
      Indicate your country’s level of confidence in the assessment of the
      proportion of degraded land:
    </h3>
    <br />
    <P4Input
      v-model="values.level_of_confidence"
      radio
      :options="OPTIONS.Form.level_of_confidence"
    />

    <h3 v-translate class="P4-caption">
      Describe why the assessment has been given the level of confidence
      selected above:
    </h3>
    <P4Input
      v-model="values.describe_level_of_confidence"
      textarea
      standalone
    />

    <!--
    <div v-if="!isAffectedAreas">
      <h2 v-translate class="P4-section-title">
        Progress towards Land Degradation Neutrality
      </h2>
      <p v-translate class="P4-guidance">
        In addition to the binary assessment of degradation provided by SDG
        Indicator 15.3.1, monitoring Land Degradation Neutrality (LDN) involves
        quantifying the balance between the area of gains (significant positive
        changes in LDN indicators, i.e. land cover, land productivity and SOC
        stocks) and area of losses (significant negative changes in LDN
        indicators) within each land type across the landscape. Neutrality is
        achieved when the area of losses equals the area of gains within each
        land type and across land types, at national scale. This section is
        pre-filled with national estimates derived from global data sources.
        Keep the default national estimates or, in the event of data and
        capacity, replace them with national data.
      </p>

      <h3 v-translate class="P4-caption">
        SO1-4.T2: National estimates of the total areas of gains and losses, and
        of the net gain or loss (km²)
      </h3>

      <p v-translate class="P4-guidance">
        Report the total area of improved land (gains) and degraded land
        (losses) and estimate the net gain or loss as an indication of progress
        towards LDN.
      </p>
      <CommentAnchor :name="form_id + '-T2'" />
      <T2 />
      <RestoreDefaultdata name="t2" @reloadDefaultData="reloadDefaultData" />
    </div>
    -->
    <h2 v-translate class="P4-section-title">
      False positives/ False negatives
    </h2>
    <h3 v-translate class="P4-caption">
      SO1-4.T3: Justify why any area identified as degraded or non-degraded in
      the SO1-1, SO1-2 or SO1-3 indicator data should or should not be included
      in the overall Sustainable Development Goal indicator 15.3.1 calculation.
    </h3>
    <p v-translate class="P4-guidance">
      If possible, provide spatial data using the Performance Review and
      Assessment of Implementation System (PRAIS) to delineate the areas of
      false positives or false negatives. It is still possible to document the
      processes that have led to the false positive or negative outcome without
      the addition of spatial data, but this will not be reflected in the
      calculation of SDG Indicator 15.3.1 and associated reporting data.
    </p>
    <CommentAnchor :name="form_id + '-T3'" />
    <T3
      :analysis-data="t3AnalysisData"
      :is-false-positives-negatives-with-te-origin="
        isFalsePositivesNegativesWithTeOrigin
      "
    />
    <h2 v-translate class="P4-section-title">
      Perform qualitative assessments of areas identified as degraded or
      improved
    </h2>
    <p v-translate class="P4-guidance">
      Identify hotspot/brightspot areas using raster data provided in the PRAIS
      raster catalogue to delineate the extent of hotspot/brightspot areas as
      accurately as possible. Provide further interpretation of the
      hotspot/brightspot areas in the fields provided in the reporting tables.
    </p>
    <h3 v-translate class="P4-caption">SO1-4.T4: Degradation hotspots</h3>
    <p v-translate class="P4-guidance">
      Report the main degradation hotspots, their location, spatial extent,
      drivers and remediating actions.
    </p>
    <CommentAnchor :name="form_id + '-T4'" />
    <T4 />

    <RankingSelect
      v-model="values.t4_indirect_drivers"
      :question="OPTIONS.T4.indirectDriversQuestion"
      select-key="indirect_driver_of_land_degradation_at_national_level-"
      :select-options="OPTIONS.T4.options.drivers_indirect"
    />

    <h3 v-translate class="P4-caption">SO1-4.T5: Improvement brightspots</h3>
    <p v-translate class="P4-guidance">
      Report the main improvement brightspots, their location, spatial extent,
      and response mechanisms.
    </p>
    <CommentAnchor :name="form_id + '-T5'" />
    <T5 />

    <RankingSelect
      v-model="values.t5_enabling_responses"
      :question="OPTIONS.T5.enablingResponsesQuestion"
      select-key="enabling_and_instrumental_responses-"
      :select-options="OPTIONS.T5.options.enabling_and_instrumental_responses"
    />

    <h2 v-translate class="P4-section-title">General comments</h2>
    <p v-translate class="P4-guidance">
      Provide any additional comments you deem relevant.
    </p>
    <div>
      <P4Input v-model="values.so1_4_general_comments" textarea standalone />
    </div>

    <ul>
      <li v-for="(htl, i) in hackyTranslationLists" :key="i">
        ===
        <ul>
          <li v-for="txt in htl" :key="txt">
            {{ txt }}
          </li>
        </ul>
      </li>
    </ul>
  </P4Form>
</template>

<script>
import api from "@/lib/api";
import range from "../../../lib/range.js";
import OPTIONS from "./OPTIONS.js";
import { allOptions } from "@/lib/translations";
import T1 from "./T1.vue";
import T3 from "./T3.vue";
import T4 from "./T4.vue";
import T5 from "./T5.vue";
import RankingSelect from "@/lib/forms/RankingSelect";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

function valuesForAllOptions() {
  function valuesForT3() {
    const { type, basic_of_judgement, periods } = OPTIONS.T3;

    const len = (dict) => Object.keys(dict).length;

    const numRows = Math.max(
      ...[len(type), len(basic_of_judgement), len(periods)]
    );

    return range(numRows).map((i) => ({
      type: Object.keys(type)[i],
      basic_of_judgement: Object.keys(basic_of_judgement)[i],
      periods: Object.keys(periods)[i],
    }));
  }

  return {
    one_out_all_out_answer: "no",
    t3: valuesForT3(),
  };
}

export default {
  components: {
    T1,
    T3,
    T4,
    T5,
    RankingSelect,
  },
  mixins: [restoreDefaultData],

  data() {
    const values = {
      t1: Object.keys(OPTIONS.T1).map((key) => ({ id: key })),
      t2: Object.keys(OPTIONS.T2).map((key) => ({ id: key })),
      sub_indicators: {},
      t4_indirect_drivers: {},
      t5_enabling_responses: {},
      ...window.hydrate.data,
    };

    if (allOptions()) {
      Object.assign(values, valuesForAllOptions());
    }

    return {
      isFalsePositivesNegativesWithTeOrigin:
        window.hydrate.is_false_positives_negatives_with_te_origin,
      values,
      t3AnalysisData: { ...values },
      OPTIONS,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
      isReanalysed: window.hydrate.data.is_recalculated
        ? window.hydrate.data.is_recalculated
        : false,
      degradedAreaBeforeReanalysis: window.hydrate.default_data.t1
        ? window.hydrate.default_data.t1.find(
            (e) => e.id === "reporting_period"
          ).degraded_area
        : 0,
    };
  },

  computed: {
    hackyTranslationLists() {
      if (!allOptions()) return [];

      return [
        OPTIONS.T4.options.assessment_process,
        OPTIONS.T4.options.drivers_direct,
        OPTIONS.T4.options.assessment_process,
        OPTIONS.T4.options.drivers_direct,
        OPTIONS.T4.options.action_taken,
        OPTIONS.T4.options.remediating_actions,
        OPTIONS.T4.options.remediating_actions
          .restore_improve_tree_covered_area,
        OPTIONS.T4.options.remediating_actions
          .increase_tree_covered_areas_extend,
        OPTIONS.T4.options.remediating_actions.restore_improve_cropland,
        OPTIONS.T4.options.remediating_actions.restore_improve_grassland,
        OPTIONS.T4.options.remediating_actions.restore_improve_wetland,
        OPTIONS.T4.options.remediating_actions
          .increase_soil_fertility_and_carbon_stock,
        OPTIONS.T4.options.remediating_actions.manage_artificial_surfaces,
        OPTIONS.T4.options.remediating_actions.restore_improve_protected_areas,
        OPTIONS.T4.options.remediating_actions.increase_protected_areas,
        OPTIONS.T4.options.remediating_actions.improve_coastal_management,
        OPTIONS.T4.options.remediating_actions.other_general_unspecified,
        OPTIONS.T4.options.drivers_indirect,
      ].map((optionList) => Object.values(optionList));
    },
  },
  methods: {
    async submit(save, data) {
      await save(data);
      if (!this.isAffectedAreas) {
        try {
          const resp = await api.getDataForReanalysis(
            "get-data-for-reanalysis"
          );
          this.t3AnalysisData = resp.data;
        } catch (e) {
          alert("Could not check for reanalysis availability");
        }
      }
    },
  },
};
</script>

import { UNCCD_CLASS_MAP } from "@/lib/constants";
import OPTIONS from "@/forms/so1/1/OPTIONS";
import { calculateTotal, round } from "@/lib/calculateTotal";
import { UNCCD_CLASS_MAP_KM } from "@/lib/constants";

export default {
  props: {
    customHeadings: {
      type: Array,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    UNCCD_CLASS_MAP: () => UNCCD_CLASS_MAP,
    UNCCD_CLASS_MAP_KM: () => UNCCD_CLASS_MAP_KM,
    OPTIONS: () => OPTIONS,
    headings() {
      if (this.customHeadings) return this.customHeadings;
      return Object.entries(this.UNCCD_CLASS_MAP).map(([key, name]) => ({
        key,
        name: name.toString(),
      }));
    },
    headingsKm() {
      if (this.customHeadings) return this.customHeadings;
      return Object.entries(this.UNCCD_CLASS_MAP_KM).map(([key, name]) => ({
        key,
        name: name.toString(),
      }));
    },
    headingKeys() {
      return this.headings.map((item) => item.key);
    },
    headingNames() {
      return this.headings.map((item) => item.name);
    },
    headingNamesKm() {
      return this.headingsKm.map((item) => item.name);
    },
  },
  methods: {
    calculateRowTotal(value) {
      let total = 0;
      total = this.headingKeys
        .map((key) => parseFloat(value[key]) || 0)
        .reduce((sum, value) => sum + value, 0);
      return round(total);
    },
    calculateColumnTotal(values, cls) {
      return calculateTotal(values, cls);
    },
  },
};

<template>
  <FormulateForm
    :key="reRender"
    v-slot="{ isLoading }"
    class="bg-white text-black px-4 py-3"
    @submit="submitComment"
  >
    <FormulateInput type="textarea" name="message" validation="required" />
    <FormulateInput
      v-if="threadId !== null"
      type="hidden"
      name="threadId"
      :value="threadId"
    />
    <FormulateInput
      type="submit"
      :disabled="isLoading"
      :label="isLoading ? $gettext('Loading...') : $gettext('Save')"
    />
  </FormulateForm>
</template>

<script>
import api from "../api";
import { gettextLazy as $gettext } from "../translations.js";

export default {
  props: {
    threadId: {
      type: Number,
      default: () => {},
    },
    anchor: {
      type: String,
      required: true,
    },
    isHiddenNewReply: Boolean,
  },

  data() {
    return {
      reRender: "foo",
      $gettext,
    };
  },
  methods: {
    async submitComment(data) {
      try {
        data.anchor = this.anchor;
        await api.createComment("comment", data);
        this.reRender = "bar";
        this.$emit("changeComment", true);
        this.$emit("toggleReply", !this.isHiddenNewReply);

        // XXX the this.$parent.emit is needed for the reply functionality
        this.$parent.$emit("changeComment", true);
      } catch (e) {
        this.$emit("errorMessage", e);
        this.$parent.$emit("errorMessage", e);
        throw e;
      }
    },
  },
};
</script>

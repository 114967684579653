<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t1"
      :column-count="4"
      :columns="[
        $gettext('Indicator'),
        $gettext('Relevant strategic objective'),
        $gettext('Change in the indicator'),
        $gettext('Comments'),
      ]"
      :label-for-new="$gettext('+ Add row')"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input :id="rowId + 'indicator'" v-model="value.indicator" />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-strategic_objective'"
            v-model="value.strategic_objective"
            :options="OPTIONS.T1.strategic"
          />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-indicator_change'"
            v-model="value.indicator_change"
            :options="OPTIONS.T1.indicator"
          />
        </td>
        <td class="P4-cell">
          <P4Input :id="rowId + '-comments'" v-model="value.comments" />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { gettextLazy as $gettext } from "../../lib/translations.js";
import OPTIONS from "./OPTIONS.js";

export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
  },
};
</script>

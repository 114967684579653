<template>
  <div>
    <div v-if="layer.has_data">
      <div id="vectordata-preview" class="mt-4 w-full h-[50vh]" />
    </div>

    <div v-else v-translate>No data</div>
  </div>
</template>

<script>
import { BACKGROUND_LAYERS } from "@/lib/constants";
import { geojson } from "@/lib/maps/layers";
import styles from "../lib/maps/styles";
import { createBackgroundLayer } from "@/lib/maps/layers";
import { Vector as VectorS } from "ol/source";
import { Vector as VectorL } from "ol/layer";
import { FullScreen, defaults as defaultControls } from "ol/control";
import { Map, View } from "ol";
import fitView from "@/lib/maps/fitView.js";

export default {
  computed: {
    layer: () => window.hydrate.layer,
  },

  mounted() {
    const { geojson_data } = window.hydrate;

    function geojsonLayer(name, style) {
      const features = geojson().readFeatures(geojson_data[name]);
      const source = new VectorS({ features });
      return new VectorL({ source, style });
    }

    const [s2cloudless] = BACKGROUND_LAYERS.filter(
      ({ id }) => id === "s2cloudless-2020"
    );
    const background = createBackgroundLayer(s2cloudless);

    const border = geojsonLayer("country_border", styles.borders());

    const layers = [
      background,
      border,
      geojsonLayer("layer_content", styles.vector()),
    ];

    const view = new View();
    new Map({
      controls: defaultControls().extend([new FullScreen()]),
      target: "vectordata-preview",
      layers,
      view,
    });

    setTimeout(function () {
      fitView(view, border.getSource(), window.hydrate.iso3);
    }, 100);
  },
};
</script>

<template>
  <div @mouseleave="hover = false" @mouseenter="hover = true">
    <h4 v-translate class="text-white text-center">Vector Layers</h4>

    <ul id="vector-layers-select" class="bg-unccdblue-dark text-white">
      <li
        v-for="layer in compVectorLayers"
        :key="layer.code"
        class="p-2 leading-tight cursor-pointer"
        :class="{ 'bg-selectedraster': selected === layer.code }"
        @click="selectLayer(layer)"
      >
        {{ layer.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import api from "../lib/api";
import { createVectorLayer, geojson } from "../lib/maps/layers";
import { BORDER_CODE } from "../lib/constants";
import fitView from "@/lib/maps/fitView.js";
import randomId from "@/lib/randomId.js";

export default {
  props: {
    olMap: {
      type: Object,
      required: true,
    },
    vectorLayers: {
      type: Array,
      required: true,
    },
    border: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selected: "",
      hover: false,
      selectedVectorLayer: {},
    };
  },
  computed: {
    compVectorLayers() {
      if (this.hover) {
        return this.vectorLayers;
      } else {
        return this.vectorLayers.filter((l) => l.code === this.selected);
      }
    },
  },

  watch: {
    selected: {
      handler(selected, oldSelected) {
        if (oldSelected && oldSelected !== BORDER_CODE) {
          this.olMap.removeLayer(this.selectedVectorLayer.olLayer);
        }

        this.loadVectorLayerAndMarkAsVisible(selected);
      },
    },

    selectedVectorLayer() {
      // XXX Hacks the param into the location to avoid reloading the page.
      // XXX Required because we do routing in the backend, and we want to
      // XXX avoid it here.
      const url = new URL(window.location);
      url.searchParams.set("selectedLayer", this.selectedVectorLayer.code);

      history.replaceState(
        { selectedLayer: this.selectedVectorLayer.code },
        "",
        url.toString()
      );
    },
  },
  async mounted() {
    // XXX Janky hacks to store a copy of the location, before this next method is called
    // XXX as that triggers a watcher that changes the location again. A keen eye will
    // XXX notice it flashing in the URL bar while the page first loads.
    const url = new URL(window.location);
    await this.loadVectorLayerAndMarkAsVisible(BORDER_CODE, true);
    this.selected =
      url.searchParams.get("selectedLayer") || this.vectorLayers[0].code;
  },

  methods: {
    async loadVectorLayerAndMarkAsVisible(code, fitToView = false) {
      const layerContent = await this.loadLayer(code);
      // Prevent loading a vector that is no longer selected, but always load the border
      if (code !== this.selected && code !== BORDER_CODE) {
        return;
      }
      const layerFeatures = geojson().readFeatures(layerContent);
      for (const feature of layerFeatures) {
        if (!feature.getId()) {
          feature.setId(randomId());
        }
      }
      const olLayer = createVectorLayer(layerFeatures, code);
      this.selectedVectorLayer = {
        ...this.getLayerByCode(code),
        olLayer,
      };

      this.olMap.addLayer(this.selectedVectorLayer.olLayer);
      this.selectedVectorLayer.olLayer.setVisible(true);
      this.$emit("input", this.selectedVectorLayer);
      if (fitToView) {
        fitView(this.olMap.getView(), olLayer.getSource(), window.hydrate.iso3);
      }
    },
    getLayerByCode(code) {
      return this.vectorLayers.find((layer) => layer.code === code);
    },
    selectLayer(layer) {
      this.selected = layer.code;
      this.hover = false;
    },
    async loadLayer(code) {
      if (code === BORDER_CODE) {
        return this.border;
      } else {
        return await api.loadVectorData(`./vector_layer/${code}`);
      }
    },
  },
};
</script>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="vt"
      :column-count="5"
      :columns="Object.values(OPTIONS.headings)"
      :label-for-new="$gettext('+ Add New')"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input :id="rowId + '-target'" v-model="value.target" />
        </td>
        <td class="P4-cell">
          <P4Input :id="rowId + '-year'" v-model="value.year" year />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-level_of_application'"
            v-model="value.level_of_application"
            :options="OPTIONS.level_of_application"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-status'"
            v-model="value.status"
            :options="OPTIONS.status_of_target_achivement"
          />
          <span v-if="value.status === 'partially_achieved'">
            <p v-translate class="P4-guidance">
              Specify actual percentage (%) of achievement
            </p>
            <P4Input
              :id="rowId + '-partial_percent'"
              v-model="value.partial_percent"
              percent
            />
          </span>
        </td>

        <td class="P4-cell">
          <P4Input :id="rowId + '-comments'" v-model="value.comments" textarea/>
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS() {
      return OPTIONS;
    },
  },
};
</script>

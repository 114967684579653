<template>
  <tr>
    <th class="P4-cell">
      <Tooltip :content="changeOptions.tooltip">
        {{ changeOptions.name }}
        <span class="tooltip-icon">ⓘ</span>
      </Tooltip>
    </th>
    <td class="P4-cell-right">
      {{ degraded_extent_change }}
    </td>
    <td class="P4-cell-right"></td>
  </tr>
</template>
<script>
import OPTIONS from "@/forms/so1/4/OPTIONS";
import { round } from "@/lib/calculateTotal";
import groupByUnique from "@/lib/groupByUnique";

export default {
  name: "T1TableFooter",
  props: {
    values: {
      type: Array,
      required: true,
    },
  },
  computed: {
    degraded() {
      return groupByUnique(this.values, "id");
    },
    baselineDegradedArea() {
      return this.degraded.baseline_period?.degraded_area;
    },
    reportingDegradedArea() {
      return this.degraded.reporting_period?.degraded_area;
    },
    degraded_extent_change() {
      if (this.reportingDegradedArea && this.baselineDegradedArea)
        return round(this.reportingDegradedArea - this.baselineDegradedArea);
      return "-";
    },
    changeOptions() {
      return OPTIONS.T1Footer.change;
    },
  },
};
</script>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t1"
      :column-count="7"
      :columns="Object.values(OPTIONS.headings).map((value) => `${value}`)"
    >
      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          {{ OPTIONS.period[value.id] }}
        </th>

        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-total_population_exposed_to_land_degradation'"
            v-model="value.total_population_exposed_to_land_degradation"
            :default-value-has-changed="
              defaultValueHasChanged(
                [
                  't1',
                  rowIndex,
                  'total_population_exposed_to_land_degradation',
                ],
                value.total_population_exposed_to_land_degradation
              )
            "
            :validate="
              isAffectedAreas ? `max:Infinity` : `max:${value.total_population}`
            "
            integer
          />
        </td>

        <td v-if="isAffectedAreas" class="P4-cell-right">
          <P4Input
            :id="rowId + '-percentage_of_total_population_exposed'"
            v-model="value.percentage_of_total_population_exposed"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t1', rowIndex, 'percentage_of_total_population_exposed'],
                value.percentage_of_total_population_exposed
              )
            "
            percent
          />
        </td>

        <td
          v-else
          :id="rowId + '-percentage_of_total_population_exposed'"
          class="P4-cell-right"
        >
          <P4DisplayValue
            :value="
              formatPercent(
                value.total_population_exposed_to_land_degradation,
                value.total_population
              )
            "
          />
        </td>

        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-female_population_exposed_to_land_degradation'"
            v-model="value.female_population_exposed_to_land_degradation"
            :default-value-has-changed="
              defaultValueHasChanged(
                [
                  't1',
                  rowIndex,
                  'female_population_exposed_to_land_degradation',
                ],
                value.female_population_exposed_to_land_degradation
              )
            "
            :validate="
              isAffectedAreas
                ? `max:Infinity`
                : `max:${value.female_population}`
            "
            integer
          />
        </td>

        <td v-if="isAffectedAreas" class="P4-cell-right">
          <P4Input
            :id="rowId + '-percentage_of_female_population_exposed'"
            v-model="value.percentage_of_female_population_exposed"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t1', rowIndex, 'percentage_of_female_population_exposed'],
                value.percentage_of_female_population_exposed
              )
            "
            percent
          />
        </td>

        <td
          v-else
          :id="rowId + '-percentage_of_female_population_exposed'"
          class="P4-cell-right"
        >
          <P4DisplayValue
            :value="
              formatPercent(
                value.female_population_exposed_to_land_degradation,
                value.female_population
              )
            "
          />
        </td>

        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-male_population_exposed_to_land_degradation'"
            v-model="value.male_population_exposed_to_land_degradation"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t1', rowIndex, 'male_population_exposed_to_land_degradation'],
                value.male_population_exposed_to_land_degradation
              )
            "
            :validate="
              isAffectedAreas ? `max:Infinity` : `max:${value.male_population}`
            "
            integer
          />
        </td>

        <td v-if="isAffectedAreas" class="P4-cell-right">
          <P4Input
            :id="rowId + '-percentage_of_male_population_exposed'"
            v-model="value.percentage_of_male_population_exposed"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t1', rowIndex, 'percentage_of_male_population_exposed'],
                value.percentage_of_male_population_exposed
              )
            "
            percent
          />
        </td>

        <td
          v-else
          :id="rowId + '-percentage_of_male_population_exposed'"
          class="P4-cell-right"
        >
          <P4DisplayValue
            :value="
              formatPercent(
                value.male_population_exposed_to_land_degradation,
                value.male_population
              )
            "
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS";
import formatPercent from "@/lib/formatPercent";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      isAffectedAreas: window.hydrate.is_aa_form,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
  },
  methods: {
    formatPercent,
  },
};
</script>

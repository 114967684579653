<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t3"
      :column-count="8"
      :columns="Object.values(OPTIONS.T3.headings)"
      :label-for-new="
        isFalsePositivesNegativesWithTeOrigin ? '' : $gettext('+ Add New')
      "
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-location_name'"
            v-model="value.location_name"
          />
        </td>

        <td v-if="!isFalsePositivesNegativesWithTeOrigin" class="P4-cell">
          <P4Input
            :id="rowId + '-type'"
            v-model="value.type"
            :options="OPTIONS.T3.options.type"
            :required="!!value.polygon"
          />
        </td>
        <td v-else class="P4-cell">
          {{ TEType(value) }}
        </td>

        <td v-if="!isFalsePositivesNegativesWithTeOrigin" class="P4-cell">
          <div :class="{ hidden: !isFalsePositive(value) }">
            <P4Input
              :id="rowId + '-recode-positive'"
              v-model="value.recode"
              :options="OPTIONS.T3.options.recode_false_positive"
              :required="!!value.polygon"
            />
          </div>
          <div :class="{ hidden: !isFalseNegative(value) }">
            <P4Input
              :id="rowId + '-recode-negative'"
              v-model="value.recode"
              :options="OPTIONS.T3.options.recode_false_negative"
              :required="!!value.polygon"
            />
          </div>
        </td>
        <td v-else class="P4-cell">
          {{ TERecode(value) }}
        </td>

        <!-- Removed, might be re-added in the future -->
        <!--
          <td class="P4-cell">
          <P4Input :id="rowId + '-indicator'" v-model="value.indicator" />
          </td>
        -->
        <td class="P4-cell">
          <P4DisplayValue
            v-if="!!value.polygon && value.polytype === 'Polygon'"
            :value="value.area"
          />
          <P4Input v-else :id="rowId + '-area'" v-model="value.area" numeric />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-process_driving_false_outcome'"
            v-model="value.process_driving_false_outcome"
          />
        </td>
        <td class="P4-cell">
          <ul>
            <li>
              <P4Input
                :id="rowId + '-basic_of_judgement'"
                v-model="value.basic_of_judgement"
                :options="OPTIONS.T3.options.basic_of_judgement"
              />
            </li>
            <template v-if="value.basic_of_judgement == 'other'">
              <li>
                <P4Input
                  :id="rowId + '-other_basic_of_judgement'"
                  v-model="value['other_basic_of_judgement']"
                />
              </li>
            </template>
          </ul>
        </td>

        <polygon-cell :value="value" table-id="t3" />
      </template>
    </FormulateInput>
    <p
      v-if="isFalsePositivesNegativesWithTeOrigin"
      v-translate
      class="P4-guidance"
    >
      * This table has data imported from a T.E. archive. The add row feature
      has been disabled and T.E. reanalysis is not possible.
    </p>

    <p></p>
    <div v-if="!isFalsePositivesNegativesWithTeOrigin || !isAffectedAreas">
      <div class="P4-box">
        <FormulateInput
          id="FormulateInput_TrendsEarthReanalysis"
          type="button"
          :label="$gettext('Trigger Trends.Earth Reanalysis')"
          ignored
          :disabled="isDisabled"
          :input-class="isDisabled ? 'P4-disabled' : 'P4-reload-data'"
          @click.prevent="triggerTEReanalysis"
        />
        <p
          v-translate
          :class="{ hidden: !inProgress }"
          class="P4-notice print-hide"
        >
          Trends.Earth Reanalysis is <b>in progress</b>! Please check back soon
          and it will be ready!
        </p>
        <p
          v-translate
          :class="{ hidden: !isSuccessful }"
          class="P4-notice print-hide"
        >
          Trends.Earth Reanalysis finished <b>successfully</b>!
        </p>
        <p
          v-translate
          :class="{ hidden: !isFailed }"
          class="P4-notice print-hide"
        >
          Trends.Earth Reanalysis <b>failed</b>!
        </p>
      </div>
      <div class="P4-guidance">
        <p v-translate>
          If you wish to trigger a Trends.Earth reanalysis, but the button is
          disabled, please make sure that:
        </p>
        <ul class="list-disc disc-inside italic px-8">
          <li v-translate>
            The data in the table above wasn't already used for a reanalysis
            (changing the Type or Recode options for an available polygon or
            adding a new polygon would enable the button)
          </li>
          <li v-translate>
            There is at least one row with an associated polygon in SO1-3.T4
          </li>
          <li v-translate>
            You selected an option for Type and Recode options for all rows that
            have an associated polygon in SO1-3.T4.
          </li>
          <li v-translate>
            All SO1 raster layers are the default ones (if you uploaded custom
            layers, they can't be recoded)
          </li>
          <li v-translate>
            You saved the form after selecting the options above
          </li>
          <li v-translate>You are working on the draft revision</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import api from "../../../lib/api.js";
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import PolygonCell from "@/forms/common/PolygonCell";
import {
  isFalseNegative,
  isFalsePositive,
  TEType,
  TERecode,
} from "@/lib/falsePositivesNegativesHelpers";

export default {
  components: { PolygonCell },
  props: {
    analysisData: {
      type: Object,
      required: true,
    },
    isFalsePositivesNegativesWithTeOrigin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      $gettext,
      status: "",
      isAffectedAreas: window.hydrate.is_aa_form,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    inProgress() {
      return this.status === "in_progress";
    },
    isSuccessful() {
      return this.status === "done";
    },
    isFailed() {
      return this.status === "failed";
    },
    isReadonly() {
      return window.hydrate.readonly;
    },
    hasCustomRasters() {
      return window.hydrate.te_reanalysis_custom_rasters;
    },
    isReanalysed() {
      const _ = require("lodash");

      if (this.analysisData.reanalysed_vector_layers && this.analysisData.t3) {
        const reanalysed = this.analysisData.reanalysed_vector_layers;
        const toBeReanalysed = this.analysisData.t3.flatMap((polygonEntry) =>
          polygonEntry.polygon ? polygonEntry : []
        );

        return _.isEqual(reanalysed, toBeReanalysed);
      }
      return false;
    },
    // test if recode option for every polygon in the window.hydrate.data.t3 was selected
    // this will also make sure that the type for the polygon is selected
    // the recode cannot be selected unless the type is selected first
    isRecodeOptionEmpty() {
      const allPolygonEntriesHaveRecode = this.getAllPolygons().reduce(
        (accumulator, currentPolygonEntry) => {
          // after first occurrence of accumulator being false, it will only return false
          return accumulator ? !!currentPolygonEntry.recode : false;
        },
        true
      );

      return !allPolygonEntriesHaveRecode;
    },
    isDisabled() {
      const result =
        this.isReadonly || // if the form is a revision, a non-draft version
        this.getAllPolygons().length === 0 || // if there isn't at least one row in SO1-4.T3 with an associated Polygon
        (this.isReanalysed && this.isSuccessful) || // if the polygons inside the SO1-4.T3 were already reanalysed
        this.inProgress || // if the T.E reanalysis status is in progress
        this.analysisData.t3 == null || // if the table SO1-4T3 does not exist
        this.analysisData.t3.length === 0 || // if the table SO1-4T3 is empty
        this.hasCustomRasters || // if SO1 rasters with origin != TE_DEFAULT exist
        this.isRecodeOptionEmpty; // all the polygons in the SO1-3.T4 need to have a recode option selected

      return result;
    },
  },
  async mounted() {
    if (!this.isAffectedAreas) {
      try {
        const response = await api.getTEReanalysisStatus(
          "trigger_te_reanalysis"
        );

        if (response) {
          this.status = response["status"];
        }
      } catch (e) {
        this.$emit("errorMessage", e);
        throw e;
      }
    }
  },
  methods: {
    isFalseNegative,
    isFalsePositive,
    TERecode,
    TEType,
    async triggerTEReanalysis() {
      this.status = "in_progress";

      try {
        await api.triggerTEReanalysis("trigger_te_reanalysis");
      } catch (e) {
        this.status = "failed";
        this.$emit("errorMessage", e);
        throw e;
      }
    },
    getAllPolygons() {
      if (this.analysisData.t3) {
        return this.analysisData.t3.filter((obj) => {
          return !!obj.polygon === true;
        });
      }
      return [];
    },
  },
};
</script>

import Cookies from "js-cookie";

class Api {
  _getJson(url) {
    return fetch(url + new URLSearchParams(url.search), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
    });
  }

  _verb(url, body, method, headers = null) {
    return fetch(url, {
      method: method,
      headers: {
        ...headers,
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      body,
    });
  }

  _verbJson(url, data, method) {
    return this._verb(url, JSON.stringify(data), method, {
      "Content-Type": "application/json",
    });
  }

  _postJson(url, data) {
    return this._verbJson(url, data, "POST");
  }

  _putJson(url, data) {
    return this._verbJson(url, data, "PUT");
  }

  _patchJson(url, data) {
    return this._verbJson(url, data, "PATCH");
  }

  async _handleErrors(response) {
    const result = await response.json();
    if (result.ok !== true) {
      throw new Error(`Something happened: ${JSON.stringify(result)}`);
    }
    return result;
  }

  async call(url, body, method, headers = null) {
    const response = await this._verb(url, body, method, headers);
    return this._handleErrors(response);
  }

  async loadVectorData(url) {
    try {
      const resp = await this._getJson(url);
      return await resp.json();
    } catch (e) {
      alert(`Error while loading the vector layer: ${e}`);
    }
  }

  async saveForm(url, data) {
    const response = await this._postJson(url, data);
    if (response.status >= 400) {
      throw new Error(
        `Failed to save form: ${response.status} ${response.statusText}`
      );
    }
    await this._handleErrors(response);
  }

  async saveVectorLayers(url, data) {
    const response = await this._putJson(url, data);

    if (response.status >= 400) {
      const result = await response.json();
      if (result["validation_error"]) {
        alert(result["validation_error"]);
      }
      throw new Error(
        `Failed to save layer: ${response.status} ${response.statusText}`
      );
    }
    await this._handleErrors(response);
  }

  async getDefaultData(url) {
    const response = await this._getJson(url);
    if (response.status >= 400) {
      throw new Error(
        `Failed to get default data: ${response.status} ${response.statusText}`
      );
    }
    const result = await response.json();
    if (result.ok !== true) {
      throw new Error(`Something happened: ${JSON.stringify(result)}`);
    }
    return result;
  }
  async getCommentThreads(url) {
    const response = await this._getJson(url);
    if (response.status >= 400) {
      throw new Error(
        `Failed to resolve comment thread: ${response.status} ${response.statusText}`
      );
    }

    const result = await response.json();
    if (result.ok !== true) {
      throw new Error(`Something happened: ${JSON.stringify(result)}`);
    }
    return result;
  }

  async createComment(url, data) {
    const response = await this._postJson(url, data);
    if (response.status >= 400) {
      throw new Error(
        `Failed to save comment: ${response.status} ${response.statusText}`
      );
    }
    await this._handleErrors(response);
  }

  async updateCommentThread(url, thread) {
    const response = await this._patchJson(url, { threadId: thread });
    if (response.status >= 400) {
      throw new Error(
        `Failed to resolve comment thread: ${response.status} ${response.statusText}`
      );
    }
    await this._handleErrors(response);
  }

  async updateComment(url, data) {
    const response = await this._putJson(url, data);
    if (response.status >= 400) {
      throw new Error(
        `Failed to save comment: ${response.status} ${response.statusText}`
      );
    }
    await this._handleErrors(response);
  }

  async dismissDisclaimer() {
    const response = await this._postJson("/dismiss_map_disclaimer/", {});
    if (response.status >= 400) {
      throw new Error(
        `Failed to dismiss disclaimer: ${response.status} ${response.statusText}`
      );
    }
    await this._handleErrors(response);
  }

  async saveLayerUpload(url, data) {
    const response = await this._postJson(url, data);

    const result = await response.json();
    if (!result.ok) {
      if (result.validation_error) {
        alert(result.validation_error);
      }
      throw new Error(
        `Failed to save layer upload: ${response.status} ${response.statusText}`
      );
    }
    return result;
  }

  async getArchiveUploadStatus(url) {
    const response = await this._getJson(url);

    if (response.status >= 400) {
      throw new Error(
        `Failed to get upload status: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  }

  async generateTemplatedMap(url) {
    const response = await this._getJson(url);

    if (response.status >= 400) {
      throw new Error(
        `Failed to generate templated map: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  }

  async checkTemplatedMapsStatus(url) {
    const response = await this._getJson(url);

    if (response.status >= 400) {
      throw new Error("Templated maps check failed");
    }

    return await response.json();
  }

  async getDataForReanalysis(url) {
    const response = await this._getJson(url);
    if (response.status >= 400) {
      throw new Error(
        `Failed to get data check reanalysis availability: ${response.status} ${response.statusText}`
      );
    }
    return await response.json();
  }

  async triggerTEReanalysis(url) {
    const response = await this._postJson(url, {});
    if (response.status >= 400) {
      const result = await response.json();
      if (result.validation_error) {
        alert(result.validation_error);
      }
      throw new Error(
        `Failed to trigger Trend.Earth Reanalysis: ${response.status} ${response.statusText}`
      );
    }
    await this._handleErrors(response);
  }

  async getTEReanalysisStatus(url) {
    const response = await this._getJson(url);

    if (response.status >= 400) {
      throw new Error(
        `Failed to get TE Reanalysis status: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  }
}

export default new Api();

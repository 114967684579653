<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t5"
      :columns="[
        '',
        $gettext('Area (km²)'),
        isAffectedAreas
          ? $gettext('Percent of total affected area (%)')
          : $gettext('Percent of total land area (%)'),
      ]"
      :warnings="[
        {
          name: 'maxTotalWarning',
          args: [
            'baseline_period',
            baselineProductivityTotalLandArea,
            baselineProductivityYear,
          ],
        },
      ]"
    >
      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          <Tooltip :content="OPTIONS.BASELINE[value.id].tooltip">
            {{ OPTIONS.BASELINE[value.id].name }}
            <span class="tooltip-icon">ⓘ</span>
          </Tooltip>
        </th>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-baseline_period'"
            v-model="value.baseline_period"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t5', rowIndex, 'baseline_period'],
                value.baseline_period
              )
            "
            numeric
          />
        </td>
        <td class="P4-cell-right">
          <P4DisplayValue
            :value="
              formatPercent(
                value.baseline_period,
                baselineProductivityTotalLandArea
              )
            "
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import formatPercent from "@/lib/formatPercent";
import countryAreas from "@/lib/mixins/countryAreas";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange, countryAreas],
  data() {
    return {
      $gettext,
      isAffectedAreas: window.hydrate.is_aa_form,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
  },
  methods: {
    formatPercent,
  },
};
</script>

export function round(value) {
  return +value.toFixed(2);
}

export function calculateTotal(values, field) {
  let total = 0;
  for (const row of values) {
    total += +(row[field] || 0);
  }
  return round(total);
}

export function calculateTotalAreaImplemented(values, iaT1Targets) {
  // For every T1 rowId, compute the total implemented area {"target-row-id": "computed_total_implemented_area"}
  const totalAreaImplemented = {};
  for (const rowId in iaT1Targets) {
    totalAreaImplemented[rowId] = 0;
  }

  for (const row of values) {
    // Check if T1.Target is not empty
    if (iaT1Targets[row["relevant_target"]] && row["extent_of_action"])
      totalAreaImplemented[row["relevant_target"]] += Number(
        row["extent_of_action"]
      );
  }

  for (const rowId in iaT1Targets)
    totalAreaImplemented[rowId] = totalAreaImplemented[rowId].toFixed(2);

  // Update total_area_implemented
  for (const row of values) {
    row["total_area_implemented"] = totalAreaImplemented[row["relevant_target"]]
      ? totalAreaImplemented[row["relevant_target"]]
      : 0;
  }

  // Replace rowId with the actual target name
  return Object.fromEntries(
    Object.keys(totalAreaImplemented).map(function (k) {
      return [[iaT1Targets[k]], totalAreaImplemented[k]];
    })
  );
}

export function calculateTotalProvidedReceived(
  values,
  field,
  fieldProvidedReceived,
  providedReceived
) {
  let total = 0;
  for (const row of values) {
    if (row[fieldProvidedReceived] == providedReceived) {
      total += +(row[field] || 0);
    }
  }
  return round(total);
}

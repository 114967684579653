<template>
  <div>
    <FormulateInput
      v-model="template"
      :options="allTemplates"
      type="select"
      :label="$gettext('Select a template')"
    />
    <FormulateInput
      v-model="filetype"
      :options="{ pdf: 'PDF', png: 'PNG', tiff: 'TIFF' }"
      type="select"
      :label="$gettext('Select a file type')"
    />
    <div class="flex">
      <button
        class="text-white text-sm font-bold rounded p-[.8em]"
        :class="{
        'bg-gray-400': !canCreateTemplate,
        'bg-oceangreen': canCreateTemplate,
      }"
        type="button"
        :disabled="!canCreateTemplate"
        @click="generateTemplatedMap"
      >
        {{ labelForGenerateButton }}
      </button>
      <div
        v-if="generationIsInProgress && !errorMessage"
        class="lds-dual-ring"
      ></div>
    </div>
    <p
      v-if="errorMessage"
      class="text-red-500 italic text-sm"
    >{{ errorMessage }}</p>
    <FormDebug
      class="mt-10"
      :values="template"
    />
  </div>
</template>

<script>
import api from "@/lib/api";

export default {
  name: "SelectMap",
  data() {
    return {
      filetype: null,
      template: {},
      allTemplates: window.hydrate.templates,
      taskID: null,
      generationIsInProgress: false,
      errorMessage: null,
    };
  },
  computed: {
    canCreateTemplate() {
      // At least a raster or a vector if not both
      return Object.keys(this.template).length > 1 && this.filetype && !this.generationIsInProgress;
    },
    labelForGenerateButton() {
      return this.generationIsInProgress ? this.$gettext("Generating...") : this.$gettext("Generate map");
    }
  },
  watch: {
    taskID() {
      this.checkStatus();
    }
  },
  methods: {
    async checkStatus() {
      try {
        const resp = await api.checkTemplatedMapsStatus(`./${ this.taskID }`);
        switch (resp.state) {
          case "SUCCESS":
            window.location.href = `./${ this.taskID }/get_file`;
            this.generationIsInProgress = false;
            return;
          case "FAILURE":
            this.errorMessage = resp.info || this.$gettext("Failed to generate the map");
            this.generationIsInProgress = false;
            return;
        }
      } catch (e) {
        alert(e.message);
      }

      setTimeout(() => {
        this.checkStatus();
      }, 3000);
    },
    async generateTemplatedMap() {
      this.generationIsInProgress = true;
      this.errorMessage = null;
      try {
        const resp = await api.generateTemplatedMap(
          `render_pdf?map_template=${ this.template.mapCode }` +
          `&file_type=${ this.filetype }`
        );
        this.taskID = resp.taskID;
      } catch (e) {
        alert(e.message);
      }
    },
  },
};
</script>

<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 4px 4px 4px 20px;
  border-radius: 50%;
  border: 3px solid #4b92db;
  border-color: #4b92db transparent #4b92db transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t3"
      :columns="['', $gettext('Explanation')]"
    >
      <template #cells="{ rowId, value }">
        <th class="P4-cell items-end text-right">
          <Tooltip :content="OPTIONS.T3[value.id].tooltip">
            {{ OPTIONS.T3[value.id].name }}
            <span class="tooltip-icon">ⓘ</span>
          </Tooltip>
        </th>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-explanation'"
            v-model="value.explanation"
            textarea
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
  },
};
</script>

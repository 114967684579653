<template>
  <button id="layer-save-button" @click="save">
    <i class="fas fa-save"></i>
    {{ label }}
  </button>
</template>

<script>
import api from "../lib/api.js";
import { gettextLazy as $gettext } from "../lib/translations.js";

export default {
  props: {
    getData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      state: "default",
      $gettext,
    };
  },
  computed: {
    label() {
      switch (this.state) {
        case "default":
          return $gettext("Save");
        case "saving":
          return $gettext("Saving ...");
        case "success":
          return $gettext("Saved ✔");
        case "failed":
          return $gettext("Save (failed)");
      }
      throw new Error(`Unknown state ${this.state}`);
    },
  },
  methods: {
    async save() {
      this.state = "saving";
      try {
        const data = this.getData();
        const result = await api.saveVectorLayers(
          `./vector_layer/${data.code}`,
          data.content
        );
        this.state = "success";
        return result;
      } catch (e) {
        this.state = "failed";
        throw e;
      }
    },
  },
};
</script>

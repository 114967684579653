<template>
  <P4Form v-model="values">
    <h2 v-translate class="P4-section-title">Drought exposure indicator</h2>
    <p v-translate class="P4-textinfo">
      Exposure is defined in terms of the number of people who are exposed to
      drought as calculated from the SO3-1 indicator data.
    </p>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO3-2.T1: National estimates of the percentage of the total population
      within each drought intensity class as well as the total population count
      and the proportion of the national population exposed to drought
      regardless of intensity.
    </h3>
    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO3-2.T1: Affected area estimates of the percentage of the total
      population within each drought intensity class as well as the total
      population count and the proportion of the affected area population
      exposed to drought regardless of intensity.
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Provide a count of the total population and the percentage of the total
      population within each drought intensity class on an annual basis.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Provide a count of the affected area population and the percentage of the
      affected area population within each drought intensity class on an annual
      basis.
    </p>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      In addition, report the total population exposed to drought, i.e. the sum
      of the population exposed to all drought classes, as well as the
      proportion of the national population that is exposed to drought, on an
      annual basis. This table is pre-filled with default population estimates
      derived from the WorldPop dataset. Keep the default national estimates,
      or, in the event of data and capacity, replace them with national data.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Affected area population estimates can be derived from the Worldpop
      dataset In addition, report the total affected area population exposed to
      drought, i.e. the sum of the population exposed to all drought classes, as
      well as the proportion of the affected area population that is exposed to
      drought, on an annual basis.
    </p>
    <CommentAnchor :name="form_id + '-T1'" />
    <T1 />
    <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO3-2.T2: National estimates of the percentage of the female population
      within each drought intensity class.
    </h3>
    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO3-2.T2: Affected area estimates of the percentage of the female
      population within each drought intensity class.
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Provide a count of the total female population and the percentage of the
      female population within each drought intensity class on an annual basis.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Provide a count of the affected area female population and the percentage
      of the female population within each drought intensity class on an annual
      basis.
    </p>
    <CommentAnchor :name="form_id + '-T2'" />
    <T2 />
    <RestoreDefaultdata name="t2" @reloadDefaultData="reloadDefaultData" />

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO3-2.T3: National estimates of the percentage of the male population
      within each drought intensity class.
    </h3>
    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO3-2.T3: Affected area estimates of the percentage of the male population
      within each drought intensity class.
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Provide a count of the total male population and percentage of the male
      population within each drought intensity class on an annual basis.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Provide a count of the affected area male population and percentage of the
      female population within each drought intensity class on an annual basis.
    </p>
    <CommentAnchor :name="form_id + '-T3'" />
    <T3 />
    <RestoreDefaultdata name="t3" @reloadDefaultData="reloadDefaultData" />

    <h2 v-translate class="P4-section-title">Qualitative assessment</h2>

    <h3 v-translate class="P4-caption">Interpretation of the indicator</h3>
    <p v-translate class="P4-guidance">
      Provide a qualitative assessment of the indicator. However, please note
      that due to the effect of natural climate variability on the occurrence of
      droughts, any observed changes or trends in the proportion of land under
      drought over this short time-frame should be interpreted with caution.
    </p>
    <P4Input v-model="values.qualitative_assessments" textarea standalone />

    <h2 v-translate class="P4-section-title">General comments</h2>
    <p v-translate class="P4-guidance">
      Provide any additional comments you deem relevant.
    </p>
    <div>
      <P4Input v-model="values.so3_2_general_comments" textarea standalone />
    </div>
  </P4Form>
</template>

<script>
import T1 from "./T1.vue";
import T2 from "./T2.vue";
import T3 from "./T3.vue";
import getYearsBetween from "../../../lib/getYears.js";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

export default {
  components: {
    T1,
    T2,
    T3,
  },
  mixins: [restoreDefaultData],
  data() {
    const values = {
      t1: getYearsBetween(2000, 2021).map((year) => ({ year })),
      t2: getYearsBetween(2000, 2021).map((year) => ({ year })),
      t3: getYearsBetween(2000, 2021).map((year) => ({ year })),

      ...window.hydrate.data,
    };
    return {
      values,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
    };
  },
};
</script>

import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  Form: {
    yes_no: {
      yes: _("Yes"),
      no: _("No"),
    },
  },
  T2a: {
    headings: {
      government_expenditures: {
        name: _("Government expenditures"),
        is_heading: true,
      },
      directly_dldd: {
        name: _("Directly related to combat DLDD"),
      },
      indirectly_dldd: {
        name: _("Indirectly related to combat DLDD"),
      },
      subsidies: {
        name: _("Subsidies"),
        is_heading: true,
      },
      subsidies_dldd: {
        name: _("Subsidies related to combat DLDD"),
      },
    },
  },
  T2b: {
    headings: {
      government_revenues: {
        name: _("Government revenues"),
        is_heading: true,
      },
      environmental_taxes: {
        name: _(
          "Environmental taxes for the conservation of land resources and taxes related to combat DLDD"
        ),
      },
    },
  },

  T3: {
    government_expenditures: {
      name: _("Government expenditures"),
      tooltip: _(
        "Explain the definitions and indicate the sources of data used for " +
          "reporting on domestic public expenditures related to the implementation of the Convention."
      ),
    },
    subsidies: {
      name: _("Subsidies"),
      tooltip: _(
        "Explain the definitions and indicate the sources of data used for " +
          "reporting on subsidies related to the implementation of the Convention."
      ),
    },
    government_revenues: {
      name: _("Government revenues"),
      tooltip: _(
        "Explain the definitions and indicate the sources of data used for reporting on taxes, " +
          "fees and charges related to the implementation of the Convention."
      ),
    },
    domestic_resources: {
      name: _(
        "Domestic resources directly or indirectly related to combat DLDD"
      ),
      tooltip: _(
        "Explain the definition used for domestic expenditures being directly or indirectly related to" +
          " the implementation of the Convention, how the relevance to DLDD is assessed, whether and how " +
          "the approach is based on the use of OECD Rio Marker, whether any coefficient has been applied to" +
          " the indirect estimate, or whether and how a sectoral approach has been used."
      ),
    },
  },
};

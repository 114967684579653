<template>
  <p v-translate>
    Upload a GeoJSON, a zip with the SHP or a KML file
  </p>
</template>

<script>
export default {
  name: "VectorHelp",
};
</script>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t3"
      :column-count="7"
      :label-for-new="$gettext('+ Add New')"
      :row-factory="rowFactory"
    >
      <template #table_header>
        <tr>
          <th
            v-for="(column, index) in [
              $gettext('Year'),
              $gettext('Title of project, programme, activity or other'),
              $gettext('Total Amount USD'),
              $gettext('Financial Instrument'),
              $gettext('Type of institution'),
              $gettext('Recipient'),
              $gettext('Additional Information'),
            ]"
            :key="index"
            class="P4-cell"
          >
            {{ column }}
          </th>
        </tr>
      </template>

      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-year'"
            v-model="value.year"
            :validate="[['year', 1990, 2022]]"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-title_of_project'"
            v-model="value.title_of_project"
            required
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-total_amount_usd'"
            v-model="value.total_amount_usd"
            numeric
          />
        </td>

        <td class="P4-cell">
          <ul>
            <li
              v-for="(option, index) in OPTIONS.T1.financial_instrument"
              :key="index"
            >
              <P4Input
                :key="'financial_instrument-' + index"
                v-model="value.financial_instrument_options[index]"
                :name="'financial_instrument-' + index"
                :label="option"
                checkbox
              />
            </li>
            <li>
              <P4Input
                v-if="value.financial_instrument_options['other']"
                id="financial_instrument_other"
                v-model="value.financial_instrument_other"
              />
            </li>
          </ul>
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-type_of_institution'"
            v-model="value.type_of_institution"
            :options="OPTIONS.T1.type_of_institution"
          />
          <P4Input
            v-if="value.type_of_institution === 'other'"
            id="type_of_institution_other"
            v-model="value['type_of_institution_other']"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-recipient'"
            v-model="value.recipient"
            :options="SO5_OPTIONS.OECD_DAC_RECIPIENT_COUNTRIES"
          />
          <P4Input
            v-if="value.recipient === 'other'"
            id="recipient_other"
            v-model="value['recipient_other']"
          />

          <ul>
            <li>
              <P4Input
                :key="'recipient_domestic_mobilization-' + rowId"
                v-model="value.recipient_domestic_mobilization"
                :name="'recipient_domestic_mobilization-' + rowId"
                :label="$gettext('Domestic mobilization')"
                checkbox
              />
            </li>
            <li>
              <P4Input
                v-if="value.recipient_domestic_mobilization"
                id="recipient_domestic_mobilization_other"
                v-model="value.recipient_domestic_mobilization_other"
              />
            </li>
          </ul>
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-additional_information'"
            v-model="value.additional_information"
            textarea
          />
        </td>
      </template>

      <template #table_footer="{ values }">
        <tr>
          <th v-translate class="P4-cell" colspan="2">Total</th>
          <td colspan="6" class="P4-cell">
            <P4DisplayValue :value="calculate_total_amount(values)" />
          </td>
        </tr>

        <tr v-for="year in get_total_years(values)" :key="year">
          <th
            v-translate="{ year: year == null ? 'year' : year }"
            class="P4-cell"
            colspan="2"
          >
            Total per year %{year}:
          </th>
          <td colspan="6" class="P4-cell">
            <P4DisplayValue :value="calculate_total_per_year(values, year)" />
          </td>
        </tr>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import SO5_OPTIONS from "../SO5_OPTIONS";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import { calculateTotal } from "../../../lib/calculateTotal.js";
import {
  calculateTotalYear,
  getTotalYears,
} from "../../../lib/calculateTotalPerYear.js";

function rowFactory() {
  return {
    financial_instrument_options: {},
    recipient_domestic_mobilization_options: {},
  };
}
export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    SO5_OPTIONS: () => SO5_OPTIONS,
    rowFactory,
    calculate_total_amount() {
      return function (values) {
        return calculateTotal(values, "total_amount_usd");
      };
    },
    get_total_years() {
      return function (values) {
        return getTotalYears(values);
      };
    },
    calculate_total_per_year() {
      return function (values, year) {
        return calculateTotalYear(values, year, "total_amount_usd");
      };
    },
  },
};
</script>

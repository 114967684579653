import so1 from "./so1/components.js";
import so2 from "./so2/components.js";
import so3 from "./so3/components.js";
import so4 from "./so4/components.js";
import so5 from "./so5/components.js";
import ai from "./ai/components.js";
import rc from "./rc/components.js";
import if_ from "./if/components.js";
import aa from "./aa/components.js";
import CountryPreferences from "./CountryPreferences.vue";
import SpatialDataLicense from "./SpatialDataLicense.vue";
import TrendsEarthArchiveUpload from "./TrendsEarthArchiveUpload.vue";
import CommentAnchor from "../lib/comments/CommentAnchor.vue";

export default {
  so1,
  so2,
  so3,
  so4,
  so5,
  ai,
  rc,
  "aa-so1": so1,
  "aa-so2": so2,
  "aa-so3": so3,
  "aa-so5": so5,
  "aa-so4": so4,
  if: if_,
  aa,
  CountryPreferences,
  SpatialDataLicense,
  TrendsEarthArchiveUpload,
  CommentAnchor,
};

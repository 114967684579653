import { gettextLazy as _ } from "../../lib/translations.js";
import qgis_encodings from "./qgis_encodings.js";

function encodingPairs() {
  const rv = {};
  for (const i of qgis_encodings) {
    rv[i] = i;
  }
  return rv;
}

export default {
  topics: {
    farming: _("Farming"),
    biota: _("Biota"),
    boundaries: _("Boundaries"),
    climatologyMeteorologyAtmosphere: _("Climatology, Meteorology, Atmosphere"),
    economy: _("Economy"),
    elevation: _("Elevation"),
    environment: _("Environment"),
    geoscientificInformation: _("Geoscientific Information"),
    health: _("Health"),
    imageryBaseMapsEarthCover: _("Imagery, Base Maps, Earth Cover"),
    intelligenceMilitary: _("Intelligence, Military"),
    inlandWaters: _("Inland Waters"),
    location: _("Location"),
    oceans: _("Oceans"),
    planningCadastre: _("Planning, Cadastre"),
    society: _("Society"),
    structure: _("Structure"),
    transportation: _("Transportation"),
    utilitiesCommunication: _("Utilities, Communication"),
  },
  contact_role: {
    "": "-",
    custodian: _("Custodian"),
    distributor: _("Distributor"),
    owner: _("Owner"),
  },
  encodings: encodingPairs(),
};

<template>
  <section>
    <P4Form v-model="values">
      <h2 v-translate class="P4-section-title print-hide">Red List Index</h2>

      <p
        v-translate="{
          html_start_link: `<a class='P4-link print-hide' href='https://www.iucnredlist.org/search/'>`,
          html_end_link: `</a>`,
        }"
        class="P4-guidance"
        render-html="true"
      >
        This indicator uses the Red List Index (RLI) as a metric. The RLI is
        Sustainable Development Goal (SDG) indicator 15.5.1. The RLI value ranges
        from 0 (all species in the country are classified as extinct) to 1 (all
        species in the country are classified as least concern; biodiversity loss
        has been halted). Various disaggregations are also available through the
        “advanced search” on the %{ html_start_link } Red List website %{
        html_end_link }.
      </p>

      <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
        SO4-2.T1: National estimates of the Red List Index of species survival
      </h3>

      <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
        SO4-2.T1: Affected area estimates of the Red List Index of species
        survival
      </h3>

      <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
        Report the Red List Index (RLI) for your country in the table below.
        This table is pre-filled from the SDG database, including the national
        level index value as well as the upper and lower bounds of uncertainty
        around the national estimate. If a customized RLI value has been
        produced using the “advanced search” on the Red List website, report the
        number here and any uncertainty bounds. Use the comment box to describe
        how the RLI value was disaggregated using the Red List website.
      </p>
      <p v-if="isAffectedAreas" v-translate class="P4-guidance">
        Report the Red List Index (RLI) for the affected areas of your country
        in the table below, including the affected area level index value as
        well as the upper and lower bounds of uncertainty around the estimate.
        If a customized RLI value has been produced using the “advanced search”
        on the Red List website, report the number here and any uncertainty
        bounds. Use the comment box to describe how the RLI value was
        disaggregated using the Red List website.
      </p>
      <CommentAnchor :name="form_id + '-T1'" />
      <T1 />
      <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />

      <h2 v-translate class="P4-section-title">Qualitative assessment</h2>

      <h3 v-translate class="P4-caption">
        SO4-2.T2: Interpretation of the indicator
      </h3>

      <p v-translate class="P4-guidance">
        Based on the quantitative data, describe the most significant negative
        or positive changes in the indicator and rank, in terms of importance,
        their direct and/or indirect drivers. While it may be difficult to
        attribute specific casual factors to changes in RLI, countries are
        encouraged to indicate which direct and/or indirect drivers are
        presumably behind the negative changes or downward trends in the RLI. To
        reverse negative trends, countries should rank the levers, in terms of
        importance, that they are using to bring about positive and
        transformative change. Likewise, countries are encouraged to rank the
        responses that have led to positive or upward RLI trends. Any deviation
        from the guidance provided should be reported and justified in the
        ‘comment’ column of the reporting table.
      </p>
      <CommentAnchor :name="form_id + '-T2'" />
      <T2 />

      <h2 v-translate class="P4-section-title">General comments</h2>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input v-model="values.so4_2_general_comments" textarea standalone />
      </div>
    </P4Form>
  </section>
</template>

<script>
import { allOptions } from "../../../lib/translations.js";
import range from "../../../lib/range.js";
import OPTIONS from "./OPTIONS.js";
import T1 from "./T1.vue";
import T2 from "./T2.vue";
import getYears from "../../../lib/getYears.js";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

function enumerateOptions(parent, name) {
  const keys = Object.keys(parent[name]).filter((i) => i); // Skip the blank value
  return Object.fromEntries(
    range(keys.length).map((i) => [`${name}-${i + 1}`, keys[i]])
  );
}

function valuesForAllOptions() {
  return {
    t2: [
      {
        change: "negative",
        ...enumerateOptions(OPTIONS.T2, "drivers_direct"),
        ...enumerateOptions(OPTIONS.T2, "drivers_indirect"),
        ...enumerateOptions(OPTIONS.T2, "change_levers"),
      },
      {
        change: "positive",
        ...enumerateOptions(OPTIONS.T2, "change_responses"),
      },
    ],
  };
}

export default {
  components: {
    T1,
    T2,
  },
  mixins: [restoreDefaultData],

  data() {
    const values = {
      t1: getYears(2000, 2020).map((y) => ({ year: y.toString() })),
      ...window.hydrate.data,
    };

    if (allOptions()) {
      Object.assign(values, valuesForAllOptions());
    }

    return {
      values,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
    };
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t1"},scopedSlots:_vm._u([{key:"table_header",fn:function(){return [_c('tr',_vm._l(([
            _vm.$gettext('Year'),
            _vm.$gettext('Total country-level DVI value (tier 1)'),
            _vm.$gettext('Male DVI value (tiers 2 and 3 only)'),
            _vm.$gettext('Female DVI value (tiers 2 and 3 only)'),
          ]),function(column,index){return _c('th',{key:index,staticClass:"P4-cell"},[_vm._v(" "+_vm._s(column)+" ")])}),0)]},proxy:true},{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('th',{staticClass:"P4-cell"},[_vm._v(" "+_vm._s(value.year)+" ")]),_vm._l(([
          'total_country_level_dvi',
          'male_dvi_value',
          'female_dvi_value',
        ]),function(column,index){return _c('td',{key:index,staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-' + column,"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t1', rowIndex, column],
            value[column]
          ),"placeholder":"No data","numeric":""},model:{value:(value[column]),callback:function ($$v) {_vm.$set(value, column, $$v)},expression:"value[column]"}})],1)})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <P4Form v-model="values">
      <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
        Please note that although “affected areas” is mentioned in the indicator
        name, estimates specific to affected areas should be reported on in the
        reporting forms provided for affected areas.
      </p>
      <h2 v-translate class="P4-section-title">
        Proportion of population using safely managed drinking water services
      </h2>

      <section>
        <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
          SO2-2.T1: National estimates of the proportion of population using
          safely managed drinking water services
        </h3>

        <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
          SO2-2.T1: Affected area estimates of the proportion of population
          using safely managed drinking water services
        </h3>

        <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
          Provide national annual values of the proportion of the population
          using safely managed drinking water services. The table is pre-filled
          with default data from from the SDG database (SDG indicator 6.1.1),
          provided by the World Health Organization/United Nations Children's
          Fund. They have produced regular estimates of national, regional and
          global progress on drinking water, sanitation and hygiene since 1990.
          The indicator is expressed as a percentage of urban, rural and total
          populations. Keep the default estimates or replace them with national
          datasets.
        </p>
        <p v-if="isAffectedAreas" v-translate class="P4-guidance">
          Provide affected area annual values of the proportion of the
          population using safely managed drinking water services.
        </p>
        <CommentAnchor :name="form_id + '-T1'" />
        <T1 />
        <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />
      </section>

      <section>
        <h2 v-translate class="P4-section-title">Qualitative assessment</h2>

        <h3 v-translate class="P4-caption">
          SO2-2.T2: Interpretation of the indicator
        </h3>
        <p v-translate class="P4-guidance">
          Based on the quantitative data, select the dominant change in the
          indicator and indicate which direct and/or indirect drivers are
          presumably behind the observed changes using the comments box in table
          below.
        </p>
        <CommentAnchor :name="form_id + '-T2'" />
        <T2 />
      </section>

      <h3 v-translate class="P4-section-title">General comments</h3>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input v-model="values.so2_2_general_comments" textarea standalone />
      </div>
    </P4Form>
  </section>
</template>

<script>
import { CHANGES_IN_THE_INDICATOR } from "../../../lib/constants.js";
import { allOptions } from "../../../lib/translations.js";
import getYearsBetween from "../../../lib/getYears.js";
import T1 from "./T1.vue";
import T2 from "./T2.vue";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

function valuesForAllOptions() {
  const keys = Object.keys(CHANGES_IN_THE_INDICATOR);

  return {
    t2: keys.map((change_in_the_indicator) => ({ change_in_the_indicator })),
  };
}

export default {
  components: {
    T1,
    T2,
  },
  mixins: [restoreDefaultData],
  data() {
    const values = {
      t1: getYearsBetween(2000, 2021).map((year) => ({ year })),
      ...window.hydrate.data,
    };

    if (allOptions()) {
      Object.assign(values, valuesForAllOptions());
    }

    return {
      values,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
    };
  },
};
</script>

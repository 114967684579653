<template>
  <P4Form v-model="values">
    <section>
      <h2 v-translate class="P4-section-title">Action Programmes:</h2>

      <p v-translate class="P4-guidance">
        Developing, implementing, revising and regularly monitoring, as
        appropriate, national, subregional and regional action programmes and/or
        plans as effective tools for UNCCD implementation.
      </p>

      <h4 v-translate class="P4-question">
        Has your country developed or helped develop, implement, revise or
        regularly monitor your national action programme?
      </h4>

      <P4Input
        id="if_policy_action_programme"
        v-model="values.national_action_programme"
        radio
        :options="OPTIONS.yes_no"
      />
      <div :class="{ hidden: !nationalActionProgramme }">
        <h4 v-translate class="P4-question">
          Use the space below to share more details about your country's
          experience:
        </h4>

        <P4Input
          id="if_policy_action_programme_experience"
          v-model="values.national_action_programme_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Would you consider the action programmes and/or plans to be successful
          and what do you consider the main reasons for success or lack thereof?
        </h4>

        <P4Input
          id="if_policy_action_programme_opinion"
          v-model="values.national_action_programme_opinion"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_policy_action_programme_challenges"
          v-model="values.national_action_programme_opinion_faced_challenges"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What do you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_policy_action_programme_lessons_learned"
          v-model="values.national_action_programme_lessons_learned"
          textarea
          standalone
        />
      </div>
    </section>

    <section>
      <h2 v-translate class="P4-section-title">
        Policies and enabling environment:
      </h2>
      <p v-translate class="P4-guidance">
        Establishing policies and enabling environments to promote and implement
        solutions to combat desertification/land degradation and mitigate the
        effects of drought, including prevention, relief and recovery.
      </p>

      <h4 v-translate class="P4-question">
        During the reporting period, has your country established or helped
        establish policies and enabling environments to promote and/or implement
        solutions to combat desertification/land degradation and mitigate the
        effects of drought?
      </h4>
      <P4Input
        id="if_policy_desertification"
        v-model="values.desertification_actions"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !desertificationActions }">
        <h4 v-translate class="P4-question">
          These policies and enabling environments were aimed at (check all that
          apply):
        </h4>
        <ul>
          <li v-for="(option, index) in OPTIONS.policies" :key="index">
            <P4Input
              :id="'if_policy_desertification-' + index"
              :key="'policies_' + index"
              v-model="values.policies[index]"
              :name="'policies_' + index"
              :label="option"
              checkbox
            />
          </li>
          <li>
            <P4Input
              v-if="values.policies['other']"
              id="policies_other"
              v-model="values.policies_other"
            />
          </li>
        </ul>

        <h4 v-translate class="P4-question">
          How best to describe these experiences (check all that apply):
        </h4>

        <ul>
          <li v-for="(option, index) in OPTIONS.experiences" :key="index">
            <P4Input
              :id="'if_policy_desertification_experiences-' + index"
              :key="'experiences-' + index"
              v-model="values.experiences[index]"
              :name="'experiences-' + index"
              :label="option"
              checkbox
            />
          </li>
          <li>
            <P4Input
              v-if="values.experiences['other']"
              id="experiences_other"
              v-model="values['experiences_other']"
            />
          </li>
        </ul>

        <h4 v-translate class="P4-question">
          Use the space below to share more details about your
          country/sub-region/region/institution's experience.
        </h4>

        <P4Input
          id="if_policy_desertification_experience"
          v-model="values.institution_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Do you consider these policies to be successful in promoting or
          implementing solutions to address DLDD, including prevention, relief
          and recovery, and what do you consider the main factors of success or
          lack thereof?
        </h4>

        <P4Input
          id="if_policy_desertification_policies"
          v-model="values.successful_policies"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_policy_desertification_challenges"
          v-model="values.successful_policies_challenges_faced"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What would you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_policy_desertification_lessons_learned"
          v-model="values.successful_policies_lessons_learned"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Has your country supported other countries in establishing policies
          and enabling environments to promote and implement solutions to combat
          desertification/land degradation and mitigate the effects of drought,
          including prevention, relief and recovery?
        </h4>

        <P4Input
          id="if_policy_desertification_other_countries"
          v-model="values.desertification_country_support"
          radio
          :options="OPTIONS.yes_no"
        />

        <div :class="{ hidden: !desertificationCountrySupport }">
          <h4 v-translate class="P4-question">
            Has your country offered support related to or including the setting
            of policy measures in terms of mainstreaming gender in the
            implementation of the UNCCD?
          </h4>

          <P4Input
            id="if_policy_desertification_other_countries_support"
            v-model="values.mainstreaming_gender"
            radio
            :options="OPTIONS.yes_no"
          />

          <h4 v-translate class="P4-question">
            Use the space below to describe your country's experience.
          </h4>
          <P4Input
            id="if_policy_desertification_other_countries_experience"
            v-model="values.mainstreaming_gender_country_experience"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            Do you consider this experience a success and, if so, what do you
            consider the reasons behind this success (or lack thereof)?
          </h4>

          <P4Input
            id="if_policy_desertification_other_countries_opinion"
            v-model="values.mainstreaming_gender_successful_experience"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            What were the challenges faced, if any?
          </h4>

          <P4Input
            id="if_policy_desertification_other_countries_challenges"
            v-model="values.mainstreaming_gender_challenges_faced"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            What would you consider to be the lessons learned?
          </h4>

          <P4Input
            id="if_policy_desertification_other_countries_lessons_learned"
            v-model="values.mainstreaming_gender_lessons_learned"
            textarea
            standalone
          />

          <h4 v-translate class="P4-question">
            Are women’s land rights protected in national legislation?
          </h4>

          <P4Input
            id="if_policy_desertification_other_countries_women_land_rights"
            v-model="values.mainstreaming_gender_women_land_rights"
            radio
            :options="OPTIONS.yes_no"
          />

          <h4 v-translate class="P4-question">
            If so, how (please provide the reference to the relevant law/policy)
          </h4>

          <P4Input
            id="if_policy_desertification_other_countries_women_land_rights_how"
            v-model="values.mainstreaming_gender_women_land_rights_how"
            textarea
            standalone
          />
        </div>
      </div>
    </section>

    <section>
      <h2 v-translate class="P4-section-title">Synergies:</h2>

      <p v-translate class="P4-guidance">
        Leveraging synergies and integrating DLDD into national plans related to
        the multilateral environmental agreements (MEAs), particularly the other
        Rio Conventions and other international commitments, as appropriate,
        within their respective mandates, optimizing efficacy and eliminating
        duplication of efforts.
      </p>

      <h4 v-translate class="P4-question">
        From your perspective, has your country leveraged synergies and
        integrated DLDD into national plans related to other MEAs, particularly
        the other Rio Conventions and other international commitments?
      </h4>

      <P4Input
        id="if_policy_synergies"
        v-model="values.synergies"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !leverageSynergies }">
        <h4 v-translate class="P4-question">
          Your country's actions were aimed at (please check all that apply):
        </h4>

        <ul>
          <li
            v-for="(option, index) in OPTIONS.synergies_country_actions"
            :key="'synergies_country_actions-' + index"
          >
            <P4Input
              :id="'if_policy_synergies-' + index"
              v-model="values.synergies_country_actions_options[index]"
              :name="'synergies_country_actions-' + index"
              :label="option"
              checkbox
            />
          </li>
          <li>
            <P4Input
              v-if="values['synergies_country_actions_options']['other']"
              id="synergies_country_actions_other"
              v-model="values.synergies_country_actions_other"
            />
          </li>
        </ul>

        <h4 v-translate class="P4-question">
          Use the space below to describe your country's experience.
        </h4>

        <P4Input
          id="if_policy_synergies_experience"
          v-model="values.synergies_country_actions_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Do you consider this experience a success and, if so, what do you
          consider the reasons behind this success (or lack thereof)?
        </h4>

        <P4Input
          id="if_policy_synergies_opinion"
          v-model="values.synergies_country_actions_successful_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_policy_synergies_challenges"
          v-model="values.synergies_country_actions_challenges_faced"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What would you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_policy_synergies_lessons_learned"
          v-model="values.synergies_country_actions_lessons_learned"
          textarea
          standalone
        />
      </div>
    </section>

    <section>
      <h2 v-translate class="P4-section-title">
        Mainstreaming desertification, land degradation and drought:
      </h2>

      <p v-translate class="P4-guidance">
        Mainstreaming DLDD, as appropriate, into economic, environmental and
        social policies with a view to increasing the impact and effectiveness
        of the implementation of the Convention.
      </p>

      <h4 v-translate class="P4-question">
        From your perspective, did your country take specific actions to
        mainstream, DLDD in economic, environmental and social policies, with a
        view to increasing the impact and effectiveness of the implementation of
        the Convention?
      </h4>

      <P4Input
        id="if_policy_dldd"
        v-model="values.mainstreaming_dldd"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !mainstreamingDLDD }">
        <h4 v-translate class="P4-question">
          If so, DLDD was mainstreamed into (check all that apply):
        </h4>

        <ul>
          <li
            v-for="(option, index) in OPTIONS.mainstreaming_dldd"
            :key="'mainstreaming_dldd-' + index"
          >
            <P4Input
              :id="'if_policy_dldd-' + index"
              v-model="values.mainstreaming_dldd_options[index]"
              :name="'mainstreaming_dldd-' + index"
              :label="option"
              checkbox
            />
          </li>
          <li>
            <P4Input
              v-if="values['mainstreaming_dldd_options']['other']"
              id="mainstreaming_dldd_other"
              v-model="values.mainstreaming_dldd_other"
            />
          </li>
        </ul>

        <h4 v-translate class="P4-question">
          Use the space below to describe your country's experience.
        </h4>

        <P4Input
          id="if_policy_dldd_experience"
          v-model="values.mainstreaming_dldd_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Do you consider this experience a success and, if so, what do you
          consider the reasons behind this success (or lack thereof)?
        </h4>

        <P4Input
          id="if_policy_dldd_opinion"
          v-model="values.mainstreaming_dldd_successful_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_policy_dldd_challenges"
          v-model="values.mainstreaming_dldd_challenges_faced"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What would you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_policy_dldd_lessons_learned"
          v-model="values.mainstreaming_dldd_lessons_learned"
          textarea
          standalone
        />
      </div>
    </section>

    <section>
      <h2 v-translate class="P4-section-title">Drought-related policies:</h2>

      <p v-translate class="P4-guidance">
        Establishing national policies, measures and governance for drought
        preparedness and management, including drought contingency plans
        according to the mandate of the Convention.
      </p>

      <h4 v-translate class="P4-question">
        Has your country established or is your country establishing national
        policies, measures and governance for drought preparedness and
        management?
      </h4>

      <P4Input
        id="if_policy_drought"
        v-model="values.drought_national_policies"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !droughtNationalPolicies }">
        <h4 v-translate class="P4-question">
          Use the space below to describe your country's experience.
        </h4>

        <P4Input
          id="if_policy_drought_experience"
          v-model="values.drought_national_policies_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Do you consider this experience a success and, if so, what do you
          consider the reasons behind this success (or lack thereof)?
        </h4>

        <P4Input
          id="if_policy_drought_opinion"
          v-model="values.drought_national_policies_successful_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_policy_drought_challenges"
          v-model="values.drought_national_policies_challenges_faced"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What would you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_policy_drought_lessons_learned"
          v-model="values.drought_national_policies_lessons_learned"
          textarea
          standalone
        />
      </div>

      <h4 v-translate class="P4-question">
        Has your country supported other countries in establishing policies,
        measures and governance for drought preparedness and management, in
        accordance with the mandate of the Convention?
      </h4>

      <P4Input
        id="if_policy_drought_other_countries"
        v-model="values.drought_countries_support"
        radio
        :options="OPTIONS.yes_no"
      />

      <div :class="{ hidden: !droughtCountriesSupport }">
        <h4 v-translate class="P4-question">
          Use the space below to describe your country's experience.
        </h4>

        <P4Input
          id="if_policy_drought_other_countries_experience"
          v-model="values.drought_countries_support_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          Do you consider this experience a success and, if so, what do you
          consider the reasons behind this success (or lack thereof)?
        </h4>

        <P4Input
          id="if_policy_drought_other_countries_opinion"
          v-model="values.drought_countries_support_successful_experience"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What were the challenges faced, if any?
        </h4>

        <P4Input
          id="if_policy_drought_other_countries_challenges"
          v-model="values.drought_countries_support_challenges_faced"
          textarea
          standalone
        />

        <h4 v-translate class="P4-question">
          What would you consider to be the lessons learned?
        </h4>

        <P4Input
          id="if_policy_drought_other_countries_lessons_learned"
          v-model="values.drought_countries_support_lessons_learned"
          textarea
          standalone
        />
      </div>
    </section>
  </P4Form>
</template>

<script>
import OPTIONS from "../OPTIONS.js";

export default {
  data() {
    const values = {
      policies: {},
      experiences: {},
      synergies_country_actions_options: {},
      mainstreaming_dldd_options: {},

      ...window.hydrate.data,
    };

    return {
      values,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,

    nationalActionProgramme() {
      return this.values["national_action_programme"] === "yes";
    },
    desertificationActions() {
      return this.values["desertification_actions"] === "yes";
    },
    desertificationCountrySupport() {
      return this.values["desertification_country_support"] === "yes";
    },
    leverageSynergies() {
      return this.values["synergies"] === "yes";
    },
    mainstreamingDLDD() {
      return this.values["mainstreaming_dldd"] === "yes";
    },
    droughtNationalPolicies() {
      return this.values["drought_national_policies"] === "yes";
    },
    droughtCountriesSupport() {
      return this.values["drought_countries_support"] === "yes";
    },
  },
};
</script>

import { translate } from "vue-gettext";
import parseQuery from "./parseQuery.js";

let allOptionsEnabled = false;

export const forceAllOptions = () => {
  allOptionsEnabled = true;
};

export const allOptions = () =>
  allOptionsEnabled || parseQuery()["allOptions"] === "true";

export const unstyled = () => parseQuery()["unstyled"] === "true";

export class LazyString {
  constructor(msgid) {
    this.msgid = msgid;
  }

  toString() {
    return translate.gettext(this.msgid);
  }
}

export const gettextLazy = (msgid) => {
  // Lazy gettext function that defers the call to `gettext` until the object
  // is converted to a string.
  return new LazyString(msgid);
};

export const recursiveTranslate = (data) => {
  if (data instanceof LazyString) {
    return `${data}`;
  }

  if (data instanceof Array) {
    return data.map(recursiveTranslate);
  }

  if (data instanceof Object) {
    const newData = {};
    for (const key of Object.keys(data)) {
      newData[key] = recursiveTranslate(data[key]);
    }
    return newData;
  }

  return data;
};

<template>
  <P4Form v-model="values">
    <h2 v-translate class="P4-section-title">Soil organic carbon stocks</h2>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This section is pre-filled with default soil organic carbon (SOC) stock
      data derived from the SoilGrids250m dataset of the International Soil
      Reference and Information Centre (ISRIC). Keep the default national
      estimates or, in the event of data and capacity, replace them with
      national datasets.
    </p>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-3.T1: National estimates of the soil organic carbon stock in topsoil
      (0-30 cm) within each land cover class (in tonnes per hectare).
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-3.T1: Affected area estimates of the soil organic carbon stock in
      topsoil (0-30 cm) within each land cover class (in tonnes per hectare).
    </h3>
    <CommentAnchor :name="form_id + '-T1'" />
    <T1 />
    <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />

    <div class="P4-element">
      <h3 v-translate class="P4-caption">
        If you opted not to use default Tier 1 data, what did you use to
        calculate the estimates above?
      </h3>
      <P4Input
        v-model="values.option_calculate_the_estimates"
        radio
        :options="OPTIONS.TIERS"
      />
    </div>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-3.T2: National estimates of the change in soil organic carbon stock in
      soil due to land conversion to a new land cover class in the baseline
      period
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-3.T2: Affected area estimates of the change in soil organic carbon
      stock in soil due to land conversion to a new land cover class in the
      baseline period
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This table will include the top four land cover changes (by area) by
      default. Add additional land conversions when deemed necessary.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      This table should include the top four land cover changes (by area). Add
      additional land conversions when deemed necessary.
    </p>
    <CommentAnchor :name="form_id + '-T2'" />
    <T2 />

    <RestoreDefaultdata name="t2" @reloadDefaultData="reloadDefaultData" />

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-3.T3: National estimates of the change in soil organic carbon stock in
      soil due to land conversion to a new land cover class in the reporting
      period
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-3.T3: Affected area estimates of the change in soil organic carbon
      stock in soil due to land conversion to a new land cover class in the
      reporting period
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This table will include the top four land cover changes (by area) by
      default. Add additional land conversions when deemed necessary.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      This table should include the top four land cover changes (by area). Add
      additional land conversions when deemed necessary.
    </p>
    <CommentAnchor :name="form_id + '-T3'" />
    <T3 />
    <RestoreDefaultdata name="t3" @reloadDefaultData="reloadDefaultData" />

    <h2 v-translate class="P4-section-title">
      Soil organic carbon stock degradation
    </h2>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This section is pre-filled with default SOC stock degradation estimates
      for the baseline and reporting periods. Keep the default data or replace
      it with national datasets.
    </p>
    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-3.T4: National estimates of soil organic carbon stock degradation in
      the baseline period
    </h3>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded or non-degraded due to SOC
      change in the baseline period, reported as the total area of degraded SOC
      in km² and the area of degraded SOC as a proportion (%) of the total land
      area.
    </p>
    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-3.T4: Affected area estimates of soil organic carbon stock degradation
      in the baseline period
    </h3>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded or non-degraded due to SOC
      change in the baseline period, reported as the total area of degraded SOC
      in km² and the area of degraded SOC as a proportion (%) of the total
      affected area.
    </p>
    <CommentAnchor :name="form_id + '-T4'" />
    <T4 />
    <RestoreDefaultdata name="t4" @reloadDefaultData="reloadDefaultData" />

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-3.T5: National estimates of SOC stock degradation in the reporting
      period
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-3.T5: Affected area estimates of SOC stock degradation in the
      reporting period
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded, improved or stable due to
      SOC change in the reporting period , reported as the total area of
      degraded SOC in km² and the area of degraded SOC as a proportion (%) of
      the total land area.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded, improved or stable due to
      SOC change in the reporting period , reported as the total area of
      degraded SOC in km² and the area of degraded SOC as a proportion (%) of
      the total affected area.
    </p>
    <CommentAnchor :name="form_id + '-T5'" />
    <T5 />
    <RestoreDefaultdata name="t5" @reloadDefaultData="reloadDefaultData" />

    <h2 v-translate class="P4-section-title">General comments</h2>
    <p v-translate class="P4-guidance">
      Provide any additional comments you deem relevant.
    </p>
    <div>
      <P4Input v-model="values.so1_3_general_comments" textarea standalone />
    </div>
  </P4Form>
</template>

<script>
import { UNCCD_CLASS_MAP } from "../../../lib/constants.js";
import OPTIONS from "./OPTIONS.js";
import getYears from "../../../lib/getYears.js";

import T1 from "./T1.vue";
import T2 from "./T2.vue";
import T3 from "./T3.vue";
import T4 from "./T4.vue";
import T5 from "./T5.vue";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

export default {
  components: {
    T1,
    T2,
    T3,
    T4,
    T5,
  },
  mixins: [restoreDefaultData],
  data() {
    const values = {
      t1: getYears(2000, 2020).map((y) => ({ year: y.toString() })),
      t4: Object.keys(OPTIONS.BASELINE).map((key) => ({ id: key })),
      t5: Object.keys(OPTIONS.REPORTING).map((key) => ({ id: key })),
      ...window.hydrate.data,
    };
    return {
      UNCCD_CLASS_MAP,
      values,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
  },
};
</script>

<template>
  <div>
    <p class="print-show">{{ formattedValue }}</p>
    <p class="print-hide">{{ value }}</p>
  </div>
</template>

<script>
import printNumberFormat from "@/lib/mixins/printNumberFormat";

export default {
  mixins: [printNumberFormat],
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    formattedValue() {
      return this.formatNumber(this.value, this.selectedLanguageSeparator);
    },
  },
};
</script>

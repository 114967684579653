<template>
  <section>
    <p v-translate class="P4-infotext text-lg">
      You can choose to complete only Tier 1 or both.
    </p>
    <P4Form v-model="values">
      <h3 v-translate class="P4-section-title">
        Tier 1: Please provide information on the domestic public expenditures,
        including subsidies, and revenues, including taxes, directly and
        indirectly related to the implementation of the Convention, including
        information on trends.
      </h3>

      <P4Input
        v-model="values.trends_domestic_expenditures"
        radio
        name="trends_domestic_expenditures"
        :label="
          $gettext(
            'Trends in domestic public expenditures and national level financing for activities relevant to the implementation of the Convention'
          )
        "
        :options="SO5_OPTIONS.STATUS"
      />

      <P4Input
        v-model="values.trends_domestic_revenues"
        radio
        name="trends_domestic_revenues"
        :label="
          $gettext(
            'Trends in domestic public revenues from activities related to the implementation of the Convention'
          )
        "
        :options="SO5_OPTIONS.STATUS"
      />

      <p v-translate class="P4-guidance">
        Please provide information, as available, on the economic instruments
        implemented to disincentivise land degradation and to incentivize land
        degradation neutrality; on the projects, activities, policies and
        measures on which your country has focused to the greatest extent.
      </p>
      <P4Input
        id="so5_2_policies_implemented"
        v-model="values.so5_2_tier1"
        textarea
        standalone
      />

      <p v-translate class="P4-guidance">
        Description may include information on national circumstances and
        institutional arrangements relevant to the reporting on domestic
        expenditures and revenues, directly and indirectly related to the
        implementation of the Convention at national level, and on their sources
        and use.
      </p>
      <P4Input
        id="so5_2_national_circumstances"
        v-model="values.information_on_national_circumstances"
        textarea
        standalone
      />

      <h3 v-translate class="P4-section-title">
        Tier 2: Table 2 Domestic public resources
      </h3>
      <CommentAnchor :name="form_id + '-T2'" />
      <T2a />
      <T2b />

      <h3 v-translate class="P4-section-title">Documentation box</h3>
      <p v-translate class="P4-guidance">
        Please provide information on definitions or methodologies used for
        reporting information in the following reporting parameters:
      </p>
      <CommentAnchor :name="form_id + '-T3'" />
      <T3 />

      <P4Input
        v-model="values.target_for_increasing_and_mobilizing"
        radio
        name="target_for_increasing_and_mobilizing"
        :label="
          $gettext(
            'Has your country set a target for increasing and mobilizing domestic resources for the implementation of the Convention?'
          )
        "
        :options="OPTIONS.Form.yes_no"
      />

      <p v-translate class="P4-guidance">Explain</p>
      <P4Input
        id="so5_2_target_increasing"
        v-model="values.target_for_increasing_and_mobilizing_explain"
        textarea
        standalone
      />

      <h3 v-translate class="P4-section-title">General comments</h3>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input
          id="so5_2_general_comments"
          v-model="values.so5_2_general_comments"
          textarea
          standalone
        />
      </div>
    </P4Form>
  </section>
</template>

<script>
import T2a from "./T2a.vue";
import T2b from "./T2b.vue";
import T3 from "./T3.vue";
import SO5_OPTIONS from "../SO5_OPTIONS";
import OPTIONS from "./OPTIONS";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  components: {
    T2a,
    T2b,
    T3,
  },
  data() {
    const values = {
      t2a: Object.keys(OPTIONS.T2a.headings).map((key) => ({ id: key })),
      t2b: Object.keys(OPTIONS.T2b.headings).map((key) => ({ id: key })),
      t3: Object.keys(OPTIONS.T3).map((key) => ({ id: key })),

      ...window.hydrate.data,
    };

    return {
      values,
      form_id: window.hydrate.id,
      $gettext,
    };
  },
  computed: {
    SO5_OPTIONS: () => SO5_OPTIONS,
    OPTIONS: () => OPTIONS,
  },
};
</script>

<template>
  <section>
    <FormulateInput type="multitable" name="t1">
      <template #table_header>
        <tr>
          <th
            v-for="(column, index) in [
              '',
              isAffectedAreas
                ? $gettext('Total area of degraded affected area (km²)')
                : $gettext('Total area of degraded land (km²)'),
            ]"
            :key="index"
            class="P4-cell"
          >
            {{ column }}
          </th>
          <th>
            <Tooltip
              :content="
                $gettext(
                  'Report the Sustainable Development Goal as a single figure quantifying the area of degraded land as a proportion of total land area, defined as the total surface area of a country excluding the area covered by inland waters, such as major rivers and lakes.'
                )
              "
            >
              <span v-translate>
                Proportion of degraded land over the total land area (%)
              </span>
              <span class="tooltip-icon">ⓘ</span>
            </Tooltip>
          </th>
        </tr>
      </template>

      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          <Tooltip :content="OPTIONS.T1[value.id].tooltip">
            {{ OPTIONS.T1[value.id].name }}
            <span class="tooltip-icon">ⓘ</span>
          </Tooltip>
        </th>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-degraded_area'"
            v-model="value.degraded_area"
            :default-value-has-changed="
              defaultValueHasChanged(
                ['t1', rowIndex, 'degraded_area'],
                value.degraded_area
              )
            "
            numeric
            :warnings="[
              {
                name: 'exceedMaxTotalWarning',
                args: [value.degraded_area, getTotal(value), getYear(value)],
              },
            ]"
          />
        </td>
        <td class="P4-cell-right">
          <P4DisplayValue
            :value="formatPercent(value.degraded_area, getTotal(value))"
          />
        </td>
      </template>

      <template #table_footer="{ values }">
        <T1TableFooter :values="values" />
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import countryAreas from "@/lib/mixins/countryAreas";
import formatPercent from "@/lib/formatPercent";
import T1TableFooter from "@/forms/so1/4/T1TableFooter";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  components: { T1TableFooter },
  mixins: [defaultValuesChange, countryAreas],
  data() {
    return {
      $gettext,
      isAffectedAreas: window.hydrate.is_aa_form,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
  },
  methods: {
    formatPercent,
    getTotal(value) {
      switch (value.id) {
        case "baseline_period":
          return this.baselineLandCoverTotalLandArea;
        case "reporting_period":
          return this.reportingLandCoverTotalLandArea;
      }
    },
    getYear(value) {
      switch (value.id) {
        case "baseline_period":
          return this.baselineLandCoverYear;
        case "reporting_period":
          return this.reportingLandCoverYear;
      }
    },
  },
};
</script>

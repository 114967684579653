<template>
  <P4Form v-model="values">
    <h2 v-translate class="P4-section-title">Drought Vulnerability Index</h2>

    <p v-translate class="P4-guidance">
      Vulnerability refers to the conditions determined by physical, social,
      economic and environmental factors or processes which increase the
      susceptibility of an individual, community, assets or systems to the
      impacts of hazards, such as drought. For the purpose of reporting, we
      consider three components of vulnerability: social, economical and
      infrastructural.
    </p>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO3-3.T1: National estimates of the Drought Vulnerability Index
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO3-3.T1: Affected area estimates of the Drought Vulnerability Index
    </h3>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Assess your country’s vulnerability to drought as represented by the
      Drought Vulnerability Index and as explained in the Good Practice Guidance
      for SO3. This table contains options to report on tier 1 – the total
      country-level Drought Vulnerability Index (DVI) value, and tiers 2 and 3 –
      on the vulnerability of male and female populations to drought. This
      section is pre-filled with a default value derived from the global DVI
      dataset of the European Commission Joint Research Centre. Keep the default
      national estimate, or, in the event of data and capacity, replace it with
      national data.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Assess your affected area’s vulnerability to drought as represented by the
      Drought Vulnerability Index and as explained in the Good Practice Guidance
      for SO3. This table contains options to report on tier 1 – the total
      country-level Drought Vulnerability Index (DVI) value, and tiers 2 and 3
      –on the vulnerability of male and female populations to drought. Affected
      area estimates of the DVI can only be derived if the factor data for the
      vulnerability assessment are available for the full extent of the affected
      areas (i.e. tier 3 vulnerability assessment).
    </p>
    <CommentAnchor :name="form_id + '-T1'" />
    <T1 />
    <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />

    <h2 v-translate class="P4-section-title">Method</h2>

    <p v-translate class="P4-guidance">
      The DVI is a composite indicator, incorporating social, economic and
      infrastructural components that reflect the vulnerability of a country's
      population to drought. The three tiers of vulnerability assessment (Tiers
      of VA) recommended in the Good Practice Guidance on SO3 represent
      increasing levels of methodological complexity and data requirement for
      the calculation of the DVI:
    </p>

    <div>
      <p v-translate class="P4-question">
        Which tier level did you use to compute the DVI?
      </p>

      <ul>
        <li
          v-for="({ label, tooltip }, index) in isAffectedAreas
            ? OPTIONS.tier_levels_affected_areas
            : OPTIONS.tier_levels"
          :key="'tiers-' + index"
        >
          <Tooltip :content="tooltip">
            <P4Input
              v-model="values.tiers[index]"
              :name="'tiers-' + index"
              :label="label + (mediaPrint ? '' : ' ⓘ')"
              :disabled="isAffectedAreas"
              checkbox
            />
          </Tooltip>
        </li>
      </ul>
    </div>

    <div :class="{ hidden: !tier2ORTier3 }">
      <T3 :tier3="values.tiers['tier3']" />
      <CommentAnchor :name="form_id + '-T3'" />
      <T4 :tier3="values.tiers['tier3']" />
      <CommentAnchor :name="form_id + '-T4'" />
      <T5 :tier3="values.tiers['tier3']" />
      <CommentAnchor :name="form_id + '-T5'" />
    </div>

    <h2 v-translate class="P4-section-title">Qualitative assessment</h2>

    <h3 v-translate class="P4-caption">
      SO3-3.T2: Interpretation of the indicator
    </h3>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      If the DVI was calculated at national level using national data, i.e. not
      using the default data, select the dominant change observed in the DVI.
      Please also provide any further comments on these changes.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      If the DVI was calculated at affected area level using national data
      select the dominant change observed in the DVI. Please also provide any
      further comments on these changes.
    </p>
    <CommentAnchor :name="form_id + '-T2'" />
    <T2 />

    <h2 v-translate class="P4-section-title">General comments</h2>
    <p v-translate class="P4-guidance">
      Provide any additional comments you deem relevant.
    </p>
    <div>
      <P4Input v-model="values.so3_3_general_comments" textarea standalone />
    </div>
  </P4Form>
</template>

<script>
import T1 from "./T1.vue";
import T2 from "./T2.vue";
import T3 from "./T3.vue";
import T4 from "./T4.vue";
import T5 from "./T5.vue";
import OPTIONS from "./OPTIONS";
import getYearsBetween from "../../../lib/getYears.js";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

export default {
  components: {
    T1,
    T2,
    T3,
    T4,
    T5,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  mixins: [restoreDefaultData],
  data() {
    const values = {
      t1: getYearsBetween(2000, 2021).map((year) => ({ year })),
      t3: Object.keys(OPTIONS.social_factors).map((factor) => ({ factor })),
      t4: Object.keys(OPTIONS.economic_factors).map((factor) => ({ factor })),
      t5: Object.keys(OPTIONS.infrastructure_factors).map((factor) => ({
        factor,
      })),

      tiers: {},

      ...window.hydrate.data,
    };

    const is_aa_form = window.hydrate.is_aa_form;
    if (is_aa_form) {
      values.tiers = { tier3: true };
    }

    return {
      values,
      isAffectedAreas: is_aa_form,
      form_id: window.hydrate.id,
      mediaPrint: !!window.matchMedia("print")?.matches,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,

    tier2ORTier3() {
      return this.values.tiers["tier2"] || this.values.tiers["tier3"];
    },
    tier3() {
      return this.values.tiers["tier3"];
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t5","columns":[
      '',
      _vm.$gettext('Area (km²)'),
      _vm.isAffectedAreas
        ? _vm.$gettext('Percent of total affected area (%)')
        : _vm.$gettext('Percent of total land area (%)'),
    ],"warnings":[
      {
        name: 'maxTotalWarning',
        args: [
          'baseline_period',
          _vm.baselineProductivityTotalLandArea,
          _vm.baselineProductivityYear,
        ],
      },
    ]},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('th',{staticClass:"P4-cell"},[_c('Tooltip',{attrs:{"content":_vm.OPTIONS.BASELINE[value.id].tooltip}},[_vm._v(" "+_vm._s(_vm.OPTIONS.BASELINE[value.id].name)+" "),_c('span',{staticClass:"tooltip-icon"},[_vm._v("ⓘ")])])],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-baseline_period',"default-value-has-changed":_vm.defaultValueHasChanged(
              ['t5', rowIndex, 'baseline_period'],
              value.baseline_period
            ),"numeric":""},model:{value:(value.baseline_period),callback:function ($$v) {_vm.$set(value, "baseline_period", $$v)},expression:"value.baseline_period"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4DisplayValue',{attrs:{"value":_vm.formatPercent(
              value.baseline_period,
              _vm.baselineProductivityTotalLandArea
            )}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";

import registerComponents from "./lib/registerComponents.js";
import forms from "./forms/components.js";
import MapPage from "./map/Page.vue";
import TemplatedMapPage from "./templated_maps/Page.vue";
import SelectMap from "./templated_maps/SelectMap.vue";
import detail_maps from "./detail_maps/components.js";
import comments from "./lib/comments/components.js";
import Vuex from 'vuex';

import "./styles/main.css";
import "./styles/print.css";

Vue.config.productionTip = false;
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    formErrors: null,
    defaultFormData: {},
    newRowTableAutocomplete: {}
  },
  mutations: {
    addFormError(state, payload) {
      state.formErrors = { ...state.formErrors, ...payload };
    },
    removeFormError(state, payload) {
      delete state.formErrors[payload];
      state.formErrors = Object.keys(state.formErrors).length === 0 ? null : state.formErrors
    },
    removeformErrors(state) {
      state.formErrors = null;
    },
    addDefaultFormData(state, payload) {
      state.defaultFormData = { ...payload };
    },
    removeDefaultFormData(state) {
      state.defaultFormData = {};
    },
    addNewRowTableAutocomplete(state, payload) {
      // payload ex: {t3: {value, rowIndex}}
      // no need to add a SO1_1 suffix
      // because changing to any page will reset (empty) the store
      state.newRowTableAutocomplete = {
        ...state.newRowTableAutocomplete,
        ...payload
      };
    },
  }
});

registerComponents(Vue);

window.Prais4 = {
  Vue,
  store,
  components: {
    forms,
    MapPage,
    detail_maps,
    comments,
  },
  templatedMaps: {
    TemplatedMapPage,
    SelectMap,
  },
  instances: {},
};

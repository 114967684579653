var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormulateForm',{on:{"submit":_vm.submit},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}},[_c('section',[_c('h2',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-section-title"},[_vm._v("Language")]),_c('h5',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-guidance"},[_vm._v(" Choose the language in which the report and templated maps for "+_vm._s(_vm.country)+" will be generated. This will also be the default language for users of "+_vm._s(_vm.country)+". ")]),_c('P4Input',{attrs:{"name":"language","options":_vm.languages,"disabled":_vm.disabled,"help":_vm.disabled
          ? _vm.$gettext(
              'This option can be changed only by the country reporting officer.'
            )
          : ''},model:{value:(_vm.values.language),callback:function ($$v) {_vm.$set(_vm.values, "language", $$v)},expression:"values.language"}}),(_vm.allOptions)?_c('ul',{staticClass:"list-disc ml-10"},_vm._l((_vm.languages),function(option){return _c('li',{key:option},[_vm._v(" "+_vm._s(option)+" ")])}),0):_vm._e()],1),_c('section',[_c('h2',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-section-title"},[_vm._v("Templated Maps Orientation")]),_c('h5',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-guidance"},[_vm._v(" Choose the orientation for the Templated Maps of "+_vm._s(_vm.country)+". ")]),_c('P4Input',{attrs:{"name":"templated_maps_orientation","options":_vm.orientations,"disabled":_vm.disabled,"help":_vm.disabled
          ? _vm.$gettext(
              'This option can be changed only by the country reporting officer.'
            )
          : ''},model:{value:(_vm.values.templated_maps_orientation),callback:function ($$v) {_vm.$set(_vm.values, "templated_maps_orientation", $$v)},expression:"values.templated_maps_orientation"}}),(_vm.allOptions)?_c('ul',{staticClass:"list-disc ml-10"},_vm._l((_vm.orientations),function(orientation){return _c('li',{key:orientation},[_vm._v(" "+_vm._s(orientation)+" ")])}),0):_vm._e()],1),_c('section',[_c('h2',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-section-title"},[_vm._v("Templated Maps Projection")]),_c('h5',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-guidance"},[_vm._v(" Choose the projection for the Templated Maps of "+_vm._s(_vm.country)+". ")]),_c('P4Input',{attrs:{"name":"templated_map_projection","options":_vm.projections,"disabled":_vm.disabled,"help":_vm.disabled
          ? _vm.$gettext(
              'This option can be changed only by the country reporting officer.'
            )
          : ''},model:{value:(_vm.values.templated_map_projection),callback:function ($$v) {_vm.$set(_vm.values, "templated_map_projection", $$v)},expression:"values.templated_map_projection"}}),(_vm.allOptions)?_c('ul',{staticClass:"list-disc ml-10"},_vm._l((_vm.projections),function(projection){return _c('li',{key:projection},[_vm._v(" "+_vm._s(projection)+" ")])}),0):_vm._e()],1),_c('FormSave',{ref:"save",attrs:{"disabled":_vm.disabled}}),_c('FormDebug',{attrs:{"values":_vm.values}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
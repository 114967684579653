<template>
  <P4Form v-model="values" :submit-handler="submit" :keep-model-data="true">
    <section>
      <h2 v-translate class="P4-section-title">Land area</h2>

      <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
        SO1-1.T1: National estimates of the total land area, the area covered by
        water bodies and total country area
      </h3>

      <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
        SO1-1.T1: Estimates of the total land area of the affected area
      </h3>

      <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
        Indicate the total land area, the area covered by water bodies and total
        country area every five years from 2000 to 2015 and then for the most
        recent reported year. This section is pre-filled with default
        information derived from the European Space Agency Climate Change
        Initiative Land Cover dataset. Keep the default national estimates, or,
        in the event of data and capacity, replace them with national data.
      </p>
      <p v-if="isAffectedAreas" v-translate class="P4-guidance">
        Indicate the total land area of the affected area in your country every
        five years from 2000 to 2015 and then for the most recent reported year.
      </p>
      <CommentAnchor :name="form_id + '-T1'" />
      <T1 />
      <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />
    </section>

    <section>
      <h2 v-translate class="P4-section-title">
        Land cover legend and transition matrix
      </h2>

      <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
        State the key degradation processes relevant in your country, define a
        land cover legend that allows for their monitoring, and generate a
        transition matrix that specifies land cover changes as being either
        degradation, improvement or neutral transitions.
      </p>
      <p v-if="isAffectedAreas" v-translate class="P4-guidance">
        State the key degradation processes relevant to the affected area in
        your country, define a land cover legend that allows for their
        monitoring, and generate a transition matrix that specifies land cover
        changes as being either degradation, improvement or neutral transitions.
      </p>

      <h3 v-translate class="P4-caption">
        SO1-1.T2: Key Degradation Processes
      </h3>
      <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
        State the key degradation processes relevant in your country and the
        corresponding land cover transitions.
      </p>
      <p v-if="isAffectedAreas" v-translate class="P4-guidance">
        State the key degradation processes relevant in the affected area of
        your country and the corresponding land cover transitions.
      </p>
      <CommentAnchor :name="form_id + '-T2'" />
      <T2 />

      <P4Input
        v-if="!isAffectedAreas"
        v-model="values.unccd_classes_sufficient"
        radio
        name="unccd_classes_sufficient"
        :label="
          $gettext(
            'Are the seven UNCCD land cover classes sufficient to monitor the key degradation processes in your country?'
          )
        "
        :options="OPTIONS.Form.yes_no"
      />

      <P4Input
        v-if="isAffectedAreas"
        v-model="values.unccd_classes_sufficient"
        radio
        name="unccd_classes_sufficient"
        :label="
          $gettext(
            'Are the seven UNCCD land cover classes sufficient to monitor the key degradation processes in the affected areas of your country?'
          )
        "
        :options="OPTIONS.Form.yes_no"
      />

      <div v-if="!isUNCCDSufficient" key="t3-t4b">
        <h3 v-translate class="P4-caption">SO1-1.T3: Land Cover Legend</h3>

        <p v-translate class="P4-guidance">
          Define a national land cover legend competent for capturing the key
          degradation processes, and specify how this legend (if used) can be
          aggregated to the 7 land cover classes required for UNCCD reporting.
        </p>
        <CommentAnchor :name="form_id + '-T3'" />
        <T3 :land-cover-classes-title="values['land_cover_classes_title']" />

        <h3 v-translate class="P4-caption">
          SO1-1.T4: Country Land Cover Legend Transition Matrix
        </h3>

        <p v-translate class="P4-guidance">
          Using the national land cover legend you reported in SO1-1.T3,
          identify in the table below which transitions correspond to
          degradation (- sign), improvement (+ sign), or those that remain
          stable or exhibit no change in terms of land condition (zero), using
          the drop-down menus provided in the table. In this context “no change”
          indicates that the transition is neither degradation nor improvement,
          or that the data is insufficient to unambiguously label as degradation
          or improvement. Highlight unlikely transitions, i.e., where
          transitions between classes are illogical or implausible, using the
          checkbox provided.
        </p>
        <CommentAnchor :name="form_id + '-T4'" />
        <T4 name="t4b" :custom-headings="T3CustomHeadings" />
        <LandLegend />
      </div>
      <div v-else key="t4a">
        <h3 v-translate class="P4-caption">
          SO1-1.T4: UNCCD land cover legend transition matrix
        </h3>

        <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
          Evaluate the default land cover transitions and adjust them, if
          needed, through a participatory process considering national and local
          conditions. Based on the outcome of this process and/or your knowledge
          of the land degradation processes occurring at the national level, use
          the drop-down menus provided in the table to identify which
          transitions correspond to degradation (- sign) or improvement (+
          sign), and which remain stable in terms of land condition (zero).
          Highlight unlikely transitions, i.e. where transitions between classes
          are illogical or implausible, using the checkbox provided.
        </p>
        <p v-if="isAffectedAreas" v-translate class="P4-guidance">
          Evaluate the default land cover transitions and adjust them, if
          needed, through a participatory process considering the affected area
          conditions. Based on the outcome of this process and/or your knowledge
          of the land degradation processes occurring in the affected areas, use
          the drop-down menus provided in the table to identify which
          transitions correspond to degradation (- sign) or improvement (+ sign)
          and which remain stable in terms of land condition (zero). Highlight
          unlikely transitions, i.e. where transitions between classes are
          illogical or implausible, using the checkbox provided.
        </p>
        <CommentAnchor :name="form_id + '-T4'" />
        <T4 name="t4a" />
        <Legend />
      </div>
    </section>

    <h2 v-translate class="P4-section-title">Land cover</h2>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      This section is pre-filled with default land cover data derived from the
      European Space Agency Climate Change Initiative Land Cover dataset. Keep
      the default national estimates or, in the event of data and capacity,
      replace them with national datasets.
    </p>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-1.T5: National estimates of land cover (km²) for the baseline and
      reporting period
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-1.T5: Affected area estimates of land cover (km²) for the baseline and
      reporting period
    </h3>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Report national annual area estimates of the land cover classes and
      calculate net area changes for the baseline and reporting periods. Keep
      the default estimates or replace them with national datasets.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Report affected area annual estimates of the land cover classes and
      calculate net area changes for the baseline and reporting periods.
    </p>
    <CommentAnchor :name="form_id + '-T5'" />
    <T5
      :key="`t5${tableVersion}`"
      :name="`t5${tableVersion}`"
      :custom-headings="customHeadings"
    />
    <RestoreDefaultdata
      v-if="tableVersion === 'a'"
      name="t5a"
      @reloadDefaultData="reloadDefaultData"
    />

    <h2 v-translate class="P4-section-title">Land cover change</h2>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This section is pre-filled with default land cover change data for the
      baseline and reporting periods. Keep the default data or replace it with
      national datasets
    </p>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-1.T6: National estimates of land cover change (km²) for the baseline
      period
    </h3>
    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-1.T6: Affected area estimates of land cover change (km²) for the
      baseline period
    </h3>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Fill in the land cover area change matrix, by reporting national estimates
      of land cover flows, which represent the losses and gains resulting from
      changes from one land cover class to a different land cover class, for the
      baseline period.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Fill in the land cover area change matrix by reporting affected area
      estimates of land cover flows, which represent the losses and gains
      resulting from changes from one land cover class to a different land cover
      class, for the baseline period.
    </p>
    <CommentAnchor :name="form_id + '-T6'" />
    <T6
      :key="`t6${tableVersion}`"
      :name="`t6${tableVersion}`"
      :custom-headings="customHeadings"
    />
    <RestoreDefaultdata
      v-if="tableVersion === 'a'"
      name="t6a"
      @reloadDefaultData="reloadDefaultData"
    />

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-1.T7: National estimates of land cover change (km²) for the reporting
      period
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-1.T7: Affected area estimates of land cover change (km²) for the
      reporting period
    </h3>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Fill in the land cover area change matrix, by reporting national estimates
      of land cover flows, which represent the losses and gains resulting from
      changes from one land cover class to a different land cover class, for the
      reporting period.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Fill in the land cover area change matrix by reporting affected area
      estimates of land cover flows, which represent the losses and gains
      resulting from changes from one land cover class to a different land cover
      class, for the reporting period.
    </p>
    <CommentAnchor :name="form_id + '-T7'" />
    <T7
      :key="`t7${tableVersion}`"
      :name="`t7${tableVersion}`"
      :custom-headings="customHeadings"
    />
    <RestoreDefaultdata
      v-if="tableVersion === 'a'"
      name="t7a"
      @reloadDefaultData="reloadDefaultData"
    />

    <h2 v-translate class="P4-section-title">Land cover degradation</h2>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This section is pre-filled with default land cover degradation estimates
      for the baseline and reporting periods. Keep the default data or replace
      it with national datasets.
    </p>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-1.T8: National estimates of land cover degradation (km²) in the
      baseline period
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-1.T8: Affected area estimates of land cover degradation (km²) in the
      baseline period
    </h3>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded or non-degraded due to land
      cover change in the baseline period, reported as the total area of
      degraded land cover in km² and the area of degraded land cover as a
      proportion (%) of the total country area.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded or non-degraded due to land
      cover change in the baseline period, reported as the area of degraded land
      cover in km² and the area of degraded land cover as a proportion (%) of
      the total affected area.
    </p>
    <CommentAnchor :name="form_id + '-T8'" />
    <T8
      :total="baselineLandCoverTotalCountryArea"
      :year="baselineLandCoverYear"
    />
    <RestoreDefaultdata name="t8" @reloadDefaultData="reloadDefaultData" />

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-1.T9: National estimates of land cover degradation (km²) in the
      reporting period
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-guidance">
      SO1-1.T9: Affected area estimates of land cover degradation (km²) in the
      reporting period
    </h3>

    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded and non-degraded due to land
      cover change in the reporting period, reported as the total area of
      degraded land cover in km² and the area of degraded land cover as a
      proportion (%) of the total country area.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded and non-degraded due to land
      cover change in the reporting period, reported as the area of degraded
      land cover in km² and the area of degraded land cover as a proportion (%)
      of the total affected area.
    </p>
    <CommentAnchor :name="form_id + '-T9'" />
    <T9
      :total="reportingLandCoverTotalCountryArea"
      :year="reportingLandCoverYear"
    />
    <RestoreDefaultdata name="t9" @reloadDefaultData="reloadDefaultData" />

    <h2 v-translate class="P4-section-title">General comments</h2>
    <p v-translate class="P4-guidance">
      Provide any additional comments you deem relevant.
    </p>
    <div>
      <P4Input v-model="values.so1_1_general_comments" textarea standalone />
    </div>
  </P4Form>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { UNCCD_CLASS_MAP } from "@/lib/constants.js";
import { allOptions } from "@/lib/translations.js";
import getYears from "@/lib/getYears.js";

import T1 from "./T1.vue";
import T2 from "./T2.vue";
import T3 from "./T3.vue";
import T4 from "./T4.vue";
import T5 from "./T5.vue";
import T6 from "./T6.vue";
import T7 from "./T7.vue";
import T8 from "./T8.vue";
import T9 from "./T9.vue";
import LandLegend from "./LandLegend.vue";
import countryAreas, {
  LC_BASELINE,
  LC_REPORTING,
  LP_BASELINE,
  LP_REPORTING,
  SOC_BASELINE,
  SOC_REPORTING,
} from "@/lib/mixins/countryAreas";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

function getTableVersion(data) {
  return data.unccd_classes_sufficient === "yes"
    ? "a"
    : data.unccd_classes_sufficient === "no"
    ? "b"
    : null;
}

function T2ValuesForAllOptions() {
  return allOptions()
    ? {
        t2: Object.keys(OPTIONS.T2.degradation).map((degradation_process) => {
          return { degradation_process };
        }),
      }
    : null;
}

const T3CustomTables = ["t4", "t5", "t6", "t7"];

export default {
  components: {
    T1,
    T2,
    T3,
    T4,
    T5,
    T6,
    T7,
    T8,
    T9,
    LandLegend,
  },
  mixins: [countryAreas, restoreDefaultData],
  data() {
    const initialValuesForTablesByVersion = {
      t4a:
        window.hydrate.default_data.t4 ||
        Object.keys(UNCCD_CLASS_MAP).map((key) => ({ initial: key })),
      t4b: [],
      t5a:
        window.hydrate.default_data.t5 ||
        getYears(2000, 2020).map((y) => ({ year: y.toString() })),
      t5b: getYears(2000, 2020).map((y) => ({ year: y.toString() })),
      t6a:
        window.hydrate.default_data.t6 ||
        Object.keys(UNCCD_CLASS_MAP).map((key) => ({ initial: key })),
      t6b: [],
      t7a:
        window.hydrate.default_data.t7 ||
        Object.keys(UNCCD_CLASS_MAP).map((key) => ({ initial: key })),
      t7b: [],
      t8: Object.keys(OPTIONS.BASELINE).map((key) => ({ id: key })),
      t9: Object.keys(OPTIONS.REPORTING).map((key) => ({ id: key })),
    };

    // The backend has only one model for these, but we want two
    // in the frontend so the user doesn't lose their values if they
    // switch between the two possible states of isUNCCDSufficient.
    // Copy the data from the backend to the correct model here.
    const tableVersion = getTableVersion(window.hydrate.data);
    const valuesForTablesByCurrentVersion = T3CustomTables.reduce(
      (accumulator, currentTableName) => {
        const tableNameByVersion = `${currentTableName}${tableVersion}`;
        const tableItemByVersion = {
          [tableNameByVersion]: window.hydrate.data[currentTableName],
        };
        const result = tableVersion
          ? {
              ...accumulator,
              ...tableItemByVersion,
            }
          : accumulator;
        return result;
      },
      {}
    );

    const values = {
      ...initialValuesForTablesByVersion,
      ...window.hydrate.data,
      ...T2ValuesForAllOptions(),
      ...valuesForTablesByCurrentVersion,
    };

    return {
      UNCCD_CLASS_MAP,
      values,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    isUNCCDSufficient() {
      return this.values.unccd_classes_sufficient === "yes";
    },
    T3CustomHeadings() {
      return (this.values.t3 || []).map(({ class_code, country_legend }) => {
        return {
          key: class_code,
          name: country_legend,
        };
      });
    },
    customHeadings() {
      return this.isUNCCDSufficient ? null : this.T3CustomHeadings;
    },
    tableVersion() {
      return getTableVersion(this.values);
    },
    so1_1_t1() {
      // Override the values here as it needs to be taken directly from values
      // instead of hydrate. This also makes everything else reactive.
      return this.values.t1;
    },
  },
  watch: {
    T3CustomHeadings() {
      this.updateCustomHeadings("t4b", this.T3CustomHeadings);
      this.updateCustomHeadings("t6b", this.T3CustomHeadings);
      this.updateCustomHeadings("t7b", this.T3CustomHeadings);
      // T5 doesn't require any updates since the rows are based on
      // years and not the table headings, which don't actually change.
    },
  },
  methods: {
    submit(save, data) {
      // The backend model only has one single field for the a/b
      // variations, so copy the correct values into the model.
      // The data here is a deep clone and safe to mutate.
      const tableVersion = getTableVersion(data);
      T3CustomTables.forEach((table) => {
        data[table] = data[table + tableVersion];
        // Cleanup
        delete data[table + "a"];
        delete data[table + "b"];
      });
      // Remove any empty row in T3
      // Aside from them not being useful information they also create empty data
      // in T5, T6, T7
      const t3 = (data.t3 || []).filter((row) => Object.keys(row).length > 0);
      let result = { ...data, ...{ t3 } };

      // Add indicators based on the year
      const t1 = (data.t1 || []).map((row) => {
        const year = Number(row.year);
        if (row.indicators.length > 0) {
          return row;
        } else if (year >= 2016) {
          return {
            ...row,
            indicators: [LC_REPORTING, LP_REPORTING, SOC_REPORTING],
          };
        } else {
          return {
            ...row,
            indicators: [LC_BASELINE, LP_BASELINE, SOC_BASELINE],
          };
        }
      });
      result = { ...result, ...{ t1 } };

      return save(result);
    },
    /**
     * Update this.values based on the specified custom headings.
     * @param {String} table table key, e.g. "t4b"
     * @param {Object[]} headings complete ordered array of new headings
     * @param {String} headings[].initial
     */
    updateCustomHeadings(table, headings) {
      // keep current table values in new const to preserve the existing data
      // ex {12: {1:2, 2:3, 12: 10, initial: 12}, 1: {1: 5, 2: 6, 12: 7, initial: 1}}
      const currentValues = {};
      (this.values[table] || []).forEach((item) => {
        currentValues[item.initial] = item;
      });
      // Add the new column and keep existing data while preserving the correct order
      // ex: for td Test1(code is 1)xTest2(code is 2) will have value 10,
      // the data for the Test1 row: {1: 3, 2: 10, initial: 1}
      const newValues = {};
      headings.forEach(({ key }) => {
        newValues[key] = currentValues[key] || { initial: key };
      });

      // order in the headings is the one from T3 table so it should dictate the order in all other tables
      this.values[table] = headings.map(({ key }) => newValues[key]);
    },
  },
};
</script>

<template>
  <P4Form v-model="values">
    <h2 v-translate class="P4-section-title">Land productivity dynamics</h2>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This section is pre-filled with default land productivity dynamics data
      derived from the Land Productivity Dynamics dataset of the Joint Research
      Centre of the European Commission. Keep the default national estimates or,
      in the event of data and capacity, replace them with national datasets.
    </p>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T1: National estimates of land productivity dynamics (in km²) within
      each land cover class for the baseline period
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T1: Affected area estimates of land productivity dynamics (in km²)
      within each land cover class for the baseline period
    </h3>

    <p v-translate class="P4-guidance">
      Report the area covered by each class of land productivity dynamics (in
      km²) for the baseline period:
    </p>
    <CommentAnchor :name="form_id + '-T1'" />
    <T1 />
    <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T2: National estimates of land productivity dynamics (in km²) within
      each land cover class for the reporting period.
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T2: Affected area estimates of land productivity dynamics (in km²)
      within each land cover class for the reporting period.
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This table will include the top four land cover changes (by area) by
      default. Add additional land conversions when deemed necessary.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Report the area covered by each class of land productivity dynamics (in
      km²) for the reporting period:
    </p>
    <CommentAnchor :name="form_id + '-T2'" />
    <T2 />
    <RestoreDefaultdata name="t2" @reloadDefaultData="reloadDefaultData" />

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T3: National estimates of land productivity dynamics for areas where
      a land conversion to a new land cover class has taken place (in km²) for
      the baseline period.
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T3: Affected area estimates of land productivity dynamics for areas
      where a land conversion to a new land cover class has taken place (in km²)
      for the baseline period.
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This table will include the top four land cover changes (by area) by
      default. Add additional land conversions when deemed necessary.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      This table should include the top four land cover changes (by area). Add
      additional land conversions when deemed necessary.
    </p>
    <CommentAnchor :name="form_id + '-T3'" />
    <T3 />
    <RestoreDefaultdata name="t3" @reloadDefaultData="reloadDefaultData" />

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T4: National estimates of land productivity dynamics for areas where
      a land conversion to a new land cover class has taken place (in km²) for
      the reporting period.
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T4: Affected area estimates of land productivity dynamics for areas
      where a land conversion to a new land cover class has taken place (in km²)
      for the reporting period.
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This table will include the top four land cover changes (by area) by
      default. Add additional land conversions when deemed necessary.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      This table should include the top four land cover changes (by area). Add
      additional land conversions when deemed necessary.
    </p>
    <CommentAnchor :name="form_id + '-T4'" />
    <T4 />
    <RestoreDefaultdata name="t4" @reloadDefaultData="reloadDefaultData" />

    <h2 v-translate class="P4-section-title">Land Productivity degradation</h2>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      This section is pre-filled with default land productivity degradation
      estimates for the baseline and reporting periods. Keep the default data or
      replace it with national datasets.
    </p>

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T5: National estimates of land productivity degradation in the
      baseline period
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T5: Affected area estimates of land productivity degradation in the
      baseline period
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded or improved and stable due
      to land productivity in the baseline period, reported as the total area of
      degraded land productivity in km² and the area of degraded land
      productivity as a proportion (%) of the total land area.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded or improved and stable due
      to land productivity in the baseline period, reported as the total area of
      degraded land productivity in km² and the area of degraded land
      productivity as a proportion (%) of the total affected area.
    </p>
    <CommentAnchor :name="form_id + '-T5'" />
    <T5 />
    <RestoreDefaultdata name="t5" @reloadDefaultData="reloadDefaultData" />

    <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T6: National estimates of land productivity degradation in the
      reporting period
    </h3>

    <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
      SO1-2.T6: Affected area estimates of land productivity degradation in the
      reporting period
    </h3>
    <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded or improved and stable due
      to land productivity in the reporting period, reported as the total area
      of degraded land productivity in km² and the area of degraded land
      productivity as a proportion (%) of the total land area.
    </p>
    <p v-if="isAffectedAreas" v-translate class="P4-guidance">
      Quantitative summary of land that is degraded or improved and stable due
      to land productivity in the reporting period, reported as the total area
      of degraded land productivity in km² and the area of degraded land
      productivity as a proportion (%) of the total affected area.
    </p>
    <CommentAnchor :name="form_id + '-T6'" />
    <T6 />
    <RestoreDefaultdata name="t6" @reloadDefaultData="reloadDefaultData" />

    <h2 v-translate class="P4-section-title">General comments</h2>
    <p v-translate class="P4-guidance">
      Provide any additional comments you deem relevant.
    </p>
    <div>
      <P4Input v-model="values.so1_2_general_comments" textarea standalone />
    </div>
  </P4Form>
</template>

<script>
import { UNCCD_CLASS_MAP } from "../../../lib/constants.js";
import OPTIONS from "./OPTIONS.js";

import T1 from "./T1.vue";
import T2 from "./T2.vue";
import T3 from "./T3.vue";
import T4 from "./T4.vue";
import T5 from "./T5.vue";
import T6 from "./T6.vue";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

export default {
  components: {
    T1,
    T2,
    T3,
    T4,
    T5,
    T6,
  },
  mixins: [restoreDefaultData],
  data() {
    const values = {
      t1: Object.keys(UNCCD_CLASS_MAP).map((key) => ({ initial: key })),
      t2: Object.keys(UNCCD_CLASS_MAP).map((key) => ({ initial: key })),
      t5: Object.keys(OPTIONS.BASELINE).map((key) => ({ id: key })),
      t6: Object.keys(OPTIONS.REPORTING).map((key) => ({ id: key })),
      ...window.hydrate.data,
    };
    return {
      UNCCD_CLASS_MAP,
      values: values,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
    };
  },
};
</script>

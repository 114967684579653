<template>
  <section>
    <P4Form v-model="values">
      <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
        Please note that although “affected areas” is mentioned in the indicator
        name, estimates specific to affected areas should be reported on in the
        reporting forms provided for affected areas.
      </p>
      <h2 v-translate class="P4-section-title">Relevant metric</h2>
      <p v-translate class="P4-guidance">
        This indicator estimates the well-being of populations in monetary
        terms. Choose one of the following metrics to report.
      </p>

      <h3 v-translate class="P4-caption">
        Choose the metric that is relevant to your country:
      </h3>
      <span>
        <P4Input
          v-model="values.relevant_metric"
          radio
          :options="OPTIONS.relevant_metric"
        />
      </span>

      <section :class="{ hidden: !proportionOfPopulation }">
        <p v-translate class="P4-question">
          Proportion of population below the international poverty line
        </p>
        <p v-translate class="P4-guidance">
          This is generally considered to be relevant to less developed
          countries, where extreme poverty and destitution are core development
          challenges. It is currently defined as percentage of the population
          living on less than USD 1.90 a day at 2011 international prices.
        </p>

        <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
          SO2-1.T1: National estimates of the proportion of population below the
          international poverty line
        </h3>

        <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
          SO2-1.T1: Affected area estimates of the proportion of population
          below the international poverty line
        </h3>

        <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
          Report national annual values of the proportion of the population
          living below the international poverty line. As the proportion of
          population below the international poverty line by sex, age,
          employment status and geographical location (urban/rural) is also a
          Sustainable Development Goal (SDG) indicator (SDG indicator 1.1.1),
          country data has been pre-filled directly from the SDG database. Keep
          the default estimates or replace them with national datasets.
        </p>
        <p v-if="isAffectedAreas" v-translate class="P4-guidance">
          Report affected area annual values of the proportion of the population
          living below the international poverty line.
        </p>
        <CommentAnchor :name="form_id + '-T1'" />
        <T1 />
        <RestoreDefaultdata name="t1" @reloadDefaultData="reloadDefaultData" />
      </section>

      <section :class="{ hidden: !incomeInequality }">
        <p v-translate class="P4-question">Income inequality (Gini Index)</p>
        <p v-translate class="P4-guidance">
          The Gini Index is employed to provide an estimate of ‘Income
          inequality’. It estimates the extent to which the distribution of
          income (or in some cases, consumption expenditure) among individuals
          or households within an economy deviates from perfectly equal
          distribution. A Gini index of zero represents perfect equality and 100
          represents perfect inequality.
        </p>

        <h3 v-if="!isAffectedAreas" v-translate class="P4-caption">
          SO2-1.T2: National estimates of income inequality (Gini index)
        </h3>

        <h3 v-if="isAffectedAreas" v-translate class="P4-caption">
          SO2-1.T2: Affected area estimates of income inequality (Gini index)
        </h3>

        <p v-if="!isAffectedAreas" v-translate class="P4-guidance">
          Report national annual values of income inequality. This table is
          pre-filled with the Gini Index. This metric is collected annually by
          the World Bank. Keep the default estimates or replace them with
          national datasets.
        </p>
        <p v-if="isAffectedAreas" v-translate class="P4-guidance">
          Report affected area annual values of income inequality.
        </p>
        <CommentAnchor :name="form_id + '-T2'" />
        <T2 />
        <RestoreDefaultdata name="t2" @reloadDefaultData="reloadDefaultData" />
      </section>

      <section>
        <h2 v-translate class="P4-section-title">Qualitative assessment</h2>

        <h3 v-translate class="P4-caption">
          SO2-1.T3: Interpretation of the indicator
        </h3>
        <p v-translate class="P4-guidance">
          Based on the quantitative data reported in the table above, select the
          dominant change in the indicator and indicate which direct and/or
          indirect drivers are presumably behind the observed changes using the
          comment box in the table below.
        </p>
        <CommentAnchor :name="form_id + '-T3'" />
        <T3 />
      </section>

      <h3 v-translate class="P4-section-title">General comments</h3>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input v-model="values.so2_1_general_comments" textarea standalone />
      </div>
    </P4Form>
  </section>
</template>

<script>
import { CHANGES_IN_THE_INDICATOR } from "../../../lib/constants.js";
import OPTIONS from "./OPTIONS.js";
import { allOptions } from "../../../lib/translations.js";
import getYearsBetween from "../../../lib/getYears.js";
import T1 from "./T1.vue";
import T2 from "./T2.vue";
import T3 from "./T3.vue";
import restoreDefaultData from "@/lib/mixins/restoreDefaultData";

function valuesForAllOptions() {
  const keys = Object.keys(CHANGES_IN_THE_INDICATOR);

  return {
    t3: keys.map((change_in_the_indicator) => ({ change_in_the_indicator })),
  };
}

export default {
  components: {
    T1,
    T2,
    T3,
  },
  mixins: [restoreDefaultData],
  data() {
    const values = {
      t1: getYearsBetween(2000, 2021).map((year) => ({ year })),
      t2: getYearsBetween(2000, 2021).map((year) => ({ year })),
      ...window.hydrate.data,
    };

    if (allOptions()) {
      Object.assign(values, valuesForAllOptions());
    }

    return {
      values,
      isAffectedAreas: window.hydrate.is_aa_form,
      form_id: window.hydrate.id,
    };
  },
  computed: {
    proportionOfPopulation() {
      return this.values["relevant_metric"] === "proportion_of_population";
    },
    incomeInequality() {
      return this.values["relevant_metric"] === "income_inequality";
    },
    OPTIONS: () => OPTIONS,
  },
};
</script>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t1"
      :column-count="4"
      :columns="[
        $gettext('Year'),
        $gettext('Urban (%)'),
        $gettext('Rural (%)'),
        $gettext('Total (%)'),
      ]"
    >
      <template #cells="{ rowId, rowIndex, value }">
        <td class="P4-cell">
          {{ value.year }}
        </td>

        <td
          v-for="(column, index) in ['urban', 'rural', 'total']"
          :key="index"
          class="P4-cell-right"
        >
          <P4Input
            :id="rowId + '-' + column"
            v-model="value[column]"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, column],
              value[column]
            )"
            placeholder="No data"
            percent
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      $gettext,
    };
  },
};
</script>

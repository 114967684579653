import { geojson } from "../../lib/maps/layers";
import { gettextLazy as _ } from "../../lib/translations";

const Malvinas = {
  type: "Feature",
  properties: {
    name: "Malvinas",
  },
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [-61.34519195599995, -52.90058135999993],
        [-57.712486267999964, -52.90058135999993],
        [-57.712486267999964, -51.01494598299996],
        [-61.34519195599995, -51.01494598299996],
        [-61.34519195599995, -52.90058135999993],
      ],
    ],
  },
};

const Jammu = {
  type: "Feature",
  properties: {
    name: "Jammu and Kashmir",
  },
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [73.75050700000008, 32.79303100000004],
        [77.01045300000004, 32.79303100000004],
        [77.01045300000004, 35.00879900000007],
        [73.75050700000008, 35.00879900000007],
        [73.75050700000008, 32.79303100000004],
      ],
    ],
  },
};

const abyeiSudan = {
  type: "Feature",
  properties: {
    name: "Abyei, Sudan and South Sudan",
  },
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [24.22335924500004, 8.642150832000027],
        [34.11324552100007, 8.642150832000027],
        [34.11324552100007, 12.237671853000052],
        [24.22335924500004, 12.237671853000052],
        [24.22335924500004, 8.642150832000027],
      ],
    ],
  },
};

const geomMalvinas = geojson().readFeature(Malvinas).getGeometry();
const malvinasDisclaimerText = _(
  "A dispute exists between the Governments of Argentina and the United Kingdom of Great Britain" +
    " and Northern Ireland concerning sovereignty over the Falkland Islands (Malvinas)."
);

const geomJammu = geojson().readFeature(Jammu).getGeometry();
const jammuDisclaimerText = _(
  "Dotted line represents approximately the Line of Control in Jammu and Kashmir agreed upon by " +
    "India and Pakistan. The final status of Jammu and Kashmir has not yet been agreed upon by the " +
    "parties."
);

const geomAbyei = geojson().readFeature(abyeiSudan).getGeometry();
const abyeiDisclaimerText = _(
  "Final boundary between the Republic of Sudan and the Republic of South Sudan has not yet been " +
    "determined. Final status of the Abyei area is not yet determined."
);

export default {
  malvinas: {
    geometry: geomMalvinas,
    disclaimerText: malvinasDisclaimerText,
  },
  jammu: {
    geometry: geomJammu,
    disclaimerText: jammuDisclaimerText,
  },
  abyei: {
    geometry: geomAbyei,
    disclaimerText: abyeiDisclaimerText,
  },
};

export const specialAreasCredits = {
  SDN: abyeiDisclaimerText,
  SSD: abyeiDisclaimerText,
  ARG: malvinasDisclaimerText,
  PAK: jammuDisclaimerText,
  IND: jammuDisclaimerText,
};

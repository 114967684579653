<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t2"
      :column-count="3"
      :columns="['', $gettext('Change in the indicator'), $gettext('Comments')]"
      :label-for-new="$gettext('+ Add New')"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-report_level'"
            v-model="value.report_level"
            :options="
              isAffectedAreas
                ? OPTIONS.T2.report_level_affected_areas
                : OPTIONS.T2.report_level
            "
          />
        </td>

        <td class="P4-cell">
          <P4Input
            v-if="value.report_level === 'so3_3_country_dvi'"
            :id="rowId + '-qualitative_assessment'"
            v-model="value.qualitative_assessment"
            :options="OPTIONS.T2.qualitative_assessment"
          />
          <span v-else v-translate class="P4-guidance italic">
            Available only for SO3-3 Country DVI
          </span>
        </td>
        <td class="P4-cell">
          <P4Input :id="rowId + '-comments'" v-model="value.comments" textarea/>
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  data() {
    return {
      $gettext,
      isAffectedAreas: window.hydrate.is_aa_form,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
  },
};
</script>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="ia_t1"
      :column-count="7"
      :columns="Object.values(OPTIONS.AI_T1)"
      :label-for-new="$gettext('+ Add New')"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-relevant_target'"
            v-model="value.relevant_target"
            :options="relevantTargets"
          />
        </td>
        <td class="P4-cell">
          <ul>
            <li>
              <P4Input
                :id="rowId + '-implemeneted_action'"
                v-model="value.implemented_action"
                :options="SO1_4_OPTIONS.T4.options.implemented_action"
              />
            </li>
            <li v-if="value.implemented_action === 'other'">
              <P4Input
                :id="rowId + '-other_implemented_action'"
                v-model="value['other_implemented_action']"
              />
            </li>
          </ul>
        </td>

        <td class="P4-cell">
          <P4Input :id="rowId + '-location'" v-model="value.location" />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-date_action_started'"
            v-model="value.date_action_started"
            date
          />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-extent_of_action'"
            v-model="value.extent_of_action"
            :disabled="!!value.polygon && value.polytype === 'Polygon'"
            numeric
          />
        </td>
        <td class="P4-cell">
          <P4DisplayValue
            :id="rowId + '-total_area_implemented'"
            :value="value.total_area_implemented"
          />
        </td>
        <polygon-cell :value="value" table-id="ia_t1" />
      </template>
      <template #table_footer="{ values }">
        <tr>
          <td colspan="5"></td>
          <td class="P4-cell">
            <p v-translate>
              Sum of all areas relevant to actions under the same target
            </p>
            <table
              v-for="(key, value) in calculate_total_area_implemented_so_far(
                values
              )"
              :key="value"
            >
              <tr>
                <td>{{ value }}:</td>&nbsp;
                <td>
                  <P4DisplayValue :value="key" />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import SO1_4_OPTIONS from "../4/OPTIONS.js";
import {
  calculateTotal,
  calculateTotalAreaImplemented,
} from "../../../lib/calculateTotal.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import PolygonCell from "@/forms/common/PolygonCell";

export default {
  components: { PolygonCell },
  props: {
    relevantTargets: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      sum_of_all_areas: "",
      $gettext,
    };
  },

  computed: {
    OPTIONS: () => OPTIONS,
    SO1_4_OPTIONS: () => SO1_4_OPTIONS,
    calculate_total_areas_relevant() {
      return function (values) {
        return calculateTotal(values, "total_area_implemented");
      };
    },
    calculate_total_area_implemented_so_far() {
      return function (values) {
        return calculateTotalAreaImplemented(values, this.relevantTargets);
      };
    },
  },
};
</script>

import styles from "./styles.js";
import { GeoJSON, WMTSCapabilities } from "ol/format";
import { Tile, Vector as VectorL } from "ol/layer";
import { TileWMS, Vector as VectorS, WMTS, XYZ } from "ol/source";
import { optionsFromCapabilities } from "ol/source/WMTS";
import { BORDER_CODE } from "@/lib/constants";
import { noOpinionDisclaimer } from "@/map/data/disclaimers";
import Crop from "ol-ext/filter/Crop";

function interpolate(template, vars) {
  return template.replace(/{(\w+)}/g, (_, name) => vars[name]);
}

export function geojson(displayProjection = "EPSG:3857") {
  return new GeoJSON({
    dataProjection: "EPSG:4326",
    featureProjection: displayProjection,
  });
}

export function createMapserverRasterLayer(url, { title }) {
  const mapLayer = new Tile({
    title,
    source: new TileWMS({
      url,
      params: { LAYERS: "p4layer" },
      serverType: "mapserver",
      transition: 500,
    }),
    visible: false,
  });
  mapLayer.setZIndex(1);
  return mapLayer;
}

export function createBackgroundLayer(spec) {
  const { type, id, title } = spec;

  switch (type) {
    case "clearmap":
      return new Tile({
        title,
        source: new XYZ({
          url:
            `https://geoservices.un.org/arcgis/rest/services/` +
            `${id}/MapServer/tile/{z}/{y}/{x}`,
          attributions: noOpinionDisclaimer,
        }),
        visible: false,
      });

    case "s2cloudless": {
      const s2CloudlessLayer = new Tile();
      let wmtsParser = new WMTSCapabilities();
      const { attribution } = spec;

      fetch("https://tiles.maps.eox.at/wmts/1.0.0/WMTSCapabilities.xml")
        .then(function (response) {
          return response.text();
        })
        .then(function (text) {
          let result = wmtsParser.read(text);
          let options = optionsFromCapabilities(result, {
            layer: "s2cloudless-2020_3857",
            matrixSet: "EPSG:3857",
          });

          s2CloudlessLayer.setSource(new WMTS(options));
          s2CloudlessLayer.setOpacity(1);

          s2CloudlessLayer.getSource().setAttributions(`
            <p>${attribution}</p>            
          `);
          s2CloudlessLayer.setZIndex(0);
        });

      return s2CloudlessLayer;
    }

    case "mapbox": {
      const token = window.hydrate.map_tokens.mapbox;
      const { url, attribution } = spec;
      // TODO retrieve attribution from https://docs.mapbox.com/api/maps/mapbox-tiling-service/#retrieve-tilejson-metadata

      return new Tile({
        title,
        source: new XYZ({
          tileUrlFunction: ([z, y, x]) =>
            interpolate(url, { z, y, x }) + `?access_token=${token}`,
          attributions: `
            <p>${attribution}</p>
          `,
        }),
        visible: false,
      });
    }

    default:
      throw new Error(`Unknown background layer type ${type}`);
  }
}

export function createVectorLayer(features, code, style = null) {
  const vectorSource = new VectorS();
  vectorSource.addFeatures(features);

  return new VectorL({
    source: vectorSource,
    style: style || (code === BORDER_CODE ? styles.borders() : styles.vector()),
    visible: false,
    zIndex: 999,
  });
}

export function createCropFilter(features) {
  const crop = new Crop({ feature: features[0], inner: false, wrapX: true });
  crop.set("active", true);
  return crop;
}

import Page_1 from "./1/Page.vue";
import Form_2 from "./2/Form.vue";
import Form_3 from "./3/Form.vue";
import Form_v from "./v/Form.vue";

export default {
  1: Page_1,
  2: Form_2,
  3: Form_3,
  v: Form_v,
};

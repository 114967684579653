<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t4"
      :column-count="8"
      :columns="
        isAffectedAreas
          ? Object.values(OPTIONS.T4.headings_affected_areas)
          : Object.values(OPTIONS.T4.headings)
      "
      :label-for-new="$gettext('+ Add New')"
      :row-factory="rowFactory"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-hotspots'"
            v-model="value.hotspots"
            textarea
          />
        </td>
        <td class="P4-cell">
          <P4Input :id="rowId + '-location'" v-model="value.location" />
        </td>
        <td class="P4-cell">
          <P4DisplayValue
            v-if="!!value.polygon && value.polytype === 'Polygon'"
            :value="value.area"
          />
          <P4Input v-else :id="rowId + '-area'" v-model="value.area" numeric />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-assessment_process'"
            v-model="value.assessment_process"
            :options="OPTIONS.T4.options.assessment_process"
          />
        </td>
        <td class="P4-cell text-center">
          <RankingSelect
            v-model="value['direct_driver_of_land_degradation_hotspots']"
            :select-key="rowId + '-direct_driver_of_land_degradation_hotspots-'"
            :select-options="OPTIONS.T4.options.drivers_direct"
          />
        </td>
        <td class="P4-cell">
          <ActionsTakenCheckboxes
            v-model="value.action_taken_to_redress_degradation"
            :options="OPTIONS.T4.options.action_taken"
            :row-id="rowId"
          />
        </td>
        <td class="P4-cell">
          <button
            :id="rowId + '-remediating-actions-button'"
            v-translate
            type="button"
            class="rounded-empty-btn-sm m-2 print-hide"
            @click="
              remediatingActionsModalData = value.remediating_actions;
              isRemediatingModalVisible = true;
            "
          >
            Choose remediating actions
          </button>

          <div
            v-for="(actionValue, action) in value.remediating_actions"
            :key="action"
            class="mt-4"
          >
            <ul v-if="actionValue === true" class="P4-ul">
              <li>
                {{ OPTIONS.T4.options.remediating_actions[action].label }}
                <ul
                  v-for="(subActionValue, subAction) in value
                    .remediating_actions['items_for_' + action]"
                  :key="subAction"
                  class="P4-ul italic"
                >
                  <li v-if="subActionValue === true">
                    {{
                      OPTIONS.T4.options.remediating_actions[action].options[
                        subAction
                      ]
                    }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </td>
        <polygon-cell :value="value" table-id="t4" />
      </template>
      <template #table_footer="{ values }">
        <tr>
          <th v-translate class="P4-cell">Total no. of hotspots</th>
          <td colspan="8" class="P4-cell">
            <P4DisplayValue :value="calculate_total_no_of_hotspots(values)" />
          </td>
        </tr>
        <tr>
          <th v-translate class="P4-cell">Total hotspot area</th>
          <td colspan="4" class="P4-cell">
            <P4DisplayValue :value="calculate_total_hotspot_area(values)" />
          </td>
        </tr>
      </template>
    </FormulateInput>

    <InputDetailModal
      v-if="isRemediatingModalVisible"
      id="remediating-actions-modal"
      @close="
        isRemediatingModalVisible = false;
        remediatingActionsModalData = null;
      "
    >
      <template #header>
        <p v-translate>Remediating action(s)</p>
      </template>
      <template #body>
        <ul>
          <li
            v-for="({ label, options }, action) in OPTIONS.T4.options
              .remediating_actions"
            :key="action"
          >
            <P4Input
              :id="action"
              v-model="remediatingActionsModalData[action]"
              :label="label"
              checkbox
            />
            <ul v-if="remediatingActionsModalData[action]">
              <li
                v-for="(subLabel, subAction) in options"
                :key="subAction"
                class="w-full text-gray-500 text-base pl-6"
              >
                <P4Input
                  :id="action + '-' + subAction"
                  v-model="
                    remediatingActionsModalData['items_for_' + action][
                      subAction
                    ]
                  "
                  checkbox
                  :label="subLabel"
                />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </InputDetailModal>
  </section>
</template>

<script>
import PolygonCell from "@/forms/common/PolygonCell";
import ActionsTakenCheckboxes from "@/lib/forms/ActionsTakenCheckboxes.vue";
import RankingSelect from "@/lib/forms/RankingSelect";
import { calculateTotal } from "@/lib/calculateTotal";
import InputDetailModal from "@/lib/forms/InputDetailModal.vue";
import { gettextLazy as $gettext } from "@/lib/translations.js";
import OPTIONS from "./OPTIONS.js";

function rowFactory() {
  const action_taken_to_redress_degradation = {};
  const remediating_actions = {};
  const direct_driver_of_land_degradation_hotspots = {};

  for (const action of Object.keys(OPTIONS.T4.options.remediating_actions)) {
    remediating_actions[`items_for_${action}`] = {};
  }

  return {
    remediating_actions,
    action_taken_to_redress_degradation,
    direct_driver_of_land_degradation_hotspots,
  };
}

export default {
  components: {
    ActionsTakenCheckboxes,
    PolygonCell,
    RankingSelect,
    InputDetailModal,
  },
  data() {
    return {
      $gettext,
      isAffectedAreas: window.hydrate.is_aa_form,
      isRemediatingModalVisible: false,
      remediatingActionsModalData: null,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    rowFactory,
    calculate_total_no_of_hotspots() {
      return function (values) {
        return values.length;
      };
    },
    calculate_total_hotspot_area() {
      return function (values) {
        return calculateTotal(values, "area");
      };
    },
  },
};
</script>

<template>
  <section>
    <h3 id="edit-panel-layer-title">{{ layer.title }} {{ layerFeatures }}</h3>

    <ul class="flex">
      <li v-if="isDraftRevision && !readonlyLayer" class="mx-2">
        <Save :get-data="bindGetData()" />
      </li>

      <li class="mx-2">
        <button v-if="notBorderLayer" @click="download()">
          <i class="fas fa-download"></i>
          <span v-translate>Download</span>
        </button>
      </li>
    </ul>

    <ul v-if="isDraftRevision" class="flex">
      <li v-if="!isFalsePositivesNegatives && notBorderLayer" class="mx-2">
        <button @click="beginAdd('Point')">
          <i class="fas fa-map-marker-alt"></i>
          <span v-translate>Point</span>
        </button>
      </li>

      <li v-if="!readonlyLayer" class="mx-2">
        <button @click="beginAdd('Polygon')">
          <i class="fas fa-draw-polygon"></i>
          <span v-translate>Polygon</span>
        </button>
      </li>
    </ul>

    <div
      v-if="showChart"
      class="container mt-5 text-center w-max cursor-pointer"
    >
      <p v-translate class="font-bold text-xs">
        False positives/negatives <br />
        reanalysis impact
      </p>
      <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
    </div>

    <p v-if="featureTarget">{{ featureTarget }}</p>
    <p v-if="featureId">{{ featureId }}</p>
    <a v-if="attributes && notBorderLayer" :href="polyUrl">
      <span v-translate>Go to feature</span>
    </a>
    <EditAttributes
      v-if="attributes"
      :key="formGeneration"
      :value="attributes"
      :schema="layer.schema"
      :is-false-positives-negatives-with-te-origin="
        isFalsePositivesNegativesWithTeOrigin
      "
      @input="updateAttributes"
    />

    <button
      v-if="attributes && isDraftRevision && !readonlyLayer"
      @click="deleteFeature()"
    >
      <i class="fas fa-trash"></i>
      <span v-translate>Delete feature</span>
    </button>
  </section>
</template>

<script>
import EditAttributes from "./EditAttributes.vue";
import Save from "./Save.vue";
import styles from "../lib/maps/styles.js";
import { geojson } from "../lib/maps/layers.js";
import downloadAsFile from "../lib/downloadAsFile.js";
import { Draw, Modify, Select } from "ol/interaction";
import {
  BORDER_CODE,
  FALSE_POSITIVES_LAYER,
  chartRasters,
} from "../lib/constants";
import randomId from "@/lib/randomId.js";
import { gettextLazy as $gettext } from "../lib/translations.js";

function copyAttributesFromFeature(schema, feature) {
  const attributes = {};
  for (const field of schema) {
    attributes[field.name] = feature.get(field.name);
  }
  return attributes;
}

function copyAttributesToFeature(schema, feature, attributes) {
  for (const field of schema) {
    feature.set(field.name, attributes[field.name]);
  }
}

export default {
  components: {
    EditAttributes,
    Save,
  },

  props: {
    layer: {
      type: Object,
      required: true,
    },
    olMap: {
      type: Object,
      required: true,
    },
    isFalsePositivesNegativesWithTeOrigin: {
      type: Boolean,
      default: false,
    },
    raster: {
      type: Object,
      default: null,
    },
  },

  data() {
    const chartOptions = {
      width: 220,
      height: 150,
      backgroundColor: "transparent",
      legend: { position: "none" },
      bar: {
        groupWidth: "80%",
      },
      vAxis: {
        maxValue: 100,
        title: "Percent (%) Coverage",
      },
    };
    return {
      attributes: null,
      formGeneration: 1,
      feature: null,
      featureId: null,
      featureTarget: "",
      $gettext,
      countryISO3: window.hydrate.iso3,
      chartData: null,
      chartOptions,
    };
  },

  computed: {
    readonlyLayer() {
      return (
        this.isFalsePositivesNegativesWithTeOrigin &&
        this.isFalsePositivesNegatives
      );
    },
    polyUrl() {
      let url = new URL(window.hydrate.urls[this.layer.code], window.location);
      url.hash = "polygon-" + this.featureId;
      return url.toString();
    },
    layerSource() {
      return this.layer.olLayer.getSource();
    },
    layerFeatures() {
      if (this.layer.olLayer.getSource().getFeatures().length) {
        return "";
      }
      return $gettext("(no features)");
    },
    isDraftRevision() {
      return window.hydrate.is_draft;
    },
    notBorderLayer() {
      return this.layer.code !== BORDER_CODE;
    },
    isFalsePositivesNegatives() {
      return this.layer.code === FALSE_POSITIVES_LAYER;
    },
    rasterHasChart() {
      return this.raster && chartRasters.includes(this.raster?.code);
    },
    showChart() {
      // There are 3 conditions for the chart to be displayed in the Spatial Viewer:
      //    1. The selected Vector Layer must be the False positives/negatives layer
      //    2. The selected Reporting Data must be included in the chartRasters variable (sub-indicator layers of interest)
      //    3. A feature must be selected in the Spatial Viewer
      return (
        this.isFalsePositivesNegatives && this.rasterHasChart && this.feature
      );
    },
  },

  watch: {
    layer() {
      this.selectFeature(null);
      this.beginSelect();
    },
    raster(newRaster) {
      // Update chart when the selected raster is changed
      if (this.showChart)
        this.olMap.once("rendercomplete", () =>
          this.computeImpactChart(newRaster.data)
        );
    },
  },

  mounted() {
    this.interactions = {};
    this.beginSelect();
  },

  methods: {
    getData() {
      const features = this.layerSource.getFeatures();
      const options = {
        decimals: 4,
      };

      return {
        code: this.layer.code,
        content: JSON.parse(geojson().writeFeatures(features, options)),
      };
    },

    bindGetData() {
      return () => this.getData();
    },

    computeImpactChart(raster) {
      const map = this.olMap;
      const geom = this.feature.getGeometry();
      const size = map.getSize();
      const pixels = [];

      // Get pixels inside a polygon (https://stackoverflow.com/a/54179779/9233528)
      if (geom.intersectsExtent(map.getView().calculateExtent(size))) {
        for (let i = 0; i < size[0]; i++) {
          for (let j = 0; j < size[1]; j++) {
            const coordinate = map.getCoordinateFromPixel([i, j]);
            if (geom.intersectsCoordinate(coordinate)) {
              pixels.push(map.getPixelFromCoordinate(coordinate));
            }
          }
        }
      }
      const countPixels = {
        stable: 0,
        improvement: 0,
        degradation: 0,
        nodata: 0,
      };
      const pixelClassification = {
        stable: "[255,255,224]",
        improvement: "[0,101,0]",
        degradation: "[155,39,121]",
        nodata: "[0,0,0]",
      };

      pixels.forEach((pixel) => {
        const pixelData = raster.getData(pixel) ? raster.getData(pixel) : [];
        const rgb = JSON.stringify(Array.from(pixelData)?.slice(0, 3));
        if (rgb === pixelClassification.stable) {
          countPixels.stable += 1;
        } else if (rgb === pixelClassification.improvement) {
          countPixels.improvement += 1;
        } else if (rgb === pixelClassification.degradation) {
          countPixels.degradation += 1;
        } else if (rgb === pixelClassification.nodata) {
          countPixels.nodata += 1;
        }
      });

      const sum = Object.values(countPixels).reduce((a, b) => a + b);
      this.chartData = [
        ["Layer", "Percent", { role: "style" }],
        ["Degradation", (countPixels.degradation * 100) / sum, "#9b2779"],
        ["Stable", (countPixels.stable * 100) / sum, "gold"],
        ["Improvement", (countPixels.improvement * 100) / sum, "#006500"],
        ["No data", (countPixels.nodata * 100) / sum, "#000000"],
      ];
    },

    selectFeature(feature) {
      this.formGeneration += 1;

      const { modify, select } = this.interactions;
      if (modify) {
        this.olMap.removeInteraction(modify);
        delete this.interactions.modify;
      }

      this.feature = feature;
      this.featureId = feature && feature.getId();
      this.featureTarget = feature && feature.getProperties()["target"];

      if (this.showChart) {
        this.olMap.once("rendercomplete", () => {
          this.computeImpactChart(this.raster.data);
        });
      }

      if (feature) {
        this.attributes = copyAttributesFromFeature(this.layer.schema, feature);

        const modify = new Modify({
          features: select.getFeatures(),
        });
        this.addInteraction("modify", modify);
      } else {
        this.attributes = null;
      }
    },

    updateAttributes(values) {
      if (this.feature) {
        copyAttributesToFeature(this.layer.schema, this.feature, values);
        this.attributes = values;
      }
    },

    resetState() {
      for (const interaction of Object.values(this.interactions)) {
        this.olMap.removeInteraction(interaction);
      }
      this.interactions = {};
      this.selectFeature(null);
    },

    addInteraction(name, interaction) {
      this.interactions[name] = interaction;
      this.olMap.addInteraction(interaction);
    },

    beginSelect(feature) {
      this.resetState();

      const select = new Select({
        layers: [this.layer.olLayer],
        style: styles.select(),
      });
      select.on("select", ({ selected }) => {
        this.selectFeature(selected[0]);
      });
      this.addInteraction("select", select);

      if (feature) {
        setTimeout(() => {
          select.getFeatures().push(feature);
          this.selectFeature(feature);
        });
      } else {
        const url = new URL(window.location);
        const featureId = url.searchParams.get("featureId");
        if (featureId) {
          const feature_param = this.layerSource.getFeatureById(featureId);
          if (feature_param) {
            select.getFeatures().push(feature_param);
            this.selectFeature(feature_param);
          }
        }
      }
    },

    beginAdd(type) {
      this.resetState();

      const source = this.layerSource;
      const draw = new Draw({ source, type });
      draw.on("drawend", ({ feature }) => {
        feature.setId(randomId());
        setTimeout(() => {
          this.beginSelect(feature);
        });
      });
      this.addInteraction("draw", draw);
    },

    deleteFeature() {
      const feature = this.feature;
      if (feature) {
        this.selectFeature(null);
        this.layerSource.removeFeature(feature);
      }
    },

    download() {
      const { code, content } = this.getData();
      downloadAsFile(
        `${code}_${this.countryISO3}.geojson`,
        JSON.stringify(content, null, 2)
      );
    },
  },
};
</script>

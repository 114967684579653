var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t1","column-count":2,"columns":[
      _vm.$gettext('Year'),
      _vm.$gettext(
        'Proportion of population below international poverty line (%)'
      ),
    ]},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('td',{staticClass:"P4-cell"},[_c('P4DisplayValue',{attrs:{"value":value.year}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-proportion_of_population',"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t1', rowIndex, 'proportion_of_population'],
            value.proportion_of_population
          ),"placeholder":"No data","percent":"","validate":"roundTo2"},model:{value:(value.proportion_of_population),callback:function ($$v) {_vm.$set(value, "proportion_of_population", $$v)},expression:"value.proportion_of_population"}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t2a"
      :allow-row-removal="allowRowRemoval"
      :label-for-new="$gettext('+ Other transfers')"
      :column-count="4"
      :columns="[
        '',
        $gettext('Year'),
        $gettext('Amounts'),
        $gettext('Additional Information'),
      ]"
    >
      <template #cells="{ rowId, value }">
        <th
          v-if="
            OPTIONS.T2a.headings[value.id] &&
            OPTIONS.T2a.headings[value.id].is_heading
          "
          class="P4-cell text-left"
        >
          {{ OPTIONS.T2a.headings[value.id].name }}
        </th>

        <td
          v-else-if="
            OPTIONS.T2a.headings[value.id] &&
            !OPTIONS.T2a.headings[value.id].is_heading
          "
          class="P4-cell text-left"
        >
          {{ OPTIONS.T2a.headings[value.id].name }}
        </td>

        <td v-else class="P4-cell text-left">
          <P4Input :id="rowId + '-id'" v-model="value.id" />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-year'"
            v-model="value['year']"
            :validate="[['year', 1990, 2022]]"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-amounts'"
            v-model="value['amounts']"
            numeric
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-additional_information'"
            v-model="value.additional_information"
            placeholder="NA"
            textarea
          />
        </td>
      </template>

      <template #table_footer="{ values }">
        <tr>
          <th v-translate class="P4-cell">
            Total expenditures / total per year
          </th>
          <td colspan="4" class="P4-cell">
            <P4Input
              :id="'total_expenditures'"
              v-model="values.total_expenditures"
              numeric
            />
          </td>
        </tr>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    frozenRowIds() {
      return new Set(Object.keys(this.OPTIONS.T2a.headings));
    },
  },
  methods: {
    allowRowRemoval(item) {
      return !this.frozenRowIds.has(item.id);
    },
  },
};
</script>

<template>
  <section>
    <h2 v-translate class="print-hide">(Optional)</h2>
    <P4Form v-model="values">
      <h2 v-translate class="P4-section-title">
        SO5-5.1: Planned provision and mobilization of domestic public and
        private resources
      </h2>
      <h3 v-translate class="P4-caption">
        Please provide information relevant to the planned provision and
        mobilization of domestic resources for the implementation of the
        Convention, including information relevant to indicator SO5-2, as well
        as information on projected levels of public financial resources, target
        sectors and planned domestic policies.
      </h3>
      <p v-translate class="P4-guidance">
        Description may include information on national circumstances and
        institutional arrangements relevant to the provision of ex-ante
        information on domestic resources.
      </p>
      <P4Input
        id="so5_5_domestic_resources"
        v-model="values.so5_5_domestic_resources"
        textarea
      />

      <h2 v-translate class="P4-section-title">
        SO5-5.2: Planned provision and mobilization of international public and
        private resources
      </h2>
      <h3 v-translate class="P4-caption">
        Please provide information relevant to the planned provision and
        mobilization of international resources for the implementation of the
        Convention, including information on projected levels of public
        financial resources and support to capacity building and transfer of
        technology, target regions or countries, and planned programmes,
        policies and priorities.
      </h3>
      <p v-translate class="P4-guidance">
        Description may include information on national circumstances and
        institutional arrangements relevant to the provision of ex-ante
        information on international resources.
      </p>
      <P4Input
        id="so5_5_international_resources"
        v-model="values.so5_5_international_resources"
        textarea
        standalone
      />

      <h2 v-translate class="P4-section-title">SO5-5.3: Resources needed</h2>
      <h3 v-translate class="P4-caption">
        Please provide information relevant to the financial resources needed
        for the implementation of the Convention, including on the projects and
        regions which needs most support and on which your country has focused
        to the greatest extent.
      </h3>
      <p v-translate class="P4-guidance">
        Description may include information on methodologies used to estimate
        quantitative amounts, on technology transfer and capacity building
        needs, as well as on national circumstances and institutional
        arrangements relevant to reporting on financial resources needed.
      </p>
      <P4Input
        id="so5_5_needed_resources"
        v-model="values.so5_5_needed_resources"
        textarea
        standalone
      />

      <h3 v-translate class="P4-section-title">General comments</h3>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input
          id="so5_5_general_comments"
          v-model="values.so5_5_general_comments"
          textarea
          standalone
        />
      </div>
    </P4Form>
  </section>
</template>

<script>
export default {
  data() {
    const values = {
      ...window.hydrate.data,
    };

    return {
      values,
    };
  },
};
</script>

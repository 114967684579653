import { gettextLazy as _ } from "../../lib/translations.js";

export default {
  yes_no: { yes: _("Yes"), no: _("No") },
  financial: {
    financial: _("Financial Resources"),
    non_financial: _("Non-Financial"),
  },
  mobilized_sources: {
    international: _("International"),
    domestic: _("Domestic"),
    public: _("Public"),
    private: _("Private"),
    local_communities: _("Local communities"),
    non_traditional_funding_sources: _("Non-traditional funding sources"),
    climate_finance: _("Climate Finance"),
    other: _("Other (please specify)"),
  },
  improved_innovative_financial_processes: {
    existing_financial_processes: _("Existing financial processes"),
    innovative_financial_processes: _("Innovative financial processes"),
    the_gef: _("The GEF"),
    other_funds: _("Other funds (please specify)"),
  },

  policies: {
    promoting_solutions_to_combat_DLDD: _(
      "Promoting solutions to combat desertification, land degradation and drought (DLDD)"
    ),
    implementing_solutions_to_combat_DLDD: _(
      "Implementing solutions to combat DLDD"
    ),
    protecting_womens_land_rights: _("Protecting women’s land rights"),
    enhancing_womens_access: _(
      "Enhancing women’s access to natural, productive and/or financial resources"
    ),
    other: _("Other (please specify)"),
  },
  experiences: {
    prevention_of_the_effects_of_dldd: _("Prevention of the effects of DLDD"),
    relief_efforts_after_dldd: _(
      "Relief efforts after DLDD has caused environmental and or socioeconomic stress on ecosystems and or populations"
    ),
    recovery_efforts_after_dldd: _(
      "Recovery efforts after DLDD has caused environmental and or socioeconomic stress on ecosystems and or populations"
    ),
    engagement_of_women_in_decision_making: _(
      "Engagement of women in decision - making"
    ),
    women_land_rights: _(
      "Implementation and promotion of women's land rights and access to land resources"
    ),
    building_womens_capacity: _(
      "Building women's capacity for effective UNCCD implementation"
    ),
    other: _("Other (please specify)"),
  },
  synergies_country_actions: {
    leveraging_dldd_with_other_national_plans_related_to_the_other_rio_conventions:
      _(
        "Leveraging DLDD with other national plans related to the other Rio Conventions"
      ),
    integrating_dldd_into_national_plans: _(
      "Integrating DLDD into national plans"
    ),
    leveraging_synergies_with_other_strategies_to_combat_dldd: _(
      "Leveraging synergies with other strategies to combat DLDD"
    ),
    integrating_dldd_into_other_international_commitments: _(
      "Integrating DLDD into other international commitments"
    ),
    other: _("Other (please specify)"),
  },
  mainstreaming_dldd: {
    economic_policie: _("Economic policies"),
    environmental_policies: _("Environmental policies"),
    social_policies: _("Social policies"),
    land_policies: _("Land policies"),
    gender_policies: _("Gender policies"),
    agricultural_policies: _("Agricultural policies"),
    other: _("Other (please specify)"),
  },
  dldd_actions: {
    agroforestry: _("Agroforestry"),
    area_closure_stop_use_support_restoration: _(
      "Area closure (stop use, support restoration)"
    ),
    beekeeping_fishfarming: _("Beekeeping, fishfarming, etc"),
    cross_slope_measure: _("Cross-slope measure"),
    ecosystem_based_disaster_risk_reduction: _(
      "Ecosystem-based disaster risk reduction"
    ),
    energy_efficiency: _("Energy efficiency"),
    forest_plantation_management: _("Forest plantation management"),
    home_gardens: _("Home gardens"),
    improved_ground_vegetation_cover: _("Improved ground/vegetation cover"),
    improved_plant_varieties_animal_breeds: _(
      "Improved plant varieties animal breeds"
    ),
    integrated_crop_livestock_management: _(
      "Integrated crop-livestock management"
    ),
    integrated_pest_and_disease_management: _(
      "Integrated pest and disease management (incl. organic agriculture)"
    ),
    integrated_soil_fertility_management: _(
      "Integrated soil fertility management"
    ),
    irrigation_management: _(
      "Irrigation management (incl. water supply, drainage)"
    ),
    minimal_soil_disturbance: _("Minimal soil disturbance"),
    natural_and_semi_natural_forest_management: _(
      "Natural and semi-natural forest management"
    ),
    pastoralism_and_grazing_land_management: _(
      "Pastoralism and grazing land management"
    ),
    post_harvest_measures: _("Post-harvest measures"),
    rotational_system: _(
      "Rotational system (crop rotation, fallows, shifting, cultivation)"
    ),
    surface_water_management: _(
      "Surface water management (spring, river, lakes, sea)"
    ),
    water_diversion_and_drainage: _("Water diversion and drainage"),
    water_harvesting: _("Water harvesting"),
    wetland_protection_management: _("Wetland protection/management"),
    windbreak_helterbelt: _("Windbreak/Shelterbelt"),
    waste_management_waste_water_management: _(
      "Waste management / Waste water management"
    ),
    other: _("Other (please specify)"),
  },
  drought_risk_management: {
    drought_risk_management_plan: _("A drought risk management plan"),
    monitoring_and_early_warning_system: _(
      "Monitoring and early warning systems"
    ),
    safety_net_programs: _("Safety net programmes"),
  },
  alternative_livelihoods: {
    crop_diversification: _("Crop diversification"),
    agroforestry_practices: _("Agroforestry practices"),
    rotational_grazing: _("Rotational grazing"),
    rain_fed_and_irrigated_agricultural_systems: _(
      "Rain-fed and irrigated agricultural systems"
    ),
    small_vegetable_gardens: _("Small vegetable gardens"),
    production_of_artisanal_goods: _("Production of artisanal goods"),
    renewable_energy_generation: _("Renewable energy generation"),
    eco_tourism: _("Eco-tourism"),
    production_of_medicinal_and_aromatic_plants: _(
      "Production of medicinal and aromatic plants"
    ),
    aquaculture_using_recycled_wastewater: _(
      "Aquaculture using recycled wastewater"
    ),
    other: _("Other (please specify)"),
  },
};

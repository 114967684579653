<template>
  <FormulateForm @submit="submit">
    <section>
      <ChunkedFileUploader
        v-model="upload"
        help="tearchive"
        :upload-url="urls.te_archive_upload_chunk"
        :complete-url="urls.te_archive_upload_complete"
        validation="mime:application/zip,application/gzip,application/x-gzip"
        accept="application/zip, application/gzip, application/x-gzip, .tgz, .tar.gz"
      />
    </section>

    <section v-if="upload && unpackStatus" id="unpack-status">
      <p>
        <span v-translate>Unpacking spatial layers:</span>

        <span v-if="unpackStatus.ready" v-translate class="success">
          Complete.
        </span>

        <span v-else>
          {{ unpackStatus.layers }} / {{ unpackStatus.total }}.
        </span>
      </p>

      <p v-if="unpackStatus.error" class="error">
        {{ unpackStatus.error }}
      </p>
    </section>

    <br />

    <FormSave ref="save" :save-handler="saveHandler" :disabled="!readyToSave" />
  </FormulateForm>
</template>

<script>
import ChunkedFileUploader from "@/lib/forms/ChunkedFileUploader";
import api from "@/lib/api";

export default {
  name: "TEArchiveUpload",

  components: { ChunkedFileUploader },

  data() {
    return {
      urls: window.hydrate.urls,
      upload: null,
      unpackStatus: null,
      checkStatusTimeout: null,
    };
  },

  computed: {
    readyToSave() {
      return this.upload && this.unpackStatus && this.unpackStatus.ready;
    },
  },

  watch: {
    upload(current, prev) {
      if (current && !prev) {
        this.checkStatus();
      }
      if (!current) {
        clearTimeout(this.checkStatusTimeout);
      }
    },
  },

  methods: {
    async checkStatus() {
      try {
        this.unpackStatus = await api.getArchiveUploadStatus(
          `${this.urls.te_archive_upload_status}?upload=${this.upload}`
        );
        if (this.unpackStatus.ready) return;
        if (this.unpackStatus.error) return;
      } catch (e) {
        console.error(e);
        alert(e.message);
        return;
      }
      this.checkStatusTimeout = setTimeout(() => {
        this.checkStatus();
      }, 1000);
    },

    async saveHandler(data) {
      await api.saveLayerUpload(this.urls.te_archive_upload_save, data);
      this.$nextTick(() => {
        window.location.href = this.urls["report-revision"];
      });
    },

    submit() {
      return this.$refs.save.submit({
        upload: this.upload,
      });
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
}

.success {
  color: #41b883;
}
</style>

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t5"
      :column-count="7"
      :columns="
        isAffectedAreas
          ? Object.values(OPTIONS.T5.headings_affected_areas)
          : Object.values(OPTIONS.T5.headings)
      "
      :label-for-new="$gettext('+ Add New')"
      :row-factory="rowFactory"
    >
      <template #cells="{ rowId, value }">
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-brightspots'"
            v-model="value.brightspots"
            textarea
          />
        </td>
        <td class="P4-cell">
          <P4Input :id="rowId + '-location'" v-model="value.location" />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-area'"
            v-model="value.area"
            numeric
            :disabled="!!value.polygon && value.polytype === 'Polygon'"
          />
        </td>

        <td class="P4-cell">
          <P4Input
            :id="rowId + '-assessment_process'"
            v-model="value.assessment_process"
            :options="OPTIONS.T4.options.assessment_process"
          />
        </td>
        <td class="P4-cell">
          <ActionsTakenCheckboxes
            v-model="value.action_taken_to_redress_degradation"
            :options="OPTIONS.T4.options.action_taken"
            :row-id="rowId"
          />
        </td>
        <td class="P4-cell">
          <button
            :id="rowId + '-implementing-actions-button'"
            v-translate
            type="button"
            class="rounded-empty-btn-sm m-2 print-hide"
            @click="
              showRowId = rowId;
              isImplementingModalVisible = true;
            "
          >
            Choose implementing actions
          </button>

          <div
            v-for="(actionValue, action) in value.implementing_actions"
            :key="action"
            class="mt-4"
          >
            <ul v-if="actionValue === true" class="P4-ul">
              <li>
                {{ OPTIONS.T4.options.remediating_actions[action].label }}
                <ul
                  v-for="(subActionValue, subAction) in value
                    .implementing_actions['items_for_' + action]"
                  :key="subAction"
                  class="P4-ul italic"
                >
                  <li v-if="subActionValue === true">
                    {{
                      OPTIONS.T4.options.remediating_actions[action].options[
                        subAction
                      ]
                    }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <InputDetailModal
            v-show="showRowId === rowId && isImplementingModalVisible"
            :id="rowId + '-implementing-actions-modal'"
            @close="isImplementingModalVisible = false"
          >
            <template #header>
              <p v-translate>Implementing action(s)</p>
            </template>
            <template #body>
              <ul>
                <li
                  v-for="({ label, options }, action) in OPTIONS.T4.options
                    .remediating_actions"
                  :key="action"
                >
                  <P4Input
                    :id="rowId + '-' + action"
                    v-model="value.implementing_actions[action]"
                    :label="label"
                    checkbox
                  />
                  <ul v-if="value.implementing_actions[action]">
                    <li
                      v-for="(subLabel, subAction) in options"
                      :key="subAction"
                      class="w-full text-gray-500 text-base pl-6"
                    >
                      <P4Input
                        :id="rowId + '-' + action + '-' + subAction"
                        v-model="
                          value.implementing_actions['items_for_' + action][
                            subAction
                          ]
                        "
                        checkbox
                        :label="subLabel"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </template>
          </InputDetailModal>
        </td>
        <polygon-cell :value="value" table-id="t5" />
      </template>
      <template #table_footer="{ values }">
        <tr>
          <th v-translate class="P4-cell" colspan="2">
            Total no. of brightpots
          </th>
          <td colspan="5" class="P4-cell">
            <P4DisplayValue
              :value="calculate_total_no_of_brightspots(values)"
            />
          </td>
        </tr>
        <tr>
          <th v-translate class="P4-cell" colspan="2">Total brightspot area</th>
          <td colspan="3" class="P4-cell">
            <P4DisplayValue :value="calculate_total_brightspot_area(values)" />
          </td>
        </tr>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import ActionsTakenCheckboxes from "@/lib/forms/ActionsTakenCheckboxes.vue";
import OPTIONS from "./OPTIONS.js";
import { gettextLazy as $gettext } from "@/lib/translations.js";
import { calculateTotal } from "@/lib/calculateTotal.js";
import PolygonCell from "@/forms/common/PolygonCell";
import InputDetailModal from "@/lib/forms/InputDetailModal.vue";

function rowFactory() {
  const action_taken_to_redress_degradation = {};
  const implementing_actions = {};

  for (const action of Object.keys(OPTIONS.T4.options.remediating_actions)) {
    implementing_actions[`items_for_${action}`] = {};
  }

  return {
    implementing_actions,
    action_taken_to_redress_degradation,
  };
}
export default {
  components: { ActionsTakenCheckboxes, PolygonCell, InputDetailModal },
  data() {
    return {
      $gettext,
      isAffectedAreas: window.hydrate.is_aa_form,
      isImplementingModalVisible: false,
      showRowId: null,
    };
  },
  computed: {
    OPTIONS: () => OPTIONS,
    rowFactory,
    calculate_total_no_of_brightspots() {
      return function (values) {
        return values.length;
      };
    },
    calculate_total_brightspot_area() {
      return function (values) {
        return calculateTotal(values, "area");
      };
    },
  },
};
</script>

import { round } from "@/lib/calculateTotal";

export function getTotalYears(values) {
  let years = [];
  for (const row of values) {
    if (!years.includes(row["year"])) {
      years.push(row["year"]);
    }
  }
  return years;
}

export function calculateTotalYear(values, year, field) {
  let total = 0;
  for (const row of values) {
    if (row["year"] == year) {
      total += +(row[field] || 0);
    }
  }
  return round(total);
}

export function calculateTotalYearProvidedReceived(
  values,
  year,
  field,
  fieldProvidedReceived,
  providedReceived
) {
  let total = 0;
  for (const row of values) {
    if (row["year"] == year && row[fieldProvidedReceived] == providedReceived) {
      total += +(row[field] || 0);
    }
  }
  return round(total);
}

import OPTIONS from "@/forms/so1/4/OPTIONS.js";

export function isFalsePositive(value) {
  return value["type"] === "false_positive";
}

export function isFalseNegative(value) {
  return value["type"] === "false_negative";
}

export function TEType(value) {
  if (this.isFalsePositive(value) || this.isFalseNegative(value))
    return OPTIONS.T3.options.type[value.type];

  return OPTIONS.T3.options.custom;
}

export function TERecode(value) {
  if (this.isFalsePositive(value))
    return OPTIONS.T3.options.recode_false_positive[value.recode];
  if (this.isFalseNegative(value))
    return OPTIONS.T3.options.recode_false_negative[value.recode];

  return OPTIONS.T3.options.custom;
}

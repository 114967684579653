import { gettextLazy as _ } from "./translations.js";

export const UNCCD_CLASS_MAP = {
  tree_covered_areas: _("Tree-covered areas"),
  grassland: _("Grasslands"),
  cropland: _("Croplands"),
  wetland: _("Wetlands"),
  artificial_surfaces: _("Artificial surfaces"),
  other_land: _("Other Lands"),
  water_bodies: _("Water bodies"),
};

export const UNCCD_CLASS_MAP_KM = {
  tree_covered_areas: _("Tree-covered areas (km²)"),
  grassland: _("Grasslands (km²)"),
  cropland: _("Croplands (km²)"),
  wetland: _("Wetlands (km²)"),
  artificial_surfaces: _("Artificial surfaces (km²)"),
  other_land: _("Other Lands (km²)"),
  water_bodies: _("Water bodies (km²)"),
};

export const BACKGROUND_LAYERS = [
  {
    type: "s2cloudless",
    id: "s2cloudless-2020",
    title: "Sentinel-2 Cloudless",
    attribution: `
      <a href="https://s2maps.eu" target="_blank">Sentinel-2 cloudless</a> by
      <a href="https://eox.at" target="_blank">EOX IT Services GmbH </a>
      (Contains modified Copernicus Sentinel data 2020)
    `,
    disclaimer: true,
  },
  {
    type: "clearmap",
    id: "ClearMap_WebPlain",
    title: "UN ClearMap Plain",
    disclaimer: false,
  },
  {
    type: "clearmap",
    id: "ClearMap_WebTopo",
    title: "UN ClearMap Topo",
    disclaimer: false,
  },
  {
    type: "clearmap",
    id: "ClearMap_WebGray",
    title: "UN ClearMap Gray",
    disclaimer: false,
  },
  {
    type: "clearmap",
    id: "ClearMap_WebDark",
    title: "UN ClearMap Dark",
    disclaimer: false,
  },
  {
    type: "mapbox",
    id: "mapbox-satellite",
    title: "Mapbox Satellite",
    url: "https://api.mapbox.com/v4/mapbox.satellite/{z}/{y}/{x}@2x.jpg90",
    attribution: `
      <a href="https://www.mapbox.com/about/maps/" target="_blank"
      title="Mapbox" aria-label="Mapbox" role="listitem">&copy; Mapbox</a> <a
      href="http://www.openstreetmap.org/about/" target="_blank"
      title="OpenStreetMap" aria-label="OpenStreetMap" role="listitem">&copy;
      OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/"
      target="_blank" title="Improve this map" aria-label="Improve this map"
      role="listitem">Improve this map</a> <a href="https://www.maxar.com/"
      target="_blank" title="Maxar" aria-label="Maxar" role="listitem">&copy;
      Maxar</a>
    `,
    disclaimer: true,
  },
  {
    type: "mapbox",
    id: "mapbox-frank",
    title: "OpenStreetMap",
    url: "https://api.mapbox.com/styles/v1/eaudeweb/ckrltvyjl0itn18tdjrbi8us0/tiles/256/{z}/{y}/{x}@2x",
    attribution: `
      © <a href="https://www.mapbox.com/about/maps/">Mapbox</a>
      © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>
    `,
    disclaimer: true,
  },
];

export const CHANGES_IN_THE_INDICATOR = {
  increase: _("Increase"),
  decrease: _("Decrease"),
  no_change: _("No change"),
};

export const BORDER_CODE = "BORDER";
export const VECTOR = "vector";
export const RASTER = "raster";
export const FALSE_POSITIVES_LAYER = "FALSE_POSITIVES_NEGATIVES";
export const chartRasters = [
  "SDG_STATUS_REPORTING",
  "LDN_REPORTING",
  "LAND_COVER_DEGRADATION_REPORTING",
  "LAND_PRODUCTIVITY_DEGRADATION_REPORTING",
  "SOC_DEGRADATION_REPORTING",
  "LDN_REPORTING_recalculated",
  "SDG_STATUS_REPORTING_recalculated",
];

// Needed because Argentina requires special disclaimers, but not the UN Clear Map background
export const ARGENTINA_ISO3 = "ARG";

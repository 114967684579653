<template>
  <div class="flex flex-col">
    <div id="map-component" class="flex items-start">
      <div id="map" class="map outline-gray background-light-blue" />
      <div id="legend" class="outline-gray background-light-gray mx-8">
        <p v-translate class="raster-legend-text py-2 font-bold text-center">
          Legend
        </p>
        <img :src="raster.legend_url" class="pb-4" alt="Raster legend" />
        <hr />
        <img
          v-if="vectorLegendSrc"
          class="pb-2 self-start"
          :src="vectorLegendSrc"
          alt="Vector legend"
        />
        <p v-translate class="bg-white pt-4">
          Projection: {{ projectionDisplay }}
        </p>
      </div>
    </div>
    <div class="mt-8 ml-1">
      <p v-translate class="font-bold text-lg">Disclaimer</p>
      <div id="disclaimer">{{ disclaimer }}</div>
      <br />
      <p v-translate class="font-bold text-lg">Source Data Credits</p>
      <ul class="list-disc">
        <li v-for="(credit, index) in credits" :key="index">
          {{ credit }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  geojson,
  createVectorLayer,
  createMapserverRasterLayer,
  createBackgroundLayer,
  createCropFilter,
} from "@/lib/maps/layers";
import {
  BORDER_CODE,
  ARGENTINA_ISO3,
  BACKGROUND_LAYERS,
} from "@/lib/constants";
import { Map, View } from "ol";
import fitView from "@/lib/maps/fitView";
import Legend from "ol-ext/legend/Legend";
import styles from "@/lib/maps/styles";
import { Fill, Stroke, Style, Text } from "ol/style";
import Compass from "ol-ext/control/Compass";
import Graticule from "ol-ext/control/Graticule";
import { specialAreasCredits } from "@/map/data/specialAreas";
import { ScaleLine } from "ol/control";
import {
  noOpinionDisclaimer,
  terrestrialAreaDisclaimer,
} from "@/map/data/disclaimers";

export default {
  data() {
    const countryISO = window.hydrate.iso3;
    const credits = window.hydrate.credits;
    let disclaimer = noOpinionDisclaimer + " " + terrestrialAreaDisclaimer;
    if (credits.border.user_added) {
      disclaimer = credits.border.credits + " " + disclaimer;
    } else {
      credits.other_layers.unshift(credits.border.credits);
    }

    if (Object.prototype.hasOwnProperty.call(specialAreasCredits, countryISO)) {
      disclaimer += specialAreasCredits[countryISO];
    }

    return {
      olMap: null,
      legend: null,
      countryISO,
      raster: window.hydrate.raster,
      countryBorderContent: window.hydrate.countryBorderContent,
      customBorder: window.hydrate.customBorder,
      vectorCodes: window.hydrate.vectorCodes,
      vectorLabels: window.hydrate.vectorLabels,
      vectorContents: window.hydrate.vectorContents,
      vectorLegendSrc: "",
      disclaimer,
      credits: credits.other_layers,
      neighboursURL: window.hydrate.neighboursURL,
      compassURL: window.hydrate.compassURL,
      borderLegendLabel: this.$gettext("International Boundary"),
      projectionEpsg: window.hydrate.projection.epsg,
      projectionDisplay: window.hydrate.projection.display,
    };
  },
  mounted() {
    const { Prais4 } = window;
    this.legend = new Legend({
      // Cannot be empty
      title: " ",
      size: [20, 10],
      margin: 5,
      textStyle: new Text({
        textAlign: "start",
        font: "14px Noto Sans SC, Roboto, Arial, Helvetica, sans-serif",
        fill: new Fill({ color: "#000" }),
      }),
    });

    const layers = [];

    const borderLayer = this.loadLegendVectorLayer(
      this.countryBorderContent,
      BORDER_CODE,
      this.borderLegendLabel,
      styles.borders("#000000")
    );
    layers.push(borderLayer);

    if (
      (this.countryISO !== ARGENTINA_ISO3 &&
        Object.prototype.hasOwnProperty.call(
          specialAreasCredits,
          this.countryISO
        )) ||
      !this.customBorder
    ) {
      const [s2cloudless] = BACKGROUND_LAYERS.filter(
        ({ id }) => id === "ClearMap_WebPlain"
      );
      const background = createBackgroundLayer(s2cloudless);
      background.setVisible(true);
      layers.push(background);
    } else {
      layers.push(this.loadRasters(this.neighboursURL, "Background"));
    }
    layers.push(this.loadRasters(this.raster.mapserver_url, this.raster.code));

    if (this.vectorCodes) {
      const vectorStyles = [
        // Use a border style to avoid having a fill color
        styles.borders("red", 3),
        styles.borders("#0000ff", 3, [4, 8], 6),
      ];

      // If there will ever be a map with more than two vector layers, we'll need to make more styles
      this.vectorCodes.forEach((code, idx) => {
        layers.push(
          this.loadLegendVectorLayer(
            this.vectorContents[idx],
            code,
            this.vectorLabels[idx],
            vectorStyles[idx % vectorStyles.length]
          )
        );
      });
    }

    const view = new View({
      projection: this.projectionEpsg,
    });
    this.olMap = new Map({
      target: "map",
      controls: [
        new ScaleLine({
          units: "metric",
          bar: true,
          steps: 4,
          text: true,
          minWidth: 100,
          className: "templated-maps-scale-bar",
        }),
        new Compass({
          className: "bottom",
          src: this.compassURL,
          rotateWithView: true,
        }),
        new Graticule({
          stepCoord: 3,
          spacing: 80,
          margin: 5,
          projection: "EPSG:4326",
          formatCoord: function (c) {
            return c.toFixed() + "°";
          },
          style: new Style({
            stroke: new Stroke({ color: "#88888899", width: 1 }),
            text: new Text({
              stroke: new Stroke({ color: "#FFF", width: 2 }),
              font: "bold 13px Noto Sans SC, Roboto, Arial, Helvetica, sans-serif",
            }),
          }),
        }),
      ],
      layers,
      view,
    });

    // Same as the fitView, I have no idea why this needs a timeout
    setTimeout(() => {
      this.vectorLegendSrc = this.legend.getCanvas().toDataURL();
      fitView(view, borderLayer.getSource(), this.countryISO);
    }, 100);

    Prais4.loaded = new Promise((resolve) => {
      this.olMap.once("rendercomplete", resolve);
    });
  },
  methods: {
    loadRasters(url, title) {
      const features = geojson(this.projectionEpsg).readFeatures(
        this.countryBorderContent
      );
      const cropFilter = createCropFilter(features);
      const rasterLayer = createMapserverRasterLayer(url, { title });

      rasterLayer.addFilter(cropFilter);
      rasterLayer.setVisible(true);
      return rasterLayer;
    },
    loadVectorLayer(features, code, style = null) {
      const feats = geojson(this.projectionEpsg).readFeatures(features);
      const olLayer = createVectorLayer(feats, code, style);
      olLayer.setVisible(true);
      return olLayer;
    },
    loadLegendVectorLayer(features, code, title, style = null) {
      const olLayer = this.loadVectorLayer(features, code, style);
      new Set(
        olLayer
          .getSource()
          .getFeatures()
          .map((feat) => feat.getGeometry().getType())
      ).forEach((geom) => {
        this.legend.addItem({
          title: code !== BORDER_CODE ? `${title} (${geom})` : title,
          style: olLayer.getStyle(),
          typeGeom: geom,
        });
      });
      return olLayer;
    },
  },
};
</script>

<style scoped>
.raster-legend-text {
  font-size: 16px;
}
</style>

import { gettextLazy as _ } from "../../lib/translations.js";

export default {
  STATUS: {
    up: _("Up ↑"),
    stable: _("Stable ←→"),
    down: _("Down ↓"),
    unknown: _("Unknown ∾"),
  },
  OECD_DAC_RECIPIENT_COUNTRIES: {
    "": "",
    other: _("Other (please specify)"),
    turkey: _("Türkiye"),
    kosovo: _("Kosovo"),
    serbia: _("Serbia"),
    bosnia_and_herzegovina: _("Bosnia and Herzegovina"),
    montenegro: _("Montenegro"),
    north_macedonia: _("North Macedonia"),
    albania: _("Albania"),
    ukraine: _("Ukraine"),
    belarus: _("Belarus"),
    states_ex_yugoslavia_unspecified: _("States Ex-Yugoslavia unspecified"),
    europe_regional: _("Europe, regional"),
    moldova: _("Moldova"),
    algeria: _("Algeria"),
    libya: _("Libya"),
    morocco: _("Morocco"),
    tunisia: _("Tunisia"),
    egypt: _("Egypt"),
    north_of_sahara_regional: _("North of Sahara, regional"),
    south_africa: _("South Africa"),
    angola: _("Angola"),
    botswana: _("Botswana"),
    burundi: _("Burundi"),
    cameroon: _("Cameroon"),
    cabo_verde: _("Cabo Verde"),
    central_african_republic: _("Central African Republic"),
    chad: _("Chad"),
    comoros: _("Comoros"),
    congo: _("Congo"),
    democratic_republic_of_the_congo: _("Democratic Republic of the Congo"),
    benin: _("Benin"),
    ethiopia: _("Ethiopia"),
    gabon: _("Gabon"),
    gambia: _("Gambia"),
    ghana: _("Ghana"),
    guinea: _("Guinea"),
    guinea_bissau: _("Guinea-Bissau"),
    equatorial_guinea: _("Equatorial Guinea"),
    cote_d_ivoire: _("Côte d'Ivoire"),
    kenya: _("Kenya"),
    lesotho: _("Lesotho"),
    liberia: _("Liberia"),
    madagascar: _("Madagascar"),
    malawi: _("Malawi"),
    mali: _("Mali"),
    mauritania: _("Mauritania"),
    mauritius: _("Mauritius"),
    mozambique: _("Mozambique"),
    niger: _("Niger"),
    nigeria: _("Nigeria"),
    zimbabwe: _("Zimbabwe"),
    rwanda: _("Rwanda"),
    sao_tome_and_principe: _("Sao Tome and Principe"),
    senegal: _("Senegal"),
    eritrea: _("Eritrea"),
    sierra_leone: _("Sierra Leone"),
    somalia: _("Somalia"),
    djibouti: _("Djibouti"),
    namibia: _("Namibia"),
    saint_helena: _("Saint Helena"),
    sudan: _("Sudan"),
    south_sudan: _("South Sudan"),
    eswatini: _("Eswatini"),
    tanzania: _("Tanzania"),
    togo: _("Togo"),
    uganda: _("Uganda"),
    burkina_faso: _("Burkina Faso"),
    zambia: _("Zambia"),
    south_of_sahara_regional: _("South of Sahara, regional"),
    africa_regional: _("Africa, regional"),
    costa_rica: _("Costa Rica"),
    cuba: _("Cuba"),
    dominican_republic: _("Dominican Republic"),
    el_salvador: _("El Salvador"),
    guatemala: _("Guatemala"),
    haiti: _("Haiti"),
    honduras: _("Honduras"),
    belize: _("Belize"),
    jamaica: _("Jamaica"),
    mexico: _("Mexico"),
    nicaragua: _("Nicaragua"),
    panama: _("Panama"),
    antigua_and_barbuda: _("Antigua and Barbuda"),
    dominica: _("Dominica"),
    grenada: _("Grenada"),
    saint_lucia: _("Saint Lucia"),
    saint_vincent_and_the_grenadines: _("Saint Vincent and the Grenadines"),
    montserrat: _("Montserrat"),
    caribbean_central_america_regional: _(
      "Caribbean & Central America, regional"
    ),
    argentina: _("Argentina"),
    bolivia: _("Bolivia"),
    brazil: _("Brazil"),
    colombia: _("Colombia"),
    ecuador: _("Ecuador"),
    guyana: _("Guyana"),
    paraguay: _("Paraguay"),
    peru: _("Peru"),
    suriname: _("Suriname"),
    venezuela: _("Venezuela"),
    south_america_regional: _("South America, regional"),
    america_regional: _("America, regional"),
    iran: _("Iran"),
    iraq: _("Iraq"),
    jordan: _("Jordan"),
    west_bank_and_gaza_strip: _("West Bank and Gaza Strip"),
    lebanon: _("Lebanon"),
    syrian_arab_republic: _("Syrian Arab Republic"),
    yemen: _("Yemen"),
    middle_east_regional: _("Middle East, regional"),
    armenia: _("Armenia"),
    azerbaijan: _("Azerbaijan"),
    georgia: _("Georgia"),
    kazakhstan: _("Kazakhstan"),
    kyrgyzstan: _("Kyrgyzstan"),
    tajikistan: _("Tajikistan"),
    turkmenistan: _("Turkmenistan"),
    uzbekistan: _("Uzbekistan"),
    central_asia_regional: _("Central Asia, regional"),
    afghanistan: _("Afghanistan"),
    bhutan: _("Bhutan"),
    myanmar: _("Myanmar"),
    sri_lanka: _("Sri Lanka"),
    india: _("India"),
    maldives: _("Maldives"),
    nepal: _("Nepal"),
    pakistan: _("Pakistan"),
    bangladesh: _("Bangladesh"),
    south_asia_regional: _("South Asia, regional"),
    south_central_asia_regional: _("South & Central Asia, regional"),
    cambodia: _("Cambodia"),
    china_peoples_republic_of: _("China (People's Republic of)"),
    indonesia: _("Indonesia"),
    democratic_people_s_republic_of_korea: _(
      "Democratic People's Republic of Korea"
    ),
    lao_people_s_democratic_republic: _("Lao People's Democratic Republic"),
    malaysia: _("Malaysia"),
    mongolia: _("Mongolia"),
    philippines: _("Philippines"),
    thailand: _("Thailand"),
    timor_leste: _("Timor-Leste"),
    vietnam: _("Viet Nam"),
    far_east_asia_regional: _("Far East Asia, regional"),
    asia_regional: _("Asia, regional"),
    fiji: _("Fiji"),
    kiribati: _("Kiribati"),
    nauru: _("Nauru"),
    vanuatu: _("Vanuatu"),
    niue: _("Niue"),
    marshall_islands: _("Marshall Islands"),
    micronesia: _("Micronesia"),
    palau: _("Palau"),
    papua_new_guinea: _("Papua New Guinea"),
    solomon_islands: _("Solomon Islands"),
    tokelau: _("Tokelau"),
    tonga: _("Tonga"),
    tuvalu: _("Tuvalu"),
    wallis_and_futuna: _("Wallis and Futuna"),
    samoa: _("Samoa"),
    oceania_regional: _("Oceania, regional"),
    developing_countries_unspecified: _("Developing countries, unspecified"),
    eastern_africa_regional: _("Eastern Africa, regional"),
    middle_africa_regional: _("Middle Africa, regional"),
    southern_africa_regional: _("Southern Africa, regional"),
    western_africa_regional: _("Western Africa, regional"),
    caribbean_regional: _("Caribbean, regional"),
    central_america_regional: _("Central America regional"),
    melanesia_regional: _("Melanesia, regional"),
    micronesia_regional: _("Micronesia, regional"),
    polynesia_regional: _("Polynesia, regional"),
    austria: _("Austria"),
    belgium: _("Belgium"),
    denmark: _("Denmark"),
    france: _("France"),
    germany: _("Germany"),
    italy: _("Italy"),
    netherlands: _("Netherlands"),
    norway: _("Norway"),
    portugal: _("Portugal"),
    sweden: _("Sweden"),
    switzerland: _("Switzerland"),
    united_kingdom: _("United Kingdom"),
    finland: _("Finland"),
    iceland: _("Iceland"),
    ireland: _("Ireland"),
    luxembourg: _("Luxembourg"),
    greece: _("Greece"),
    spain: _("Spain"),
    slovenia: _("Slovenia"),
    czech_republic: _("Czech Republic"),
    slovak_republic: _("Slovak Republic"),
    hungary: _("Hungary"),
    poland: _("Poland"),
    canada: _("Canada"),
    united_states: _("United States"),
    japan: _("Japan"),
    korea: _("Korea"),
    australia: _("Australia"),
    new_zealand: _("New Zealand"),
    eu_institutions: _("EU Institutions"),
  },
  SECTOR: {
    agriculture: _("Agriculture"),
    forestry: _("Forestry"),
    water_and_sanitation: _("Water and Sanitation"),
    cross_cutting: _("Cross-cutting"),
    other: _("Other(specify)"),
  },
  PROVIDED_RECEIVED: {
    provided: _("Provided"),
    received: _("Received"),
  },
};

<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t1"
      :column-count="2"
      :columns="[
        $gettext('Year'),
        $gettext(
          'Proportion of population below international poverty line (%)'
        ),
      ]"
    >
      <template #cells="{ rowId, rowIndex, value }">
        <td class="P4-cell">
          <P4DisplayValue :value="value.year" />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-proportion_of_population'"
            v-model="value.proportion_of_population"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, 'proportion_of_population'],
              value.proportion_of_population
            )"
            placeholder="No data"
            percent
            validate="roundTo2"
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange],
  data() {
    return {
      $gettext,
    };
  },
};
</script>

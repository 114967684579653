<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t2"
      :columns="[
        '',
        $gettext('Total area under drought (km²)'),
        isAffectedAreas
          ? $gettext('Proportion of affected area under drought (%)')
          : $gettext('Proportion of land under drought (%)'),
      ]"
    >
      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          {{ value.year }}
        </th>
        <td class="P4-cell-right">
          <P4Input
            :id="rowId + '-total_area_under_drought'"
            v-model="value.total_area_under_drought"
            :default-value-has-changed="defaultValueHasChanged(
              ['t2', rowIndex, 'total_area_under_drought'],
              value.total_area_under_drought
            )"

            :warnings="[
                {
                  name: 'exceedMaxTotalWarning',
                  args: [value.total_area_under_drought, getTotalLandArea(value.year), getNearestYear(value.year)]
                },
            ]"
            :placeholder="$gettext('No data')"
            numeric
          />
        </td>

        <td class="P4-cell-right">
          <P4DisplayValue
            :value="
              formatPercent(
                value.total_area_under_drought,
                getTotalLandArea(value.year)
              )
            "
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import countryAreas from "@/lib/mixins/countryAreas";
import formatPercent from "@/lib/formatPercent";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange, countryAreas],
  data() {
    return {
      isAffectedAreas: window.hydrate.is_aa_form,
    };
  },
  methods: {
    formatPercent,
    getNearestYear(year) {
      if (this.allAvailableYears.length === 0) return;
      const nearestYear = this.allAvailableYears.reduce((prev, curr) =>
        Math.abs(curr - year) < Math.abs(prev - year) ? curr : prev
      );
      return nearestYear
    },
    getTotalLandArea(year) {
      return this.getNearestYear(year) ? this.countryAreas[this.getNearestYear(year)].total_land_area : null;
    },
  },
};
</script>

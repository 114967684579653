import VueFormulate from "@braid/vue-formulate";
import GetTextPlugin from "vue-gettext";
import VuePortal from "@linusborg/vue-simple-portal";
import { GChart } from "vue-google-charts/legacy";

import MultiTable from "./forms/MultiTable.vue";
import FormSave from "./forms/FormSave.vue";
import NextButton from "./forms/NextButton.vue";
import RestoreDefaultdata from "./forms/RestoreDefaultdata";
import FormDebug from "./forms/FormDebug.vue";
import P4Input from "./forms/P4Input.vue";
import Tooltip from "./forms/Tooltip.vue";
import CommentAnchor from "./comments/CommentAnchor.vue";
import P4Modal from "./P4Modal.vue";
import P4DisplayValue from "./forms/P4DisplayValue.vue";

import translations from "../../translations.json";
import P4Form from "@/forms/common/P4Form";
import customRules from "@/lib/validation/customRules";
import customMessages from "@/lib/validation/customMessages";

const availableLanguages = {
  en: "English",
  es: "Español",
  fr: "Français",
  ar: "العربية",
  ru: "Русский",
  zh_hans: "中文",
};

function fakeString(value) {
  return {
    toString: () => value,
  };
}

export default function (Vue) {
  Vue.use(VueFormulate, {
    library: {
      multitable: {
        // XXX horrible hack to prevent VueFormulate from replacing
        // our component with FormulateInputGroup
        // https://github.com/wearebraid/vue-formulate/blob/v2.5.2/src/FormulateInput.vue#L312
        classification: fakeString("group"),
        component: MultiTable,
        slotProps: {
          component: [
            "columns",
            "tooltipColumns",
            "columnCount",
            "labelForNew",
            "rowFactory",
            "allowRowRemoval",
            "disableRowAdd",
            "warnings",
          ],
        },
      },
    },
    rules: customRules,
    // Set the same functions for ALL languages, since vue-gettext will
    // automatically choose the current language.
    locales: Object.keys(availableLanguages).reduce((result, lang) => {
      result[lang] = customMessages;
      return result;
    }, {}),
  });

  Vue.use(GetTextPlugin, {
    availableLanguages,
    defaultLanguage: "en",
    translations: translations,
    silent: true,
  });

  const LANGUAGE_CODE = JSON.parse(
    document.getElementById("LANGUAGE_CODE").textContent
  );
  // XXX hack to conciliate zh-hans chinese translation from django with zh_hans from vue-gettext
  Vue.config.language = LANGUAGE_CODE.replace("-", "_");

  Vue.use(VuePortal);

  Vue.component("GChart", GChart);
  Vue.component("FormSave", FormSave);
  Vue.component("NextButton", NextButton);
  Vue.component("RestoreDefaultdata", RestoreDefaultdata);
  Vue.component("FormDebug", FormDebug);
  Vue.component("P4Form", P4Form);
  Vue.component("P4Input", P4Input);
  Vue.component("Tooltip", Tooltip);
  Vue.component("CommentAnchor", CommentAnchor);
  Vue.component("P4Modal", P4Modal);
  Vue.component("P4DisplayValue", P4DisplayValue);
}

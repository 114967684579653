<template>
  <FormulateForm v-model="values" @submit="submit">
    <section>
      <h2 v-translate class="P4-section-title">Language</h2>
      <h5 v-translate class="P4-guidance">
        Choose the language in which the report and templated maps for
        {{ country }} will be generated. This will also be the default language
        for users of {{ country }}.
      </h5>
      <P4Input
        v-model="values.language"
        name="language"
        :options="languages"
        :disabled="disabled"
        :help="
          disabled
            ? $gettext(
                'This option can be changed only by the country reporting officer.'
              )
            : ''
        "
      />

      <ul v-if="allOptions" class="list-disc ml-10">
        <li v-for="option in languages" :key="option">
          {{ option }}
        </li>
      </ul>
    </section>
    <section>
      <h2 v-translate class="P4-section-title">Templated Maps Orientation</h2>
      <h5 v-translate class="P4-guidance">
        Choose the orientation for the Templated Maps of {{ country }}.
      </h5>
      <P4Input
        v-model="values.templated_maps_orientation"
        name="templated_maps_orientation"
        :options="orientations"
        :disabled="disabled"
        :help="
          disabled
            ? $gettext(
                'This option can be changed only by the country reporting officer.'
              )
            : ''
        "
      />

      <ul v-if="allOptions" class="list-disc ml-10">
        <li v-for="orientation in orientations" :key="orientation">
          {{ orientation }}
        </li>
      </ul>
    </section>
    <section>
      <h2 v-translate class="P4-section-title">Templated Maps Projection</h2>
      <h5 v-translate class="P4-guidance">
        Choose the projection for the Templated Maps of {{ country }}.
      </h5>
      <P4Input
        v-model="values.templated_map_projection"
        name="templated_map_projection"
        :options="projections"
        :disabled="disabled"
        :help="
          disabled
            ? $gettext(
                'This option can be changed only by the country reporting officer.'
              )
            : ''
        "
      />

      <ul v-if="allOptions" class="list-disc ml-10">
        <li v-for="projection in projections" :key="projection">
          {{ projection }}
        </li>
      </ul>
    </section>

    <FormSave ref="save" :disabled="disabled" />

    <FormDebug :values="values" />
  </FormulateForm>
</template>

<script>
import { allOptions } from "../lib/translations.js";
import { gettextLazy as $gettext } from "../lib/translations.js";

export default {
  name: "CountryPreferences",

  data() {
    const values = {
      ...(window.hydrate.data || {}),
    };

    return {
      values,
      languages: window.hydrate.languages,
      orientations: window.hydrate.templated_maps_orientation,
      projections: window.hydrate.templated_map_projections,
      disabled: window.hydrate.disabled,
      country: window.hydrate.country,
      $gettext,
    };
  },

  computed: {
    allOptions,
  },

  methods: {
    submit(data) {
      return this.$refs.save.submit(data);
    },
  },
};
</script>

<template>
  <FormulateInput
    type="multitable"
    name="t1"
    :columns="OPTIONS.COLUMNS"
    :column-count="5"
    :label-for-new="labelForNew"
    :row-factory="rowFactory"
  >
    <template #cells="{ rowId, value }">
      <td class="P4-cell">
        <P4Input
          :id="rowId + '-indicator_recalculated'"
          v-model="value.indicator_recalculated"
          :options="indicators"
        />
      </td>

      <td class="P4-cell">
        <ul>
          <li
            v-for="(option, index) in OPTIONS.justifications"
            :key="index"
            class="max-w-max"
          >
            <P4Input
              :key="'justifications-' + index"
              v-model="value.justifications[index]"
              :name="'justifications-' + index"
              :label="option"
              checkbox
            />
          </li>
          <li>
            <P4Input
              v-if="value.justifications['other']"
              id="justifications_other"
              v-model="value['justifications_other']"
            />
          </li>
        </ul>
      </td>

      <td class="P4-cell">
        <P4Input
          id="explanatory_information"
          v-model="value.explanatory_information"
          textarea
        />
      </td>

      <td class="P4-cell">
        <P4Input
          id="quantitative_information"
          v-model="value.quantitative_information"
          textarea
        />
      </td>

      <td class="P4-cell">
        <P4Input
          id="national_impact"
          v-model="value.national_impact"
          textarea
        />
      </td>
    </template>
  </FormulateInput>
</template>

<script>
import {
  gettextLazy as _,
  recursiveTranslate,
} from "../../lib/translations.js";
import OPTIONS from "./OPTIONS.js";

function rowFactory() {
  return {
    justifications: {},
  };
}

export default {
  props: {
    indicators: {
      type: Object,
      required: true,
    },
  },

  computed: {
    OPTIONS: () => recursiveTranslate(OPTIONS),

    labelForNew() {
      return recursiveTranslate(_("+ Add Record"));
    },

    rowFactory,
  },
};
</script>

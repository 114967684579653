<template>
  <p v-translate>
    Upload a .zip or .tar.gz file exported from Trends.Earth.
  </p>
</template>

<script>
export default {
  name: "TrendsEarthArchiveUploadHelp",
};
</script>

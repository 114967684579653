import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  TIERS: {
    tier1: _("Modified Tier 1 methods and data"),
    tier2: _("Tier 2 (additional use of country-specific data)"),
    tier3: _(
      "Tier 3 (more complex methods involving ground measurements and modelling)"
    ),
  },
  BASELINE: {
    land_area_with_degraded_land_productivity: {
      name: _("Land area with degraded soil organic carbon (SOC)"),
      tooltip: _("Area degraded based on SOC change in the baseline period"),
    },
    land_area_with_land_productivity_not_degraded: {
      name: _("Land area with non-degraded SOC"),
      tooltip: _(
        "Area not degraded based on SOC change in the baseline period"
      ),
    },
    land_area_with_no_data_for_land_productivity: {
      name: _("Land area with no SOC data"),
      tooltip: _("Area not reported due to a lack of valid SOC data"),
    },
  },
  REPORTING: {
    land_area_with_improved_land_productivity: {
      name: _("Land area with improved SOC"),
      tooltip: _(
        "Area improved with respect to SOC degradation in the reporting period"
      ),
    },
    land_area_with_stable_land_productivity: {
      name: _("Land area with stable SOC"),
      tooltip: _(
        "Area stable with respect to SOC degradation in the reporting period"
      ),
    },
    land_area_with_degraded_land_productivity: {
      name: _("Land area with degraded SOC"),
      tooltip: _(
        "Area degraded due to SOC change degradation in the reporting period"
      ),
    },
    land_area_with_no_data_for_land_productivity: {
      name: _("Land area with no SOC data"),
      tooltip: _("Area not reported due to a lack of valid SOC data"),
    },
  },
};

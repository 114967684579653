<template>
  <section>
    <FormulateInput
      type="multitable"
      name="t3"
      :column-count="3"
      :label-for-new="$gettext('+ Add Class')"
    >
      <template #table_header>
        <tr>
          <th
            v-for="(column, index) in [
              $gettext('Country legend class'),
              $gettext('Country legend class code'),
              $gettext('UNCCD legend class'),
            ]"
            :key="index"
            class="P4-cell"
          >
            {{ column }}
          </th>
        </tr>
      </template>

      <template #cells="{ rowId, value, model }">
        <td class="P4-cell">
          <!-- Debounce this input as it will trigger repaints of other tables -->
          <P4Input
            :id="rowId + '-country_legend'"
            v-model="value.country_legend"
            :validate="[['uniqueCustomLandcover', 'country_legend', model]]"
            show-errors
            :debounce="500"
            required
          />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-class_code'"
            v-model="value.class_code"
            :validate="[['uniqueCustomLandcover', 'class_code', model]]"
            numeric
            required
          />
        </td>
        <td class="P4-cell">
          <P4Input
            :id="rowId + '-land_cover'"
            v-model="value.land_cover"
            :options="UNCCD_CLASS_MAP"
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { UNCCD_CLASS_MAP } from "@/lib/constants";
import { gettextLazy as $gettext } from "@/lib/translations.js";

export default {
  props: {
    landCoverClassesTitle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      $gettext,
    };
  },
  computed: {
    UNCCD_CLASS_MAP: () => UNCCD_CLASS_MAP,
  },
};
</script>

<template>
  <section>
    <h1 class="P4-page-title">{{ report_form_title }}</h1>

    <h2 v-translate class="P4-section-title">Soil organic carbon stocks</h2>

    <p v-translate class="text-gray-500 text-sm">
      Trends in carbon stock above and below ground is a multi-purpose indicator
      used to measure progress towards both strategic objectives 1 and 4.
    </p>

    <p v-translate class="text-gray-500 text-sm">
      Quantitative data and a qualitative assessment of trends in this indicator
      are reported under strategic objective 1, progress indicator SO1-3.
    </p>

    <NextButton ref="next" />
  </section>
</template>

<script>
export default {
  data() {
    return {
      report_form_title: window.hydrate.report_form_title,
    };
  },
};
</script>

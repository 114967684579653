<template>
  <section>
    <h2 v-translate class="P4-infotext">(Optional)</h2>
    <p v-translate class="P4-infotext text-lg">
      You can choose to complete only Tier 1 or both.
    </p>
    <P4Form v-model="values">
      <h2 v-translate class="P4-section-title">
        Tier 1: Please provide information relevant to the resources provided,
        received for the transfer of technology for the implementation of the
        Convention, including information on trends.
      </h2>

      <P4Input
        v-model="values.trends_resources_provided"
        radio
        name="trends_resources_provided"
        :label="
          $gettext(
            'Trends in international bilateral and multilateral public resources provided'
          )
        "
        :options="SO5_OPTIONS.STATUS"
      />

      <P4Input
        v-model="values.trends_resources_received"
        radio
        name="trends_resources_received"
        :label="
          $gettext(
            'Trends in international bilateral and multilateral public resources received'
          )
        "
        :options="SO5_OPTIONS.STATUS"
      />

      <p v-translate class="P4-infotext">
        Please provide information, as available, on technology development,
        diffusion, transfer, and application of new technologies, as well as the
        transfer of related knowledge, know-how and good practices; on policies
        and measures implemented to accelerate, encourage and enable innovation
        and the transfer of technologies by the private sector; as well as on
        plans, needs and priorities related to the transfer of technology.
      </p>
      <P4Input
        id="so5_4_policies_implemented"
        v-model="values.so5_4_technology"
        textarea
        standalone
      />

      <p v-translate class="P4-guidance">
        Description may include information on national circumstances and
        institutional arrangements relevant to the reporting on the transfer of
        technology.
      </p>
      <P4Input
        id="so5_4_national_circumstances"
        v-model="values.information_on_national_circumstances"
        textarea
        standalone
      />
      <h3 v-translate class="P4-section-title">
        Tier 2: Table 4 Resources provided and received for technology transfer
        measures or activities
      </h3>
      <CommentAnchor :name="form_id + '-T1'" />
      <T1 />

      <h2 v-translate class="P4-section-title">
        Please provide methodological information relevant to data presented in
        table 4
      </h2>
      <h3 v-translate class="P4-caption">
        Include information on underlying assumptions, definitions and
        methodologies used to identify and report on technology transfer support
        provided and/or received and/or required. Please include links to
        relevant documentation.
      </h3>
      <P4Input
        id="so5_4_methodologies_info"
        v-model="values.so5_4_underlying_assumptions"
        textarea
        standalone
      />

      <h2 v-translate class="P4-section-title">
        Please provide information on the types of new or current technologies
        required by your country to address desertification, land degradation
        and drought (DLDD), and the challenges encountered in acquiring or
        developing such technologies.
      </h2>
      <P4Input
        id="so5_4_taken_measures"
        v-model="values.so5_4_desertification_technologies"
        textarea
        standalone
      />

      <h3 v-translate class="P4-section-title">General comments</h3>
      <p v-translate class="P4-guidance">
        Provide any additional comments you deem relevant.
      </p>
      <div class="pb-8">
        <P4Input
          id="so5_4_general_comments"
          v-model="values.so5_4_general_comments"
          textarea
          standalone
        />
      </div>
    </P4Form>
  </section>
</template>

<script>
import T1 from "./T1.vue";
import SO5_OPTIONS from "../SO5_OPTIONS";
import { gettextLazy as $gettext } from "../../../lib/translations.js";

export default {
  components: {
    T1,
  },

  data() {
    const values = {
      ...window.hydrate.data,
    };

    return {
      values,
      form_id: window.hydrate.id,
      $gettext,
    };
  },
  computed: {
    SO5_OPTIONS: () => SO5_OPTIONS,
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t2b","allow-row-removal":_vm.allowRowRemoval,"column-count":4,"label-for-new":_vm.$gettext('+ Other revenues')},scopedSlots:_vm._u([{key:"table_header",fn:function(){return [_c('tr',_vm._l(([
            '',
            _vm.$gettext('Year'),
            _vm.$gettext('Amounts'),
            _vm.$gettext('Additional Information'),
          ]),function(column,index){return _c('th',{key:index,staticClass:"P4-cell"},[_vm._v(" "+_vm._s(column)+" ")])}),0)]},proxy:true},{key:"cells",fn:function({ rowId, value }){return [(
          _vm.OPTIONS.T2b.headings[value.id] &&
          _vm.OPTIONS.T2b.headings[value.id].is_heading
        )?_c('th',{staticClass:"P4-cell text-left"},[_vm._v(" "+_vm._s(_vm.OPTIONS.T2b.headings[value.id].name)+" ")]):(
          _vm.OPTIONS.T2b.headings[value.id] &&
          !_vm.OPTIONS.T2b.headings[value.id].is_heading
        )?_c('td',{staticClass:"P4-cell text-left"},[_vm._v(" "+_vm._s(_vm.OPTIONS.T2b.headings[value.id].name)+" ")]):_c('td',{staticClass:"P4-cell text-left"},[_c('P4Input',{attrs:{"id":rowId + '-id'},model:{value:(value.id),callback:function ($$v) {_vm.$set(value, "id", $$v)},expression:"value.id"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-year',"text":""},model:{value:(value['year']),callback:function ($$v) {_vm.$set(value, 'year', $$v)},expression:"value['year']"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-amounts',"numeric":""},model:{value:(value['amounts']),callback:function ($$v) {_vm.$set(value, 'amounts', $$v)},expression:"value['amounts']"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-additional_information',"placeholder":"NA","textarea":""},model:{value:(value.additional_information),callback:function ($$v) {_vm.$set(value, "additional_information", $$v)},expression:"value.additional_information"}})],1)]}},{key:"table_footer",fn:function({ values }){return [_c('tr',[_c('th',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"P4-cell"},[_vm._v("Total revenues / total per year")]),_c('td',{staticClass:"P4-cell",attrs:{"colspan":"3"}},[_c('P4Input',{attrs:{"id":'total_expenditures',"numeric":""},model:{value:(values.total_revenues),callback:function ($$v) {_vm.$set(values, "total_revenues", $$v)},expression:"values.total_revenues"}})],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
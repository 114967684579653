<template>
  <section>
    <P4Form v-model="values">
      <h3 v-translate class="P4-caption">SO4-VT.T1</h3>
      <p v-translate class="P4-guidance">
        List any target that your country has set relevant to strategic
        objective 4 and indicate the expected year of achievement and level of
        application (i.e. national, subnational).
      </p>
      <CommentAnchor :name="form_id + 'V.T1'" />
      <T1 />

      <h2 v-translate class="P4-section-title">Complementary information</h2>
      <p v-translate class="P4-guidance">
        Provide any complementary information you deem relevant including any
        documentary information (with metadata) in the space provided in the
        PRAIS portal.
      </p>
      <div class="pb-8">
        <P4Input v-model="values.so4_v_general_comments" textarea standalone />
      </div>
    </P4Form>
  </section>
</template>

<script>
import { allOptions } from "../../../lib/translations.js";
import T1 from "./T1.vue";
import OPTIONS from "./OPTIONS.js";

function valuesForAllOptions() {
  return {
    t1: Object.keys(OPTIONS.level_of_application).map(
      (level_of_application) => {
        return { level_of_application };
      }
    ),
  };
}

export default {
  components: {
    T1,
  },
  data() {
    const values = window.hydrate.data || {};

    if (allOptions()) {
      Object.assign(values, valuesForAllOptions());
    }

    return {
      values,
      form_id: window.hydrate.id,
    };
  },
};
</script>

import groupByUnique from "@/lib/groupByUnique";

export const LC_REPORTING = "LC-REPORTING";
export const LC_BASELINE = "LC-BASELINE";
export const LP_REPORTING = "LP-REPORTING";
export const LP_BASELINE = "LP-BASELINE";
export const SOC_REPORTING = "SOC-REPORTING";
export const SOC_BASELINE = "SOC-BASELINE";

function periodIndicatorYear(allAvailableYears, countryAreas, indicator) {
  return allAvailableYears.find((year) =>
    countryAreas[year].indicators.includes(indicator)
  );
}

/**
 * Expose several computed properties for the land area of the country
 * in the baseline and reporting year.
 */
export default {
  computed: {
    so1_1_t1() {
      return window.hydrate.so1_1_t1;
    },
    countryAreas() {
      return groupByUnique(this.so1_1_t1, "year");
    },
    allAvailableYears() {
      // Sorted in descending order.
      return Object.keys(this.countryAreas)
        .filter((year) => !year.endsWith("."))
        .map((year) => parseInt(year))
        .filter((year) => Number.isInteger(year))
        .sort((a, b) => b - a);
    },
    baselineLandCoverYear() {
      return periodIndicatorYear(
        this.allAvailableYears,
        this.countryAreas,
        LC_BASELINE
      );
    },
    reportingLandCoverYear() {
      return periodIndicatorYear(
        this.allAvailableYears,
        this.countryAreas,
        LC_REPORTING
      );
    },
    baselineProductivityYear() {
      return periodIndicatorYear(
        this.allAvailableYears,
        this.countryAreas,
        LP_BASELINE
      );
    },
    reportingProductivityYear() {
      return periodIndicatorYear(
        this.allAvailableYears,
        this.countryAreas,
        LP_REPORTING
      );
    },
    baselineSOCYear() {
      return periodIndicatorYear(
        this.allAvailableYears,
        this.countryAreas,
        SOC_BASELINE
      );
    },
    reportingSOCYear() {
      return periodIndicatorYear(
        this.allAvailableYears,
        this.countryAreas,
        SOC_REPORTING
      );
    },
    baselineLandCoverTotalLandArea() {
      return this.countryAreas[this.baselineLandCoverYear]?.total_land_area;
    },
    reportingLandCoverTotalLandArea() {
      return this.countryAreas[this.reportingLandCoverYear]?.total_land_area;
    },
    baselineProductivityTotalLandArea() {
      return this.countryAreas[this.baselineProductivityYear]?.total_land_area;
    },
    reportingProductivityTotalLandArea() {
      return this.countryAreas[this.reportingProductivityYear]?.total_land_area;
    },
    baselineSOCTotalLandArea() {
      return this.countryAreas[this.baselineSOCYear]?.total_land_area;
    },
    reportingSOCTotalLandArea() {
      return this.countryAreas[this.reportingSOCYear]?.total_land_area;
    },
    baselineLandCoverTotalCountryArea() {
      return this.countryAreas[this.baselineLandCoverYear]?.total_country_area;
    },
    reportingLandCoverTotalCountryArea() {
      return this.countryAreas[this.reportingLandCoverYear]?.total_country_area;
    },
  },
};

<template>
  <FormulateInput type="multitable" :name="name">
    <template #table_header>
      <tr class="P4-cell P4-cell-border">
        <th></th>
        <th
          v-for="header in tableHeaders"
          :key="header.key"
          colspan="2"
          class="P4-cell-border"
        >
          {{ header.name }}
        </th>
      </tr>
      <tr>
        <th v-translate class="P4-cell P4-cell-border">Reporting year</th>

        <template v-for="header in tableHeaders">
          <th
            :key="`${header.key}-unit`"
            v-translate
            class="P4-cell P4-cell-border"
          >
            Population count
          </th>
          <th
            :key="`${header.key}-header-percent`"
            class="P4-cell P4-cell-border"
          >
            %
          </th>
        </template>
      </tr>
    </template>

    <template #cells="{ rowId, rowIndex, value }">
      <th class="P4-cell">
        {{ value.year }}
      </th>

      <template v-for="field in tableFields">
        <td :key="`${field.key}-input`" class="text-right px-2">
          <P4Input
            :id="`${rowId}-${field.key}`"
            v-model="value[field.key]"
            :default-value-has-changed="
              defaultValueHasChanged(
                [name, rowIndex, field.key],
                value[field.key]
              )
            "
            :placeholder="$gettext('No data')"
            integer
          />
        </td>
        <td :key="`${field.key}-percent`" class="text-right px-2">
          <P4DisplayValue
            :value="formatPercent(value[field.key], totalPopulation(value))"
          />
        </td>
      </template>

      <td class="text-right px-2">
        <P4DisplayValue :value="totalExposed(value)" />
      </td>
      <td class="text-right px-2">
        <P4DisplayValue
          :value="formatPercent(totalExposed(value), totalPopulation(value))"
        />
      </td>
    </template>
  </FormulateInput>
</template>

<script>
import { round } from "@/lib/calculateTotal";
import formatPercent from "@/lib/formatPercent";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  name: "ExposedTable",
  mixins: [defaultValuesChange],
  props: {
    name: {
      type: String,
      required: true,
    },
    populationType: {
      type: String,
      required: true,
    },
  },
  computed: {
    tableFields() {
      return [
        {
          name: this.$gettext("Non-exposed"),
          key: "non_drought_population_count",
        },
        {
          name: this.$gettext("Mild drought"),
          key: "mild_drought_population_count",
        },
        {
          name: this.$gettext("Moderate drought"),
          key: "moderate_drought_population_count",
        },
        {
          name: this.$gettext("Severe drought"),
          key: "severe_drought_population_count",
        },
        {
          name: this.$gettext("Extreme drought"),
          key: "extreme_drought_population_count",
        },
      ];
    },
    tableHeaders() {
      return [
        ...this.tableFields,
        {
          name: this.populationType,
          key: "population-type",
        },
      ];
    },
  },
  methods: {
    formatPercent,
    totalPopulation(value) {
      let total = 0;
      total = this.tableFields
        .map((field) => Number(value[field.key]))
        .reduce((sum, i) => sum + i, 0);
      return round(total);
    },
    totalExposed(value) {
      const total = this.totalPopulation(value);
      const exposed = total - value.non_drought_population_count;
      return isNaN(exposed) ? "-" : round(exposed);
    },
  },
};
</script>

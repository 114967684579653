var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormulateInput',{attrs:{"type":"multitable","name":"t1","columns":[
    _vm.$gettext('Year'),
    _vm.$gettext('Protected Areas Coverage(%)'),
    _vm.$gettext('Lower Bound'),
    _vm.$gettext('Upper Bound'),
    _vm.$gettext('Comments'),
  ]},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('td',{staticClass:"P4-cell"},[_vm._v(" "+_vm._s(value.year)+" ")]),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-protected_areas_coverage',"default-value-has-changed":_vm.defaultValueHasChanged(
          ['t1', rowIndex, 'protected_areas_coverage'],
          value.protected_areas_coverage
        ),"percent":"","validate":"roundTo2"},model:{value:(value.protected_areas_coverage),callback:function ($$v) {_vm.$set(value, "protected_areas_coverage", $$v)},expression:"value.protected_areas_coverage"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-lower_bound',"default-value-has-changed":_vm.defaultValueHasChanged(
          ['t1', rowIndex, 'lower_bound'],
          value.lower_bound
        ),"numeric":"","validate":"roundTo2"},model:{value:(value.lower_bound),callback:function ($$v) {_vm.$set(value, "lower_bound", $$v)},expression:"value.lower_bound"}})],1),_c('td',{staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-upper_bound',"default-value-has-changed":_vm.defaultValueHasChanged(
          ['t1', rowIndex, 'upper_bound'],
          value.upper_bound
        ),"numeric":"","validate":"roundTo2"},model:{value:(value.upper_bound),callback:function ($$v) {_vm.$set(value, "upper_bound", $$v)},expression:"value.upper_bound"}})],1),_c('td',{staticClass:"P4-cell"},[_c('P4Input',{attrs:{"id":rowId + '-comments'},model:{value:(value.comments),callback:function ($$v) {_vm.$set(value, "comments", $$v)},expression:"value.comments"}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <FormulateInput
      type="multitable"
      :name="name"
      :columns="
        [''].concat(headingNamesKm).concat([$gettext('Total land area (km²)')])
      "
    >
      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          {{ headingNamesKm[rowIndex] }}
        </th>
        <td
          v-for="(cls, index) in headingKeys"
          :key="`${cls}-${index}`"
          class="P4-cell-right"
        >
          <P4Input
            :id="rowId + '-' + cls"
            v-model="value[cls]"
            :default-value-has-changed="defaultValueHasChanged(
              ['t7', rowIndex, cls],
              value[cls]
            )"
            numeric
          />
        </td>
        <th :id="`${rowId}-total`" class="P4-cell-right">
          <P4DisplayValue :value="calculateRowTotal(value)" />
        </th>
      </template>

      <template #table_footer="{ values, id }">
        <tr>
          <th v-translate class="P4-cell">Total</th>
          <td
            v-for="(cls, index) in headingKeys"
            :id="`${id}-${cls}-total`"
            :key="`${cls}-${index}`"
            class="P4-cell-right"
          >
            <P4DisplayValue :value="calculateColumnTotal(values, cls)" />
          </td>
          <th class="P4-cell-right"></th>
        </tr>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { gettextLazy as $gettext } from "../../../lib/translations.js";
import T3HeadingsMixIn from "@/forms/so1/1/customT3HeadingsMixIn";
import defaultValuesChange from "../../../lib/mixins/defaultValuesChange";

export default {
  mixins: [defaultValuesChange, T3HeadingsMixIn],
  data() {
    return {
      $gettext,
    };
  },
};
</script>

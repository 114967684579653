import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  Form: {
    answers: {
      yes: _("Yes"),
      only1: _("Only1"),
      only2: _("Only2"),
      no: _("No"),
    },
    sub_indicators: {
      land_cover: _("Land Cover"),
      land_productivity_dinamics: _("Land Productivity Dynamics"),
      soil_organic_carbon_stock: _("SOC Stock"),
    },
    level_of_confidence: {
      high: _("High (based on comprehensive evidence)"),
      medium: _("Medium (based on partial evidence)"),
      low: _("Low (based on limited evidence)"),
    },
    yes_no: {
      yes: _("Yes"),
      no: _("No"),
    },
  },
  T1: {
    baseline_period: {
      name: _("Baseline Period"),
      tooltip: _(
        "The baseline sets the benchmark extent of degradation, against which progress towards achieving SDG Target 15.3 and Land Degradation Neutrality is assessed"
      ),
    },
    reporting_period: {
      name: _("Reporting Period"),
      tooltip: _(
        "The reporting period estimate includes (i) areas of land with new degradation since 2015; and (ii) areas of land that have persisted in a degraded state since the baseline period (i.e. have not improved to a non-degraded state)."
      ),
    },
  },
  T1Footer: {
    change: {
      name: _("Change in degraded extent"),
      tooltip: _(
        "Change in degraded extent between the baseline and current reporting period"
      ),
    },
  },
  T2: {
    total_area_of_improved_land_gains: _("Total Area of Improved Land (Gains)"),
    total_area_of_degraded_land_losses: _(
      "Total Area of Degraded Land (Losses)"
    ),
    ldn_status_net_gain_losses: _(
      "Land Degradation Neutrality Status (Net Gain/Loss)"
    ),
  },
  T3: {
    headings: {
      location_name: {
        name: _("Location Name"),
        tooltip: _("Provide a place name to locate the area being reported"),
      },
      type: {
        name: _("Type"),
        // Might be added back in the future
        // tooltip: _(
        //   "False Positive: Justify why any area identified as degraded in the SO1-1, SO1-2 or SO1-3 data should not be included in the Sustainable Development Goal (SDG) indicator 15.3.1 calculation. False Negative: Justify why any area identified as non-degraded in the SO1-1, SO1-2 or SO1-3 data should be included as degradation in the SDG indicator 15.3.1 calculation"
        // ),
      },
      recode: {
        name: _("Recode Options"),
        tooltip: "",
      },
      // Removed, might be re-added in the future
      // indicator: {
      //   name: _("Indicator Values"),
      //   tooltip: _(
      //     "The graph represents the percentage of the total area of the polygon that is degraded or improved per sub-indicator. It should be used as a guide to understand which sub-indicator is driving the false positive or negative process being reported within the polygon extent provided."
      //   ),
      // },
      area: {
        name: _("Area (km²)"),
        tooltip: _(
          "The spatial extent of the area identified (if spatial data is used). Please enter the approximate extent of the area being reported (if known) if the area being reported is not supported by spatial information."
        ),
      },
      process_driving: {
        name: _("Process driving false +/- outcome"),
        tooltip: _(
          "A description of the processes driving reevaluation of the degradation assessment"
        ),
      },
      basic_of_judgement: {
        name: _("Basis for Judgement"),
        tooltip: _(
          "What led to the assessment – a local confirmation, scientific study or “another” assessment process?"
        ),
      },
      polygon: {
        name: _("Edit Polygon"),
        tooltip: _(
          "A button will appear in this column after adding a feature in the Spatial data viewer. If the reporting of the false positive or false negative area is to be supported by spatial information, please use this button to delineate the area within PRAIS or to upload an existing spatial file of the areas being reported. Note: If this option is not selected, only certain (non-spatial) fields in this table remain editable and there will be no recalculation of the SDG Indicator 15.3.1 for your country."
        ),
      },
    },
    options: {
      custom: _("Custom"),
      degradation: {
        "": "",
        urban_expansion: _("Urban expansion"),
        deforestation: _("Deforestation"),
        vegetation_loss: _("Vegetation Loss"),
        inundation: _("Inundation"),
        woody_encroachment: _("Woody Encroachment"),
        wetland_drainage: _("Wetland Drainage"),
        soil_erosion: _("Soil Erosion"),
        invasive_species_encroachment: _("Invasive Species Encroachment"),
        loss_of_soil_fertility: _("Loss of Soil Fertility"),
        other: _("Other"),
      },
      recode_false_positive: {
        recode_improved_degraded: _("Recode improved as degraded"),
        recode_improved_stable: _("Recode improved as stable"),
      },
      recode_false_negative: {
        recode_degraded_improved: _("Recode degraded as improved"),
        recode_degraded_stable: _("Recode degraded as stable"),
      },
      type: {
        false_positive: _("False Positive"),
        false_negative: _("False Negative"),
      },
      basic_of_judgement: {
        "": "",
        confirmed_locally: _("Confirmed Locally"),
        scientific_study: _("Scientific Study"),
        other: _("Other"),
      },
    },
  },

  T4: {
    indirectDriversQuestion: {
      text: _(
        "What is/are the indirect driver(s) of land degradation at the national level?"
      ),
      tooltipText: _(
        "These are the indirect drivers of land degradation identified in the Assessment Report on Land Degradation and Restoration by IPBES. Select all applicable drivers which indirectly drive degradation hotspots at the national level and rank them in terms of importance. Please note that this reporting item is only relevant at the national level when all reported hotspots have been accumulated."
      ),
    },
    headings: {
      hotspots: {
        name: _("Hotspots"),
        tooltip: _(
          "Areas of intense degradation, highly vulnerable to further degradation in the absence of urgent remediation activities"
        ),
      },
      location: {
        name: _("Location"),
        tooltip: _("Provide a placename to identify the location of hotspot"),
      },
      area: {
        name: _("Area (km²)"),
        tooltip: _(
          "The spatial extent of the hotspot – this will be pre-populated if the hotspot has been delineated in PRAIS. Otherwise, this is an optional field to be filled in based on the country’s best estimate of the area of the hotspot."
        ),
      },
      assessment_process: {
        name: _("Assessment Process"),
        tooltip: _(
          "Select the assessment process which determined the location and extent of the hotspot."
        ),
      },
      direct_driver_of_land_degradation_hotspots: {
        name: _("Direct drivers of land degradation hotspots"),
        tooltip: _(
          "These are the direct anthropogenic drivers of land degradation identified in the Assessment Report on Land Degradation and Restoration by the Intergovernmental Science-Policy Platform on Biodiversity and Ecosystem Services (IPBES). Select all applicable local drivers which exacerbate the hotspot and rank them in terms of importance."
        ),
      },
      action_taken_to_redress_degradation: {
        name: _(
          "Action(s) taken to redress degradation in terms of Land Degradation Neutrality response hierarchy"
        ),
        tooltip: _(
          "Indicate whether the action(s) taken at the hotspot level is/are aimed at avoiding, reducing and/or reversing land degradation"
        ),
      },
      remediating_action: {
        name: _("Remediating action(s) (both forward-looking and current)"),
        tooltip: _(
          "Describe actions taken, either now or in the future, to remediate the hotspot. First. select one or more relevant groups of measures, then select all specific restoration measures that apply."
        ),
      },
      polygon: {
        name: _("Edit Polygon"),
        tooltip: _(
          "A button will appear in this column after adding a feature in the Spatial data viewer"
        ),
      },
    },
    headings_affected_areas: {
      hotspots: {
        name: _("Hotspots"),
        tooltip: _(
          "Areas of intense degradation, highly vulnerable to further degradation in the absence of urgent remediation activities"
        ),
      },
      location: {
        name: _("Location"),
        tooltip: _("Provide a placename to identify the location of hotspot"),
      },
      area: {
        name: _("Area (km²)"),
        tooltip: _(
          "The spatial extent of the hotspot – this will be pre-populated if the hotspot has been delineated in PRAIS. Otherwise, this is an optional field to be filled in based on the country’s best estimate of the area of the hotspot."
        ),
      },
      assessment_process: {
        name: _("Assessment Process"),
        tooltip: _(
          "Select the assessment process which determined the location and extent of the hotspot."
        ),
      },
      direct_driver_of_land_degradation_hotspots: {
        name: _("Direct drivers of land degradation hotspots"),
        tooltip: _(
          "These are the direct anthropogenic drivers of land degradation identified in the Assessment Report on Land Degradation and Restoration by the Intergovernmental Science-Policy Platform on Biodiversity and Ecosystem Services (IPBES). Select all applicable local drivers which exacerbate the hotspot and rank them in terms of importance."
        ),
      },
      action_taken_to_redress_degradation: {
        name: _(
          "Action(s) taken to redress degradation in terms of Land Degradation Neutrality response hierarchy"
        ),
        tooltip: _(
          "Indicate whether the action(s) taken at the hotspot level is/are aimed at avoiding, reducing and/or reversing land degradation"
        ),
      },
      remediating_action: {
        name: _("Remediating action(s) (both forward-looking and current)"),
        tooltip: _(
          "Describe actions taken, either now or in the future, to remediate the hotspot. First. select one or more relevant groups of measures, then select all specific restoration measures that apply."
        ),
      },
      polygon: {
        name: _("Edit Polygon"),
        tooltip: _(
          "A button will appear in this column after adding a feature in the Spatial data viewer"
        ),
      },
    },
    options: {
      drivers_direct: {
        "": "",
        deforestation: _(
          "Deforestation and clearance of other native vegetation"
        ),
        grazing: _("Grazing land management"),
        cropland_and_agroforestry: _("Cropland and agroforestry management"),
        native_and_planted_forest: _("Native and planted forest management"),
        non_timber_natural__resource_extraction: _(
          "Non-timber natural resource extraction"
        ),
        fire_regime_change: _("Fire regime change"),
        invasive_alien_species: _("Invasive Alien Species"),
        land_abandonment: _("Land abandonment"),
        mineral_resource_extraction: _("Mineral resource extraction"),
        infrastructure: _("Infrastructure, industry and urbanization"),
        climate_change: _("Climate change"),
      },
      drivers_indirect: {
        "": "",
        demographic: _("Demographic"),
        economic: _("Economic"),
        science: _("Science, knowledge and technology"),
        institutions: _("Institutions and governance"),
        cultural: _("Cultural"),
      },
      action_taken: {
        avoid: _("Avoid"),
        reduce: _("Reduce"),
        reverse: _("Reverse"),
      },
      remediating_actions: {
        restore_improve_tree_covered_area: {
          label: _("Restore/improve tree-covered areas"),
          options: {
            reduce_halt_deforestation: _(
              "Reduce/halt deforestation and conversion of tree cover to other land cover types (e.g. conserving forest land)"
            ),
            restore_tree_covered_areas: _("Restore tree-covered areas"),
            increase_land_productivity: _(
              "Increase land productivity in tree covered areas"
            ),
            restore_improve_grassland: _("Restore/improve grasslands"),
            improve_tree_cover_management: _(
              "Improve tree cover management e.g. fire management"
            ),
          },
        },
        increase_tree_covered_areas_extend: {
          label: _("Increase tree-covered area extent"),
          options: {
            increase_tree_covered_land: _(
              "Increase tree covered land (net gain) e.g. plantations"
            ),
          },
        },
        restore_improve_cropland: {
          label: _("Restore/improve croplands"),
          options: {
            increase_land_productivity_in_agricultural_areas: _(
              "Increase land productivity in agricultural areas"
            ),
            improve_water_use_for_irrigation: _(
              "Improve water use for irrigation"
            ),
            rehabilitate_bare_or_degraded_land_for_crop_production: _(
              "Rehabilitate bare or degraded land for crop production"
            ),
            sustainable_land_management: _(
              "Practise sustainable land management"
            ),
            halt_reduce_conversion_of_cropland: _(
              "Halt/reduce conversion of cropland to other land cover types"
            ),
          },
        },
        restore_improve_grassland: {
          label: _("Restore/improve grasslands"),
          options: {
            restore_and_improve_pastures: _("Restore and improve pastures"),
            restore_rangeland: _(
              "Restore rangeland (e.g. by controlling livestock and wildfires)"
            ),
            improve_land_productivity_in_grassland: _(
              "Improve land productivity in grasslands"
            ),
            halt_reduce_conversion_of_grassland: _(
              "Halt/reduce conversion of grassland to other land cover types"
            ),
          },
        },
        restore_improve_wetland: {
          label: _("Restore/improve wetlands"),
          options: {
            halt_reduce_conversion_of_wetland: _(
              "Halt/reduce wetland conversion to other land uses (includes conserving wetlands)"
            ),
            restore_preserve_wetlands: _(
              "Restore/preserve wetlands and reduce degradation of wetlands"
            ),
          },
        },
        increase_soil_fertility_and_carbon_stock: {
          label: _("Increase soil fertility and carbon stock"),
          options: {
            rehabilitate_bare_land_and_or_restore_degraded_land: _(
              "Rehabilitate bare land and/or restore degraded land"
            ),
            reduce_soil_erosion: _("Reduce soil erosion"),
            increase_carbon_stock_and_reduce_soil_land_degradation: _(
              "Increase carbon stock and reduce soil/land degradation"
            ),
            reduce_sand_encroachment: _("Reduce sand encroachment"),
            maintain_current_level_of_soc: _(
              "Maintain the current level of SOC"
            ),
            improve_watershed_landscape_management: _(
              "Improve watershed/landscape management"
            ),
          },
        },
        manage_artificial_surfaces: {
          label: _("Manage artificial surfaces"),
          options: {
            restore_degraded_mining_areas: _("Restore degraded mining areas"),
            halt_illegal_mining_and_or_reduce_mining_area: _(
              "Halt illegal mining and/or reduce mining areas"
            ),
            improve_land_productivity_on_artificial_surfaces: _(
              "Improve land productivity on artificial surfaces"
            ),
            halt_reduce_regulate_expansion_of_urban_artificial_surfaces: _(
              "Halt/reduce/regulate expansion of urban/artificial surfaces"
            ),
          },
        },
        restore_improve_protected_areas: {
          label: _("Restore/improve protected areas"),
          options: {
            restore_protected_areas: _("Restore protected areas"),
            improve_management_of_protected_areas: _(
              "Improve management of protected areas"
            ),
          },
        },
        increase_protected_areas: {
          label: _("Increase protected areas"),
          options: {
            increase_protected_area_extent: _("Increase protected area extent"),
          },
        },
        improve_coastal_management: {
          label: _("Improve coastal management"),
          options: {
            reduce_coastal_erosion: _("Reduce coastal erosion"),
            reduce_saline_water_intrusion_in_coastal_zone: _(
              "Reduce saline water intrusion in coastal zones"
            ),
          },
        },
        general_instrument: {
          label: _("General instrument (e.g. policies, economic incentives)"),
        },
        restore_improve_multiple_land_use: {
          label: _("Restore/improve multiple land uses"),
        },
        reduce_halt_conversion_of_multiple_land_uses: {
          label: _("Reduce/halt conversion of multiple land uses"),
        },
        restore_improve_multiple_functions: {
          label: _("Restore/improve multiple functions"),
        },
        restore_productivity_and_soc_stock: {
          label: _(
            "Restore productivity and soil organic carbon stock in croplands and grasslands"
          ),
        },
        other_general_unspecified: {
          label: _("Other/general/unspecified"),
          options: {
            avoid_prevent_halt_degradation_of_degraded_lands: _(
              "Avoid/prevent/halt degradation (of degraded lands)"
            ),
            restore_vegetation_cover_unspecified_land_use: _(
              "Restore vegetation cover (unspecified land use)"
            ),
            achieve_ldn: _("Achieve LDN"),
            improve_land_productivity_unspecified_land_use: _(
              "Improve land productivity (unspecified land use)"
            ),
            other_general_unspecified: _("Other/general/unspecified"),
          },
        },
      },
      status_of_target_achivement: {
        achieved: _("Achieved"),
        ongoing: _("Ongoing"),
        extended: _("Extended or postponed"),
        not_achived: _("Not achieved"),
        partially_achieved: _("Partially achieved"),
      },
      ldn_target_answer_yes: {
        ldn_pilot_project: _("LDN pilot project"),
        ldn_target_setting_programme: _(
          "Participation in the LDN Target Setting Programme"
        ),
        other: _("Other process"),
      },
      other_important_goals_for_target: {
        cbd: _(
          "Convention on Biological Diversity – National Biodiversity Strategies and Action Plans & National Targets"
        ),
        unfccc: _(
          "United Nations Framework Convention on Climate Change – Nationally Determined Contributions"
        ),
        bonn: _("Bonn Challenge"),
        afr100: _("AFR100"),
        initiative: _("Initiative 20x20"),
        ecca30: _("ECCA30"),
        other: _("Other"),
      },
      assessment_process: {
        site_based_data: _("Site-based data"),
        qualitative_information: _("Qualitative information"),
        stakeholder_perspectives: _(
          "Stakeholder perspectives from surveys, workshops and interviews"
        ),
        establishment_of_expert_panels: _("Establishment of expert panels"),
      },
      implemented_action: {
        same_as_targeted_actions: _("Same As Targeted Actions"),
        other: _("Other"),
      },
    },
  },
  T5: {
    enablingResponsesQuestion: {
      text: _(
        "What are the enabling and instrumental responses at the national level driving the occurrence of brightspots?"
      ),
      tooltipText: _(
        "Enabling and Instrumental Responses taken from the Assessment Report on Land Degradation and Restoration (2018) by Intergovernmental Science-Policy Platform on Biodiversity and Ecosystem Services. Select any enabling and instrumental responses which have created positive outcomes at the national level resulting in brightspots, and rank them in terms of importance. Please note that this reporting item is only relevant at the national level when all brightspots have been accumulated."
      ),
    },
    headings: {
      brightspots: {
        name: _("Brightspots"),
        tooltip: _(
          "Areas which do not exhibit any signs of degradation or which have been remediated from a degraded state by implementing appropriate remediation activities or through land planning processes to prevent degradation."
        ),
      },
      location: {
        name: _("Location"),
        tooltip: _(
          "Provide a placename to identify the location of the brightspot."
        ),
      },
      area: {
        name: _("Area (km²)"),
        tooltip: _(
          "The spatial extent of the brightspot – this will be pre-populated if the brightspot has been delineated in PRAIS. Otherwise, this is an optional field to be filled in based on the country’s best estimate of the area of the brightspot."
        ),
      },
      assessment_process: {
        name: _("Assessment Process"),
        tooltip: _(
          "Select the assessment process which determined the location and extent of the brightspot."
        ),
      },
      action_taken: {
        name: _(
          "What action(s) led to the brightspot in terms of the Land Degradation Neutrality hierarchy?"
        ),
        tooltip: _(
          "Indicate whether the action(s) that led to the brightspot were aimed at avoiding, reducing and/or reversing land degradation."
        ),
      },
      implementing_action: {
        name: _("Implementing action(s) (both forward-looking and current)"),
        tooltip: _(
          "Describe the actions which led to the brightspot. First, select one or more relevant groups of measures, then select all specific restoration measures that apply."
        ),
      },
      polygon: {
        name: _("Edit Polygon"),
        tooltip: _(
          "A button will appear in this column after adding a feature in the Spatial data viewer"
        ),
      },
    },
    headings_affected_areas: {
      brightspots: {
        name: _("Brightspots"),
        tooltip: _(
          "Areas which do not exhibit any signs of degradation or which have been remediated from a degraded state by implementing appropriate remediation activities or through land planning processes to prevent degradation."
        ),
      },
      location: {
        name: _("Location"),
        tooltip: _(
          "Provide a placename to identify the location of the brightspot."
        ),
      },
      area: {
        name: _("Area (km²)"),
        tooltip: _(
          "The spatial extent of the brightspot – this will be pre-populated if the brightspot has been delineated in PRAIS. Otherwise, this is an optional field to be filled in based on the country’s best estimate of the area of the brightspot."
        ),
      },
      assessment_process: {
        name: _("Assessment Process"),
        tooltip: _(
          "Select the assessment process which determined the location and extent of the brightspot."
        ),
      },
      action_taken: {
        name: _(
          "What action(s) led to the brightspot in terms of the Land Degradation Neutrality hierarchy?"
        ),
        tooltip: _(
          "Indicate whether the action(s) that led to the brightspot were aimed at avoiding, reducing and/or reversing land degradation."
        ),
      },
      implementing_action: {
        name: _("Implementing action(s) (both forward-looking and current)"),
        tooltip: _(
          "Describe the actions which led to the brightspot. First, select one or more relevant groups of measures, then select all specific restoration measures that apply."
        ),
      },
      polygon: {
        name: _("Edit Polygon"),
        tooltip: _(
          "A button will appear in this column after adding a feature in the Spatial data viewer"
        ),
      },
    },
    options: {
      enabling_and_instrumental_responses: {
        "": "",
        responses_to_the_adverse_effects_of_globalisation: _(
          "Responses to the adverse effects of globalisation, demographic change, migration"
        ),
        legal_and_regulatory_instruments: _("Legal and regulatory instruments"),
        rights_based_instruments_and_customary_norms: _(
          "Rights-based instruments and customary norms"
        ),
        economic_and_financial_instruments: _(
          "Economic and financial instruments"
        ),
        social_and_cultural_instruments: _("Social and cultural instruments"),
        protected_areas: _("Protected areas"),
        climate_change_adaptation_planning: _(
          "Climate change adaptation planning"
        ),
        integrated_landscape_planning: _("Integrated landscape planning"),
        anthropogenic_assets: _("Anthropogenic assets"),
        institutional_and_policy_reform: _("Institutional and policy reform"),
      },
    },
  },
};

<template>
  <div
    :id="name + '-comments'"
    :class="{ 'P4-comment-box': true, hidden: !isCommonRevision }"
  >
    <div v-if="!isReadOnly">
      <div class="pb-6" @click="isHidden = !isHidden">
        <a v-translate class="P4-comment-btn text-xl"> Comments 🗨 </a>
      </div>
    </div>

    <div v-if="!isHidden && !isReadOnly">
      <div v-translate>New comment thread</div>
      <CommentThreadSubmit
        :thread-id="null"
        :anchor="name"
        @changeComment="onSubmit"
        @errorMessage="showError"
      />

      <div v-if="mess" class="P4-comment-message">{{ mess }}</div>
    </div>
    <div v-if="!isHidden || isReadOnly">
      <div
        v-for="thread in commentThreads"
        :key="thread.id"
        class="overflow-hidden pb-6"
      >
        <CommentThread
          :thread="thread"
          :logged-user="loggedUser"
          :is-read-only="isReadOnly"
          :anchor="name"
          @changeComment="onSubmit"
          @errorMessage="showError"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CommentThreadSubmit from "./CommentThreadSubmit";
import CommentThread from "./CommentThread";
import api from "../api";

export default {
  components: {
    CommentThread,
    CommentThreadSubmit,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    isReadOnly: Boolean,
  },

  data() {
    const values = window.hydrate;

    return {
      commentThreads: null,
      isCommonRevision: true,
      loggedUser: values.logged_user,
      context: this.$attrs.context,
      isHidden: true,
      values,
      loading: false,
      mess: "",
    };
  },

  watch: {
    loading: "loadData",
    mess(val) {
      if (val) {
        setTimeout(() => (this.mess = ""), 4000);
      }
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    async loadData() {
      const ct = await api.getCommentThreads("comment?anchor=" + this.name);
      this.commentThreads = ct.comment_threads.threads;

      this.isCommonRevision = ct.comment_threads.is_common_revision;
      this.loading = false;
    },

    onSubmit(event) {
      this.loading = event;
      this.mess = "Success Operation!";
    },

    showError(event) {
      this.mess = event;
    },
  },
};
</script>

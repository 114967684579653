<template>
  <section>
    <FormulateInput type="multitable" name="t1">
      <template #table_header>
        <tr>
          <th v-translate rowspan="2">Year</th>
          <th v-translate colspan="7">
            Soil organic carbon stock in topsoil (t/ha)
          </th>
        </tr>
        <tr>
          <th
            v-for="(column, index) in Object.values(UNCCD_CLASS_MAP)"
            :key="index"
            class="P4-cell"
          >
            {{ column }}
          </th>
        </tr>
      </template>
      <template #cells="{ rowId, rowIndex, value }">
        <th class="P4-cell">
          {{ value.year }}
        </th>
        <td
          v-for="cls in Object.keys(UNCCD_CLASS_MAP)"
          :key="cls"
          class="P4-cell-right"
        >
          <P4Input
            :id="rowId + '-' + cls"
            v-model="value[cls]"
            :default-value-has-changed="defaultValueHasChanged(
              ['t1', rowIndex, cls],
              value[cls]
            )"
            numeric
          />
        </td>
      </template>
    </FormulateInput>
  </section>
</template>

<script>
import { UNCCD_CLASS_MAP } from "../../../lib/constants.js";
import defaultValuesChange from '../../../lib/mixins/defaultValuesChange';

export default {
  mixins: [defaultValuesChange],
  computed: {
    UNCCD_CLASS_MAP: () => UNCCD_CLASS_MAP,
  },
};
</script>

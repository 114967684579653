<template>
  <div v-if="version === 'draft' && isAAForm === false">
    <FormulateInput
      id="FormulateInput_Reload"
      type="button"
      :label="label"
      ignored
      :disabled="isDisabled"
      :input-class="state === 'failed' ? 'P4-failed' : 'P4-reload-data'"
      @click="loadData"
    />
    <p v-if="isDisabled" v-translate class="italic">No available data</p>
  </div>
</template>

<script>
import { gettextLazy as $gettext } from "../translations";
import api from "../api";

export default {
  name: "RestoreDefaultdata",
  props: {
    name: String,
  },
  data() {
    return {
      $gettext,
      form_id: window.hydrate.id,
      iso3: window.hydrate.iso3,
      version: window.hydrate.version,
      isDisabled: false,
      state: "default",
      isAAForm: window.hydrate.is_aa_form,
    };
  },
  computed: {
    label() {
      switch (this.state) {
        case "default":
          return this.$gettext("Reload default data");
        case "reloading":
          return this.$gettext("Reloading...");
        case "failed":
          return this.$gettext("Reload (failed)");
      }
      throw new Error(`Unknown state ${this.state}`);
    },
    get_url: function () {
      let url = "";
      url =
        "/country/" +
        this.iso3 +
        "/report/" +
        this.version +
        "/defaultdata/" +
        this.form_id;
      return { url };
    },
  },

  methods: {
    async loadData() {
      const tableName =
        this.name.length !== 2 ? this.name.substring(0, 2) : this.name;
      try {
        this.state = "reloading";
        const ct = await api.getDefaultData(this.get_url.url);
        if (ct.data_values[tableName] == null) {
          this.state = "failed";
          this.isDisabled = true;
          return;
        }
        this.$emit("reloadDefaultData", {
          tableValue:
            (ct.form_id_table === "so1-2" &&
              (tableName === "t3" || tableName === "t4")) ||
            (ct.form_id_table === "so1-3" &&
              (tableName === "t2" || tableName === "t3"))
              ? ct.data_values[tableName].slice(0, 4)
              : ct.data_values[tableName],
          tableName: this.name,
        });
        this.state = "default";
      } catch (e) {
        this.state = "failed";
        throw e;
      }
    },
  },
};
</script>

<template>
  <div>
    <table class="rounded-t-lg m-5 bg-gray-200 text-gray-800 P4-multitable">
      <thead>
        <FormulateSlot :context="context" name="table_header">
          <tr class="text-left">
            <th
              v-for="(column, index) in columnObjects"
              :key="index"
              class="P4-cell"
            >
              <Tooltip v-if="column.tooltip" :content="column.tooltip">
                {{ column.name }}
                <span class="tooltip-icon">ⓘ</span>
              </Tooltip>
              <span v-if="!column.tooltip"> {{ column.name }} </span>
            </th>

            <th v-if="labelForNew" class="print-hide">
              <!-- column for delete buttons -->
            </th>
          </tr>
        </FormulateSlot>
      </thead>

      <tbody :data-is-repeatable="true">
        <tr v-for="(item, index) in context.model" :key="index" class="P4-row">
          <FormulateSlot
            :context="context"
            :model="context.model"
            :row-id="idPrefix + '-' + index"
            :row-index="index"
            :id-prefix="idPrefix"
            :value="item"
            name="cells"
          />
          <td v-if="labelForNew" class="P4-cell print-hide">
            <div v-if="canBeRemoved(item)" class="relative">
              <a
                :id="idPrefix + '-remove-' + index"
                :class="context.classes.groupRepeatableRemove"
                role="button"
                @click.prevent="removeItem(index)"
                @keypress.enter="removeItem(index)"
                v-text="context.removeLabel"
              />
            </div>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr
          v-if="labelForNew"
          class="bg-gray-100 border-b border-gray-200 print-hide"
        >
          <td :colspan="columnCount + 1" class="P4-cell">
            <FormulateInput
              :id="idPrefix + '-new'"
              type="button"
              :label="labelForNew"
              data-minor
              data-ghost
              :disabled="isDisabled"
              :input-class="isDisabled ? 'P4-disabled' : ''"
              @click="addMore"
            />
          </td>
        </tr>
        <FormulateSlot
          :id="idPrefix + '-footer'"
          :context="context"
          :values="context.model"
          name="table_footer"
        />
      </tfoot>
    </table>
    <div
      v-for="(warning, index) in customWarnings"
      :key="index"
      class="P4-text-lightblue-info text-xs"
    >
      <p class="print-hide">
        <i v-if="warning" class="fas fa-exclamation-circle"></i> {{ warning }}
      </p>
    </div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip.vue";
import { allOptions } from "../translations.js";
import * as utils from "../warnings.js";
import { mapState } from "vuex";

export default {
  name: "MultiTable",
  components: {
    Tooltip,
  },
  // XXX Note any prop defined here will need to redefined in registerComponents.js as well
  props: {
    columns: {
      type: Array,
      default: () => [],
      required: false,
    },
    labelForNew: {
      type: String,
      default: "",
      required: false,
    },
    columnCount: {
      type: Number,
      default: 0,
      required: false,
    },
    rowFactory: {
      type: [Object, Function],
      default: () => ({}),
      required: false,
    },
    allowRowRemoval: {
      type: Function,
      required: false,
      default: () => true,
    },
    disableRowAdd: {
      type: Boolean,
      required: false,
      default: false,
    },
    warnings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      context: this.$attrs.context,
      isReadOnly: window.hydrate.readonly,
      isCompleted: window.hydrate.is_completed,
    };
  },
  computed: {
    inputs() {
      return this.context.model;
    },
    defaultTableData() {
      return window.hydrate.default_data[this.context.name];
    },
    customWarnings() {
      const result =
        this.warnings?.reduce((accumulator, currentValue) => {
          const warning = utils[currentValue.name](
            this.inputs,
            ...currentValue.args
          );

          return warning ? [...accumulator, warning] : accumulator;
        }, []) || [];

      return result;
    },
    idPrefix() {
      return "multitableinput-" + this.context.name;
    },
    allOptions,
    columnObjects() {
      // XXX: normalize the columns as `{ name, tooltip }` objects
      return this.columns
        ? this.columns.map((column) => {
            if (typeof column === "object") {
              return column;
            } else {
              return { name: column };
            }
          })
        : [];
    },
    isDisabled() {
      return this.isReadOnly || this.isCompleted || this.disableRowAdd;
    },
    // add newRowTableAutocomplete from store
    ...mapState({
      newRowTableAutocomplete: (state) => state.newRowTableAutocomplete,
    }),
  },
  watch: {
    newRowTableAutocomplete: {
      // XXX watching store (vuex) changes is instead of using @input for more reliability
      // if any tables changed any relevant input values (from, to)
      // find existing from/to combination in default values to replace in order to autocomplete
      // or if it doesn't exist it will replace with empty values
      handler: function (storeItem) {
        if (!this.newRowTableAutocomplete[this.context.name]) return;

        const item = storeItem[this.context.name].value;
        const index = storeItem[this.context.name].rowIndex;

        const findItem = this.defaultTableData.find((itemData) => {
          return itemData.from === item.from && itemData.to === item.to;
        });
        // replace the model for immutability allow other imput values
        // to be changed
        this.context.model = findItem
          ? [
              ...this.context.model.slice(0, index),
              { ...findItem },
              ...this.context.model.slice(index + 1),
            ]
          : [
              ...this.context.model.slice(0, index),
              { from: item.from, to: item.to },
              ...this.context.model.slice(index + 1),
            ];
      },
      deep: true,
    },
  },
  created() {
    if (!Array.isArray(this.context.model)) {
      this.context.model = [];
    }
    // Certain rows will be autogenerated from the backend. (see vector layers)
    // Make sure the default row factory is always used as a starting base for
    // all rows in the multitable model, otherwise the component will crash.
    //
    // Do this for ALL tables instead of only the ones with a rowFactory, for
    // consistency's sake and to ensure that reactivity is the same across the
    // board.
    this.context.model = this.context.model.map((row) => {
      return {
        ...this.getRowFactoryData(),
        ...row,
      };
    });
  },
  methods: {
    getRowFactoryData() {
      let rowFactoryData = {};
      if (typeof this.rowFactory === "function") {
        rowFactoryData = this.rowFactory();
      } else if (typeof this.rowFactory === "object") {
        // Make a deep copy, as even if you destructure, objects inside share references
        rowFactoryData = JSON.parse(JSON.stringify(this.rowFactory));
      }
      return rowFactoryData;
    },
    addMore() {
      this.context.model.push(this.getRowFactoryData());
    },
    removeItem(index) {
      this.context.model.splice(index, 1);
    },
    canBeRemoved(item) {
      return (
        this.labelForNew &&
        !this.allOptions &&
        !this.isDisabled &&
        this.allowRowRemoval(item)
      );
    },
  },
};
</script>

<style scoped>
.P4-multitable::v-deep .formulate-input {
  margin: 0;
}
</style>

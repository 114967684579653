var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t1"},scopedSlots:_vm._u([{key:"table_header",fn:function(){return [_c('tr',[_c('th',{attrs:{"rowspan":"2"}}),_c('th',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"colspan":"5"}},[_vm._v("Drought intensity classes")])]),_c('tr',_vm._l(([
            _vm.$gettext('Mild drought (km²)'),
            _vm.$gettext('Moderate drought (km²)'),
            _vm.$gettext('Severe drought (km²)'),
            _vm.$gettext('Extreme drought (km²)'),
            _vm.$gettext('Non-drought (km²)'),
          ]),function(column,index){return _c('th',{key:index,staticClass:"P4-cell"},[_vm._v(" "+_vm._s(column)+" ")])}),0)]},proxy:true},{key:"cells",fn:function({ rowId, rowIndex, value }){return [_c('th',{staticClass:"P4-cell"},[_vm._v(" "+_vm._s(value.year)+" ")]),_vm._l(([
          'mild_drought',
          'moderate_drought',
          'severe_drought',
          'extreme_drought',
          'non_drought',
        ]),function(column,index){return _c('td',{key:index,staticClass:"P4-cell-right"},[_c('P4Input',{attrs:{"id":rowId + '-' + column,"default-value-has-changed":_vm.defaultValueHasChanged(
            ['t1', rowIndex, column],
            value[column]
          ),"placeholder":"No data","numeric":""},model:{value:(value[column]),callback:function ($$v) {_vm.$set(value, column, $$v)},expression:"value[column]"}})],1)})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { gettextLazy as _ } from "../../../lib/translations.js";

export default {
  relevant_metric: {
    proportion_of_population: _(
      "Proportion of population below the international poverty line"
    ),
    income_inequality: _("Income inequality (Gini Index)"),
  },
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('FormulateInput',{attrs:{"type":"multitable","name":"t2","columns":[
      _vm.$gettext('Degradation Process'),
      _vm.$gettext('Starting Land Cover'),
      _vm.$gettext('Ending Land Cover'),
    ],"column-count":3,"label-for-new":_vm.$gettext('+ Add Record')},scopedSlots:_vm._u([{key:"cells",fn:function({ rowId, value }){return [_c('td',{staticClass:"P4-cell"},[_c('ul',[_c('li',[_c('P4Input',{attrs:{"id":rowId + '-degradation_process',"options":_vm.OPTIONS.T2.degradation},model:{value:(value.degradation_process),callback:function ($$v) {_vm.$set(value, "degradation_process", $$v)},expression:"value.degradation_process"}})],1),(value.degradation_process === 'other')?_c('li',[_c('P4Input',{attrs:{"id":rowId + '-other_degradation_process'},model:{value:(value['other_degradation_process']),callback:function ($$v) {_vm.$set(value, 'other_degradation_process', $$v)},expression:"value['other_degradation_process']"}})],1):_vm._e()])]),_c('td',{staticClass:"P4-cell"},[_c('ul',[_c('li',[_c('P4Input',{attrs:{"id":rowId + '-starting_land_cover',"options":Object.assign({}, _vm.UNCCD_CLASS_MAP, {
                  other: _vm.$gettext('Other'),
                })},model:{value:(value.starting_land_cover),callback:function ($$v) {_vm.$set(value, "starting_land_cover", $$v)},expression:"value.starting_land_cover"}})],1),(value.starting_land_cover === 'other')?_c('li',[_c('P4Input',{attrs:{"id":rowId + '-other_starting_land_cover'},model:{value:(value['other_starting_land_cover']),callback:function ($$v) {_vm.$set(value, 'other_starting_land_cover', $$v)},expression:"value['other_starting_land_cover']"}})],1):_vm._e()])]),_c('td',{staticClass:"P4-cell"},[_c('ul',[_c('li',[_c('P4Input',{attrs:{"id":rowId + '-ending_land_cover',"options":Object.assign({}, _vm.UNCCD_CLASS_MAP, {
                  other: _vm.$gettext('Other'),
                })},model:{value:(value.ending_land_cover),callback:function ($$v) {_vm.$set(value, "ending_land_cover", $$v)},expression:"value.ending_land_cover"}})],1),(value.ending_land_cover === 'other')?_c('li',[_c('P4Input',{attrs:{"id":rowId + '-other_ending_land_cover'},model:{value:(value['other_ending_land_cover']),callback:function ($$v) {_vm.$set(value, 'other_ending_land_cover', $$v)},expression:"value['other_ending_land_cover']"}})],1):_vm._e()])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
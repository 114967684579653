import { gettextLazy as _ } from "../../lib/translations.js";

export default {
  T1: {
    strategic: {
      so1: "SO1",
      so2: "SO2",
      so3: "SO3",
      so4: "SO4",
    },
    indicator: {
      increasing: _("Increasing"),
      no_change: _("No change"),
      decreasing: _("Decreasing"),
    },
  },
};
